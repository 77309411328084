import React from 'react';
import HtmlRenderer from '../HTMLRenderer';
import CustomModal from '../customModal';

interface HTMLContentModalProps {
    openModal: boolean;
    onClosingModal: Function;
    documentData: string;
}

const HTMLContentModal = ({
  openModal,
  onClosingModal,
  documentData,
}: HTMLContentModalProps) => {
  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => onClosingModal()}
      title={
        <div>
          <h1 className="font-semibold text-lg">Complete Document Title</h1>{" "}
        </div>
      }
      size="w-[100%] max-w-[1200px]"
    >
      <HtmlRenderer htmlString={`${documentData}`} />
    </CustomModal>
  );
};

export default HTMLContentModal;