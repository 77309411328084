// Node groups collection for Azure

export const azure_groups = [
  {
    name: "azure",
    style: {
      group_icon_bg_color: "#268FDD",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    icon: "azure-a",
    subdirectory: "other",
  },
  {
    name: "subscription",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    icon: "subscriptions",
    subdirectory: "general",
  },
  {
    name: "resource_group",
    style: {
      group_icon_bg_color: "#0f172a20",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    icon: "resource-groups",
    subdirectory: "general",
  },
  {
    name: "vNet",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3"
    },
    icon: "virtual-networks",
    subdirectory: "networking",
  },
  {
    name: "Subnet",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "dashed",
      group_bg_color: "#f3f3f3"
    },
    icon: "subnet",
    subdirectory: "networking",
  },
  {
    name: "AKS",
    style: {
      group_icon_bg_color: "#0f172a20",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    icon: "kubernetes-services",
    subdirectory: "containers",
  },
  {
    name: "ASP",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    icon: "app-service-plans",
    subdirectory: "web",
  },
  {
    name: "Azure DevOps",
    style: {
      group_icon_bg_color: "#0f172a",
      group_border_style: "solid",
      group_bg_color: "#f3f3f3",
    },
    icon: "azure-devops",
    subdirectory: "azure_devops",
  },
];
