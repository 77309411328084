import { createSlice } from "@reduxjs/toolkit";
import {
  getSwotList,
  createSwot,
  updateSwot,
  getSwotById,
  deleteSwot,
} from "./swotApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  swotsList: any[];
  createSwotRes: any;
  updateSwotRes: any;
  swotByIdRes: any;
  swotDeleteRes: any;
  loadingSwot: boolean;
  selectedSwot: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  swotsList: [],
  createSwotRes: null,
  updateSwotRes: null,
  swotByIdRes: null,
  swotDeleteRes: null,
  loadingSwot: false,
  selectedSwot: null,
};
const swotSlice = createSlice({
  name: "swot",
  initialState,
  reducers: {
    setSelectedSwot: (state, action) => {
      state.selectedSwot = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get SWOT
    builder.addCase(getSwotList.pending, (state) => {
      state.error = null;
      state.message = null;

      state.loadingSwot = true;
    });
    builder.addCase(getSwotList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.swotsList = action.payload?.data;

      state.loadingSwot = false;
    });
    builder.addCase(getSwotList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";

      state.loadingSwot = false;
    });

    // Get SWOT By Id
    builder.addCase(getSwotById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getSwotById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.swotByIdRes = action.payload?.data;
    });
    builder.addCase(getSwotById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create SWOT
    builder.addCase(createSwot.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createSwot.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createSwotRes = action.payload?.data;
    });
    builder.addCase(createSwot.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update SWOT
    builder.addCase(updateSwot.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateSwot.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateSwotRes = action.payload?.data;
    });
    builder.addCase(updateSwot.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete SWOT
    builder.addCase(deleteSwot.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteSwot.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.swotDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteSwot.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedSwot } = swotSlice.actions;
export default swotSlice.reducer;
