import React, { useId } from 'react';
import logoIcon from '../../assets/images/mascot.svg';

interface LoaderPageProps {
  page?: string;
}

const LoaderPage = ({ page }: LoaderPageProps) => {
    return (
        <div className={`flex flex-col justify-center items-center h-screen bg-white ${page === 'design-detail' ? 'h-[300px]' : ''}`}>
            <div className='text-center relative'>
                <BackgroundIllustration className='absolute inset-0 w-full h-full' />
                <img
                    className='h-[250px] mx-auto mb-5 relative z-10'
                    src={logoIcon}
                    alt='Solution Pilot'
                />
            </div>
        </div>
    );
};

function BackgroundIllustration(props: React.ComponentPropsWithoutRef<'div'>) {
    let id = useId();
  
    return (
      <div {...props}>
        <svg
          viewBox="0 0 4200 4200" // Keep the current viewBox size
          fill="none"
          aria-hidden="true"
          className="absolute inset-0 h-full w-full animate-spin-slow"
        >
          <path
            d="M4199 2100c0 1050-1050 2100-2100 2100S-1 3150-1 2100 1050-1 2100-1s2100 1050 2100 2100Z"
            stroke="#F2F2F2"
            strokeOpacity="1"
            strokeWidth="200" // Increase the strokeWidth for a larger appearance
          />
          <path
            d="M2100 4199C1050 4199-1 3150-1 2100"
            stroke={`url(#${id}-gradient-1)`}
            strokeLinecap="round"
            strokeWidth="20"
          />
          <defs>
            <linearGradient
              id={`${id}-gradient-1`}
              x1="-1"
              y1="2100"
              x2="-1"
              y2="4199"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#CBD8F6" />
              <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        <svg
          viewBox="0 0 2700 2700" // Keep the current viewBox size
          fill="none"
          aria-hidden="true"
          className="absolute inset-0 h-full w-full animate-spin-reverse-slow"
        >
          <path
            d="M2700 1350c0 900-900 1350-1350 1350S0 2250 0 1350s450-1350 1350-1350 1350 900 1350 1350Z"
            stroke="#F2F2F2"
            strokeOpacity="1"
            strokeWidth="20" // Increase the strokeWidth for a larger appearance
          />
          <path
            d="M2700 1350c0 900-900 1350-1350 1350"
            stroke={`url(#${id}-gradient-2)`}
            strokeLinecap="round"
            strokeWidth="20"
          />
          <defs>
            <linearGradient
              id={`${id}-gradient-2`}
              x1="2700"
              y1="1350"
              x2="2700"
              y2="2700"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#b060ff" />
              <stop offset="1" stopColor="#06b6d4" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    );
}

export default LoaderPage;
