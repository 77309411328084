import { Tooltip } from "react-tooltip";
import {
  CloudIcon,
} from "@heroicons/react/24/outline";
import { formatLatestDate, categoriesList } from "../../utils/utilities";

interface TemplateBoxProps {
  data: any;
  onShow: Function;
  onUse: Function;
  tempType?: string;
}

export default function TemplateBox({
  data,
  onShow,
  onUse,
  tempType,
}: TemplateBoxProps) {

  const SelectedIcon = categoriesList.find(
    (option) => option.title === data?.category
  )?.icon;
  return (
    <div
      onClick={() => onShow()}
      className="p-1 bg-gradient-to-r hover:from-blue-400 hover:via-purple-300 hover:to-purple-500 rounded-2xl transition duration-300 ease-in-out"
    >
      <div className="bg-white p-8 h-full rounded-xl border border-zinc-200 cursor-pointer">
        <div className="flex flex-1 flex-col">
          <div className="text-xs text-gray-700 mb-2">
            {formatLatestDate(
              tempType !== "my" ? data?.published_at : data?.updated_at
            )}
          </div>
          <div
            className="mt-2 text-base/7 font-medium line-clamp-1"
            title={data?.title}
          >
            <div className="text-sm">{data?.title}</div>
          </div>
          <div className="mb-4 mt-2 flex-1 text-sm/6 text-gray-500 line-clamp-3">
            {data?.description}
          </div>

          <div className="text-sm/5 text-gray-700">
            <span className="ml-auto capitalize w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
              {data?.complexity}
            </span>
          </div>

          <div className="flex items-center justify-between mt-6">
            <div className="flex items-center gap-3">
              <img
                alt=""
                src="https://cdn.sanity.io/images/ssqh4ksj/production/cd1ee59e9e4c2ff30c303de6c7d1066c057419d5-7952x5304.jpg?rect=2370,0,5304,5304&amp;w=64&amp;h=64&amp;auto=format"
                className="aspect-square size-6 rounded-full object-cover"
              />
              <div className="text-sm/5 text-gray-700">Marcus Eldridge</div>
            </div>

            <div data-tooltip-id={`${data.id}`}>
              {SelectedIcon && <CloudIcon className="h-5 text-zinc-700" />}
            </div>

            <Tooltip className="z-[50]" id={`${data.id}`} place="top">
              {data?.category}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
