function NodeLabel(props: {
  placeholder: string;
  fontSize: number;
  value: string;
  onChange: Function;
  textareaRef: React.RefObject<HTMLTextAreaElement>;
  textColor: string;
}) {
  return (
    <textarea
      ref={props.textareaRef}
      className="node-label cursor-text w-full capitalize bg-transparent border-none outline-none focus:outline-none focus:ring-0"
      placeholder={props.placeholder}
      value={props.value}
      rows={1}
      autoFocus={true}
      onChange={(e) => props.onChange(e.target.value)}
      style={{
        fontSize: `${props.fontSize}px`,
        lineHeight: `${props.fontSize * 1.5}px`,
        overflow: "hidden",
        resize: "none",
        minWidth: 50,
        maxWidth: "100%",
        color: props.textColor,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    />
  );
}

export default NodeLabel;
