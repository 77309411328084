import './style.css';

interface Props {
  htmlString: string;
}

const HtmlRenderer: React.FC<Props> = ({ htmlString }) => {
  return (
    <>
      <style>
        {`
          /* Styling for headings */
          h1, h2, h3, h4, h5 {
             margin-bottom: 20px;
          }
          h1 {
            font-size: 27.5px;
            color: #2f3659;
          }

          h2 {
            font-size: 22.8px;
            color: #2f3659;
          }

          h3 {
            font-size: 18.3px;
            color: #333a5c;
          }

          h4 {
            font-size: 16px;
            color: #333a5c;
          }

          /* Style for paragraphs */
          p {
            margin-bottom: 10px;
            color: #333a5c;
            line-height: 30px;
            margin-bottom: 20px;
          }

          /* Style for lists */
          ul,
          ol {
            margin-left: 20px;
          }

          /* Style for list items */
          li {
            margin-bottom: 5px;
          }

          /* Style for bold text */
          strong {
            font-weight: bold;
          }

          /* Style for italic text */
          em {
            font-style: italic;
          }

          /* Style for links */
          a {
            color: #0066cc;
            text-decoration: underline;
          }

          /* Style for blockquotes */
          blockquote {
            border-left: 2px solid #999;
            padding-left: 10px;
            margin-left: 10px;
          }

          /* Style for code */
          code {
            background-color: #f2f2f2;
            padding: 2px 4px;
            border: 1px solid #ddd;
            font-family: "Courier New", monospace;
          }

          /* Style for tables */
          table {
            border-collapse: collapse;
            width: 100%;
          }

          th,
          td {
            border: 1px solid #ddd;
            padding: 8px;
            text-align: left;
          }

          th {
            background-color: #f2f2f2;
          }
        `}
      </style>
      <div
        className="htmlRenderer"
        dangerouslySetInnerHTML={{ __html: htmlString }}
      />
    </>
  );
};

export default HtmlRenderer;
