import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoaderPage from "src/components/loaderPage";

export default function LoginPage() {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const getHeader = document.getElementById("main_header");
    const getSidebar = document.getElementById("main_side_bar");
    const getMainContent = document.getElementById("main_content");

    if (getHeader) {
      getHeader.style.display = "none";
    }

    if (getSidebar) {
      getSidebar.style.display = "none";
    }

    if (getMainContent) {
      getMainContent.style.padding = "0px";
      getMainContent.style.margin = "0px";
    }

    loginWithRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderPage />;
}
