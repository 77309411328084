import { ReactElement } from 'react';
import { useMatch } from 'react-router';
import ProjectDetailsProvider from './ProjectDetails.provider';
import TemplateDetailsProvider from './TemplateDetails.provider';

export function DetailsProvider({ children }: Readonly<{ children: ReactElement }>) {
  const projectDetailsPageMatch = useMatch('/projects/:id');
  const templateEditPageMatch = useMatch('/template/:id/edit');

  if (projectDetailsPageMatch) {
    return <ProjectDetailsProvider projectId={projectDetailsPageMatch.params.id!}>{children}</ProjectDetailsProvider>;
  }

  if (templateEditPageMatch) {
    return <TemplateDetailsProvider templateId={templateEditPageMatch.params.id!}>{children}</TemplateDetailsProvider>;
  }

  return children;
}
