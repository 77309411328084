import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

interface DiagramServicesListProps {
  options: any[];
  value: any;
  onSelect: Function;
}

const DiagramServicesList: React.FC<DiagramServicesListProps> = ({
  options,
  value,
  onSelect,
}) => {

  // Handle selection change
  const handleSelect = (e: any, data: any) => {
    e.stopPropagation();
    onSelect(data);
  };

  return (
    <Menu as="div" className={`relative inline-block text-left w-full`}>
      <div>
        <MenuButton
          className={`inline-flex w-full justify-between items-center gap-1 rounded-md bg-white px-3 py-2 text-xs text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50`}
        >
          <div className="flex items-center px-1">
            <img
              src={value.icon}
              alt={value.name}
              className="h-[18px] mr-3"
            />
            <span>{value?.name}</span>
          </div>
          {/* Show selected value */}
          <ChevronDownIcon
            aria-hidden="true"
            className="h-5 w-5 text-gray-400"
          />
        </MenuButton>
      </div>

      <MenuItems className="absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        {options.map((data, index) => (
          <MenuItem key={index}>
            {({ active }) => (
              <button
                onClick={(e) => handleSelect(e, data)}
                className={`${
                  active ? "bg-gray-100 text-gray-600" : "text-gray-500"
                } group flex items-center gap-2 px-4 py-2 text-xs w-full text-left`}
              >
                {data.icon && (
                  <img src={data.icon} alt={data.name} className="h-[18px]" />
                )}
                {data.name}
              </button>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};

export default DiagramServicesList;
