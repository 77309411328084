import {
  ArrowTopRightOnSquareIcon,
  PencilIcon,
  TrashIcon,
  SquaresPlusIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  // ChevronDownIcon,
  // ChevronUpIcon,
  // RectangleStackIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState, useCallback, useMemo } from "react";
import {
  setOpenNodeSetting,
  setSelectedNodeSetting,
} from "../../../redux/diagrams/diagramsSlice";

import { Tooltip } from "react-tooltip";
import { debounce } from "lodash";
import { getRelativeNodesBounds } from "./utils";
import { useAppDispatch } from "../../../hooks";
import useDetachNodes from "./useDetachNodes";
import Popover from "../../../components/popover";
import NodeIconsDropdown from "./NodeIconsDropdown";

const lineStyle = { borderColor: "white" };

const SimpleNode = memo(({ id, data, width, style }: any) => {
  const [isEditText, setIsEditText] = useState(false);
  const [nodeTitle, setNodeTitle] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const parentId = useStore((store) => store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes, getNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const dispatch = useAppDispatch();
  const nodesList = getNodes();

  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const nodeRef = useRef<any>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const fontSize = useMemo(() => {
    const baseSize = 14; // Default font size
    return Math.max(baseSize, Math.min(width) / 16); // Adjust font size
  }, [width]);

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  // Get Parent Object
  const parentObject = useMemo(
    () => nodesList.find((n) => n.id === parentId),
    [nodesList, parentId]
  );

  useEffect(() => {
    if (parentObject?.style?.zIndex) {
      setNodes((nds) => {
        // Find the maximum zIndex of nodes with the same parent
        const maxSiblingZIndex = nds
          .filter((n) => n.parentId === parentId)
          .reduce((max, n) => Math.max(max, Number(n.style?.zIndex || 0)), 0);

        return nds.map((n) =>
          n.id === id
            ? {
                ...n,
                style: {
                  ...n.style,
                  zIndex: maxSiblingZIndex + 1, // Set zIndex to max sibling zIndex + 1
                },
              }
            : n
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = useCallback(
    () => deleteElements({ nodes: [{ id }] }),
    [deleteElements, id]
  );
  const onDetach = useCallback(() => detachNodes([id]), [detachNodes, id]);

  const onOpenNodeEditSetting = useCallback(() => {
    dispatch(setSelectedNodeSetting({ id, data }));
    dispatch(setOpenNodeSetting(true));
  }, [dispatch, id, data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      // Resize logic
    }, 100),
    []
  );

  const onUpdateNodes = useCallback(() => {
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? { ...node, data: { ...node.data, label: nodeTitle || data?.label } }
          : node
      )
    );
    setNodeTitle("");
  }, [setNodes, id, nodeTitle, data]);

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [handleResize, id, onUpdateNodes]);

  const onEditNodeText = useCallback(() => {
    setNodeTitle(data?.label);
    setIsEditText((prev) => !prev);
  }, [data]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        onUpdateNodes();
        setIsEditText(false);
        event.preventDefault();
      }
    },
    [onUpdateNodes]
  );

  const onChangeNodeTitle = useCallback((e: any) => {
    setNodeTitle(e.target.value);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        textareaRef.current &&
        !textareaRef.current.contains(event.target as Node)
      ) {
        onUpdateNodes();
        setIsEditText(false);
        setNodeTitle("");
      }
    },
    [onUpdateNodes]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const onChangeNodeIcon = useCallback(
    (url: string, name: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, icon_url: url, label: name },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        // if (index === -1 || (absolute && index === nds.length - 1)) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        if (absolute) {
          node.style = {
            ...node.style,
            zIndex: maxZIndex + 1000,
          };
        } else {
          node.style = {
            ...node.style,
            zIndex: greaterThenSelectedNode + 300,
          };
        }

        return newNodes;
      });
      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        // Determine the new zIndex
        let newZIndex = absolute
          ? minZIndex - 300
          : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = nds.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const handleNodeMouseLeave = useCallback(() => {
    if (nodeTitle) {
      onUpdateNodes();
      setIsEditText(false);
      setNodeTitle("");
    }
  }, [onUpdateNodes, nodeTitle]);

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  return (
    <div
      // onMouseEnter={handleNodeMouseEnter}
      onMouseLeave={handleNodeMouseLeave}
      style={{ zIndex: Number(style?.zIndex ?? 0) + 1 }}
      className="relative px-4 py-2 shadow-xl rounded-xl bg-white h-[100%]"
    >
      <div className="text-center">
        <img
          src={data?.icon_url}
          alt="icon"
          className="h-auto w-[70%] mx-auto mb-3"
        />
        <div
          className="font-semibold cursor-text h-full"
          ref={nodeRef}
          style={{ fontSize: `${fontSize}px` }}
        >
          {isEditText ? (
            <textarea
              ref={textareaRef}
              autoFocus
              value={nodeTitle || data?.label}
              onChange={onChangeNodeTitle}
              onKeyDown={handleKeyDown}
              className="p-0 pl-1 border-0 bg-white shadow-sm text-xs font-semibold text-center resize-none h-full w-full"
              style={{ boxShadow: "none", minHeight: 40 }}
              draggable={false}
            />
          ) : (
            <span onClick={onEditNodeText}>{data?.label}</span>
          )}
        </div>
      </div>

      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true} // Always show the resizer handles
        handleStyle={{
          width: "12px", // Resizer handle size for easy grabbing
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag nowheel absolute p-[4px] top-0 left-0 bg-white border border-[#e0e0e0] flex items-center justify-center"
      >
        <div className="p-[4px]">
          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <SquaresPlusIcon className={`${iconStyle}`} />
              </div>
            }
            popoverContent={
              <NodeIconsDropdown
                onChangeNodeIcon={onChangeNodeIcon}
                nodeIcon={data?.icon_url}
              />
            }
            classes="w-[400px] bottom-[40px] left-[-110px]"
          />
        </div>

        {nodesList?.length > 1 && (
          <div className="flex items-center justify-center gap-2">
            {Number(nodeObject?.style?.zIndex) < findMaxZIndex(nodesList) && (
              <>
                {/* <div
                className={`${iconWrapper} order-1`}
                onClick={() => bringNodeToFront(id)}
                data-tooltip-id="move-up-node-tooltip"
              >
                <ChevronUpIcon className={`${iconStyle}`} />
              </div> */}

                <div
                  className={`${iconWrapper} order-3`}
                  onClick={() => bringNodeToFront(id, true)}
                  data-tooltip-id="move-absolute-top-node-tooltip"
                >
                  <ChevronDoubleUpIcon className={`${iconStyle}`} />
                </div>
              </>
            )}

            {Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList) && (
              <>
                {/* <div
                className={`${iconWrapper} order-2`}
                onClick={() => sendNodeToBack(id)}
                data-tooltip-id="move-down-node-tooltip"
              >
                <ChevronDownIcon className={`${iconStyle}`} />
              </div> */}

                <div
                  className={`${iconWrapper} order-4`}
                  onClick={() => sendNodeToBack(id, true)}
                  data-tooltip-id="move-absolute-bottom-node-tooltip"
                >
                  <ChevronDoubleDownIcon className={`${iconStyle}`} />
                </div>
              </>
            )}
          </div>
        )}

        <div className={`${iconWrapper}`} data-tooltip-id="delete-node-tooltip">
          <TrashIcon onClick={onDelete} className={`${iconStyle}`} />
        </div>

        <div className={`${iconWrapper}`} data-tooltip-id="edit-node-tooltip">
          <PencilIcon
            onClick={onOpenNodeEditSetting}
            className={`${iconStyle}`}
          />
        </div>

        {hasParent && (
          <div
            className={`${iconWrapper}`}
            data-tooltip-id="detach-node-tooltip"
          >
            <ArrowTopRightOnSquareIcon
              onClick={onDetach}
              className={`${iconStyle}`}
            />
          </div>
        )}
      </NodeToolbar>

      <Tooltip className="z-50" id="move-up-node-tooltip" place="top">
        Move Up
      </Tooltip>

      <Tooltip className="z-50" id="move-absolute-top-node-tooltip" place="top">
        Move to Absolute Top
      </Tooltip>

      <Tooltip className="z-50" id="move-down-node-tooltip" place="top">
        Move Down
      </Tooltip>

      <Tooltip
        className="z-50"
        id="move-absolute-bottom-node-tooltip"
        place="top"
      >
        Move to Absolute Bottom
      </Tooltip>

      <Tooltip className="z-50" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-50" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-50" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>

      <Tooltip className="z-50" id="move-up-down-tooltip" place="top">
        Move Up/Down
      </Tooltip>
    </div>
  );
});

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default SimpleNode;
