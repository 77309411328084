import { useEffect } from 'react';
import { useAppDispatch } from 'src/hooks';
import { setCurrentPage } from 'src/redux/profile/profileSlice';

function ComponentRender({ routeKey, Component }: any) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentPage(routeKey));
  }, [dispatch, routeKey]);

  return <Component />;
}

export default ComponentRender;
