import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../utils/api";

export const getTbarArgumentsList = createAsyncThunk(
  "tbarArguments/get",
  async ({ accessToken, option_id, tbar_id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/tbar/arguments?tbar_id=${tbar_id}&option_id=${option_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createTbarArgument = createAsyncThunk(
  "create/TBarArgument",
  async ({ body, accessToken, tbar_id, option_id, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/tbar/argument?tbar_id=${tbar_id}&option_id=${option_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateTbarArgument = createAsyncThunk(
  "updateArgument/Tbar",
  async ({
    body,
    accessToken,
    argument_id,
    option_id,
    tbar_id,
    project_id,
  }: any) => {
    try {
      const response = await putRequest({
        url: `/tbar/argument?tbar_id=${tbar_id}&option_id=${option_id}&argument_id=${argument_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteTbarArgument = createAsyncThunk(
  "deleteArgument/Tbar",
  async ({ accessToken, argument_id, tbar_id, option_id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/tbar/argument?tbar_id=${tbar_id}&option_id=${option_id}&argument_id=${argument_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
