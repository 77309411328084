import React from "react";
import AiAssistantsData from "../aiAssistants/aiAssistantsData";
import TemplatesData from "../templates/templatesData";
import { useAppSelector } from "../../hooks";

const CommunityPage = () => {
  const { communityPageTab } = useAppSelector(
    (state) => state.userAiAssistants
  );

  return (
    <div>
      {communityPageTab === 1 && (
        <TemplatesData isModal={false} tempType="community" />
      )}
      {communityPageTab === 2 && (
        <AiAssistantsData isModal={false} tempType="community" />
      )}
    </div>
  );
};

export default CommunityPage;
