import React, { useEffect, useState } from "react";
import CustomButton from "src/components/customButton";
import InputField from "../../../../../components/formFields/inputField";
import SelectField from "src/components/formFields/selectField";

const diagramsTypesList = [
  { value: "Network", icon: "" },
  { value: "Security", icon: "" },
  { value: "Monitoring", icon: "" },
  { value: "Identity and Access", icon: "" },
  { value: "Logical", icon: "" },
];

interface DiagramProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
}

export default function Diagram({
  onCloseModal,
  loading,
  onSubmitData,
  editData,
}: DiagramProps) {
  const [title, setTitle] = useState("");
  const [isTitle, setIsTitle] = useState(false);
  const [diagramType, setDiagramType] = useState("Network");

  useEffect(() => {
    if (editData) {
      if (editData?.title) setTitle(editData?.title);
      if (editData?.diagram_type) setDiagramType(editData?.diagram_type);
    }
  }, [editData]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    const payload = {
      title: title,
      diagram_type: diagramType,
    };

    if (title) {
      onSubmitData(payload);
    } else {
      setIsTitle(true);
    }
  };

  const onChangeTitle = (value: string) => {
    setTitle(value);
    setIsTitle(false);
  };

  return (
    <form className="flex h-full flex-col" onSubmit={onSubmitForm}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Title
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="title"
            error={isTitle}
            name="title"
            value={title}
            onChange={(e) => onChangeTitle(e.target.value)}
          />
        </div>
      </div>

      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Diagram Type
          </label>
        </div>
        <div className="sm:col-span-2">
          <SelectField
            options={diagramsTypesList}
            value={diagramType}
            onSelect={setDiagramType}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <CustomButton
          text="Cancel"
          type="button"
          onClickBtn={onCloseModal}
          buttonType="secondary"
          btnStyle="mr-2"
        />
        <CustomButton
          text="Save"
          type="submit"
          onClickBtn={() => console.log()}
          loading={loading}
          buttonType="primary"
        />
      </div>
    </form>
  );
}
