import { ComponentProps, ReactNode, forwardRef } from 'react';

import { classNames } from 'src/utils/utilities';

type Props<E extends keyof JSX.IntrinsicElements, P extends Record<string, unknown> = {}> = Readonly<
  P & Omit<ComponentProps<E>, keyof P>
>;

export const NavButton = forwardRef<HTMLButtonElement, Props<'button'>>(
  ({ className, children, ...props }, forwardedRef) => {
    return (
      <button
        ref={forwardedRef}
        type='button'
        className={classNames(
          'py-1 px-3 rounded-md border-[1px]',
          'transition-colors enabled:hover:bg-blue-50 enabled:hover:border-blue-400',
          'disabled:opacity-60 disabled:cursor-not-allowed',
          className
        )}
        {...props}
      >
        {children}
      </button>
    );
  }
);

export function Kbd({ className = '', children, optional = false, ...props }: Props<'kbd', { optional?: boolean }>) {
  return (
    <kbd
      className={classNames(
        'mx-1 min-w-5 h-5 px-1 rounded-[0.25rem] border-[1px] border-gray-400',
        'flex items-center justify-center bg-white font-semibold text-gray-900',
        optional && 'opacity-50',
        className
      )}
      {...props}
    >
      {children}
    </kbd>
  );
}

export const DocumentPopover = forwardRef<HTMLDivElement, Props<'div'>>(
  ({ className, children, ...props }, forwardedRef) => {
    return (
      <div
        ref={forwardedRef}
        className={classNames('max-w-[28rem] w-full flex flex-col rounded-lg border-[1px] z-50 shadow-lg', className)}
        {...props}
      >
        {children}
      </div>
    );
  }
);

function DocumentPopoverHeader({
  title,
  className,
  children,
  ...props
}: Props<'div', { title: ReactNode; }>) {
  return (
    <div
      className={classNames(
        className,
        'py-2 px-4 flex items-center justify-between bg-gray-50 rounded-t-lg border-b-[1px]',
      )}
      {...props}
    >
      <p className='text-sm font-medium text-gray-900'>{title}</p>
      <div className='flex items-center gap-2'>{children}</div>
    </div>
  );
};

function DocumentPopoverBody({ className, children, ...props }: Props<'div'>) {
  return (
    <div
      className={classNames('p-4 bg-white flex flex-col gap-4', className)}
      {...props}
    >
      {children}
    </div>
  );
};

function DocumentPopoverFooter({ className, children, ...props }: Props<'div'>) {
  return (
    <div
      className={classNames(
        'basis-full px-4 py-[0.625rem] border-t-[1px] rounded-b-lg bg-gray-50',
        'flex flex-wrap items-center text-xs text-gray-700',
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Object.assign(DocumentPopover, {
  Header: DocumentPopoverHeader,
  Body: DocumentPopoverBody,
  Footer: DocumentPopoverFooter,
  Kbd: Kbd,
  Button: NavButton,
});
