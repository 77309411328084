import React, { ReactNode, useState } from "react";

interface TooltipProps {
  text?: any;
  position: "top" | "bottom" | "left" | "right";
  children: ReactNode;
}

const Tooltip = ({ text, position, children }: TooltipProps) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="relative"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <div
        data-tooltip-target={`tooltip-${position}`}
        data-tooltip-placement={position}
      >
        {children}
      </div>

      {(show && text) && (
        <div
          id={`tooltip-${position}`}
          role="tooltip"
          className={`absolute ${
            (position === "bottom" && "-bottom-[20px] -left-[40px]") ||
            (position === "top" && "bottom-[28px] left-[0px]") ||
            (position === "left" && "-bottom-[35%] -left-[40px]") ||
            (position === "right" && "top-[5px] left-[100%]") ||
            ""
          } z-10 px-3 py-2 text-xs font-normal text-white bg-gray-800 rounded-lg shadow-sm dark:bg-gray-700`}
        >
          {text}
          <div className="tooltip-arrow" data-popper-arrow></div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;