function RawText({
  content,
  className,
  style,
  ...props
}: Readonly<{ content?: string; className?: string; style?: React.CSSProperties }>) {

  if (!content) return null;

  return (
    <p
      className={className}
      style={style}
      {...props}
    >
      {content.split('\n').map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))}
    </p>
  );
}

export default RawText;
