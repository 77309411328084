import { createSlice } from "@reduxjs/toolkit";
import {
  getUserAllAiAssistants,
  getUserAiAssistant,
  getPublicAiAssistant,
  createUserAiAssistant,
  updateUserAiAssistant,
  deleteUserAiAssistant,
  publishUserAiAssistant,
  unPublishUserAiAssistant
} from "./userAiAssistantsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  getUserAllAiAssistantsRes: any[];
  getUserAiAssistantRes: any;
  getPublicAiAssistantRes: any;
  createUserAiAssistantRes: any;
  updateUserAiAssistantRes: any;
  deleteUserAiAssistantRes: any;
  publishUserAiAssistantRes: any;
  unPublishUserAiAssistantRes: any;
  currentAiAssistantTab: any;
  communityPageTab: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  getUserAllAiAssistantsRes: [],
  getUserAiAssistantRes: null,
  createUserAiAssistantRes: null,
  getPublicAiAssistantRes: null,
  updateUserAiAssistantRes: null,
  deleteUserAiAssistantRes: null,
  publishUserAiAssistantRes: null,
  unPublishUserAiAssistantRes: null,
  currentAiAssistantTab: 1,
  communityPageTab: 1
};
const userAiAssistantSlice = createSlice({
  name: "userAiAssistantSlice",
  initialState,
  reducers: {
    setCurrentAiAssistantTab: (state, action) => {
      state.currentAiAssistantTab = action.payload;
    },
    setCommunityPageTabs: (state, action) => {
      state.communityPageTab = action.payload;
    }
  },
  extraReducers: (builder) => {
    // Get User All Ai Assistants
    builder.addCase(getUserAllAiAssistants.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getUserAllAiAssistants.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getUserAllAiAssistantsRes = action?.payload?.data;
    });
    builder.addCase(getUserAllAiAssistants.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get User Single Ai Assistant
    builder.addCase(getUserAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getUserAiAssistant.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getUserAiAssistantRes = action.payload?.data;
    });
    builder.addCase(getUserAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Public Single Ai Assistant
    builder.addCase(getPublicAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPublicAiAssistant.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload;

      state.getPublicAiAssistantRes = action.payload?.data;
    });
    builder.addCase(getPublicAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create User Ai Assistant
    builder.addCase(createUserAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createUserAiAssistant.fulfilled, (state, action) => {
      const previousData = [...(state.getUserAllAiAssistantsRes || [])];
      state.getUserAllAiAssistantsRes = [...previousData, action.payload?.data];

      state.error = null;
      state.message = action.payload?.message;
      state.createUserAiAssistantRes = action.payload?.data;
    });
    builder.addCase(createUserAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update User Ai Assistant
    builder.addCase(updateUserAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateUserAiAssistant.fulfilled, (state, action) => {
      const previousData = [...state.getUserAllAiAssistantsRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousData[previousIndex] = action.payload.data;
        state.getUserAllAiAssistantsRes = previousData;
      }

      // AI Assistant Detail
      state.getUserAiAssistantRes = action.payload.data;

      state.error = null;
      state.message = action.payload?.message;
      state.updateUserAiAssistantRes = action.payload.data;
    });
    builder.addCase(updateUserAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete User Ai Assistant
    builder.addCase(deleteUserAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteUserAiAssistant.fulfilled, (state, action) => {
      const filterData = state.getUserAllAiAssistantsRes?.filter(
        (item: { id: string }) => item?.id !== action.payload?.data?.id
      );
      state.getUserAllAiAssistantsRes = filterData;

      state.error = null;
      state.message = action.payload?.message;
      state.deleteUserAiAssistantRes = action.payload?.data;
    });
    builder.addCase(deleteUserAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Publish User Ai Assistant
    builder.addCase(publishUserAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(publishUserAiAssistant.fulfilled, (state, action) => {
      const previousData = [...state.getUserAllAiAssistantsRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );

      if (previousIndex !== -1) {
        previousData[previousIndex].privacy = false;
        state.getUserAllAiAssistantsRes = previousData;
      }

      // AI Assistant Detail
      state.getUserAiAssistantRes = {
        ...state.getUserAiAssistantRes,
        privacy: false,
      };

      state.error = null;
      state.message = action.payload?.message;
      state.publishUserAiAssistantRes = action.payload.data;
    });
    builder.addCase(publishUserAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // UnPublish User Ai Assistant
    builder.addCase(unPublishUserAiAssistant.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(unPublishUserAiAssistant.fulfilled, (state, action) => {
      const previousData = [...state.getUserAllAiAssistantsRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousData[previousIndex].privacy = true;
        state.getUserAllAiAssistantsRes = previousData;
      }

      // AI Assistant Detail
      state.getUserAiAssistantRes = {
        ...state.getUserAiAssistantRes,
        privacy: true,
      };

      state.error = null;
      state.message = action.payload?.message;
      state.unPublishUserAiAssistantRes = action.payload.data;
    });
    builder.addCase(unPublishUserAiAssistant.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setCurrentAiAssistantTab, setCommunityPageTabs } = userAiAssistantSlice.actions;
export default userAiAssistantSlice.reducer;
