import React, { useEffect, useState, useMemo, useCallback } from "react";
import { DragEvent } from "react";
import { iconsList, getNodeGroupsList } from "./utils";
import { Tooltip } from "react-tooltip";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import IconsCategoryTabs from "../iconsCategoryTabs";
import SearchField from "src/components/searchField";
import NodeListBox from "./nodeListBox";
import SidebarItem from "../flow/shapeNode/sidebarList";
import { ShapeComponents, ShapeType } from "../flow/shapeNode/shape/types";
import Azure from "../flow/assets/vendor_icons/azure.svg";

const onDragStart = (
  event: DragEvent,
  nodeType: string,
  iconUrl: string,
  name: string,
  style?: string
) => {
  event.dataTransfer.setData("application/reactflow", nodeType);
  event.dataTransfer.setData("iconUrl/reactflow", iconUrl);
  event.dataTransfer.setData("name/reactflow", name);

  if (nodeType === "group") {
    event.dataTransfer.setData("style/reactflow", style || "");
  }

  event.dataTransfer.effectAllowed = "move";
};

const Sidebar = () => {
  const [search, setSearch] = useState("");
  const [iconsCompleteList, setIconsCompleteList] = useState([]);
  const [iconsNonfilterList, setIconsNonfilterList] = useState([]);
  const [nodeGroupsList, setNodeGroupsList] = useState([]);
  const [currentTab, setCurrentTab] = useState<any>({
    id: 1,
    name: "Azure",
    icon: Azure,
  });
  const [isCollapse, setIsCollapse] = useState(false);
  const [isShapeCollapse, setIsShapeCollapse] = useState(false);

  const currentTabKey = useMemo(() => {
    switch (currentTab?.id) {
      case 1:
        return "azure";
      case 2:
        return "aws";
      case 3:
        return "gcp";
      case 4:
        return "kubernetes";
      case 5:
        return "devops";
      case 6:
        return "software";
      case 7:
        return "vendor";
      case 8:
        return "misc";
      default:
        return "";
    }
  }, [currentTab]);

  useEffect(() => {
    const iconsData: any = iconsList(currentTabKey);
    const groupsList: any = getNodeGroupsList(currentTabKey);

    setIconsCompleteList(iconsData);
    setIconsNonfilterList(iconsData);

    // Groups List
    setNodeGroupsList(groupsList);
  }, [currentTabKey]);

  const filteredIcons = useMemo(() => {
    if (search.length === 0) return iconsNonfilterList;

    return iconsCompleteList
      .map((category: any) => ({
        ...category,
        data: category.data.filter((item: any) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        ),
      }))
      .filter((category: any) => category.data.length > 0);
  }, [search, iconsCompleteList, iconsNonfilterList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterIconsByName = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const onChangeTab = (tab: any) => {
    setCurrentTab(tab);
    setSearch("");
  };

  return (
    <aside className="w-[220px] border-r border-zinc-200">
      <div className="py-2 border-b border-zinc-200 px-2">
        <IconsCategoryTabs
          setCurrentTab={onChangeTab}
          currentTab={currentTab}
        />
      </div>

      <div className="py-2 border-b border-zinc-200 px-2">
        <SearchField onSearchField={filterIconsByName} searchValue={search} />
      </div>

      <div className="px-3 inline-block w-full">
        <div className="border-b border-zinc-200 py-4">
          <h3
            className="flex items-center justify-between text-zinc-500 font-semibold text-xs cursor-pointer ml-2 w-full"
            onClick={() => setIsCollapse(!isCollapse)}
          >
            <div className="flex items-center">
              <img
                src="https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg"
                alt="Dynamic Group"
                className="mr-2 w-[17px]"
              />
              <span>Dynamic Group</span>
            </div>

            <ChevronRightIcon
              className={`h-4 mr-2 transition-all text-zinc-400`}
              style={{ rotate: isCollapse ? "0deg" : "90deg" }}
            />
          </h3>
          <div
            className={`${isCollapse ? "opacity-0 h-0 overflow-hidden" : "opacity-100 h-[100%] mt-1"} flex items-center justify-start flex-wrap transition-all`}
          >
            {nodeGroupsList.length > 0 &&
              nodeGroupsList.map((item: any) => (
                <div
                  key={item.id}
                  className="cursor-move mx-1 mb-1 bg-white shadow-lg rounded-md"
                  onDragStart={(event: DragEvent) =>
                    onDragStart(
                      event,
                      "group",
                      item?.icon,
                      item?.name,
                      JSON.stringify(item?.style)
                    )
                  }
                  draggable
                >
                  <div data-tooltip-id={`group-tooltip-${item.id}`}>
                    <div className="px-[5px] py-[8px]">
                      <img
                        src={item.icon}
                        alt="iconsList"
                        className="h-[29px]"
                      />
                    </div>
                  </div>

                  <Tooltip
                    className="z-[50]"
                    id={`group-tooltip-${item.id}`}
                    place="top"
                  >
                    {item.name}
                  </Tooltip>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="px-3 inline-block w-full">
        <div className="border-b border-zinc-200 py-4 mb-4">
          <h3
            className="flex items-center justify-between text-zinc-500 font-semibold text-xs cursor-pointer ml-2 w-full"
            onClick={() => setIsShapeCollapse(!isShapeCollapse)}
          >
            <div className="flex items-center">
              <img
                src="https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg"
                alt="Shapes"
                className="mr-2 w-[17px]"
              />
              <span>Shapes</span>
            </div>

            <ChevronRightIcon
              className={`h-4 mr-2 transition-all text-zinc-400`}
              style={{ rotate: isCollapse ? "0deg" : "90deg" }}
            />
          </h3>
          <div
            className={`${isShapeCollapse ? "opacity-0 h-0 overflow-hidden" : "opacity-100 h-[100%] mt-1"} flex items-center justify-start flex-wrap transition-all`}
          >
            {Object.keys(ShapeComponents).map((type) => (
              <SidebarItem
                type={type as ShapeType}
                nodeType="shape"
                key={type}
              />
            ))}

            <div
              className="cursor-move mx-1 mb-1 bg-white shadow-lg rounded-md"
              onDragStart={(event: DragEvent) =>
                onDragStart(event, "text_node", "", "Click & type here...")
              }
              draggable
            >
              <div data-tooltip-id={`node-text-tooltip`}>
                <div className="px-[5px] py-[8px]">
                  <img
                    src="https://icons.veryicon.com/png/o/commerce-shopping/online-retailers/text-38.png"
                    alt="text_node"
                    className="h-[29px]"
                  />
                </div>
              </div>

              <Tooltip className="z-[50]" id={`node-text-tooltip`} place="top">
                Text Node
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="h-[calc(100vh_-_510px)] overflow-hidden mb-3 px-3 hover:overflow-y-auto">
        {filteredIcons.length > 0 &&
          filteredIcons.map((iconDataList: any, index: number) => (
            <NodeListBox
              key={index}
              itemIndex={index}
              iconDataList={iconDataList}
              onDragStart={onDragStart}
              search={search}
            />
          ))}
      </div>
    </aside>
  );
};

export default React.memo(Sidebar);
