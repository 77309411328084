import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react"; // Replace with your actual import
import { jwtDecode } from "jwt-decode";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setUserTokenInfo } from "src/redux/profile/profileSlice";

// Update the context type to match the object structure
interface AccessTokenContextType {
  accessToken: string;
  userTokenInfo: any;
}

const AccessTokenContext = createContext<AccessTokenContextType>({
  accessToken: "",
  userTokenInfo: null,
});

export const AccessTokenProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [accessToken, setAccessToken] = useState("");

  const { userTokenInfo } = useAppSelector((state) => state.profile);
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchAccessToken = async () => {
      const token = await getAccessTokenSilently();
      const idTokenClaims = await getIdTokenClaims();
      const idToken = idTokenClaims?.__raw; // This is the raw JWT (ID Token)
      setAccessToken(token);

      if (idToken) {
        const decodedToken = jwtDecode(idToken);
        if (decodedToken) {
          const tokenWithMeta = {
            ...decodedToken,
            app_meta: (decodedToken as any)[
              "https://solutionpilot.ai/app_metadata"
            ],
            user_metadata: (decodedToken as any)[
              "https://solutionpilot.ai/user_metadata"
            ],
          };
          dispatch(setUserTokenInfo(tokenWithMeta));
        }
      }
    };

    fetchAccessToken();
  }, [getAccessTokenSilently, dispatch, getIdTokenClaims]);

  const contextValue = {
    accessToken,
    userTokenInfo,
  };
  return (
    <AccessTokenContext.Provider value={contextValue}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessToken = () => {
  return useContext(AccessTokenContext);
};
