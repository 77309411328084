export const showMessage = (message: string, type: string) => {
  const messageContainer = document.createElement("div");
  messageContainer.className = `fixed z-[300] top-0 right-0 m-4 p-4 bg-white border rounded border-zinc-300 flex items-center shadow-md`;

  const icon = document.createElement("div");
  icon.className = `w-5 h-5 mr-2 ${
    type === "success" ? "text-green-500" : "text-red-500"
  }`;

  if (type === "success") {
    icon.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M0 10a10 10 0 1 1 20 0 10 10 0 0 1-20 0zm18.95-.36a1 1 0 0 1 0 1.41l-7 7a1 1 0 0 1-1.41 0l-3-3a1 1 0 1 1 1.41-1.41L10 14.17l6.54-6.54a1 1 0 0 1 1.41 0z"/></svg>';
  } else if (type === "error") {
    icon.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1.73-12.95a1 1 0 0 0-1.41 0l-3 3a1 1 0 1 0 1.41 1.41L9 7.41V15a1 1 0 1 0 2 0V7.41l2.29 2.3a1 1 0 0 0 1.41-1.41l-3-3z"/></svg>';
  }

  const messageText = document.createElement("div");
  messageText.innerText = message;

  const closeButton = document.createElement("button");
  closeButton.className =
    "ml-auto text-customLightBlue hover:text-gray-700 focus:outline-none";
  closeButton.innerHTML = "&times;";
  closeButton.addEventListener("click", () =>
    document.body.removeChild(messageContainer)
  );

  messageContainer.appendChild(icon);
  messageContainer.appendChild(messageText);
  messageContainer.appendChild(closeButton);

  document.body.appendChild(messageContainer);

  setTimeout(() => {
    document.body.removeChild(messageContainer);
  }, 5000); // Auto-hide after 5 seconds
};