import { useRef, useCallback } from 'react';

export function useDialogAutoFocus() {
  const elementRef = useRef<HTMLDivElement | null>(null);

  const refCallback = useCallback((el: HTMLDivElement | null) => {
    if (!elementRef.current) {
      const autoFocusElement = el?.querySelector('[data-auto-focus]');

      if (autoFocusElement instanceof HTMLElement) {
        setTimeout(() => {
          autoFocusElement.focus();
        }, 0);
      }
    }

    elementRef.current = el;
  }, []);

  return {
    setRef: refCallback,
  };
}
