import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../utils/api";

export const getSwotList = createAsyncThunk(
  "Swot/get",
  async ({ accessToken, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/swots?project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getSwotById = createAsyncThunk(
  "SwotById/get",
  async ({ accessToken, id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/swot?swot_id=${id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createSwot = createAsyncThunk(
  "Swot/Create",
  async ({ body, accessToken, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/swot?project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateSwot = createAsyncThunk(
  "Swot/Update",
  async ({ body, accessToken, id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/swot?swot_id=${id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteSwot = createAsyncThunk(
  "Swot/Delete",
  async ({ accessToken, id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/swot?swot_id=${id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
