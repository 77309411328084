import { MutableRefObject, RefObject, memo, useContext, useEffect, useMemo, useState } from 'react';

import MarkdownRenderer from 'src/components/formatter/markdown';
import { OptionName } from 'src/components/advancedCopyAndPaste';
import {
  ArrowUpIcon as AddToTopIcon,
  ArrowDownIcon as AddToBottomIcon,
  CursorArrowRippleIcon as InsertAtCursorIcon,
  ClipboardIcon as CopyIcon,
  DocumentPlusIcon as CreateDocumentIcon,
} from '@heroicons/react/24/outline';
import { DetailsContext } from 'src/contexts/details/context';

import CustomModal from 'src/components/customModal';
import DocumentForm from '../../documents/documentForm';
import { useNavigate } from 'react-router-dom';
import { copyToClipboard } from 'src/utils/utilities';
import { useSaveCurrentWork } from 'src/hooks/useSaveCurrentWork';

const NewDocumentModal = Object.assign(
  memo<{
    data: string | null;
    onCloseModal: () => void;
  }>(({ data, onCloseModal }) => {
    const [formLoader, setFormLoader] = useState(false);
    const context = useContext(DetailsContext);

    const navigate = useNavigate();
    const saveCurrentWork = useSaveCurrentWork();

    const creatingDocument = async (body: any) => {
      setFormLoader(true);

      try {
        await saveCurrentWork();
        const res = await context.createDocument({ ...body, content: data });
        const { id: documentId } = res.payload?.data || {};

        if (context.type === 'project') {
          const projectId = context.project?.id;
          navigate(`/projects/${projectId}?active=documents&id=${documentId}`);
        } else if (context.type === 'template') {
          const templateId = context.template?.project_template?.id;
          navigate(`/template/${templateId}/edit?active=documents&id=${documentId}`);
        }

        onCloseModal();
      } finally {
        setFormLoader(false);
      }
    };

    return (
      <CustomModal
        open={!!data}
        onCloseModal={() => onCloseModal()}
        title='Add Document From Chat Message'
        size='max-w-lg'
      >
        <DocumentForm
          onCloseModal={() => onCloseModal()}
          loading={formLoader}
          onSubmitData={creatingDocument}
          editData={null}
        />
      </CustomModal>
    );
  }),
  { displayName: 'NewDocumentModal' }
);

const MessageActions = ({
  markdownElRef,
  onAddDocument,
}: Readonly<{
  markdownElRef: RefObject<HTMLDivElement>;
  onAddDocument: (markdown: string) => void;
}>) => {
  const { hasTextEditor, editorRef } = useContext(DetailsContext);

  const copyOptions = useMemo(() => {
    const options = [
      {
        name: OptionName.COPY,
        icon: CopyIcon,
        label: 'Copy',
      },
      {
        name: OptionName.CREATE_DOCUMENT,
        icon: CreateDocumentIcon,
        label: 'Create Document',
      },
    ];

    if (hasTextEditor) {
      options.unshift(
        {
          name: OptionName.ADD_TO_TOP,
          icon: AddToTopIcon,
          label: 'Add to Top',
        },
        {
          name: OptionName.ADD_TO_BOTTOM,
          icon: AddToBottomIcon,
          label: 'Add to Bottom',
        },
        {
          name: OptionName.INSERT_AT_CURSOR,
          icon: InsertAtCursorIcon,
          label: 'Insert at Cursor',
        }
      );
    }

    return options;
  }, [hasTextEditor]);

  const handleCopyOption = async (option: OptionName) => {
    switch (option) {
      case OptionName.COPY:
        if (markdownElRef.current?.textContent) {
          await copyToClipboard(markdownElRef.current.textContent);
        }
        break;

      case OptionName.CREATE_DOCUMENT:
        if (markdownElRef.current) {
          onAddDocument(markdownElRef.current.innerHTML);
        }
        break;

      case OptionName.ADD_TO_TOP:
        editorRef.current?.execute('pasteAt:afterTheHeading', {
          content: markdownElRef.current?.innerHTML,
        });
        break;

      case OptionName.ADD_TO_BOTTOM:
        editorRef.current?.execute('pasteAt:theBottom', {
          content: markdownElRef.current?.innerHTML,
        });
        break;

      case OptionName.INSERT_AT_CURSOR:
        editorRef.current?.execute('pasteAt:cursor', {
          content: markdownElRef.current?.innerHTML,
        });
        break;
    }
  };

  return (
    <div className='ml-auto flex items-center justify-end p-1 rounded-[7px]'>
      {copyOptions.map(({ name, icon: Icon, label }) => (
        <button
          key={name}
          type='button'
          className='cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]'
          title={label}
          onClick={() => handleCopyOption(name)}
        >
          <Icon className='h-[16px] text-[#6f717c]' />
        </button>
      ))}
    </div>
  );
};

function AssistantMessage({
  content: rootContent,
  addItemToTextEditor,
  markdownElRef,
  isStreamTarget,
}: Readonly<{
  content?: string;
  addItemToTextEditor: Function;
  markdownElRef?: MutableRefObject<HTMLDivElement | null>;
  isStreamTarget: boolean;
}>) {
  const [content, setContent] = useState(rootContent);

  useEffect(() => {
    setContent(rootContent);
  }, [rootContent]);

  useEffect(() => {
    if (!isStreamTarget) return;

    const listener = (event: CustomEvent<string>) => {
      if (typeof event.detail === 'string') {
        setContent(event.detail);
      }
    };

    document.addEventListener('message-stream', listener as EventListener);

    return () => {
      document.removeEventListener('message-stream', listener as EventListener);
    };
  }, [isStreamTarget]);

  const isLoading = content === 'q_loading';

  return (
    <div className='text-sm leading-6 text-gray-600 py-2 messages-response break-words max-w-full overflow-hidden'>
      {isLoading ? (
        <div className='flex space-x-2 items-center'>
          <span className='sr-only'>Loading...</span>
          <div className='h-2 w-2 bg-blue-300 rounded-full animate-bounce [animation-delay:-0.2s]'></div>
          <div className='h-2 w-2 bg-blue-300 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
          <div className='h-2 w-2 bg-blue-300 rounded-full animate-bounce'></div>
        </div>
      ) : (
        <div ref={markdownElRef}>
          <MarkdownRenderer
            content={content?.replaceAll('&nbsp;', ' ')}
            addItemToTextEditor={addItemToTextEditor}
          />
        </div>
      )}
    </div>
  );
}

export default Object.assign(AssistantMessage, {
  NewDocumentModal,
  MessageActions,
});
