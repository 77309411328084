import { createSlice } from "@reduxjs/toolkit";
import {
  getProfileData,
  updateProfile,
  updateSubscription,
} from "./profileApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  profileData: null;
  updateProfileRes: null;
  currentPage: any;
  homeCurrentTab: any;
  templatesCurrentTab: number;
  collapseSidebar: boolean;
  userTokenInfo: any;
  showPricingModal: boolean;
  showCloseModalOption: boolean;
  updateSubscriptionRes: null;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  profileData: null,
  updateProfileRes: null,
  currentPage: null,
  homeCurrentTab: 1,
  templatesCurrentTab: 1,
  collapseSidebar: false,
  userTokenInfo: null,
  showPricingModal: false,
  showCloseModalOption: false,
  updateSubscriptionRes: null,
};
const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setHomeCurrentTab: (state, action) => {
      state.homeCurrentTab = action.payload;
    },
    setTemplatesCurrentTab: (state, action) => {
      state.templatesCurrentTab = action.payload;
    },
    setCollapseSidebar: (state, action) => {
      state.collapseSidebar = action.payload;
    },
    setUserTokenInfo: (state, action) => {
      state.userTokenInfo = action.payload;
    },
    setShowPricingModal: (state, action) => {
      state.showPricingModal = action.payload;
    },
    setShowCloseModalOption: (state, action) => {
      state.showCloseModalOption = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Profile
    builder.addCase(getProfileData.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.profileData = action.payload?.data;
    });
    builder.addCase(getProfileData.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Profile
    builder.addCase(updateProfile.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateProfileRes = action.payload?.data;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Subscription
    builder.addCase(updateSubscription.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateSubscriptionRes = action.payload?.data;
    });
    builder.addCase(updateSubscription.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  setCurrentPage,
  setHomeCurrentTab,
  setTemplatesCurrentTab,
  setCollapseSidebar,
  setUserTokenInfo,
  setShowPricingModal,
  setShowCloseModalOption,
} = profileSlice.actions;
export default profileSlice.reducer;
