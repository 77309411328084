import { useMemo, useState } from 'react';
import CustomModal from '../../../components/customModal';
import ArgumentForm from './argumentForm';
import ArgumentBox, { BaseArgumentBoxProps } from '../../../components/argumentBox';
import { classNames } from '../../../utils/utilities';
import RoundButton from 'src/components/roundButton';
import ConfirmationModal from '../../../components/confirmationModal';
import { ColorVariant, colorClasses } from 'src/components/configurationGrid';

export type Side = 'strength' | 'weakness' | 'opportunity' | 'threat';

interface SwotSectionProps {
  type: Side;
  dataList: any[] | null | undefined;
  percentage: string;
  submittedSuggestionArgument: string | null;
  onOpenModal: (type: Side) => void;
  onOpenEditModal: (data: any, type: Side) => void;
  deleteConfirmationModal: (data: any) => void;
  submittingAiSuggestionArgument: (argument: any, type: Side) => void;
  deletingAiSuggestionArgument: (argument: any, type: Side) => void;
}

function SwotSection({
  type,
  dataList,
  percentage,
  submittedSuggestionArgument,
  onOpenModal,
  onOpenEditModal,
  deleteConfirmationModal,
  submittingAiSuggestionArgument,
  deletingAiSuggestionArgument,
}: SwotSectionProps) {
  const sectionConfig: Record<Side, { title: string; colorVariant: ColorVariant; margin: string }> = {
    strength: { title: 'Strengths', colorVariant: 'green', margin: 'ml-0' },
    weakness: { title: 'Weaknesses', colorVariant: 'blue', margin: 'mr-0' },
    opportunity: { title: 'Opportunities', colorVariant: 'green', margin: 'ml-0' },
    threat: { title: 'Threats', colorVariant: 'orange', margin: 'mr-0' },
  };

  const { title, colorVariant, margin } = sectionConfig[type];

  return (
    <div className={classNames(`h-[calc(100%-2rem)] bg-white rounded-2xl m-4 shadow-md`, margin)}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center p-3'>
          <span className='font-semibold text-xs'>{title}</span>
        </div>

        {percentage && (
          <span
            className={classNames('flex items-center justify-center', colorClasses[colorVariant])}
            style={{ width: '47px', height: '47px' }}
          >
            <span className={`font-medium text-xs`}>{percentage}</span>
          </span>
        )}
      </div>

      <div className='border-t border-gray-200 p-4'>
        <div
          onClick={() => onOpenModal(type)}
          className='mb-3 w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400'
        >
          <RoundButton onClickBtn={() => console.log('...')} />
        </div>

        <ul
          className='relative w-[calc(100%+0.5rem)] pr-2 pb-px grid grid-cols-1 overflow-y-auto'
          style={{ maxHeight: `calc(((100vh - (103px + 4rem)) / 2) - (84px + 3.75rem))` }}
        >
          {dataList?.map((argument: any) => {
            const source = argument?.source || 'user';

            const sharedProps: BaseArgumentBoxProps = {
              name: argument.argument,
              value: argument.argument_weight,
              color: colorVariant,
              status: submittedSuggestionArgument === argument?.id ? 'creating' : null,
            };

            if (source === 'user') {
              return (
                <ArgumentBox
                  key={argument?.id}
                  {...sharedProps}
                  type='swot'
                  variant='user'
                  description={argument?.description}
                  onEdit={() => onOpenEditModal(argument, type)}
                  onDelete={() => deleteConfirmationModal(argument)}
                />
              );
            }

            return (
              <ArgumentBox
                key={argument?.id}
                {...sharedProps}
                type='swot'
                variant='ai'
                description={argument?.explanation}
                onThumbDown={() => deletingAiSuggestionArgument(argument, type)}
                onThumbUp={() => submittingAiSuggestionArgument(argument, type)}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

interface AnalysisProps {
  detailsData: any;
  strengthsList: any[] | null;
  weaknessesList: any[] | null;
  opportunitiesList: any[] | null;
  threatsList: any[] | null;
  updateArgument: (body: any, selectedArgument: any, side: Side) => Promise<void>;
  createArgument: (argumentBody: any, side: Side) => Promise<void>;
  deleteArgument: (selectedArgument: any, side: Side) => Promise<void>;
}

export default function Analysis({
  detailsData,
  strengthsList,
  weaknessesList,
  opportunitiesList,
  threatsList,
  updateArgument,
  createArgument,
  deleteArgument,
}: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [argumentFormLoader, setArgumentFormLoader] = useState(false);
  const [selectedArgument, setSelectedArgument] = useState<any>(null);
  const [argumentDeleteLoader, setArgumentDeleteLoader] = useState(false);
  const [side, setSide] = useState<Side>('strength');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [submittedSuggestionArgument, setSubmittedSuggestionArgument] = useState<string | null>(null);

  const percentages = useMemo(() => {
    // TODO: Is it correct to show percentage only when all sections are filled?
    if (
      strengthsList &&
      weaknessesList &&
      opportunitiesList &&
      threatsList &&
      strengthsList?.length > 0 &&
      weaknessesList?.length > 0 &&
      opportunitiesList?.length > 0 &&
      threatsList?.length > 0
    ) {
      const calculateSum = (list: any[]) =>
        list.reduce((start: number, { argument_weight, source }: any) => {
          if (source === 'ai') return start;

          return start + argument_weight;
        }, 0);

      const strengthsSum = calculateSum(strengthsList);
      const weaknessesSum = calculateSum(weaknessesList);
      const opportunitiesSum = calculateSum(opportunitiesList);
      const threatsSum = calculateSum(threatsList);

      const allSum = strengthsSum + weaknessesSum + opportunitiesSum + threatsSum;

      return {
        strength: Math.ceil((strengthsSum / allSum) * 100) + '%',
        weakness: Math.ceil((weaknessesSum / allSum) * 100) + '%',
        opportunity: Math.ceil((opportunitiesSum / allSum) * 100) + '%',
        threat: Math.ceil((threatsSum / allSum) * 100) + '%',
      };
    }

    return {
      strength: '',
      weakness: '',
      opportunity: '',
      threat: '',
    };
  }, [strengthsList, weaknessesList, opportunitiesList, threatsList]);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedArgument(null);
  };

  const onOpenModal = (type: Side) => {
    setSide(type);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, type: Side) => {
    setSide(type);
    setSelectedArgument(data);
    setOpenFormModal(true);
  };

  const updatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);

      try {
        await updateArgument(body, selectedArgument, side);
        onCloseModal();
      } finally {
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);

      try {
        await createArgument(body, side);
        onCloseModal();
      } finally {
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingArgument = () => {
    const fetchData = async () => {
      setArgumentDeleteLoader(true);

      try {
        await deleteArgument(selectedArgument, side);
        closeConfirmationModal();
      } finally {
        setArgumentDeleteLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedArgument) {
      updatingArgument(body);
    } else {
      creatingArgument(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedArgument(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedArgument(null);
  };

  const submittingAiSuggestionArgument = (argument: any, type: Side) => {
    const fetchData = async () => {
      setSubmittedSuggestionArgument(argument?.id);

      try {
        const { id, argument: argumentName, argument_weight, explanation } = argument;

        await createArgument(
          {
            id,
            argument: argumentName,
            argument_weight,
            side,
            description: explanation,
            source: 'ai',
          },
          type
        );
      } finally {
        setSubmittedSuggestionArgument(null);
      }
    };

    fetchData();
  };

  const sectionHandlers = {
    onOpenModal,
    onOpenEditModal,
    deleteConfirmationModal,
    submittingAiSuggestionArgument,
    deletingAiSuggestionArgument: deleteArgument,
  };

  return (
    <>
      <div className='h-1/2 grid grid-cols-2 space-6'>
        {/** Strengths Column **/}
        <div className='h-full border-r border-gray-200'>
          <SwotSection
            type='strength'
            dataList={strengthsList}
            percentage={percentages.strength}
            submittedSuggestionArgument={submittedSuggestionArgument}
            {...sectionHandlers}
          />
        </div>

        {/** Weaknesses Column **/}
        <SwotSection
          type='weakness'
          dataList={weaknessesList}
          percentage={percentages.weakness}
          submittedSuggestionArgument={submittedSuggestionArgument}
          {...sectionHandlers}
        />
      </div>

      <div className='h-1/2 grid grid-cols-2 border-t border-gray-200'>
        {/** Opportunities Column **/}
        <div className='h-full border-r border-gray-200'>
          <SwotSection
            type='opportunity'
            dataList={opportunitiesList}
            percentage={percentages.opportunity}
            submittedSuggestionArgument={submittedSuggestionArgument}
            {...sectionHandlers}
          />
        </div>

        {/** Threats Column **/}
        <SwotSection
          type='threat'
          dataList={threatsList}
          percentage={percentages.threat}
          submittedSuggestionArgument={submittedSuggestionArgument}
          {...sectionHandlers}
        />
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedArgument ? 'Update' : 'Add'} Argument`}
        size='max-w-lg'
      >
        <ArgumentForm
          onCloseModal={() => onCloseModal()}
          loading={argumentFormLoader}
          onSubmitData={onSubmitForm}
          editData={selectedArgument}
          side={side}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingArgument}
        deleteLoader={argumentDeleteLoader}
        description={
          <span>
            Are you sure you want to delete an argument: <strong>{selectedArgument?.argument ?? ''}</strong>?
          </span>
        }
      />
    </>
  );
}
