import { createSlice } from "@reduxjs/toolkit";
import {
  getProsConsList,
  createProsCons,
  updateProsCons,
  getProsConsById,
  deleteProsCons,
} from "./prosConsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  prosConsList: any[];
  createProsConsRes: any;
  updateProsConsRes: any;
  prosConsByIdRes: any;
  prosConsDeleteRes: any;
  loadingProsCons: boolean;
  selectedProsCons: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  prosConsList: [],
  createProsConsRes: null,
  updateProsConsRes: null,
  prosConsByIdRes: null,
  prosConsDeleteRes: null,
  loadingProsCons: false,
  selectedProsCons: null,
};
const prosConsSlice = createSlice({
  name: "prosCons",
  initialState,
  reducers: {
    setSelectedProsCons: (state, action) => {
      state.selectedProsCons = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Pros Cons
    builder.addCase(getProsConsList.pending, (state) => {
      state.error = null;
      state.message = null;
      state.loadingProsCons = true;
    });
    builder.addCase(getProsConsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.prosConsList = action.payload?.data;
      state.loadingProsCons = false;
    });
    builder.addCase(getProsConsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loadingProsCons = false;
    });

    // Get Pros Cons By Id
    builder.addCase(getProsConsById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getProsConsById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.prosConsByIdRes = action.payload?.data;
    });
    builder.addCase(getProsConsById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Pros Cons
    builder.addCase(createProsCons.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createProsCons.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createProsConsRes = action.payload?.data;
    });
    builder.addCase(createProsCons.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Pros Cons
    builder.addCase(updateProsCons.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateProsCons.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateProsConsRes = action.payload?.data;
    });
    builder.addCase(updateProsCons.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Pros Cons
    builder.addCase(deleteProsCons.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteProsCons.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.prosConsDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteProsCons.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedProsCons } = prosConsSlice.actions;
export default prosConsSlice.reducer;
