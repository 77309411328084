import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import WrapperLoader from 'src/components/wrapperLoader';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getSwotById } from '../../../../redux/swot/swotApi';
import { getQueryParams, getLastIdFromUrl } from '../../../../utils/utilities';
import DetailsOfAllDecisions from '../details';

export default function SwotDetails() {
  const [swotByIdLoader, setSwotByIdLoader] = useState(false);
  const [detailsData, setDetailsData] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { selectedSwot, swotByIdRes } = useAppSelector((state) => state.swot);

  useEffect(() => {
    const fetchData = async () => {
      const queryStringObj = getQueryParams(window.location.href);
      setSwotByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getSwotById({
              accessToken,
              id: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setSwotByIdLoader(false);
      } catch (error) {
        console.error('Error getting access token:', error);
        setSwotByIdLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwot]);

  useEffect(() => {
    if (swotByIdRes) {
      const showData = {
        title: swotByIdRes?.details?.title || '',
        description: swotByIdRes?.details?.swot_description || '',
        status: swotByIdRes?.details?.swot_status || '',
        category: swotByIdRes?.details?.category || '',
        assumptions: swotByIdRes?.details?.assumptions || '',
        implications: swotByIdRes?.details?.implications || '',
        final_decision: swotByIdRes?.details?.final_decision || '',
      };
      setDetailsData(showData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swotByIdRes]);

  return (
    <WrapperLoader loading={swotByIdLoader}>
      <DetailsOfAllDecisions
        detailsData={detailsData}
        editEventName='swot:edit'
      />
    </WrapperLoader>
  );
}
