import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowDownCircleIcon,
  PencilIcon,
  TrashIcon,
  ArrowUpOnSquareStackIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '../../hooks';
import WrapperLoader from '../../components/wrapperLoader';

import TemplateDetailPage from '../../components/templateDetailPage';
import {
  deleteTemplate,
  getTemplateById,
  createProjectFromTemplate,
  getPublicTemplateById,
  createProjectFromPublicTemplate,
  cloneFromPublicTemplate,
} from '../../redux/templates/templatesApi';
import CustomButton from '../../components/customButton';
import CustomModal from '../../components/customModal';
import ProjectsSelection from '../templates/ProjectsSelectionForm';
import { createConversation } from '../../redux/chatGPT/chatGPTApi';
import ProjectAsTemplateForm from '../templates/ProjectAsTemplateForm';
import ConfirmationModal from '../../components/confirmationModal';
import PublishConfirmation from '../../components/publishConfirmation';
import { getLastIdFromUrl, getFirstPathFromUrl, categoriesList } from '../../utils/utilities';
import SaveProjectAsTemplate from '../templates/SaveProjectAsTemplate';
import CustomDrawer from '../../components/customDrawer';
import { publishProjectTemplate, unPublishProjectTemplate } from '../../redux/templates/templatesApi';
import { useNotification } from '../../components/apiNotification';
import BrandedSelect from 'src/components/brandedSelect';
import { publishUserAiAssistant, unPublishUserAiAssistant } from 'src/redux/userAiAssistants/userAiAssistantsApi';

const TemplatesDetails = () => {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [selectProjectLoader, setSelectProjectLoader] = useState(false);
  const [projectSelectionModal, setProjectSelectionModal] = useState(false);
  const [selectedAIChatId, setSelectedAIChatId] = useState('');
  const [openSaveAsTemp, setOpenSaveAsTemp] = useState(false);
  const [saveAsTempLoader, setSaveAsTempLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [publishingLoader, setPublishingLoader] = useState(false);
  const [publishConfirmation, setPublishConfirmation] = useState(false);
  const [unpublishConfirmation, setUnpublishConfirmation] = useState(false);
  // const [openDocumentForm, setOpenDocumentForm] = useState(false);
  const [tempDetailLoader, setTempDetailLoader] = useState(false);
  const [openCreateProjectFromTemp, setOpenCreateProjectFromTemp] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [publishTempModal, setPublishTempModal] = useState(false);
  const [cloneTemplateLoader, setCloneTemplateLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { templateByIdRes, getPublicTemplateByIdRes } = useAppSelector((state) => state.templates);
  const { addNotification } = useNotification();

  const firstPath = getFirstPathFromUrl(window.location.pathname);

  const onClosingProjectSelection = () => {
    setSelectedAIChatId('');
    setProjectSelectionModal(false);
  };

  const creatingAIChat = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSelectProjectLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/projects/${project_id}`, {
                  state: { conversation: res.payload.data }
                });
                onClosingProjectSelection();
              }
              setSelectProjectLoader(false);
            })
            .catch(() => {
              setSelectProjectLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const savingDocumentAsTemplateData = (project_id: string, templateType: string) => {
    const fetchData = async () => {
      try {
        setSaveAsTempLoader(true);
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id,
              conversation_configuration_id: selectedAIChatId,
              template_type: templateType,
            })
          )
            .then((res) => {
              if (res?.payload) {
                navigate(`/project/${project_id}`);
              }
              setOpenSaveAsTemp(false);
              setSaveAsTempLoader(false);
            })
            .catch(() => {
              setSaveAsTempLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

  const deletingProjectTemplate = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteTemplate({
              accessToken,
              id: templateByIdRes?.project_template?.id,
            })
          )
            .then(() => {
              setConfirmationLoader(false);
              deletingModalClose();
              navigate('/templates');
            })
            .catch(() => {
              setConfirmationLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const gettingProjectTempDetail = () => {
    const fetchData = async () => {
      setTempDetailLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (firstPath === 'template') {
            await dispatch(
              getTemplateById({
                accessToken,
                id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          if (firstPath === 'community-template') {
            await dispatch(
              getPublicTemplateById({
                accessToken,
                id: getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          setTempDetailLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setTempDetailLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  const creatingProjectFromTemp = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          if (firstPath === 'community-template') {
            await dispatch(
              createProjectFromPublicTemplate({
                body,
                accessToken,
                id: getPublicTemplateByIdRes?.project_template?.id || getLastIdFromUrl(window.location.pathname),
              })
            ).then((res) => {
              if (res) {
                if (res?.payload?.data) {
                  setOpenCreateProjectFromTemp(false);
                  setFormLoader(false);
                  navigate(
                    `/projects/${res?.payload?.data?.id}?active=documents&id=${res?.payload?.data?.components?.document?.id}`
                  );
                }
              }
            });
          }

          if (firstPath === 'template') {
            await dispatch(
              createProjectFromTemplate({
                body,
                accessToken,
                id: templateByIdRes?.project_template?.id || getLastIdFromUrl(window.location.pathname),
              })
            ).then((res) => {
              if (res) {
                if (res?.payload?.data) {
                  setOpenCreateProjectFromTemp(false);
                  setFormLoader(false);
                  navigate(`/projects/${res?.payload?.data?.id}`);
                }
              }
            });
          }
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const publishingProjectTemplate = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      setPublishingLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            publishProjectTemplate({
              body,
              accessToken,
              id: templateByIdRes?.project_template?.id,
            })
          );

          setFormLoader(false);
          setPublishingLoader(false);
          setPublishTempModal(false);

          navigate(`/community-template/${templateByIdRes?.project_template?.id}`);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const unPublishingTemplate = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishProjectTemplate({
              accessToken,
              id: templateByIdRes?.project_template?.public_template_ref,
            })
          );

          setUnpublishConfirmation(false);
          setPublishingLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const publishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            publishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: false,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const unPublishingAIAssistant = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setPublishingLoader(true);

          await dispatch(
            unPublishUserAiAssistant({
              accessToken,
              resource_id: showTempDetail?.id,
            })
          )
            .then((res) => {
              if (res?.payload) {
                setShowTempDetail((prev: any) => {
                  return {
                    ...prev,
                    privacy: true,
                  };
                });

                setPublishConfirmation(false);
              }
              setPublishingLoader(false);
            })
            .catch(() => {
              setPublishingLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  };

  const cloningProjectTemplate = () => {
    const fetchData = async () => {
      setCloneTemplateLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            cloneFromPublicTemplate({
              accessToken,
              id: getPublicTemplateByIdRes?.project_template?.id,
            })
          )
            .then((res) => {
              if (res?.payload?.data) {
                const response = res?.payload?.data;
                setCloneTemplateLoader(false);
                addNotification(
                  'Template Saved Successfully',
                  'We saved this template in your private Project Templates. Click the button to see the details.',
                  'Show Template',
                  () => navigate(`/template/${response?.project_template?.id}`)
                );
              }
            })
            .catch(() => {
              setCloneTemplateLoader(false);
            });
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setCloneTemplateLoader(false);
      }
    };

    fetchData();
  };

  const projectTemplate = firstPath === 'template' ? templateByIdRes?.project_template : getPublicTemplateByIdRes?.project_template;
  const templateTitle = projectTemplate?.title;
  const templateCategory = projectTemplate?.category;
  const CategoryIcon = categoriesList.find((category) => category.title === templateCategory)?.icon;

  return (
    <WrapperLoader loading={tempDetailLoader}>
      <div className='px-4'>
        <div className='py-3 border-b border-gray-200 text-right'>
          <div className='flex items-center justify-between w-full'>
            <h3 className='text-sm font-semibold flex items-center text-customLightBlue'>
              {CategoryIcon && <CategoryIcon className='mr-2 h-5 text-zinc-500 ' />}
              <span>{templateTitle}</span>
            </h3>

            <div className='flex'>
              {firstPath === 'template' && (
                <>
                  <BrandedSelect
                    className='ml-3'
                    icon={<ArrowUpOnSquareStackIcon />}
                    highlighted={projectTemplate?.privacy === false}
                    loading={publishingLoader}
                    onOptionChange={({ value }) => {
                      const isCurrentlyPrivate = projectTemplate?.privacy === true;

                      if (value === 'publish' && isCurrentlyPrivate) {
                        setPublishTempModal(true);
                      } else if (value === 'unpublish' && !isCurrentlyPrivate) {
                        setUnpublishConfirmation(true);
                      }
                    }}
                  >
                    <BrandedSelect.Option
                      value='publish'
                      title='Published'
                      description='Make this template public and available to the entire community.'
                      selected={projectTemplate?.privacy === false}
                    />
                    <BrandedSelect.Option
                      value='unpublish'
                      title='Unpublished'
                      description='This template is private and only you have access to it.'
                      selected={projectTemplate?.privacy === true}
                    />
                  </BrandedSelect>
                  <CustomButton
                    text='Delete'
                    type='button'
                    onClickBtn={() => deletingModalOpen(showTempDetail?.id)}
                    beforeIcon={<TrashIcon className='h-4 mr-1' />}
                    loading={false}
                    buttonType='secondary'
                    btnStyle='ml-3'
                  />
                </>
              )}

              {firstPath === 'community-template' && (
                <CustomButton
                  text='Save'
                  type='button'
                  onClickBtn={() => cloningProjectTemplate()}
                  beforeIcon={<ArrowDownCircleIcon className='h-4 mr-1' />}
                  loading={cloneTemplateLoader}
                  buttonType='secondary'
                />
              )}

              {firstPath === 'template' && (
                <CustomButton
                  text='Edit'
                  type='button'
                  onClickBtn={() => navigate(`/template/${projectTemplate?.id}/edit`)}
                  beforeIcon={<PencilIcon className='h-4 mr-1' />}
                  loading={false}
                  buttonType='secondary'
                  btnStyle='ml-3'
                />
              )}

              <CustomButton
                text='Create Project'
                type='button'
                onClickBtn={() => setOpenCreateProjectFromTemp(true)}
                beforeIcon={<PlusIcon className='h-4 mr-1' />}
                loading={false}
                btnStyle='ml-3'
                buttonType='primary'
              />
            </div>
          </div>
        </div>

        <TemplateDetailPage gettingProjectTempDetail={gettingProjectTempDetail} />
      </div>

      <CustomModal
        open={projectSelectionModal}
        onCloseModal={() => onClosingProjectSelection()}
        title={
          <div>
            <h1 className='font-semibold text-lg mb-1'>Create a new Chat</h1>
            <p className='text-xs font-normal'>Select project and start a new chat</p>
          </div>
        }
        size='max-w-lg'
      >
        <ProjectsSelection
          onCloseModal={() => onClosingProjectSelection()}
          loading={selectProjectLoader}
          onSubmitData={creatingAIChat}
          templateType='solutionPilot'
        />
      </CustomModal>

      <CustomModal
        open={openSaveAsTemp}
        onCloseModal={() => setOpenSaveAsTemp(false)}
        title={<h1 className='font-semibold'>Save Document as Template</h1>}
        size='max-w-lg'
      >
        <ProjectAsTemplateForm
          onCloseModal={() => setOpenSaveAsTemp(false)}
          loading={saveAsTempLoader}
          onSubmitData={savingDocumentAsTemplateData}
          templateType='community'
          titleValue={showTempDetail?.title}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProjectTemplate}
        deleteLoader={confirmationLoader}
        description={
          <span>
            Are you sure you want to delete <strong>{templateTitle}</strong> template and all it's components? This
            change is <strong>irreversible</strong>.
          </span>
        }
      />

      <PublishConfirmation
        open={publishConfirmation}
        closingModal={() => setPublishConfirmation(false)}
        onClickYes={showTempDetail?.privacy === true ? () => publishingAIAssistant() : () => unPublishingAIAssistant()}
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
      />

      <PublishConfirmation
        open={unpublishConfirmation}
        closingModal={() => setUnpublishConfirmation(false)}
        onClickYes={unPublishingTemplate}
        loading={publishingLoader}
        privacy={showTempDetail?.privacy}
        message={
          <span>
            Are you sure you want to {showTempDetail?.privacy === true ? 'publish' : 'unpublish'}{' '}
            <strong>{templateTitle}</strong> template?
          </span>
        }
      />

      <CustomDrawer
        open={openCreateProjectFromTemp}
        onCloseModal={() => setOpenCreateProjectFromTemp(false)}
        title='Create Project from Template'
        description='Create a new project from a template'
        distanced
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setOpenCreateProjectFromTemp(false)}
          onSubmitForm={creatingProjectFromTemp}
          loader={formLoader}
          projectData={getPublicTemplateByIdRes?.project_template}
        />
      </CustomDrawer>

      <CustomDrawer
        open={publishTempModal}
        onCloseModal={() => setPublishTempModal(false)}
        title='Publish Template'
        description='You can Publish your template for public'
        distanced
      >
        <SaveProjectAsTemplate
          onCloseModal={() => setPublishTempModal(false)}
          onSubmitForm={publishingProjectTemplate}
          loader={formLoader}
          projectData={templateByIdRes?.project_template}
          btnText='Publish'
        />
      </CustomDrawer>
    </WrapperLoader>
  );
};

export default TemplatesDetails;
