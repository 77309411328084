import React, { useMemo } from "react";
import { Tooltip } from "react-tooltip";

interface NodeIconsBoxForDropdownProps {
  iconDataList: any;
  onChangeNodeIcon: Function;
  selectedNodeIcon: string;
}

const NodeIconsBoxForDropdown = ({
  iconDataList,
  onChangeNodeIcon,
  selectedNodeIcon,
}: NodeIconsBoxForDropdownProps) => {
  const renderedIcons = useMemo(() => {
    return iconDataList.data.length > 0
      ? iconDataList.data.slice(0, 6).map((item: any) => (
          <Icon
            key={item.id}
            item={item}
            onChangeNodeIcon={onChangeNodeIcon}
            selectedNodeIcon={selectedNodeIcon}
          />
        ))
      : null;
  }, [iconDataList, onChangeNodeIcon, selectedNodeIcon]);

  return (
    <div className="mb-1 pb-1 border-b border-zinc-100">
      <div className="opacity-100 h-[100%] mt-2 flex items-center justify-start flex-wrap">
        {renderedIcons}
      </div>
    </div>
  );
};

const Icon = React.memo(
  ({
    item,
    onChangeNodeIcon,
    selectedNodeIcon,
  }: {
    item: any;
    onChangeNodeIcon: Function;
    selectedNodeIcon: string;
  }) => (
    <div
      className="cursor-pointer"
      onClick={() => onChangeNodeIcon(item?.icon)}
    >
      <div
        data-tooltip-id={`edge-icon-tooltip-${item.id}`}
        className={`${
          selectedNodeIcon === item?.icon
            ? "rounded-md border-2 border-green-400 bg-white shadow-lg"
            : ""
        } transition-all hover:scale-125`}
      >
        <div className="m-[5px]">
          <img src={item.icon} alt="iconsList" className="h-[26px]" />
        </div>
      </div>

      <Tooltip
        className="z-[50]"
        id={`edge-icon-tooltip-${item.id}`}
        place="top"
      >
        {item.name}
      </Tooltip>
    </div>
  )
);

export default React.memo(NodeIconsBoxForDropdown);
