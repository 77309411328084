import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import WrapperLoader from 'src/components/wrapperLoader';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { getDecisionMatrixById } from '../../../../redux/decisionMatrix/decisionMatrixApi';
import { getQueryParams, getLastIdFromUrl } from '../../../../utils/utilities';
import DetailsOfAllDecisions from '../details';

export default function DecisionMatrixDetails() {
  const [decisionMatrixByIdLoader, setDecisionMatrixByIdLoader] = useState(false);
  const [detailsData, setDetailsData] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { selectedDecisionMatrix, decisionMatrixByIdRes } = useAppSelector((state) => state.decisionMatrixs);

  useEffect(() => {
    const fetchData = async () => {
      const queryStringObj = getQueryParams(window.location.href);
      setDecisionMatrixByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getDecisionMatrixById({
              accessToken,
              id: queryStringObj?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setDecisionMatrixByIdLoader(false);
      } catch (error) {
        console.error('Error getting access token:', error);
        setDecisionMatrixByIdLoader(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMatrix]);

  useEffect(() => {
    if (decisionMatrixByIdRes) {
      const showData = {
        title: decisionMatrixByIdRes?.details?.title || '',
        description: decisionMatrixByIdRes?.details?.matrix_description || '',
        status: decisionMatrixByIdRes?.details?.matrix_status || '',
        category: decisionMatrixByIdRes?.details?.category || '',
        assumptions: decisionMatrixByIdRes?.details?.assumptions || '',
        implications: decisionMatrixByIdRes?.details?.implications || '',
        final_decision: decisionMatrixByIdRes?.details?.final_decision || '',
      };
      setDetailsData(showData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMatrixByIdRes]);

  return (
    <WrapperLoader loading={decisionMatrixByIdLoader}>
      <DetailsOfAllDecisions
        detailsData={detailsData}
        editEventName='decision-matrix:edit'
      />
    </WrapperLoader>
  );
}
