import React, { DragEvent, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

interface NodeListBoxProps {
  itemIndex: number;
  iconDataList: any;
  onDragStart: Function;
  search: string;
}

const NodeListBox = React.memo(({
  itemIndex,
  iconDataList,
  onDragStart,
  search,
}: NodeListBoxProps) => {
  const [isCollapse, setIsCollapse] = useState(true);

  useEffect(() => {
    if (itemIndex === 0 && !search) {
      setIsCollapse(false);
    } else if (search) {
      setIsCollapse(false);
    }
  }, [itemIndex, search]);

  const handleDragStart = (event: DragEvent, item: any) => {
    onDragStart(event, "node", item.icon, item.name);
  };

  const toggleCollapse = () => {
    setIsCollapse((prev) => !prev);
  };

  return (
    <div className="py-4 border-b border-zinc-200 last:border-b-0 first:pt-0">
      <h3
        className="flex items-center justify-between text-zinc-500 font-semibold text-xs cursor-pointer ml-2 w-full"
        onClick={toggleCollapse}
      >
        <div className="flex items-center">
          {iconDataList?.categoryIcon && (
            <img
              src={iconDataList?.categoryIcon}
              alt={iconDataList.name}
              className="mr-2 w-[17px]"
            />
          )}
          <span>{iconDataList.name}</span>
        </div>

        <ChevronRightIcon
          className={`h-4 mr-2 transition-all text-zinc-400 ${isCollapse ? '' : 'rotate-90'}`}
        />
      </h3>
      <div
        className={`${
          isCollapse ? "opacity-0 h-0 overflow-hidden" : "opacity-100 h-[100%] mt-2"
        } flex items-center justify-start flex-wrap transition-all`}
      >
        {iconDataList.data.length > 0 &&
          iconDataList.data.map((item: any) => (
            <div
              key={item.id}
              className="cursor-move mx-1 mb-1 bg-white shadow-lg rounded-md"
              onDragStart={(event) => handleDragStart(event, item)}
              draggable
            >
              <div data-tooltip-id={`node-tooltip-${item.id}`}>
                <div className="px-[5px] py-[8px]">
                  <img src={item.icon} alt="iconsList" className="h-[29px]" />
                </div>
              </div>

              <Tooltip
                className="z-[50]"
                id={`node-tooltip-${item.id}`}
                place="top"
              >
                {item.name}
              </Tooltip>
            </div>
          ))}
      </div>
    </div>
  );
});

export default NodeListBox;
