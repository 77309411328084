import Home from '../../pages/home';
import TBar from '../../pages/T-bar';
import TBarDetails from '../../pages/T-bar-Details';
import DecisionMatrix from '../../pages/decisionMatrix';
import DecisionMatrixDetails from '../../pages/decisionMatrixDetails';
import SWOT from '../../pages/swot';
import SWOTDetails from '../../pages/swotDetails';
import ProCons from '../../pages/prosCons';
import ProConsDetails from '../../pages/prosConsDetails';
import Profile from '../../pages/profile';
import Projects from '../../pages/projects';
import ProjectsDetails from '../../pages/projectsDetails';
import AIAssitants from '../../pages/aiAssistants';
import CommunityPage from '../../pages/communityPage';
import LoginPage from '../../pages/loginPage';
import SignUpPage from '../../pages/signupPage';
import Templates from '../../pages/templates';
import TemplatesDetails from '../../pages/templatesDetails';
import DocumentDetails from '../../pages/documentDetails';
import FlowChart from '../../pages/flowchart/flow/App';
import AiAssistantsDetails from '../../pages/aiAssistantsDetials';
import DocumentComponents from '../../pages/documentComponents';
import CommunityTemplates from '../../pages/communityPage/communityTemplates';
import CommunityAiPrompts from '../../pages/communityPage/communityAiPrompts';
import Playground from '../../pages/playground';
import routeHierarchy from 'src/utils/routeHierarchy';

const routes: any = [
  {
    key: routeHierarchy.login.name,
    component: LoginPage,
    path: '/login',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.signup.name,
    component: SignUpPage,
    path: '/signup',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.home.name,
    component: Home,
    path: '/home',
    exact: true,
    index: true,
  },
  {
    key: routeHierarchy.community.name,
    component: CommunityPage,
    path: '/community',
    exact: false,
    index: true,
  },
  {
    key: 't-bar',
    component: TBar,
    path: '/t-bar',
    exact: false,
    index: true,
  },
  {
    key: 't-bar-detail',
    component: TBarDetails,
    path: '/t-bar/:id',
    exact: false,
    index: true,
  },
  {
    key: 'pro-con',
    component: ProCons,
    path: '/pro-con',
    exact: false,
    index: true,
  },
  {
    key: 'pro-con-details',
    component: ProConsDetails,
    path: '/pro-con/:id',
    exact: false,
    index: true,
  },
  {
    key: 'decisionMatrix',
    component: DecisionMatrix,
    path: '/decision-matrix',
    exact: false,
    index: true,
  },
  {
    key: 'decisionMatrix-details',
    component: DecisionMatrixDetails,
    path: '/decision-matrix/:id',
    exact: false,
    index: true,
  },
  {
    key: 'SWOTAnalysis',
    component: SWOT,
    path: '/swot-analysis',
    exact: false,
    index: true,
  },
  {
    key: 'SWOTAnalysis-details',
    component: SWOTDetails,
    path: '/swot-analysis/:id',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.profile.name,
    component: Profile,
    path: '/profile',
    exact: false,
    index: true,
  },

  {
    key: routeHierarchy.projects.name,
    component: Projects,
    path: '/projects',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.projects.details.name,
    component: ProjectsDetails,
    path: '/projects/:id',
    exact: false,
    index: true,
  },

  {
    key: routeHierarchy.communityAiAssistants.name,
    component: CommunityAiPrompts,
    path: '/community-ai-assistants',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.communityAiAssistants.details.name,
    component: AiAssistantsDetails,
    path: '/community-ai-assistant/:id',
    exact: false,
    index: true,
  },

  {
    key: routeHierarchy.privateAiAssistants.name,
    component: AIAssitants,
    path: '/ai-assistants',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.privateAiAssistants.details.name,
    component: AiAssistantsDetails,
    path: '/ai-assistant/:id',
    exact: false,
    index: true,
  },

  {
    key: routeHierarchy.privateTemplates.name,
    component: Templates,
    path: '/templates',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.privateTemplates.details.name,
    component: TemplatesDetails,
    path: '/template/:id',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.privateTemplates.details.edit.name,
    component: ProjectsDetails,
    path: '/template/:id/edit',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.privateTemplates.details.documentView.name,
    component: DocumentDetails,
    path: '/template/:id/document/:docId',
    exact: false,
    index: true,
  },

  {
    key: routeHierarchy.communityTemplates.name,
    component: CommunityTemplates,
    path: '/community-templates',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.communityTemplates.details.name,
    component: TemplatesDetails,
    path: '/community-template/:id',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.communityTemplates.details.documentView.name,
    component: DocumentDetails,
    path: '/community-template/:id/document/:docId',
    exact: false,
    index: true,
  },

  {
    key: 'flow-chart',
    component: FlowChart,
    path: '/flow-chart',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.documentComponents.name,
    component: DocumentComponents,
    path: '/document-components',
    exact: false,
    index: true,
  },
  {
    key: routeHierarchy.playground.name,
    component: Playground,
    path: '/playground',
    exact: false,
    index: true,
  },
];

export default routes;
