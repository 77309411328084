import React, { useContext, useEffect, useState } from 'react';
import { DocumentTextIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import RoundButton from '../../../../components/roundButton';
import CustomModal from '../../../../components/customModal';
import DocumentForm from './documentForm';
import ConfirmationModal from '../../../../components/confirmationModal';
import DocumentBox from './documentBox';
import { useAppDispatch } from '../../../../hooks';
import WrapperLoader from '../../../../components/wrapperLoader';
import ItemWithDropdown from '../../../../components/itemWithDropdown';
import TemplatesProjectsDocumentsModal from '../../../templates/templatesProjectsDocumentsModal';
import { setTemplatesCurrentTab } from '../../../../redux/profile/profileSlice';
import { classNames, getQueryParams } from '../../../../utils/utilities';
import { DetailsContext } from '../../../../contexts/details/context';

interface DocumnetsPagesProps {
  isTextEditorDirty: boolean;
  updatingProjectDocument: Function;
}

interface Item {
  content: string;
  created_at: string;
  document_order: number;
  id: string;
  project_id: string;
  title: string;
  updated_at: string;
  user_id: string;
}

const DocumnetsPages = ({ isTextEditorDirty, updatingProjectDocument }: DocumnetsPagesProps) => {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);
  const [openDocumentsTemplatesModal, setOpenDocumentsTemplatesModal] = useState(false);

  const [draggedItem, setDraggedItem] = useState<Item | null>(null);
  const [documentItems, setDocumentItems] = useState<Item[]>([]);
  const [targetItemIndex, setTargetItemIndex] = useState<any>(null);

  const navigate = useNavigate();

  const {
    allDocuments,
    selectedDocument,
    selectDocument,
    getAllDocuments,
    createDocument,
    updateDocument,
    deleteDocument,
  } = useContext(DetailsContext);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: Item) => {
    setDraggedItem(item);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>, targetItem: Item) => {
    const newList = [...documentItems];
    const draggedIndex = documentItems.findIndex((i) => i === draggedItem);
    const targetIndex = documentItems.findIndex((i) => i === targetItem);

    setTargetItemIndex(targetIndex);

    // Swap the docuemntsitems
    if (draggedIndex !== -1 && targetIndex !== -1) {
      newList[draggedIndex] = targetItem;
      newList[targetIndex] = draggedItem as Item;
    }

    setDocumentItems(newList);
  };

  const handleDragEnd = () => {
    if (draggedItem) {
      const payloadBody = {
        document_order: targetItemIndex + 1,
        project_id: draggedItem?.project_id,
        id: draggedItem?.id,
      };

      updatingDocument(payloadBody);
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    const documentsList = [...(allDocuments || [])];
    documentsList.sort((a: any, b: any) => a.document_order - b.document_order);
    setDocumentItems(documentsList);
  }, [allDocuments]);

  useEffect(() => {
    if (!getAllDocuments) return;

    const fetchData = async () => {
      try {
        setAllDocumentsLoading(true);
        await getAllDocuments();
      } finally {
        setAllDocumentsLoading(false);
      }
    };

    fetchData();
  }, [getAllDocuments]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (!queryStringObj?.active || queryStringObj?.active === 'documents') {
      navigate(`${window.location.pathname}?active=documents&id=${selectedDocument?.id || queryStringObj?.id}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocument]);

  useEffect(() => {
    if (!Array.isArray(allDocuments) || allDocuments.length !== documentItems.length) return;

    const queryStringObj = getQueryParams(window.location.href);

    if (documentItems.length > 0 && queryStringObj?.active === 'documents') {
      const findDocument = documentItems.find((data: any) => data.id === queryStringObj.id);

      selectDocument(findDocument || documentItems[0]);
    }
  }, [allDocuments, documentItems, selectDocument]);

  useEffect(() => {
    const handleDocumentEdit = () => {
      if (selectedDocument) {
        onOpenEditModal(selectedDocument);
      }
    };

    const handleDocumentDelete = () => {
      if (selectedDocument) {
        deleteConfirmationModal(selectedDocument);
      }
    };

    document.addEventListener('document:edit', handleDocumentEdit);
    document.addEventListener('document:delete', handleDocumentDelete);

    return () => {
      document.removeEventListener('document:edit', handleDocumentEdit);
      document.removeEventListener('document:delete', handleDocumentDelete);
    };
  }, [selectedDocument]);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = () => {
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any) => {
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingDocument = async (body: any) => {
    setFormLoader(true);

    try {
      await updateDocument(body, selectedData);

      onCloseModal();
      setDraggedItem(null);
    } finally {
      setFormLoader(false);
    }
  };

  const creatingDocument = async (body: any) => {
    setFormLoader(true);

    try {
      await createDocument(body);

      onCloseModal();
    } finally {
      setFormLoader(false);
    }
  };

  const deletingDocument = async () => {
    setDeleteLoader(true);

    try {
      await deleteDocument(selectedData);
      closeConfirmationModal();

      if (selectedData.id === selectedDocument.id) {
        selectDocument(documentItems[0] || allDocuments[0]);
      }
    } finally {
      setDeleteLoader(false);
    }
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingDocument(body);
    } else {
      creatingDocument(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedDocument = async (data: any) => {
    if (isTextEditorDirty) {
      await updatingProjectDocument();
    }

    selectDocument(data);
  };

  const openingTemplateDocuments = (value: number) => {
    dispatch(setTemplatesCurrentTab(value));
    setOpenDocumentsTemplatesModal(true);
  };

  const actionsList = [
    {
      id: 1,
      name: 'Black Document',
      onActionClick: () => onOpenModal(),
      icon: <DocumentTextIcon className='mr-3 h-4 w-4 text-gray-400 transition-colors group-hover:text-blue-500' />,
    },
    {
      id: 2,
      name: 'My Templates',
      onActionClick: () => openingTemplateDocuments(3),
      icon: <DocumentTextIcon className='mr-3 h-4 w-4 text-gray-400 transition-colors group-hover:text-blue-500' />,
    },
    {
      id: 4,
      name: 'Community Templates',
      onActionClick: () => openingTemplateDocuments(2),
      icon: <DocumentTextIcon className='mr-3 h-4 w-4 text-gray-400 transition-colors group-hover:text-blue-500' />,
    },
  ];

  return (
    <>
      <WrapperLoader loading={allDocumentsLoading}>
        <div className='p-0'>
          <div className='mb-3'>
            <h3 className='h-toolbar text-md mb-2 border-b border-zinc-100 flex items-center'>Documents</h3>
            <ItemWithDropdown actionsData={actionsList}>
              <div
                className={classNames(
                  'w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400',
                  'flex justify-center items-center'
                )}
              >
                <RoundButton
                  asSpan
                  onClickBtn={() => console.log('clicking...')}
                />
              </div>
            </ItemWithDropdown>
          </div>

          <div
            className='overflow-y-hidden hover:overflow-y-auto transition-all'
            style={{ height: 'calc(100vh - 169px)' }}
          >
            <ul className='drag-drop-container mt-3 grid grid-cols-1'>
              {documentItems.map((item, index) => (
                <div
                  key={item.id}
                  draggable
                  onDragStart={(e) => handleDragStart(e, item)}
                  onDragEnter={(e) => handleDragEnter(e, item)}
                  onDragEnd={handleDragEnd}
                  className={`drag-item ${draggedItem === item ? 'dragging' : ''}`}
                >
                  <DocumentBox
                    totalLength={documentItems?.length}
                    key={index}
                    data={item}
                    onEdit={() => onOpenEditModal(item)}
                    onDelete={() => deleteConfirmationModal(item)}
                    deleteLoader={false}
                    selectedData={selectedData}
                    onSelectDocument={() => onSelectedDocument(item)}
                    selectedDocument={selectedDocument}
                  />
                </div>
              ))}
              {/* {getProjectAllDocumentsRes?.length > 0
                ? getProjectAllDocumentsRes.map((data: any, index: number) => (
                    <DocumentBox
                      totalLength={getProjectAllDocumentsRes?.length}
                      key={index}
                      data={data}
                      onEdit={() => onOpenEditModal(data)}
                      onDelete={() => deleteConfirmationModal(data)}
                      deleteLoader={false}
                      selectedData={selectedData}
                      onSelectDocument={() => onSelectedDocument(data)}
                      selectedDocument={selectedDocument}
                    />
                  ))
                : ""} */}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? 'Update' : 'Add'} Document`}
        size='max-w-lg'
      >
        <DocumentForm
          onCloseModal={() => onCloseModal()}
          loading={formLoader}
          onSubmitData={onSubmitForm}
          editData={selectedData}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingDocument}
        deleteLoader={deleteLoader}
        title='Document Delete Confirmation'
        description={
          <span>
            Are you sure you want to delete
            <strong> {selectedData?.title}</strong>? This change is
            <strong className='text-red-700'> irreversible</strong>.
          </span>
        }
      />

      {openDocumentsTemplatesModal && (
        <TemplatesProjectsDocumentsModal
          onCloseModal={() => setOpenDocumentsTemplatesModal(false)}
          openModal={openDocumentsTemplatesModal}
          onUseTemplate={() => console.log('Creating Document')}
        />
      )}
    </>
  );
};

export default DocumnetsPages;
