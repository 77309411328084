export const documentsList = [
  { id: 1, title: "HLD - High level design with sketchhold" },
  { id: 2, title: "HLD - High level design with sketchhold" },
  { id: 3, title: "HLD - High level design with sketchhold" },
  { id: 4, title: "HLD - High level design with sketchhold" },
  { id: 5, title: "HLD - High level design with sketchhold" },
];

export const templatesList = [
  {
    id: 1,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "10k",
    published_by: "userTemplate",
    rating: 4.5,
    type: "document",
  },
  {
    id: 2,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "20k",
    published_by: "solutionPilotTemplate",
    rating: 4.5,
    type: "project",
  },
  {
    id: 3,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "10k",
    published_by: "communityTemplate",
    rating: 4.5,
    type: "document",
  },
  {
    id: 4,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "30k",
    published_by: "userTemplate",
    rating: 4.5,
    type: "project",
  },
  {
    id: 5,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "40k",
    published_by: "communityTemplate",
    rating: 4.5,
    type: "document",
  },
  {
    id: 6,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "10k",
    published_by: "solutionPilotTemplate",
    rating: 4.5,
    type: "project",
  },
  {
    id: 7,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "70k",
    published_by: "userTemplate",
    rating: 4.5,
    type: "document",
  },
  {
    id: 8,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "90k",
    published_by: "solutionPilotTemplate",
    rating: 4.5,
    type: "document",
  },
  {
    id: 9,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "10k",
    published_by: "solutionPilotTemplate",
    rating: 4.5,
    type: "project",
  },
  {
    id: 10,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "30k",
    published_by: "solutionPilotTemplate",
    rating: 4.5,
    type: "project",
  },
  {
    id: 11,
    title: "Aliquam venenatis egestas",
    category: "Azure",
    complexity: "High",
    description:
      "Curabitur velit mi, ullamcorper id elit eu, vulputate porta odio. Proin eget ex sed risus pharetra efficitur in sit amet turpis.",
    imports: "40k",
    published_by: "solutionPilotTemplate",
    rating: 4.5,
    type: "document",
  },
];
