import { classNames } from 'src/utils/utilities';

type BadgeVariant = 'blue';

interface BadgeLabelProps {
  text: string;
  variant?: BadgeVariant;
  className?: string;
}

const BadgeLabel = ({ text, variant = 'blue', className }: BadgeLabelProps) => {
  const variantStyles: Record<BadgeVariant, string> = {
    blue: 'bg-blue-50 text-blue-700 ring-blue-600/20',
  };

  return (
    <span
      className={classNames(
        'inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset',
        variantStyles[variant],
        className
      )}
    >
      {text}
    </span>
  );
};

export default BadgeLabel;
