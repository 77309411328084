import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import React, { MouseEvent, ReactElement, ReactNode, createContext, useContext } from 'react';

import { DROPDOWN_TRANSITION_CLASSES } from 'src/config';
import { classNames } from '../../utils/utilities';
import BadgeLabel from '../badgeLabel';

export interface ButtonChildrenProps {
  name: string;
  icon?: ReactNode;
}

export interface ActionProps {
  id: number;
  name: string;
  icon?: React.ReactNode;
  onActionClick?: (e: MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  tag?: string;
}

export interface RequiredActionGroupProps extends ActionProps {
  actions: ActionProps[];
  renderInToolbar: (props: RequiredActionGroupProps) => ReactNode;
  renderInDropdown: (props: RequiredActionGroupProps & DropdownRenderProps) => ReactElement;
  width: number;
  dropdownClassName?: string;
}

export interface DropdownRenderProps {
  renderButtonContent: (props: ButtonChildrenProps) => ReactElement;
}

export type ActionGroupProps = ActionProps & Partial<RequiredActionGroupProps>;

type ItemProps = RequiredActionGroupProps | ActionProps;

interface ItemWithDropdownProps {
  title?: string;
  actionsData: ItemProps[];
  children: React.ReactNode;
  menuPosition?: string;
  isNested?: boolean;
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  dropdownClassName?: string;
  buttonClassName?: string;
  className?: string;
}

function isActionGroup(props: ItemProps): props is RequiredActionGroupProps {
  return 'renderInDropdown' in props;
}

const DropdownContext = createContext<{ close?: () => void }>({});

export function useDropdownContext() {
  return useContext(DropdownContext);
}

export default function ItemWithDropdown({
  title,
  actionsData,
  children,
  menuPosition,
  isNested = false,
  onButtonClick,
  buttonClassName,
  dropdownClassName,
  className,
}: ItemWithDropdownProps) {
  const parentContext = useDropdownContext();

  return (
    <Menu
      as='div'
      className={className}
    >
      {({ close }) => {
        const handleClose = () => {
          close();
          parentContext.close?.();
        };

        return (
          <DropdownContext.Provider
            value={{
              close: handleClose,
            }}
          >
            <>
              <MenuButton
                className={classNames(
                  'menu-btn w-full flex items-center rounded-full text-customLightBlue hover:text-gray-600',
                  buttonClassName
                )}
                onClick={(e) => onButtonClick?.(e)}
              >
                {children}
              </MenuButton>

              <Transition
                as='div'
                {...DROPDOWN_TRANSITION_CLASSES}
              >
                <MenuItems
                  className={classNames(
                    `absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white`,
                    `shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${menuPosition || ''}`,
                    'divide-y divide-gray-300',
                    dropdownClassName
                  )}
                  anchor={isNested ? 'left start' : undefined}
                >
                  {!isNested && title && (
                    <div className='px-5 py-3'>
                      <span className='text-sm text-customDarkBlue font-semibold'>{title}</span>
                    </div>
                  )}
                  <div className='py-1'>
                    {actionsData?.map((item, index) => {
                      const renderContent = ({ icon, name }: ButtonChildrenProps) => (
                        <span
                          className={classNames(
                            'group w-full px-4 py-3 text-xs rounded-md text-gray-700',
                            'flex items-center transition-colors hover:text-customDarkBlue',
                            !item.disabled &&
                              classNames(
                                '[.menu-btn[data-open]_&]:bg-gray-100 [.menu-btn[data-open]_&]:text-customDarkBlue',
                                'data-[active]:bg-gray-100 data-[active]:text-customDarkBlue',
                                'hover:bg-gray-100 hover:text-customDarkBlue'
                              )
                          )}
                        >
                          <span className={classNames('flex items-center', item.disabled && 'opacity-80')}>
                            {icon}
                            {name}
                          </span>
                          {item.tag && (
                            <BadgeLabel
                              text={item.tag}
                              variant='blue'
                              className='ml-3'
                            />
                          )}
                        </span>
                      );

                      return (
                        <div
                          key={index}
                          className='px-1 cursor-pointer'
                          onClick={(e) => {
                            item.onActionClick?.(e);

                            if (isNested) {
                              handleClose();
                            }
                          }}
                        >
                          <MenuItem>
                            {() => {
                              if (isActionGroup(item)) {
                                return item.renderInDropdown({
                                  ...item,
                                  renderButtonContent: renderContent,
                                });
                              }

                              return renderContent({
                                name: item.name,
                                icon: item.icon,
                              });
                            }}
                          </MenuItem>
                        </div>
                      );
                    })}
                  </div>
                </MenuItems>
              </Transition>
            </>
          </DropdownContext.Provider>
        );
      }}
    </Menu>
  );
}
