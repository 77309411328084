import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../utils/api";

export const getSwotArgumentsList = createAsyncThunk(
  "SwotArgumentsList/get",
  async ({ accessToken, id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/swotArguments?swot_id=${id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createSwotArgument = createAsyncThunk(
  "SwotArgument/create",
  async ({ body, accessToken, id, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/swotArgument?swot_id=${id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateSwotArgument = createAsyncThunk(
  "SwotArgument/udate",
  async ({ body, accessToken, argument_id, swot_id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/swotArgument?swot_id=${swot_id}&argument_id=${argument_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteSwotArgument = createAsyncThunk(
  "SwotArgument/Delete",
  async ({ accessToken, argument_id, swot_id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/swotArgument?swot_id=${swot_id}&argument_id=${argument_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
