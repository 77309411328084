import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AdjustmentsHorizontalIcon,
  BookOpenIcon,
  Cog6ToothIcon,
  CubeIcon,
  DocumentTextIcon,
  ScaleIcon,
  SparklesIcon,
  Squares2X2Icon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import { useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getLastIdFromUrl, getQueryParams } from '../../utils/utilities';
import { useAppDispatch, useAppSelector } from '../../hooks';

import ChatGPT from './leftSideBar/chatGPT';
import CustomDrawer from '../../components/customDrawer';
import CustomModal from '../../components/customModal';
// import DecisionMatrix from "../decisionMatrix";
import DecisionMatrixAnalysis from '../decisionMatrixDetails';
import DecisionMatrixDetails from './rightSideBar/decisionMatrixDetails';
import DecisionMatrixList from './leftSideBar/decisionMatrix';
import DecisionsMatrixEmpty from './leftSideBar/emptyPages/decisionsMatrixEmpty';
// import DiagramTabs from "./leftSideBar/diagrams/diagramTabs";
import DiagramActions from './leftSideBar/diagrams/diagramActions';
import Diagrams from './leftSideBar/diagrams';
import DiagramsEmpty from './leftSideBar/emptyPages/diagramsEmpty';
import DocumentHeader from '../../components/documentHeader';
import Documents from './leftSideBar/documents';
import EditProjectForm from './leftSideBar/editProjectForm';
import FlowChart from '../flowchart/flow/App';
import FlowChartGenerateForm from '../flowchart/flowChartGenerateForm';
import MovingComp from '../../components/movingComp';
// import ProsCons from "../prosCons";
import ProsConsAnalysis from '../prosConsDetails';
import ProsConsDetails from './rightSideBar/prosConsDetails';
import ProsConsEmpty from './leftSideBar/emptyPages/prosConsEmpty';
import ProsConsList from './leftSideBar/prosCons';
import SaveProjectAsTemplate from '../templates/SaveProjectAsTemplate';
// import SWOT from "../swot";
import SwotAnalysis from '../swotDetails';
import SwotDetails from './rightSideBar/swotDetails';
import SwotEmpty from './leftSideBar/emptyPages/swotEmpty';
import SwotList from './leftSideBar/swot';
// import TChart from "../T-bar";
import TChartAnalysis from '../T-bar-Details';
import TChartDetails from './rightSideBar/tChartDetails';
import TChartEmpty from './leftSideBar/emptyPages/tChartEmpty';
import TChartList from './leftSideBar/tChart';
import TextEditor from './textEditor';
import { Tooltip } from 'react-tooltip';
import WrapperLoader from '../../components/wrapperLoader';
import { classNames } from '../../utils/utilities';
import { getDocumentFavoriteComponents } from '../../redux/documentComponents/documentComponentsApi';
import { promptMessage } from '../../redux/chatGPT/chatGPTApi';
import throttle from 'lodash/throttle';
import { updateDiagram } from '../../redux/diagrams/diagramsApi';
import { useAuth0 } from '@auth0/auth0-react';
import { useReactFlow } from '@xyflow/react';
import { DetailsContext } from 'src/contexts/details/context';
import { MULTI_SELECTED_OPTIONS } from 'src/hooks/useMultiSelectedOption';
import DocumentEmpty from './leftSideBar/emptyPages/documentsEmpty';
import { createProjectDocument } from '../../redux/projects/projectsApi';
import type { OpenSpecialChatFn, SelectedConversation } from 'src/type';
import { nanoid } from '@reduxjs/toolkit';
import DocumentFromDiagramForm from '../flowchart/flow/documentFromDiagramForm';
import { useSaveListener } from 'src/hooks/useSaveCurrentWork';

// const steps: Step[] = [
//   {
//     target: '.project-details-step-one',
//     content: 'Here you can add documents to your project.',
//   },
//   {
//     target: '.project-details-step-two',
//     content: 'Here you can add diagrams to your project.',
//   },
//   {
//     target: '.project-details-step-three',
//     content: 'Here you can add T-Charts to your project.',
//   },
//   {
//     target: '.project-details-step-four',
//     content: 'Here you can add Pros-Cons to your project.',
//   },
//   {
//     target: '.project-details-step-five',
//     content: 'Here you can add Decision Matrices to your project.',
//   },
//   {
//     target: '.project-details-step-six',
//     content: 'Here you can add SWOTs to your project.',
//   },
//   {
//     target: '.project-details-step-seven',
//     content: 'Here you can change your project settings.',
//   },
//   {
//     target: '.project-details-step-eight',
//     content: 'Here you can change your project settings.',
//   },
// ];

const leftOptions: any = [
  {
    id: 1,
    name: <DocumentTextIcon className='h-[20px] mx-auto' />,
    label: 'Documents',
    classes: 'project-details-step-one',
  },
  {
    id: 2,
    name: <CubeIcon className='h-[20px] mx-auto' />,
    label: 'Diagrams',
    classes: 'project-details-step-two',
  },
  {
    id: 4,
    name: <ScaleIcon className='h-[20px] mx-auto' />,
    label: 'T-Chart',
    classes: 'project-details-step-three',
  },
  {
    id: 5,
    name: <TableCellsIcon className='h-[20px] mx-auto' />,
    label: 'Pros-Cons',
    classes: 'project-details-step-four',
  },
  {
    id: 6,
    name: <AdjustmentsHorizontalIcon className='h-[20px] mx-auto' />,
    label: 'Matrix',
    classes: 'project-details-step-five',
  },
  {
    id: 7,
    name: <Squares2X2Icon className='h-[20px] mx-auto' />,
    label: 'SWOT',
    classes: 'project-details-step-six',
  },
  {
    id: 8,
    name: <Cog6ToothIcon className='h-[20px] mx-auto' />,
    label: 'Settings',
    classes: 'project-details-step-seven',
  },
];

const ProjectsDetails = React.memo(() => {
  const [value, setValue] = useState('');
  const [width, setWidth] = useState(330);
  const [rightWidth, setRightWidth] = useState(400);
  const [rightSelectedOption, setRightSelectedOption] = useState(0);
  const [isTextEditorDirty, setIsTextEditorDirty] = useState(false);
  const [documentSaveLoading, setDocumentSaveLoading] = useState(false);
  const [openSaveProjectTemp, setOpenSaveProjectTemp] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [generateFlowChartLoader, setGenerateFlowChartLoader] = useState(false);
  const [generatedAICode, setGeneratedAICode] = useState('');
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [isHideBox, setIsHideBox] = useState(false);
  const [isHideRightBox, setIsHideRightBox] = useState(true);
  const [rightOptionsList, setRightOptionsList] = useState<any>([]);
  const [selectedConversation, setSelectedConversation] = useState<SelectedConversation | null>(null);

  const [isDiagramDirty, setIsDiagramDirty] = useState(false);
  const [diagramSaveLoading, setDiagramSaveLoading] = useState(false);
  const [openDocumentFromDiagramModal, setOpenDocumentFromDiagramModal] = useState(false);
  const [diagramDocumentLoading, setDiagramDocumentLoading] = useState(false);

  const [, setDocumentComponentsList] = useState(null);

  const ckeRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { projectsByIdRes, getProjectAllDocumentsRes } = useAppSelector((state) => state.projects);
  const { getDocumentFavoriteComponentsRes } = useAppSelector((state) => state.documentComponents);
  const { selectedDiagram, getDiagramsListRes } = useAppSelector((state) => state.diagrams);
  const { collapseSidebar } = useAppSelector((state) => state.profile);

  const { tBarsList } = useAppSelector((state) => state.tbars);
  const { prosConsList } = useAppSelector((state) => state.prosCons);
  const { decisionMatrixList } = useAppSelector((state) => state.decisionMatrixs);
  const { swotsList } = useAppSelector((state) => state.swot);

  const queryStringObj = getQueryParams(window.location.href);
  const { getNodes, getEdges } = useReactFlow();
  const navigate = useNavigate();
  const { state: historyState } = useLocation();

  const context = useContext(DetailsContext);

  const {
    loader,
    autosave,
    tabs,
    selectedDocument,
    multiSelectedOption,
    updateDocument,
    setDocumentHtml,
    switchMultiSelectedOption,
  } = context;

  useEffect(() => {
    checkingDetailTabShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSelectedOption]);

  useEffect(() => {
    if (queryStringObj?.active === 't-chart') {
      if (!tBarsList?.length) {
        setIsHideBox(true);
      }

      if (tBarsList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'pros-cons') {
      if (!prosConsList?.length) {
        setIsHideBox(true);
      }

      if (prosConsList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'decision-matrix') {
      if (!decisionMatrixList?.length) {
        setIsHideBox(true);
      }

      if (decisionMatrixList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'swot') {
      if (!swotsList?.length) {
        setIsHideBox(true);
      }

      if (swotsList?.length > 0) {
        setIsHideBox(false);
      }
    }

    if (queryStringObj?.active === 'diagrams') {
      if (!getDiagramsListRes?.length) {
        setIsHideBox(true);
      }

      // if (getDiagramsListRes?.length > 0) {
      //   setIsHideBox(false);
      // }
    }

    if (queryStringObj?.active === 'documents') {
      if (!getProjectAllDocumentsRes?.length) {
        setIsHideBox(true);
      }

      if (getProjectAllDocumentsRes?.length > 0) {
        setIsHideBox(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tBarsList?.length,
    prosConsList?.length,
    decisionMatrixList?.length,
    swotsList?.length,
    getDiagramsListRes?.length,
    getProjectAllDocumentsRes?.length,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(getDocumentFavoriteComponents({ accessToken }));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getDocumentFavoriteComponentsRes?.length > 0) {
      const newList = [];

      getDocumentFavoriteComponentsRes?.forEach((data: any) => {
        newList.push({
          title: data?.title,
          data: () => data?.content,
          description: data?.short_description,
        });
      });

      setDocumentComponentsList(getDocumentFavoriteComponentsRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDocumentFavoriteComponentsRes]);

  useEffect(() => {
    setTimeout(() => {
      const buttons: any = document.querySelectorAll('.ck-button');
      const dropdowns: any = document.querySelectorAll('.ck-dropdown');
      const separators = document.querySelectorAll('.ck-toolbar__separator');

      separators.forEach(function (separator, index) {
        separator.classList.add('separator-' + (index + 1));
      });

      /* For Buttons */
      buttons.forEach(function (button: any) {
        const labelText = button.querySelector('.ck-button__label').textContent.trim();

        if (labelText === 'Undo') {
          button.id = 'undo-button';
        } else if (labelText === 'Redo') {
          button.id = 'redo-button';
        } else if (labelText === 'Strikethrough') {
          button.id = 'strikethrough-button';
        } else if (labelText === 'Italic') {
          button.id = 'italic-button';
        } else if (labelText === 'Bold') {
          button.id = 'bold-button';
        } else if (labelText === 'Link') {
          button.id = 'link-button';
        } else if (labelText === 'Underline') {
          button.id = 'underline-button';
        } else if (labelText === 'Subscript') {
          button.id = 'subscript-button';
        } else if (labelText === 'Superscript') {
          button.id = 'superscript-button';
        } else if (labelText === 'Remove Format') {
          button.id = 'remove-format-button';
        } else if (labelText === 'To-do List') {
          button.id = 'to-do-list-button';
        } else if (labelText === 'Multi-level List') {
          button.id = 'multi-level-list-button';
        } else if (labelText === 'Decrease indent') {
          button.id = 'decrease-indent-button';
        } else if (labelText === 'Increase indent') {
          button.id = 'increase-indent-button';
        } else if (labelText === 'Table of contents') {
          button.id = 'table-of-contents-button';
        } else if (labelText === 'Show blocks') {
          button.id = 'show-blocks-button';
        } else if (labelText === 'Block quote') {
          button.id = 'block-quote-button';
        } else if (labelText === 'Code') {
          button.id = 'code-button';
        } else if (labelText === 'Find and replace') {
          button.id = 'find-and-replace-button';
        } else if (labelText === 'Insert HTML') {
          button.id = 'insert-html-button';
        } else if (labelText === 'Horizontal line') {
          button.id = 'horizontal-line-button';
        } else if (labelText === 'Select all') {
          button.id = 'select-all-button';
        } else if (labelText === 'Enable editing') {
          button.id = 'enable-editing-button';
        } else if (labelText === 'Align left') {
          button.id = 'align-left-button';
        } else if (labelText === 'Align right') {
          button.id = 'align-right-button';
        } else if (labelText === 'Align center') {
          button.id = 'align-center-button';
        } else if (labelText === 'Justify') {
          button.id = 'justify-button';
        } else if (labelText === 'Accessibility help') {
          button.id = 'accessibility-help-button';
        }
      });

      /* For Dropdowns */
      dropdowns.forEach(function (drop: any) {
        const labelText = drop.querySelector('.ck-button__label').textContent.trim();

        if (labelText === 'Insert template') {
          drop.id = 'insert-template-dropdown';
        } else if (labelText === 'Insert table') {
          drop.id = 'insert-table-dropdown';
        } else if (labelText === 'Insert media') {
          drop.id = 'insert-media-dropdown';
        } else if (labelText === 'Upload image from computer') {
          drop.id = 'upload-image-dropdown';
        } else if (labelText === 'Upload image from computer') {
          drop.id = 'upload-image-dropdown';
        } else if (labelText === 'Special characters') {
          drop.id = 'special-characters-dropdown';
        } else if (labelText === 'Highlight') {
          drop.id = 'highlight-dropdown';
        }
      });
    }, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, selectedDocument]);

  useEffect(() => {
    setValue(selectedDocument?.content ?? '');
  }, [selectedDocument]);

  useEffect(() => {
    if (autosave && isTextEditorDirty) {
      const timeOutId = setTimeout(() => {
        updatingDocument();
      }, 5000);

      return () => {
        clearTimeout(timeOutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autosave, isTextEditorDirty]);

  useEffect(() => {
    return () => {
      if (isTextEditorDirty) {
        updatingDocument();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calculatingWidth = throttle(() => {
      const mainWrapper = document.getElementById('project_detail_id');
      const contentArea = document.getElementById('project-detail-content');

      if (contentArea && mainWrapper) {
        const contentWidth =
          mainWrapper.clientWidth - ((isHideRightBox ? 0 : rightWidth) + (isHideBox ? 0 : width) + 103);

        contentArea.style.width = `${contentWidth}px`;
      }
    }, 100);

    calculatingWidth();
    window.addEventListener('resize', calculatingWidth);

    return () => {
      window.removeEventListener('resize', calculatingWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, rightWidth, isHideBox, isHideRightBox, collapseSidebar]);

  useEffect(() => {
    if (
      multiSelectedOption === 4 ||
      multiSelectedOption === 5 ||
      multiSelectedOption === 6 ||
      multiSelectedOption === 7
    ) {
      setIsHideBox(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!historyState?.conversation) return;

    setRightSelectedOption(1);
    setIsHideRightBox(false);
    setSelectedConversation(historyState.conversation);

    // Clear conversation from history state
    window.history.replaceState(
      {
        ...historyState,
        conversation: undefined,
      },
      ''
    );
  }, [historyState]);

  const checkingDetailTabShow = useCallback(() => {
    const rightOptions: any = [
      {
        id: 1,
        name: <SparklesIcon className='h-[17px] mx-auto' />,
      },
    ];

    if (
      (queryStringObj?.active === 't-chart' && tBarsList?.length > 0) ||
      (queryStringObj?.active === 'pros-cons' && prosConsList?.length > 0) ||
      (queryStringObj?.active === 'decision-matrix' && decisionMatrixList?.length > 0) ||
      (queryStringObj?.active === 'swot' && swotsList?.length > 0)
    ) {
      rightOptions.push({
        id: 2,
        name: <BookOpenIcon className='h-[17px] mx-auto' />,
      });
    } else {
      if (rightSelectedOption === 2) {
        setIsHideRightBox(true);
      }
    }

    setRightOptionsList(rightOptions);
  }, [queryStringObj, tBarsList, prosConsList, decisionMatrixList, swotsList, rightSelectedOption]);

  const updatingDocument = useCallback(async () => {
    setDocumentSaveLoading(true);

    try {
      const res = await updateDocument(
        {
          content: value,
        },
        selectedDocument
      );

      setIsTextEditorDirty(false);

      return res;
    } finally {
      setDocumentSaveLoading(false);
    }
  }, [updateDocument, value, selectedDocument]);

  const updatingProjectDiagram = useCallback(async () => {
    try {
      setDiagramSaveLoading(true);
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const res = await dispatch(
        updateDiagram({
          body: {
            title: selectedDiagram?.title,
            diagram_status: 'Approved',
            design: JSON.stringify({
              nodes: getNodes(),
              edges: getEdges(),
            }),
          },
          accessToken,
          project_id: selectedDiagram?.project_id,
          diagram_id: selectedDiagram?.id,
        })
      );
      const requestStatus = res?.meta?.requestStatus;

      if (requestStatus === 'rejected') {
        throw new Error('Request rejected');
      }

      setIsDiagramDirty(false);
      setDiagramSaveLoading(false);

      return res?.payload?.data;
    } catch (error) {
      setDiagramSaveLoading(false);
      console.error('Error getting access token:', error);
    }
  }, [dispatch, getAccessTokenSilently, getNodes, getEdges, selectedDiagram]);

  const addItemToTextEditor = useCallback((text: string) => {
    const newText = '\n\n```plaintext\n' + text + '\n```';

    if (ckeRef.current) {
      const currentPosition = ckeRef.current.model.document.selection.getFirstPosition();

      // Access the model and then call insertContent
      ckeRef.current.model.change((writer: any) => {
        writer.insert(newText, currentPosition);
      });
    }
  }, []);

  const onChangeMultiOptions = useCallback(
    (id: number) => {
      if (multiSelectedOption !== id) {
        setIsHideBox(false);
      }

      if (multiSelectedOption === id) {
        setIsHideBox(!isHideBox);
      }

      switchMultiSelectedOption(id);
    },
    [multiSelectedOption, isHideBox, switchMultiSelectedOption]
  );

  const openSpecialChat: OpenSpecialChatFn = (
    { chatTitle, chatDescription, chatType, agentName },
    { entityId, data }
  ) => {
    setRightSelectedOption(1);
    setIsHideRightBox(false);
    setSelectedConversation({
      id: nanoid(),
      title: chatTitle,
      project_id: getLastIdFromUrl(window.location.pathname),
      agent_name: agentName,
      description: chatDescription,
      conversation_type: chatType,
      messageContext: {
        entityId,
        data,
      },
    });
  };

  const onChangeRightOptions = useCallback(
    (id: number) => {
      if (rightSelectedOption !== id) {
        setIsHideRightBox(false);
      }

      if (rightSelectedOption === id) {
        setIsHideRightBox(!isHideRightBox);
        setRightSelectedOption(0);
      } else {
        setRightSelectedOption(id);
      }
    },
    [rightSelectedOption, isHideRightBox]
  );

  const onChangedDocumentContent = useCallback(
    (documentValue: string) => {
      setValue(documentValue);
      setDocumentHtml(documentValue);
      setIsTextEditorDirty(true);
    },
    [setDocumentHtml]
  );

  const savingProjectAsTemp = useCallback(
    async (body: any) => {
      if (context.type !== 'project') return;

      setFormLoader(true);

      try {
        await context.saveProjectAsTemplate(body);
        setFormLoader(false);
        setOpenSaveProjectTemp(false);
      } finally {
        setFormLoader(false);
      }
    },
    [context]
  );

  const generatingFlowChartWithAI = useCallback(
    (text: string) => {
      const payload = {
        prompt: text,
      };

      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();

          if (accessToken) {
            setGenerateFlowChartLoader(true);

            await dispatch(
              promptMessage({
                body: payload,
                accessToken,
                type: 'diagram-generator',
              })
            ).then(async (res) => {
              setGeneratedAICode(res?.payload?.data?.message);

              setGenerateFlowChartLoader(false);
              setOpenGenerateModal(false);
            });
          }
        } catch (error) {
          console.error('Error getting access token:', error);
          setGenerateFlowChartLoader(false);
        }
      };

      fetchData();
    },
    [dispatch, getAccessTokenSilently]
  );

  const generateDocumentFromDiagram = useCallback(
    (data: any) => {
      const payload = {
        prompt:
          JSON.stringify({
            nodes: getNodes(),
            edges: getEdges(),
          }) +
          `Please note in the reponse if you see first <h1> tag then replace that only tag with this <h1>${data?.title}</h1> and only include html tags in reaponse don't add any back tick or html text in the start and end of the reponse`,
      };

      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();

          if (accessToken) {
            setDiagramDocumentLoading(true);

            await dispatch(
              promptMessage({
                body: payload,
                accessToken,
                type: 'document-generator',
              })
            ).then(async (res) => {
              if (res?.payload?.data?.message) {
                const payload = {
                  title: data?.title,
                  content: res?.payload?.data?.message,
                  complexity: 'medium',
                };

                await dispatch(
                  createProjectDocument({
                    body: payload,
                    accessToken,
                    project_id: selectedDiagram?.project_id,
                  })
                ).then((res) => {
                  if (res?.payload?.data) {
                    navigate(
                      `/projects/${res?.payload?.data?.project_id}?active=documents&id=${res?.payload?.data?.id}`
                    );

                    setDiagramDocumentLoading(false);
                    setOpenDocumentFromDiagramModal(false);
                  }
                });
              }
            });
          }
        } catch (error) {
          console.error('Error getting access token:', error);
          setGenerateFlowChartLoader(false);
        }
      };

      fetchData();
    },
    [dispatch, navigate, getAccessTokenSilently, getNodes, getEdges, selectedDiagram]
  );

  useSaveListener('documents', async () => {
    if (isTextEditorDirty) {
      return await updatingDocument();
    }

    return null;
  });

  useSaveListener('diagrams', async () => {
    if (isDiagramDirty) {
      return await updatingProjectDiagram();
    }

    return null;
  });

  const filteredLeftOptions = useMemo(() => {
    const supportedIds = tabs.map((tabName) => {
      return MULTI_SELECTED_OPTIONS.find(({ name }) => name === tabName)?.id;
    });

    return leftOptions.filter(({ id }: any) => supportedIds.includes(id));
  }, [tabs]);

  // const handleTourCallback = useCallback((data: any) => {
  //   const { status } = data;

  //   if (status === 'finished' || status === 'skipped') {
  //     // Handle tour completion or skip actions here
  //     console.log('Tour finished or skipped');
  //   }
  // }, []);

  return (
    <WrapperLoader loading={loader}>
      <div
        className={`-mb-6 flex`}
        id='project-container'
      >
        <div className='w-full'>
          <div
            className={`flex w-full`}
            id='project_detail_id'
          >
            <div
              // className="bg-white border border-zinc-100 rounded-xl ml-2 mb-2 shadow-xl z-30"
              // style={{ height: "calc(100vh - 202px)" }}
              className='p-0.5 bg-white border-r border-zinc-200 z-30'
              style={{
                height: `calc(100vh - ${
                  (multiSelectedOption === 2 && 49) ||
                  ((multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) && 49) ||
                  ((multiSelectedOption === 4 ||
                    multiSelectedOption === 5 ||
                    multiSelectedOption === 6 ||
                    multiSelectedOption === 7) &&
                    57)
                }px)`,
              }}
            >
              <nav
                className='-mb-px flex flex-col'
                aria-label='Tabs'
              >
                {filteredLeftOptions.map((tab: any) => (
                  <div
                    key={tab.id}
                    className='mb-2'
                  >
                    <span
                      data-tooltip-id={`tab-tooltip-${tab?.id}`}
                      onClick={() => onChangeMultiOptions(tab.id)}
                      className={classNames(
                        tab.id === multiSelectedOption
                          ? 'text-primaryLight bg-zinc-50 border border-blue-100'
                          : 'hover:text-primaryLight border border-transparent',
                        tab.classes,
                        'group transition-colors',
                        'whitespace-nowrap w-full inline-block py-2 px-1 text-xs font-medium cursor-pointer text-center hover:bg-zinc-50 rounded-md'
                      )}
                    >
                      <span>{tab.name}</span>
                      <span
                        className={classNames(
                          'text-[8px] text-zinc-400 transition-colors group-hover:text-primaryLight',
                          tab.id === multiSelectedOption && 'text-primaryLight'
                        )}
                      >
                        {tab.label}
                      </span>
                    </span>

                    <Tooltip
                      id={`tab-tooltip-${tab?.id}`}
                      place='right'
                    >
                      <div className='line-clamp-1'>
                        {(tab?.id === 1 && 'Documents') ||
                          (tab?.id === 2 && 'Diagrams') ||
                          (tab?.id === 3 && 'Ai Chats') ||
                          (tab?.id === 4 && 'T-Chart') ||
                          (tab?.id === 5 && 'Pros & Cons') ||
                          (tab?.id === 6 && 'Decision Matrix') ||
                          (tab?.id === 7 && 'SWOT Analysis') ||
                          (tab?.id === 8 && 'Settings') ||
                          ''}
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </nav>
            </div>
            <MovingComp
              side='left'
              setWidth={setWidth}
              width={isHideBox ? 0 : width}
            >
              <div
                className='bg-white pl-2 pr-4 z-30'
                style={{
                  height: `calc(100vh - 48px)`,
                }}
              >
                {multiSelectedOption === 1 && (
                  <Documents
                    isTextEditorDirty={isTextEditorDirty}
                    updatingProjectDocument={updatingDocument}
                  />
                )}

                {multiSelectedOption === 2 && (
                  <Diagrams
                    isTextEditorDirty={isTextEditorDirty}
                    updatingProjectDocument={updatingDocument}
                  />
                )}

                {multiSelectedOption === 3 && (
                  <div className={`mt-3`}>
                    <ChatGPT
                      addItemToTextEditor={addItemToTextEditor}
                      selectedConversation={selectedConversation}
                      setSelectedConversation={setSelectedConversation}
                    />
                  </div>
                )}

                {multiSelectedOption === 4 && <TChartList checkingDetailTabShow={checkingDetailTabShow} />}

                {multiSelectedOption === 5 && <ProsConsList checkingDetailTabShow={checkingDetailTabShow} />}

                {multiSelectedOption === 6 && <DecisionMatrixList checkingDetailTabShow={checkingDetailTabShow} />}

                {multiSelectedOption === 7 && <SwotList checkingDetailTabShow={checkingDetailTabShow} />}

                {multiSelectedOption === 8 && <EditProjectForm />}
              </div>
            </MovingComp>

            <div id='project-detail-content'>
              {multiSelectedOption === 2 && (
                <>
                  {getDiagramsListRes?.length > 0 ? (
                    <div className={`relative bg-white`}>
                      <DiagramActions
                        setOpenGenerateModal={() => setOpenGenerateModal(true)}
                        setOpenDocumentFromDiagramModal={() => {
                          updatingProjectDiagram();
                          setOpenDocumentFromDiagramModal(true);
                        }}
                        diagramSaveLoading={diagramSaveLoading}
                        updatingProjectDiagram={updatingProjectDiagram}
                        generateDocumentFromDiagram={generateDocumentFromDiagram}
                        diagram={generatedAICode}
                        openSpecialChat={openSpecialChat}
                      />
                      <FlowChart diagram={generatedAICode} />
                    </div>
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <DiagramsEmpty />
                    </div>
                  )}
                </>
              )}

              {(multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) &&
                (getProjectAllDocumentsRes?.length > 0 ? (
                  <div>
                    <DocumentHeader
                      documentSaveLoading={documentSaveLoading}
                      updatingProjectDocument={updatingDocument}
                      onOpenSaveProjectTemp={setOpenSaveProjectTemp}
                      openSpecialChat={openSpecialChat}
                    />
                    <TextEditor
                      value={value}
                      setValue={onChangedDocumentContent}
                      ckeRef={ckeRef}
                      // tempList={documentComponentsList}
                    />
                  </div>
                ) : (
                  <div
                    className='flex items-center justify-center'
                    style={{ height: 'calc(100vh - 140px)' }}
                  >
                    <DocumentEmpty />
                  </div>
                ))}

              {multiSelectedOption === 4 && (
                <>
                  {tBarsList?.length > 0 ? (
                    <TChartAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <TChartEmpty />
                    </div>
                  )}
                </>
              )}
              {multiSelectedOption === 5 && (
                <>
                  {prosConsList?.length > 0 ? (
                    <ProsConsAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <ProsConsEmpty />
                    </div>
                  )}
                </>
              )}
              {multiSelectedOption === 6 && (
                <>
                  {decisionMatrixList?.length > 0 ? (
                    <DecisionMatrixAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <DecisionsMatrixEmpty />
                    </div>
                  )}
                </>
              )}
              {multiSelectedOption === 7 && (
                <>
                  {swotsList?.length > 0 ? (
                    <SwotAnalysis />
                  ) : (
                    <div
                      className='flex items-center justify-center'
                      style={{ height: 'calc(100vh - 140px)' }}
                    >
                      <SwotEmpty />
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Right Side Bar */}
            <MovingComp
              side='right'
              setWidth={setRightWidth}
              width={isHideRightBox ? 0 : rightWidth}
            >
              <div
                className='bg-white z-30 border-l-2 border-zinc-200'
                style={{
                  height: `calc(100vh - 48px)`,
                }}
              >
                <div className={`${rightSelectedOption === 1 ? 'block' : 'hidden'}`}>
                  <ChatGPT
                    addItemToTextEditor={addItemToTextEditor}
                    selectedConversation={selectedConversation}
                    setSelectedConversation={setSelectedConversation}
                  />
                </div>

                {rightSelectedOption === 2 && (
                  <div>
                    {queryStringObj?.active === 't-chart' && <TChartDetails />}

                    {queryStringObj?.active === 'pros-cons' && <ProsConsDetails />}

                    {queryStringObj?.active === 'decision-matrix' && <DecisionMatrixDetails />}

                    {queryStringObj?.active === 'swot' && <SwotDetails />}
                  </div>
                )}
              </div>
            </MovingComp>

            <div
              // className="bg-white border border-zinc-100 rounded-xl ml-2 mb-2 shadow-xl z-30"
              // style={{ height: "calc(100vh - 202px)" }}
              className='bg-white border-l border-zinc-200 z-30'
              style={{
                height: `calc(100vh - ${
                  (multiSelectedOption === 2 && 49) ||
                  ((multiSelectedOption === 1 || multiSelectedOption === 3 || multiSelectedOption === 8) && 49) ||
                  ((multiSelectedOption === 4 ||
                    multiSelectedOption === 5 ||
                    multiSelectedOption === 6 ||
                    multiSelectedOption === 7) &&
                    57)
                }px)`,
              }}
            >
              <nav
                className='-mb-px flex flex-col'
                aria-label='Tabs'
              >
                {rightOptionsList?.length > 0 &&
                  rightOptionsList.map((tab: any) => (
                    <div key={tab.id}>
                      <span
                        data-tooltip-id={`right-tab-tooltip-${tab?.id}`}
                        onClick={() => onChangeRightOptions(tab.id)}
                        className={classNames(
                          tab.id === rightSelectedOption
                            ? 'text-blue-500 bg-zinc-50 border border-blue-100'
                            : 'hover:text-blue-500 border border-transparent',
                          'whitespace-nowrap inline-block border-b-2 p-3 text-xs font-medium cursor-pointer text-center hover:bg-zinc-50 rounded-xl'
                        )}
                        style={{ marginLeft: 0, border: 0 }}
                      >
                        {tab.name}
                      </span>

                      <Tooltip
                        id={`right-tab-tooltip-${tab?.id}`}
                        place='right'
                      >
                        <div className='line-clamp-1'>
                          {(tab?.id === 1 && 'Ai Chats') || (tab?.id === 2 && 'Details')}
                        </div>
                      </Tooltip>
                    </div>
                  ))}
              </nav>
            </div>
          </div>
        </div>
      </div>

      {context.type === 'project' && (
        <CustomDrawer.SaveProjectAsTemplate
          open={openSaveProjectTemp}
          onCloseModal={() => setOpenSaveProjectTemp(false)}
        >
          <SaveProjectAsTemplate
            onCloseModal={() => setOpenSaveProjectTemp(false)}
            onSubmitForm={savingProjectAsTemp}
            loader={formLoader}
            projectData={projectsByIdRes}
          />
        </CustomDrawer.SaveProjectAsTemplate>
      )}

      <CustomModal
        open={openGenerateModal}
        onCloseModal={() => setOpenGenerateModal(false)}
        title='Generate Diagram'
        size='max-w-lg'
      >
        <FlowChartGenerateForm
          onCloseModal={() => setOpenGenerateModal(false)}
          loading={generateFlowChartLoader}
          onSubmitData={generatingFlowChartWithAI}
        />
      </CustomModal>

      <CustomModal
        open={openDocumentFromDiagramModal}
        onCloseModal={() => setOpenDocumentFromDiagramModal(false)}
        title='Generate Document'
        size='max-w-lg'
      >
        <DocumentFromDiagramForm
          onCloseModal={() => setOpenDocumentFromDiagramModal(false)}
          loading={diagramDocumentLoading}
          onSubmitData={generateDocumentFromDiagram}
        />
      </CustomModal>

      {/* <AppTour
        steps={steps}
        callback={handleTourCallback}
      /> */}
    </WrapperLoader>
  );
});

export default ProjectsDetails;
