import React from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

interface LabelWithActionsProps {
  label: any;
  onEdit: () => void;
  onDelete: () => void;
}

export default function LabelWithActions({
  label,
  onEdit,
  onDelete,
}: LabelWithActionsProps) {
  return (
    <div className="relative group">
      {label}
      <div className="p-1 items-center justify-center bg-white rounded-lg absolute right-0 top-0 hidden group-hover:flex">
        <PencilIcon
          className="h-4 w-4 mr-1 cursor-pointer text-customLightBlue hover:text-yellow-700"
          onClick={onEdit}
        />
        <TrashIcon
          onClick={onDelete}
          className="h-4 w-4 cursor-pointer text-customLightBlue hover:text-red-700"
        />
      </div>
    </div>
  );
}
