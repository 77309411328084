import { Category } from 'src/type';
import { categoriesList, statusList } from './utilities';

class Filter {
  readonly categoryTitleToValueMap = new Map(categoriesList.map((item) => [item.title, item.value]));
  readonly categoryValueToTitleMap = new Map(categoriesList.map((item) => [item.value, item.title]));
  readonly statusValueToTitleMap = new Map(statusList.map((item) => [item.value, item.title]));

  groupByCategory = <T extends { category: Category }>(items: T[]) => {
    const groupedItems = items.reduce<Record<string, T[]>>((acc, item) => {
      const categoryValue = this.categoryTitleToValueMap.get(item?.category);

      if (!categoryValue) {
        return acc;
      }

      if (!acc[categoryValue]) {
        acc[categoryValue] = [];
      }

      acc[categoryValue].push(item);

      return acc;
    }, {});

    return {
      all: items,
      ...groupedItems,
    } as Partial<Record<Category, T[]>>;
  };

  byCategory =
    <T extends { category: Category }>(selectedCategory: Category) =>
    (items: T[]) => {
      if (selectedCategory === 'all') return items;

      const selectedCategoryTitle = this.categoryValueToTitleMap.get(selectedCategory);

      return items.filter((item) => item?.category === selectedCategoryTitle);
    };

  byTitle =
    <T extends { title: string }>(search: string) =>
    (items: T[]) => {
      if (!search) return items;

      return items.filter((item) => item?.title?.toLowerCase()?.includes(search?.toLowerCase()));
    };

  byStatus =
    <T extends { status: string }>(selectedStatus: string) =>
    (items: T[]) => {
      if (selectedStatus === 'all') return items;

      const selectedStatusTitle = this.statusValueToTitleMap.get(selectedStatus);

      return items.filter((item) => item?.status === selectedStatusTitle);
    };
}

export const filter = new Filter();
