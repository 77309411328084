import React from "react";

interface StatusCircleProps {
  status: string;
}

const StatusCircle = ({ status }: StatusCircleProps) => {
  return (
    <span
      className={`w-[14px] h-[13px] rounded-full flex bg- ${
        (status === "Not Started" &&
          "bg-slate-700 border-4 border-slate-300") ||
        (status === "In Progress" && " bg-blue-500 border-4 border-blue-200") ||
        (status === "Concluded" && "bg-green-700 border-4 border-green-200") ||
        (status === "Canceled" && "bg-red-700 border-4 border-red-200") ||
        ""
      }`}
    />
  );
};

export default StatusCircle;
