import React from "react";
import { PlusIcon } from "@heroicons/react/24/outline";

interface RoundButtonProps {
  onClickBtn: () => void;
  asSpan?: boolean;
}

export default function RoundButton({ onClickBtn, asSpan = false }: RoundButtonProps) {
  const Element = asSpan ? 'span' : 'button'

  return (
    <Element
      onClick={onClickBtn}
      type={asSpan ? undefined : "button"}
      className="inline-block rounded-full bg-blue-500 p-1 text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
    >
      <PlusIcon className="h-4 w-4" aria-hidden="true" />
    </Element>
  );
}
