import './style.css';

import { MutableRefObject, memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  createConversation,
  getAllMessages,
  getAllThreadMessages,
  promptStreamMessage,
  promptStreamMessageThread,
} from '../../../../redux/chatGPT/chatGPTApi';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { PaintBrushIcon, Cog6ToothIcon, ChartBarIcon, WrenchIcon } from '@heroicons/react/24/outline';
import { classNames, getLastIdFromUrl } from '../../../../utils/utilities';
import { useAuth0 } from '@auth0/auth0-react';
import { useResizeObserver } from 'src/hooks/useResizeObserver';

import ChatGPTInput, { type InputRef } from '../../../../components/chatGPTInput';
import WrapperLoader from 'src/components/wrapperLoader';
import AdvancedCopyAndPaste from 'src/components/advancedCopyAndPaste';
import type { SelectedConversation } from 'src/type';
import { useWatch } from 'src/hooks/useWatch';
import UserMessage from './userMessage';
import { useMessageStreamer } from '../../../../hooks/useMessageStreamer';

import illustrationSrc from '../../../../assets/images/mascot.svg';
import linkedInIcon from '../../../../assets/images/linkedin-logo.png';
import { isValidationChat } from 'src/utils/special-chat-assistants';
import AssistantMessage from './assistantMessage';

interface ChatGPTProps {
  addItemToTextEditor: Function;
  selectedConversation: SelectedConversation;
  setSelectedConversation: (conversation: SelectedConversation | null) => void;
}

interface Suggestion {
  icon: 'design' | 'operate' | 'optimize' | 'troubleshoot';
  title: string;
  description: string;
}

interface ChatSuggestionsProps {
  onSuggestionSelected: (description: string) => void;
}

interface ConversationHeaderProps {
  chatTitle: string;
  className?: string;
  style?: React.CSSProperties;
  onSuggestionSelected: (suggestion: string) => void;
}

const ChatSuggestions: React.FC<ChatSuggestionsProps> = ({ onSuggestionSelected }) => {
  const suggestions: Suggestion[] = [
    {
      icon: 'design',
      title: 'Executive Summary',
      description: 'Generate an executive summary document for IT Solution Design.',
    },
    {
      icon: 'operate',
      title: 'Requirements',
      description: 'Write a list of requirements for IT Solution Design, during a discovery call with the client.',
    },
    {
      icon: 'optimize',
      title: 'Solution Design',
      description: 'How should I approach documenting design for IT Solution?',
    },
    {
      icon: 'troubleshoot',
      title: 'Plan and Strategize',
      description: 'What kind of documentation should my cloud solution design project include?',
    },
  ];

  return (
    <div className='bg-white p-4 rounded-lg shadow-[0_0_2px_rgba(0,0,0,0.12),_0_2px_4px_rgba(0,0,0,0.14)]'>
      <p className='mb-2 text-sm text-customDarkBlue'>
        You can use prompt examples below as a starting point or click the link to explore more options for optimal
        results. Feel free to reach out to me directly on LinkedIn if you need further assistance or visit our page.
      </p>
      <div className='flex gap-4 mb-4'>
        <a
          href='https://solutionpilot.ai/templates'
          className='text-blue-600 text-sm hover:underline'
          target='_blank'
          rel='noopener noreferrer'
        >
          Learn more
        </a>
        <a
          href='https://www.linkedin.com/in/sebastiancwiek'
          className='text-blue-600 text-sm hover:underline flex items-center gap-1'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={linkedInIcon}
            alt='LinkedIn'
            className='w-4 h-4'
          />
          Sebastian Cwiek
        </a>
        <a
          href='https://www.linkedin.com/company/solution-pilot'
          className='text-blue-600 text-sm hover:underline flex items-center gap-1'
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            src={linkedInIcon}
            alt='LinkedIn'
            className='w-4 h-4'
          />
          Solution Pilot
        </a>
      </div>
      <br />
      <p className='mb-2 text-sm font-medium text-customDarkBlue'>
        Select one of the suggestions below to get started.
      </p>
      <div className='space-y-2'>
        {suggestions.map(({ icon, title, description }, index) => (
          <button
            key={index}
            type='button'
            className='p-[2px] bg-gradient-to-r hover:from-blue-400 hover:via-purple-300 hover:to-purple-500 rounded-lg transition duration-300 ease-in-out w-full'
            onClick={() => onSuggestionSelected(description)}
          >
            <div className='w-full text-left p-3 bg-white rounded-md border border-gray-200 group'>
              <div className='flex items-start'>
                <span className='mr-3'>
                  {icon === 'design' && (
                    <PaintBrushIcon className='w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors' />
                  )}
                  {icon === 'operate' && (
                    <Cog6ToothIcon className='w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors' />
                  )}
                  {icon === 'optimize' && (
                    <ChartBarIcon className='w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors' />
                  )}
                  {icon === 'troubleshoot' && (
                    <WrenchIcon className='w-5 h-5 text-gray-400 group-hover:text-[#2D61D2] transition-colors' />
                  )}
                </span>
                <div>
                  <h3 className='text-sm font-medium text-gray-900 group-hover:text-[#2D61D2] transition-colors'>
                    {title}
                  </h3>
                  <p className='text-sm text-gray-500'>{description}</p>
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

const ConversationHeader: React.FC<ConversationHeaderProps> = ({
  chatTitle,
  className,
  style,
  onSuggestionSelected,
}) => {
  return (
    <div
      className={classNames('flex flex-col items-center justify-center gap-6', className)}
      style={style}
    >
      <div className='flex flex-col items-center'>
        <img
          src={illustrationSrc}
          alt='Logo'
          aria-hidden
          width={140}
          height={96}
        />
        <h3 className='mt-3 text-base font-semibold text-center text-customDarkBlue'>{chatTitle}</h3>
        <p className='mt-2 text-sm text-gray-500 text-center'>
          Assistant can make mistakes, always review the accuracy of responses. Use it wisely but remember you are the
          brain behind the wheel.
        </p>
      </div>
      <ChatSuggestions onSuggestionSelected={onSuggestionSelected} />
    </div>
  );
};

const Message = Object.assign(
  memo<
    Readonly<{
      data: any;
      addItemToTextEditor: Function;
      isStreamTarget: boolean;
      chatType: string;
      onAddDocument: (markdown: string) => void;
    }>
  >(function ({ data, addItemToTextEditor, isStreamTarget, chatType, onAddDocument }) {
    const markdownElRef = useRef<HTMLDivElement | null>(null);

    const { user } = useAuth0();
    const { profileData } = useAppSelector<any>((state: any) => state.profile);

    return (
      <div
        className={classNames(
          `relative flex gap-x-4 rounded-lg max-w-full overflow-auto`,
          data.role === 'user' ? 'w-fit self-end bg-[#ebf3fc]' : 'w-full bg-white'
        )}
        style={{
          boxShadow: '0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14)',
        }}
      >
        <div className={classNames('w-full flex items-start py-2 px-4')}>
          <div
            className='flex-grow'
            style={{ width: 'calc(100% - 40px)' }}
          >
            <div
              className={classNames(
                'mb-2 py-2 border-b flex items-center',
                data.role === 'user' ? 'border-[#9B59B6]' : 'border-[#13B48B]'
              )}
            >
              <div className='mr-2'>
                <img
                  src={`${
                    data.role === 'user'
                      ? user?.picture
                      : 'https://static.vecteezy.com/system/resources/previews/021/059/825/original/chatgpt-logo-chat-gpt-icon-on-green-background-free-vector.jpg'
                  }`}
                  alt=''
                  className='relative h-6 w-6 flex-none rounded-full bg-gray-50'
                />
              </div>
              <div className='mr-4 py-0.5 text-sm text-customLightBlue font-semibold'>
                {data.role === 'user'
                  ? profileData?.first_name
                    ? `${profileData?.first_name} ${profileData?.last_name}`
                    : profileData?.email
                  : 'Chat GPT'}
              </div>
              {/* <time
                dateTime='2023-01-24T09:20'
                className='ml-auto min-w-20 py-0.5 text-right flex-none text-[12px]/[1] text-gray-500 italic'
              >
                {timeAgoOrFormattedDate(data?.created_at)}
              </time> */}
              {data.role === 'assistant' && (
                <AssistantMessage.MessageActions
                  markdownElRef={markdownElRef}
                  onAddDocument={onAddDocument}
                />
              )}
            </div>

            {data.role === 'user' ? (
              <UserMessage
                chatType={chatType}
                content={data.content}
              />
            ) : (
              <AdvancedCopyAndPaste.Selectable>
                <AssistantMessage
                  content={data?.content}
                  addItemToTextEditor={addItemToTextEditor}
                  markdownElRef={markdownElRef}
                  isStreamTarget={isStreamTarget}
                />
              </AdvancedCopyAndPaste.Selectable>
            )}
          </div>
        </div>
      </div>
    );
  }),
  { displayName: 'Message' }
);

function useAutoScroll() {
  const [isAtBottom, setIsAtBottom] = useState(true);
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null);
  const prevHeightRef = useRef<number>(0);

  const scrollToBottom = useCallback((options?: ScrollOptions) => {
    scrollableContainerRef.current?.scrollTo({
      top: scrollableContainerRef.current.scrollHeight,
      ...options,
    });
    setIsAtBottom(true);
  }, []);

  const handleScroll = useCallback(() => {
    if (scrollableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollableContainerRef.current;
      setIsAtBottom(scrollHeight - scrollTop <= clientHeight + 5);
    }
  }, []);

  const observeListItems = useResizeObserver(
    useCallback(
      ([
        {
          contentRect: { height },
        },
      ]) => {
        if (isAtBottom && height > prevHeightRef.current) {
          scrollToBottom();
        }
        prevHeightRef.current = height;
      },
      [isAtBottom, scrollToBottom]
    )
  );

  const setScrollableContainerRef = useCallback((node: HTMLDivElement | null) => {
    scrollableContainerRef.current = node;
  }, []);

  return {
    refs: {
      setScrollableContainer: setScrollableContainerRef,
      setListRef: observeListItems,
    },
    onScroll: handleScroll,
    scrollToBottom,
    isAtBottom,
  };
}

const ChatGPT = ({ addItemToTextEditor, selectedConversation, setSelectedConversation }: ChatGPTProps) => {
  const [messages, setMessages] = useState<any>([]);
  const [messageLoader, setMessageLoader] = useState(false);
  const [allMessagesLoader, setAllMessagesLoader] = useState(false);
  const [markdownForDocumentCreation, setMarkdownForDocumentCreation] = useState<string | null>(null);

  const distanceFromBottomRef = useRef(133);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<InputRef | null>(null);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const currentAiAssistantTab = useAppSelector((state) => state.userAiAssistants.currentAiAssistantTab);

  const { refs, onScroll, scrollToBottom, isAtBottom } = useAutoScroll();
  const { messageStream, stopStream } = useMessageStreamer(
    isAtBottom ? {} : { charactersPerChunk: Number.MAX_SAFE_INTEGER, intervalBetweenChunksMs: 0 }
  );

  const chatInputRef = useResizeObserver(
    useCallback(
      ([
        {
          contentRect: { height: inputHeight },
        },
      ]) => {
        distanceFromBottomRef.current = inputHeight + 16 * 2;

        messagesContainerRef.current?.style.setProperty(
          'height',
          `calc(100vh - ${100 + distanceFromBottomRef.current}px)`
        );
      },
      []
    )
  );

  useEffect(() => {
    const fetchMessages = async () => {
      setMessages([]);
      setAllMessagesLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        if (!selectedConversation) {
          throw new Error('No selected conversation');
        }

        const res = await (() => {
          if (isValidationChat(selectedConversation?.conversation_type)) {
            return dispatch(
              getAllThreadMessages({
                accessToken,
                thread_id: selectedConversation.thread_id,
              })
            );
          }

          return dispatch(
            getAllMessages({
              accessToken,
              project_id: selectedConversation.project_id,
              conversation_id: selectedConversation.id,
            })
          );
        })();

        const messages = res?.payload?.data;

        setMessages(Array.isArray(messages) ? messages : []);
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        setAllMessagesLoader(false);
        scrollToBottom();
      }
    };

    // no history for special chat
    if (!selectedConversation?.messageContext) {
      fetchMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversation]);

  const sendMessage = useCallback(
    async (suggestionMessage?: string, specialConversation?: SelectedConversation) => {
      const { current: input } = inputRef;
      const message = suggestionMessage || input?.textareaEl?.value.trim();

      if (!message) return;

      setMessageLoader(true);
      setMessages((prev: any) => [
        ...(prev || []),
        {
          content: message,
          created_at: new Date(),
          role: 'user',
        },
        {
          content: 'q_loading',
          created_at: new Date(),
          role: 'assistant',
        },
      ]);
      scrollToBottom({ behavior: 'smooth' });

      if (!suggestionMessage) {
        input?.clear();
      }

      const getConversation = async (accessToken: string) => {
        if (!specialConversation) {
          return selectedConversation;
        }

        const { agent_name, conversation_type, title, description } = specialConversation;

        try {
          const res = await dispatch(
            createConversation({
              body: {
                title,
                description,
                conversation_type,
              },
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
              agent_name,
              template_type: (currentAiAssistantTab === 1 && 'community') || (currentAiAssistantTab === 2 && 'private'),
            })
          );

          const conversationData = res?.payload?.data;

          if (!conversationData) return null;

          const conversation = {
            ...selectedConversation,
            ...conversationData,
          };

          setSelectedConversation(conversation);

          return conversation as SelectedConversation;
        } catch (error) {
          console.error('Error creating special conversation:', error);

          return null;
        }
      };

      let lastMessageContent = '';

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const conversation = await getConversation(accessToken);

        if (!conversation) {
          throw new Error('No conversation');
        }

        if (!conversation?.id) {
          throw new Error('No conversation id');
        }

        const { id: conversationId, project_id: projectId, thread_id: threadId, agent_name } = conversation;

        if (agent_name) {
          if (!threadId) {
            throw new Error('No thread id');
          }

          await dispatch(
            promptStreamMessageThread({
              body: {
                prompt: message,
              },
              accessToken,
              thread_id: threadId,
              project_id: projectId,
              conversation_id: conversationId,
              agent_name,
            })
          );
        } else {
          // POST to start the generation
          await dispatch(
            promptStreamMessage({
              body: {
                messages: [
                  {
                    role: 'user',
                    content: message,
                  },
                ],
              },
              accessToken,
              project_id: selectedConversation?.project_id,
              conversation_id: conversation.id,
            })
          );
        }

        const streamUrl = agent_name
          ? `ai/assistantStreamRequest?conversation_id=${conversationId}`
          : `aoaiCompletionStream?conversation_id=${conversationId}`;

        for await (const chunk of messageStream(streamUrl)) {
          lastMessageContent += chunk;
          document.dispatchEvent(new CustomEvent('message-stream', { detail: lastMessageContent }));
        }
      } catch (error) {
        console.error('Error sending message: ', error);
      } finally {
        setMessages((prev: any) => {
          if (!lastMessageContent) return prev;

          const lastMessageIndex = prev.length - 1;
          const updatedMessages = [...prev];
          updatedMessages[lastMessageIndex] = {
            ...updatedMessages[lastMessageIndex],
            content: lastMessageContent,
          };

          return updatedMessages;
        });

        setMessageLoader(false);
      }
    },
    [
      currentAiAssistantTab,
      dispatch,
      getAccessTokenSilently,
      messageStream,
      selectedConversation,
      setSelectedConversation,
      scrollToBottom
    ]
  );

  const handleCreateDocument = useCallback((markdown: string) => {
    setMarkdownForDocumentCreation(markdown);
  }, []);

  useWatch({
    dependencies: { sendMessage, messageLoader },
    triggers: [selectedConversation],
    callback: ({ sendMessage, messageLoader }) => {
      const { conversation_configuration_id, messageContext } = selectedConversation;

      if (messageLoader || conversation_configuration_id || !messageContext) return;

      setMessages([]);
      sendMessage(`>>pilot${JSON.stringify(messageContext)}`, selectedConversation);
    },
  });

  return (
    <WrapperLoader loading={allMessagesLoader}>
      <div className='relative h-full bg-customGray'>
        <div
          ref={(node) => {
            messagesContainerRef.current = node;
            refs.setScrollableContainer(node);
          }}
          onScroll={onScroll}
          className='h-full pb-0.5 px-3 overflow-y-auto overflow-x-hidden'
          style={{
            height: `calc(100vh - ${100 + distanceFromBottomRef.current}px)`,
          }}
        >
          <div className='w-full max-w-2xl mx-auto'>
            <ConversationHeader
              chatTitle={selectedConversation?.title || ''}
              onSuggestionSelected={(message) => sendMessage(message)}
              className='mt-8'
            />
          </div>

          {messages.length > 0 && (
            <AdvancedCopyAndPaste
              inputRef={inputRef}
              onAddDocument={handleCreateDocument}
            >
              <ul
                ref={refs.setListRef}
                className='pt-4 flex flex-col gap-y-5'
              >
                {messages?.map((data: any, dataIdx: number) => (
                  <li
                    key={dataIdx}
                    id={`${messages?.length === dataIdx + 1 ? 'show-first' : 'dont-show'}`}
                  >
                    <Message
                      data={data}
                      chatType={selectedConversation?.conversation_type}
                      addItemToTextEditor={addItemToTextEditor}
                      isStreamTarget={dataIdx === messages.length - 1 && data.role === 'assistant' && messageLoader}
                      onAddDocument={handleCreateDocument}
                    />
                  </li>
                ))}
              </ul>
            </AdvancedCopyAndPaste>
          )}
        </div>

        <div className='bg-transparent flex items-center'>
          <div
            ref={chatInputRef}
            className='chat-form w-full py-3.5 pb-4 px-3'
          >
            <ChatGPTInput
              inputRef={inputRef}
              onSubmit={sendMessage}
              messageLoader={messageLoader}
              sendMessage={sendMessage}
              onStop={stopStream}
            />
          </div>
        </div>
      </div>
      <AssistantMessage.NewDocumentModal
        data={markdownForDocumentCreation}
        onCloseModal={() => setMarkdownForDocumentCreation(null)}
      />
    </WrapperLoader>
  );
};

export default memo(ChatGPT);
