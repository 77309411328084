import React from "react";
import AiAssistantsData from './aiAssistantsData';

export default function AiAssistants() {
  return (
    <div className="w-full">
      <AiAssistantsData isModal={false} tempType="my" />
    </div>
  );
}
