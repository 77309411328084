import React from "react";

interface StatusLabelProps {
  status: string;
}

const StatusLabel = ({ status }: StatusLabelProps) => {
  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset w-24 justify-center ${
        (status === "Not Started" &&
          "bg-slate-50 text-slate-700 ring-slate-600/20") ||
        (status === "In Progress" &&
          " bg-blue-50 text-blue-700 ring-blue-600/20") ||
        (status === "Concluded" &&
          "bg-green-50 text-green-700 ring-green-600/20") ||
        (status === "Canceled" && "bg-red-50 text-red-700 ring-red-600/20") ||
        ""
      }`}
    >
      {status}
    </span>
  );
};

export default StatusLabel;
