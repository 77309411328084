import { useState, useRef, useEffect, useCallback } from 'react';
import { useFloating, offset, flip, shift } from '@floating-ui/react-dom';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { classNames } from 'src/utils/utilities';

const categories = [
  {
    id: 0,
    name: 'Edit',
    options: ['Improve writing', 'Make shorter', 'Make longer', 'Simplify language'],
  },
  {
    id: 1,
    name: 'Change tone',
    options: ['Professional', 'Casual', 'Direct', 'Formal', 'Friendly'],
  },
  {
    id: 2,
    name: 'Change style',
    options: ['Business', 'Legal', 'Academic', 'Technical'],
  },
];

export default function CommandsDropdown({
  show,
  inputValue,
  onSelect,
  children,
}: {
  show: boolean;
  inputValue: string;
  onSelect: (option: string | null, ref: HTMLElement | null) => void;
  children: (setReference: (el: HTMLElement | null) => void) => React.ReactNode;
}) {
  const [openCategory, setOpenCategory] = useState<number | null>(null);

  const onSelectRef = useRef(onSelect);
  const categoryRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const optionRefs = useRef<{ [key: number]: { [key: number]: HTMLButtonElement | null } }>({});

  const { refs, floatingStyles, placement } = useFloating({
    placement: 'bottom-start',
    middleware: [
      offset({ mainAxis: 4, crossAxis: 8 }),
      flip({
        fallbackPlacements: ['top-start'],
        fallbackStrategy: 'bestFit',
        mainAxis: true,
        crossAxis: false,
        padding: {
          bottom: 100,
        },
      }),
      shift({
        padding: 8,
      }),
    ],
  });

  // Function to determine the placement of nested dropdowns
  const getNestedDropdownPlacement = (mainPlacement: string) => {
    return mainPlacement.startsWith('top') ? 'bottom-start' : 'top-start';
  };

  useEffect(() => {
    onSelectRef.current = onSelect;
  }, [onSelect]);

  useEffect(() => {
    if (!show) return;

    const handleClickOutside = (event: MouseEvent) => {
      const { floating: dropdownRef } = refs;

      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenCategory(null);
        onSelectRef.current(null, refs.reference.current as HTMLElement);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, show]);

  useEffect(() => {
    if (show) {
      const firstCategory = categoryRefs.current[0];

      if (firstCategory) {
        firstCategory.focus();
      }
    }
  }, [show]);

  const handleCategoryKeyDown = useCallback(
    (event: React.KeyboardEvent, categoryId: number) => {
      const { key } = event;

      if (key === 'Enter' || key === 'ArrowRight') {
        event.preventDefault();
        setOpenCategory(categoryId);

        const firstOption = optionRefs.current[categoryId]?.[0];

        if (firstOption) {
          firstOption.focus();
        }
      }

      if (key === 'Escape' || key === 'ArrowLeft') {
        event.preventDefault();
        setOpenCategory(null);
        onSelectRef.current(null, refs.reference.current as HTMLElement);
      }

      if (key === 'ArrowDown') {
        event.preventDefault();
        const nextCategory = categoryRefs.current[categoryId + 1];

        if (nextCategory) {
          nextCategory.focus();
        }
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        const prevCategory = categoryRefs.current[categoryId - 1];

        if (prevCategory) {
          prevCategory.focus();
        }
      }
    },
    [refs]
  );

  const handleOptionKeyDown = useCallback(
    (event: React.KeyboardEvent, categoryId: number, optionIndex: number) => {
      const { key } = event;

      if (key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();

        const option = categories.find(({ id }) => id === categoryId)?.options[optionIndex];

        if (option) {
          onSelectRef.current(option, refs.reference.current as HTMLElement);
        }
      }

      if (key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();

        setOpenCategory(null);

        const category = categoryRefs.current[categoryId];

        if (category) {
          category.focus();
        }
      }

      if (key === 'ArrowLeft') {
        event.preventDefault();
        event.stopPropagation();

        setOpenCategory(null);

        const category = categoryRefs.current[categoryId];

        if (category) {
          category.focus();
        }
      }

      if (key === 'ArrowDown') {
        event.preventDefault();
        event.stopPropagation();

        const nextOption = optionRefs.current[categoryId]?.[optionIndex + 1];

        if (nextOption) {
          nextOption.focus();
        }
      }

      if (key === 'ArrowUp') {
        event.preventDefault();
        event.stopPropagation();

        const prevOption = optionRefs.current[categoryId]?.[optionIndex - 1];

        if (prevOption) {
          prevOption.focus();
        }
      }
    },
    [refs]
  );

  return (
    <>
      {children(refs.setReference)}
      {show && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className='absolute z-10 top-0 left-4 min-w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
        >
          {categories.map(({ id: categoryId, name, options }) => {
            const isOpen = openCategory === categoryId;
            const nestedPlacement = getNestedDropdownPlacement(placement);

            return (
              <div
                key={categoryId}
                className='relative p-1'
              >
                <div
                  ref={(el) => (categoryRefs.current[categoryId] = el)}
                  className={classNames(
                    'relative group px-4 py-3 rounded-md text-gray-700 transition-colors',
                    'flex items-center justify-between gap-x-2 text-xs',
                    isOpen && '!text-customDarkBlue bg-gray-100',
                    'focus:!text-customDarkBlue focus:bg-gray-100'
                  )}
                  role='menuitem'
                  aria-haspopup='true'
                  aria-expanded={isOpen}
                  tabIndex={0}
                  onMouseEnter={() => setOpenCategory(categoryId)}
                  onKeyDown={(event) => handleCategoryKeyDown(event, categoryId)}
                >
                  {name}
                  <ChevronRightIcon
                    className={classNames('h-4 w-4 text-gray-400 transition-colors', isOpen && '!text-customDarkBlue')}
                  />
                  <div
                    className={classNames(
                      'absolute',
                      nestedPlacement === 'top-start' ? 'top-1' : 'bottom-1',
                      'left-full -translate-x-2 z-10 min-w-48 p-1 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
                      !isOpen && 'invisible'
                    )}
                    role='menu'
                  >
                    {options.map((option, optionIndex) => (
                      <button
                        key={option}
                        ref={(el) => {
                          if (!optionRefs.current[categoryId]) {
                            optionRefs.current[categoryId] = {};
                          }
                          optionRefs.current[categoryId][optionIndex] = el;
                        }}
                        type='button'
                        className={classNames(
                          'w-full text-gray-700 transition-colors',
                          'group flex items-center px-4 py-3 text-xs rounded-md cursor-pointer',
                          'hover:text-customDarkBlue hover:bg-gray-100',
                          'focus:text-customDarkBlue focus:bg-gray-100',
                          !isOpen && 'invisible'
                        )}
                        onKeyDown={(event) => handleOptionKeyDown(event, categoryId, optionIndex)}
                        onClick={() => onSelect(option, refs.reference.current as HTMLElement)}
                        role='menuitem'
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}
