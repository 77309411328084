import { PropsWithChildren, useState } from 'react';
import DropdownSelectionOption from '../dropdownSelectionOption';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { createConversation } from 'src/redux/chatGPT/chatGPTApi';
import { classNames, getLastIdFromUrl } from 'src/utils/utilities';
import ChatAIModal from 'src/pages/projectsDetails/leftSideBar/chatAImodal';
import type { AnchorPropsWithSelection } from '@headlessui/react/dist/internal/floating';

export type ChatSelectorProps = PropsWithChildren<{
  selected?: string;
  setSelected: (chatId: string) => void;
  isStyled?: boolean;
  anchor?: AnchorPropsWithSelection;
  isNested?: boolean;
}>;

export function ChatSelector({
  selected,
  setSelected,
  isStyled = true,
  anchor,
  isNested = false,
  children,
}: Readonly<ChatSelectorProps>) {
  const [aiChatsModal, setAiChatsModal] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { getAllConversationsRes } = useAppSelector((state) => state.chatGPT);
  const { currentAiAssistantTab } = useAppSelector((state) => state.userAiAssistants);
  const dispatch = useAppDispatch();

  const selectedAiChat = getAllConversationsRes?.find(({ id }) => id === selected);

  const creatingConversation = (conversation_configuration_id: string) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          const res = await dispatch(
            createConversation({
              body: {},
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
              conversation_configuration_id,
              template_type: (currentAiAssistantTab === 1 && 'community') || (currentAiAssistantTab === 2 && 'private'),
            })
          );

          setSelected(res.payload.data.id);
          setAiChatsModal(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
  };

//   <div className='shrink-0 relative'>
//   <ItemWithDropdown
//     title={name}
//     actionsData={actions}
//   >
//     <CustomButton
//       text={
//         <div className='flex items-center'>
//           <span>{name}</span>
//           <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' />
//         </div>
//       }
//       type='button'
//       outlined
//       onClickBtn={onActionClick}
//       btnStyle='transition-colors border-zinc-300 text-customLightBlue hover:text-amber-400'
//     />
//   </ItemWithDropdown>
// </div>

  return (
    <>
      <div className={isStyled ? 'ml-4' : ''}>
        <DropdownSelectionOption
          title='Select chat'
          optionsList={getAllConversationsRes}
          selected={selectedAiChat}
          setSelected={({ id }) => setSelected(id)}
          onAddingDocument={() => setAiChatsModal(true)}
          btnText='Ai Chat'
          buttonClassName={
            isStyled
              ? classNames(
                  'shrink-0 text-xs font-semibold text-customLightBlue border border-zinc-300 pr-2 pl-3 py-2 rounded-md',
                  'transition-colors hover:border-blue-500 hover:text-blue-500'
                )
              : ''
          }
          anchor={anchor}
          isNested={isNested}
        >
          {children}
        </DropdownSelectionOption>
      </div>
      {aiChatsModal && (
        <ChatAIModal
          onCloseModal={() => setAiChatsModal(false)}
          openModal={aiChatsModal}
          onUseTemplate={creatingConversation}
        />
      )}
    </>
  );
}
