import { createSlice } from "@reduxjs/toolkit";
import {
  getDecisionMatrixList,
  createDecisionMatrix,
  updateDecisionMatrix,
  getDecisionMatrixById,
  deleteDecisionMatrix,
} from "./decisionMatrixApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  decisionMatrixList: any[];
  createDecisionMatrixRes: any;
  updateDecisionMatrixRes: any;
  decisionMatrixByIdRes: any;
  decisionMatrixDeleteRes: any;
  loadingDecisionMatrix: boolean;
  selectedDecisionMatrix: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  decisionMatrixList: [],
  createDecisionMatrixRes: null,
  updateDecisionMatrixRes: null,
  decisionMatrixByIdRes: null,
  decisionMatrixDeleteRes: null,
  loadingDecisionMatrix: false,
  selectedDecisionMatrix: null,
};
const decisionMatrixSlice = createSlice({
  name: "decisionMatrix",
  initialState,
  reducers: {
    setSelectedDecisionMatrix: (state, action) => {
      state.selectedDecisionMatrix = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Pros Cons
    builder.addCase(getDecisionMatrixList.pending, (state) => {
      state.error = null;
      state.message = null;
      state.loadingDecisionMatrix = true;
    });
    builder.addCase(getDecisionMatrixList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.decisionMatrixList = action.payload?.data;

      state.loadingDecisionMatrix = false;
    });
    builder.addCase(getDecisionMatrixList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";

      state.loadingDecisionMatrix = false;
    });

    // Get Pros Cons By Id
    builder.addCase(getDecisionMatrixById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getDecisionMatrixById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.decisionMatrixByIdRes = action.payload?.data;
    });
    builder.addCase(getDecisionMatrixById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Pros Cons
    builder.addCase(createDecisionMatrix.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createDecisionMatrix.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createDecisionMatrixRes = action.payload?.data;
    });
    builder.addCase(createDecisionMatrix.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Pros Cons
    builder.addCase(updateDecisionMatrix.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateDecisionMatrix.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateDecisionMatrixRes = action.payload?.data;
    });
    builder.addCase(updateDecisionMatrix.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Pros Cons
    builder.addCase(deleteDecisionMatrix.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteDecisionMatrix.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.decisionMatrixDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteDecisionMatrix.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedDecisionMatrix } = decisionMatrixSlice.actions;
export default decisionMatrixSlice.reducer;
