import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import CustomButton from '../customButton';
import { useAppSelector, useAppDispatch } from '../../hooks';
import Discussions from '../Comments';
import HTMLContentModal from './HTMLContentModal';
import { getUserAiAssistant, getPublicAiAssistant } from '../../redux/userAiAssistants/userAiAssistantsApi';
import { getFirstPathFromUrl, getLastIdFromUrl, formatLatestDate, classNames } from '../../utils/utilities';

import chatGPT from '../../assets/icons/chat-gpt-icon.svg';
// import twitterIcon from '../../assets/images/twitter-logo.png';
// import linkedInIcon from '../../assets/images/linkedin-logo.png';
import WrapperLoader from '../wrapperLoader';
import ConfigurationGrid, { ColorVariant } from '../configurationGrid';
import RawText from '../formatter/rawText';
import ShareButton from '../shareButton';

const tabsList = [
  { id: 1, title: 'Details' },
  { id: 2, title: 'Discussion' },
];

interface AIChatTempDetailProps {
  onCancel: Function;
  onUse: Function;
  isModal: boolean;
  tempType?: string;
  showTempDetail?: any;
  showTabs?: boolean;
}

export default function AIChatTempDetail({
  onCancel,
  onUse,
  isModal,
  tempType,
  showTempDetail,
  showTabs = true,
}: AIChatTempDetailProps) {
  const [selectedTab, setSelectedTab] = useState(1);
  const [isOpenHTML, setIsOpenHTML] = useState(false);
  const [detailLoader, setDetailLoader] = useState(false);
  const [aiDetail, setAiDetail] = useState<any>(null);

  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);
  const { getUserAiAssistantRes, getPublicAiAssistantRes } = useAppSelector((state) => state.userAiAssistants);

  const firstPath = getFirstPathFromUrl(window.location.pathname);

  const configurationsList: ReadonlyArray<{
    id: number;
    title: string;
    description: string;
    value: any;
    variant: ColorVariant;
  }> = [
    {
      id: 1,
      title: 'Temperature',
      description: 'Controls creativity',
      value: aiDetail?.configuration?.ai_temperature,
      variant: 'gray',
    },
    {
      id: 2,
      title: 'Presence Penalty',
      description: 'Discourages word repetition',
      value: aiDetail?.configuration?.presence_penalty,
      variant: 'halfBlue',
    },
    {
      id: 3,
      title: 'Frequency Penalty',
      description: 'Penalizes frequent words',
      value: aiDetail?.configuration?.frequency_penalty,
      variant: 'red',
    },
    {
      id: 4,
      title: 'Top P',
      description: 'Limits diverse responses',
      value: aiDetail?.configuration?.top_p,
      variant: 'green',
    },
    {
      id: 5,
      title: 'Max Response Tokens',
      description: 'Restricts output length',
      value: aiDetail?.configuration?.max_tokens,
      variant: 'blue',
    },
    {
      id: 6,
      title: 'Stop Sequence',
      description: 'Ends response generation',
      value: aiDetail?.configuration?.stop,
      variant: 'orange',
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setDetailLoader(true);

          if (firstPath === 'ai-assistant' || tempType === 'my') {
            await dispatch(
              getUserAiAssistant({
                accessToken,
                resource_id: showTempDetail?.id ? showTempDetail?.id : getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          if (firstPath === 'community-ai-assistant' || tempType === 'community') {
            await dispatch(
              getPublicAiAssistant({
                accessToken,
                resource_id: showTempDetail?.id ? showTempDetail?.id : getLastIdFromUrl(window.location.pathname),
              })
            );
          }

          setDetailLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstPath]);

  useEffect(() => {
    if (firstPath === 'ai-assistant' || tempType === 'my') {
      setAiDetail(getUserAiAssistantRes);
    }

    if (firstPath === 'community-ai-assistant' || tempType === 'community') {
      setAiDetail(getPublicAiAssistantRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAiAssistantRes, getPublicAiAssistantRes]);

  const isPrivate = aiDetail?.privacy === true;

  return (
    <WrapperLoader loading={detailLoader}>
      <div className='w-full'>
        <div
          className={classNames(
            'grid overflow-y-auto',
            isModal ? 'h-[calc(100vh_-_193px)]' : 'h-[calc(100vh_-_110px)]',
            isModal ? 'grid-cols-4' : 'grid-cols-custom'
          )}
        >
          <div
            className={`col-span-3 border-r border-zinc-200 ${isModal ? 'pr-4 pl-4 pt-4 pb-0' : 'pr-7 pl-2 pt-[22px] pb-4'}`}
          >
            <div className='flex items-center justify-between mb-5'>
              <div>
                {showTabs ? (
                  <nav
                    className='flex space-x-4'
                    aria-label='Tabs'
                  >
                    {tabsList.map((tab: any) => (
                      <span
                        key={tab.id}
                        onClick={() => setSelectedTab(tab.id)}
                        className={classNames(
                          tab.id === selectedTab
                            ? 'text-blue-500 border-blue-500'
                            : 'border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700',
                          'whitespace-nowrap border-b-2 py-2 px-2 text-xs font-medium cursor-pointer'
                        )}
                      >
                        {tab.title}
                      </span>
                    ))}
                  </nav>
                ) : (
                  <h3
                    className={classNames(
                      'mb-3 border-transparent text-customLightBlue text-lg',
                      'whitespace-nowrap border-b-2 text-gray-500'
                    )}
                  >
                    Details
                  </h3>
                )}
              </div>

              {isModal && (
                <div className='flex items-center justify-between'>
                  <div>
                    <CustomButton
                      text='Back'
                      type='button'
                      onClickBtn={() => onCancel()}
                      buttonType='secondary'
                      // btnStyle="border-gray-300 text-customLightBlue px-6 text-md mr-4 hover:border-gray-500 hover:text-customDarkBlue"
                    />

                    <CustomButton
                      text='Use'
                      type='button'
                      onClickBtn={() => onUse(aiDetail?.id)}
                      // btnStyle="w-24 flex items-center justify-center text-md bg-blue-500 hover:bg-amber-500"
                      buttonType='primary'
                    />
                  </div>
                </div>
              )}
            </div>

            {selectedTab === 1 && (
              <>
                <div className={`bg-[#F0F4FE] border border-y border-[#4778F5] mt-2 mb-10 p-7 rounded-xl`}>
                  <p className='text-xs'>{aiDetail?.description}</p>
                  <div className='mt-4 pt-4 border-t border-[#4778F5] text-[#14151A]'>
                    <h3 className='text-xs font-semibold mb-2 line-clamp-1'>Prompt Message</h3>
                    <RawText
                      className='text-xs'
                      content={aiDetail?.configuration?.system_config}
                    />
                  </div>
                </div>
                <ConfigurationGrid
                  title='Configuration'
                  isModal={isModal}
                >
                  {configurationsList.map(({ id, title, description, value, variant }) => (
                    <ConfigurationGrid.Item
                      key={id}
                      title={title}
                      value={value}
                      description={description}
                      variant={variant}
                    />
                  ))}
                </ConfigurationGrid>
              </>
            )}

            {selectedTab === 2 && <Discussions />}
          </div>
          <div className='col-span-1 p-6'>
            <div className='mb-7'>
              <div className='text-gray-500 mb-2 text-xs uppercase'>Publisher</div>

              <div className='mb-2 text-xs'>
                <div
                  className='flex items-center'
                  data-tooltip-id='user-name'
                >
                  <div className='mr-3'>
                    <img
                      className='h-8 w-8 rounded-full bg-gray-50'
                      src={user?.picture}
                      alt={profileData?.first_name}
                    />
                  </div>
                  <div>
                    {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-7'>
              <div className='text-gray-500 mb-2 text-xs uppercase'>Model</div>
              <div className='flex items-center mb-2 text-xs'>
                <img
                  src={chatGPT}
                  alt='Chat GPT'
                  className='mr-3 w-6 h-6 rounded-full'
                />
                <span>Chat GPT</span>
              </div>
            </div>

            <div className='mb-7'>
              <div className='text-gray-500 mb-2 text-xs uppercase'>Published</div>
              <div className='flex items-center mb-2 text-xs'>{formatLatestDate(aiDetail?.created_at)}</div>
            </div>

            <div className='mb-7'>
              <div className='text-gray-500 mb-2 text-xs uppercase'>Version</div>
              <div className='flex items-center mb-2 text-xs'>1.0.0</div>
            </div>

            <div className='mb-7'>
              <div className='text-gray-500 mb-2 text-xs uppercase'>Last Update</div>
              <div className='flex items-center mb-2 text-xs'>{formatLatestDate(aiDetail?.updated_at)}</div>
            </div>

            <div className='mb-7'>
              <div className='text-gray-500 mb-2 text-xs uppercase'>Category</div>
              <div className='flex items-center mb-2 text-xs'>
                <span
                  className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-500/20`}
                >
                  {aiDetail?.category}
                </span>
              </div>
            </div>

            {!isPrivate && (
              <div>
                <div className='text-gray-500 mb-2 text-xs uppercase'>Share</div>
                <div className='flex items-center'>
                  {/* <div className='cursor-pointer mr-2 hover:scale-110'>
                  <img
                    className='h-[15px]'
                    src={twitterIcon}
                    alt='Twitter Logo'
                  />
                </div> */}
                  <ShareButton.LinkedIn text={aiDetail?.title || 'AI Assistant'} />
                  {/* <div className='cursor-pointer mr-2 hover:scale-110'>
                  <LinkIcon className='h-[15px]' />
                </div> */}
                </div>
              </div>
            )}
          </div>
        </div>

        <HTMLContentModal
          openModal={isOpenHTML}
          onClosingModal={() => setIsOpenHTML(false)}
        />
      </div>
    </WrapperLoader>
  );
}
