import React from "react";
import StatusCircle from "../statusCircle";
import { humanReadDate } from "../../utils/utilities";

interface TableTitleProps {
  title: any;
  status: any;
  created_at?: any;
  updated_at?: any;
}

export default function TableTitle({
  title,
  status,
  created_at,
  updated_at,
}: TableTitleProps) {
  return (
    <div className=" min-w-[230px]">
      <div className="text-customDarkBlue text-md font-semibold mb-1 flex">
        <div className="w-4 relative top-1">
          <StatusCircle status={status} />
        </div>

        <span className="ml-1 line-clamp-1 text-xs">{title}</span>
      </div>

      <div className="text-gray-400 text-xs">
        {created_at && (
          <span className="mb-2 block">
            <span className="text-zinc-500 font-semibold">Created: </span>
            {humanReadDate(created_at)}
          </span>
        )}
        {updated_at && (
          <span className="block">
            <span className="text-zinc-500 font-semibold">Updated: </span>
            {humanReadDate(updated_at)}
          </span>
        )}
      </div>
    </div>
  );
}
