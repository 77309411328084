import React, { useState, useEffect, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import WrapperLoader from "../../components/wrapperLoader";

import {
  getPrivateDocumentsTemplatesList,
  getPublicDocumentsTemplatesList,
  createDocumentFromPublicTemplate,
  createDocumentFromPrivateTemplate,
} from "../../redux/templates/templatesApi";
import CompleteEmptyPage from "src/components/completeEmptyPage";
import CommunityleftMenu from "../communityPage/CommunityleftMenu";
import { CategoriesLeftSidebar } from "src/components/categoriesLeftSidebar";
import DocumentBox from "../../components/documentBox";
import SingleDocumentDetails from "../../components/singleDocumentDetails";

import designTemplateSVG from "../../assets/images/Illustration-08.svg";
import { getLastIdFromUrl } from "src/utils/utilities";
import { useAppSearchParams } from "src/hooks/useAppSearchParams";
import { Category } from "src/type";
import { DetailsContext } from "src/contexts/details/context";

interface DocumentsTemplatesProps {
  isModal: boolean;
  tempType: string;
  selectedCategory?: Category;
  onCloseModal: Function;
}

const DocumentsTemplates = ({
  isModal,
  tempType,
  selectedCategory,
  onCloseModal,
}: DocumentsTemplatesProps) => {
  const [documentsTemplatesCompleteList, setDocumentsTemplatesCompleteList] =
    useState<any>([]);
  const [showDocumentDetail, setShowDocumentDetail] = useState<any>(null);
  const [allTemplatesLoader, setAllTemplatesLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const {
    getPrivateDocumentsTemplatesListRes,
    getPublicDocumentsTemplatesListRes,
  } = useAppSelector((state) => state.templates);

  const [{ category }, searchParamsSetter] = useAppSearchParams<{
    search: string;
    category: Category;
  }>({ search: "", category: "all" });

  const { getAllDocuments } = useContext(DetailsContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          setAllTemplatesLoader(true);
          if (tempType === "my") {
            await dispatch(
              getPrivateDocumentsTemplatesList({
                accessToken,
                query: selectedCategory
                  ? `?category=${selectedCategory === "all" ? "" : selectedCategory}`
                  : `?category=${category === "all" ? "" : category}`,
              })
            );
          }
          if (tempType === "community")
            await dispatch(
              getPublicDocumentsTemplatesList({
                accessToken,
                query: selectedCategory
                  ? `?category=${selectedCategory === "all" ? "" : selectedCategory}`
                  : `?category=${category === "all" ? "" : category}`,
              })
            );

          setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, category]);

  useEffect(() => {
    if (tempType === "my")
      setDocumentsTemplatesCompleteList(getPrivateDocumentsTemplatesListRes);
    if (tempType === "community")
      setDocumentsTemplatesCompleteList(getPublicDocumentsTemplatesListRes);
  }, [
    getPrivateDocumentsTemplatesListRes,
    getPublicDocumentsTemplatesListRes,
    tempType,
  ]);

  const onUsingDocumentTemplate = (data: any) => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          // setAllTemplatesLoader(true);
          if (tempType === "my") {
            await dispatch(
              createDocumentFromPrivateTemplate({
                accessToken,
                project_id: getLastIdFromUrl(window.location.pathname),
                document_id: data?.id,
              })
            ).then((res: any) => {
              if (res?.payload?.data) {
                // const allData = [...documentsTemplatesCompleteList];
                // let itemsList = [
                //   ...allData,
                //   res?.payload?.data,
                // ];
                // setDocumentsTemplatesCompleteList(itemsList);
                onCloseModal();

                if(getAllDocuments) {
                  getAllDocuments();
                }
              }
            });
          }

          if (tempType === "community") {
            await dispatch(
              createDocumentFromPublicTemplate({
                accessToken,
                project_id: getLastIdFromUrl(window.location.pathname),
                document_id: data?.id,
              })
            ).then((res: any) => {
              if (res?.payload?.data) {
                let itemsList = [
                  ...documentsTemplatesCompleteList,
                  res?.payload?.data,
                ];
                setDocumentsTemplatesCompleteList(itemsList);
                onCloseModal();
              }
            });
          }

          // setAllTemplatesLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };
    fetchData();
  };

  return (
    <>
      <div className="flex">
        {!isModal && (
          <div className="w-[270px]">
            {tempType === "community" ? (
              <div className="bg-white p-4 border-r border-gray-200 ml-2 mr-3 mt-2 h-[calc(100vh_-_80px)]">
                {" "}
                <CategoriesLeftSidebar
                  setSelectedCategory={(newCategory) =>
                    searchParamsSetter.set("category", newCategory)
                  }
                  selectedCategory={category}
                />
              </div>
            ) : (
              <CommunityleftMenu />
            )}
          </div>
        )}

        <div
          className="pl-4"
          style={{
            width:
              showDocumentDetail || isModal ? "100%" : "calc(100% - 182px)",
          }}
        >
          <div>
            {showDocumentDetail ? (
              <SingleDocumentDetails
                data={showDocumentDetail}
                templateType={tempType}
                onUse={onUsingDocumentTemplate}
                onBack={() => setShowDocumentDetail(null)}
              />
            ) : (
              <WrapperLoader loading={allTemplatesLoader}>
                <div
                  className="w-full overflow-y-auto"
                  style={{
                    height: isModal
                      ? "calc(100vh - 165px)"
                      : "calc(100vh - 105px)",
                  }}
                >
                  {documentsTemplatesCompleteList?.length > 0 ? (
                    <div
                      className={`grid ${isModal ? "grid-cols-3" : "grid-cols-3"} gap-2`}
                    >
                      {documentsTemplatesCompleteList?.map(
                        (data: any, index: number) => {
                          return (
                            <DocumentBox
                              key={index}
                              data={data}
                              onShow={() => {
                                isModal
                                  ? setShowDocumentDetail(data)
                                  : (tempType === "my" &&
                                      navigate(`/template/${data?.id}`)) ||
                                    (tempType === "community" &&
                                      navigate(
                                        `/community-template/${data?.id}`
                                      ));
                              }}
                              onUse={onUsingDocumentTemplate}
                            />
                          );
                        }
                      )}
                    </div>
                  ) : (
                    <div
                      className="flex items-center justify-center"
                      style={{ height: "calc(100vh - 165px)" }}
                    >
                      <CompleteEmptyPage
                        title="Approach documenting strategically"
                        subTitle="Any project you create, any document you create can always be saved as a template for future re-use. From here you can also publish your work and share it with comunity. To create a new template start with creating a new project."
                        btnText="New Template"
                        onClickBtn={null}
                        icon={designTemplateSVG}
                      />
                    </div>
                  )}
                </div>
              </WrapperLoader>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentsTemplates;
