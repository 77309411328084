import ArgumentForm from 'src/components/argumentForm';

interface ArgumentProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
  editData: any;
  side: string;
}

export default function Argument({ onCloseModal, loading, onSubmitData, editData, side }: ArgumentProps) {
  const onSubmitForm = (data: any) => {
    const payload = {
      argument: data?.argumentName,
      argument_weight: data?.argumentWeight,
      description: data?.description,
      side,
    };

    onSubmitData(payload);
  };

  return (
    <ArgumentForm
      onCloseModal={onCloseModal}
      loading={loading}
      onSubmitData={onSubmitForm}
      editData={{
        argumentName: editData?.argument,
        argumentWeight: editData?.argument_weight,
        description: editData?.description,
      }}
    />
  );
}
