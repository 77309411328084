import React, { useCallback, memo, useMemo, useEffect, useRef } from "react";
import {
  NodeResizer,
  type NodeProps,
  useStore,
  Handle,
  Position,
  useKeyPress,
  useReactFlow,
  NodeToolbar,
} from "@xyflow/react";
import { Tooltip } from "react-tooltip";
import Shape from "./shape";
import ColorToolbar from "./colorToolbar";
import { type ShapeNode } from "./shape/types";
import NodeLabel from "./label";
import Popover from "../../../../components/popover";
import { ChevronDoubleDownIcon, ChevronDoubleUpIcon, PaintBrushIcon, TrashIcon, DocumentTextIcon } from "@heroicons/react/24/outline";

// this will return the current dimensions of the node (measured internally by react flow)
function useNodeDimensions(id: string) {
  const node = useStore((state) => state.nodeLookup.get(id));
  return {
    width: node?.measured?.width || 0,
    height: node?.measured?.height || 0,
  };
}

function CustomShapeNode({ id, selected, data }: NodeProps<ShapeNode>) {
  const { color, type, textColor } = data;
  const { setNodes, deleteElements, getNodes } = useReactFlow();

  const { width, height } = useNodeDimensions(id);
  const shiftKeyPressed = useKeyPress("Shift");

  const parentId = useStore((store) => store.nodeLookup.get(id)?.parentId);

  const nodesList = getNodes();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const fontSize = useMemo(() => {
    const baseSize = 14; // Default font size
    return Math.max(baseSize, Math.min(width) / 16); // Adjust font size
  }, [width]);

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  // Get Parent Object
  const parentObject = useMemo(
    () => nodesList.find((n) => n.id === parentId),
    [nodesList, parentId]
  );

  useEffect(() => {
    adjustTextareaHeight();
  }, [nodeObject?.data?.label]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (parentObject?.style?.zIndex) {
      setNodes((nds) => {
        // Find the maximum zIndex of nodes with the same parent
        const maxSiblingZIndex = nds
          .filter((n) => n.parentId === parentId)
          .reduce((max, n) => Math.max(max, Number(n.style?.zIndex || 0)), 0);

        return nds.map((n) =>
          n.id === id
            ? {
                ...n,
                style: {
                  ...n.style,
                  zIndex: maxSiblingZIndex + 1, // Set zIndex to max sibling zIndex + 1
                },
              }
            : n
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [deleteElements, id]);

  const onColorChange = useCallback(
    (color: string) => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            return {
              ...node,
              data: {
                ...node.data,
                color,
              },
            };
          }
          return node;
        })
      );
    },
    [setNodes, id]
  );

   const onTextColorChange = useCallback(
     (color: string) => {
       setNodes((nodes) =>
         nodes.map((node) => {
           if (node.id === id) {
             return {
               ...node,
               data: {
                 ...node.data,
                 textColor: color,
               },
             };
           }
           return node;
         })
       );
     },
     [setNodes, id]
   );

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        // if (index === -1 || (absolute && index === nds.length - 1)) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        if (absolute) {
          node.style = {
            ...node.style,
            zIndex: maxZIndex + 1000,
          };
        } else {
          node.style = {
            ...node.style,
            zIndex: greaterThenSelectedNode + 300,
          };
        }

        return newNodes;
      });
      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        let newZIndex = absolute
          ? minZIndex - 300
          : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = newNodes.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const onChangeShapeLabel = useCallback(
    (value: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, label: value },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  return (
    <>
      <NodeResizer
        color={color}
        keepAspectRatio={shiftKeyPressed}
        isVisible={selected}
      />
      <Shape
        type={type}
        width={width - 4}
        height={height - 4}
        fill={color}
        strokeWidth={1}
        stroke={"white"}
        fillOpacity={1}
        // onMouseEnter={handleNodeMouseEnter}
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag nowheel absolute p-[4px] top-0 left-0 bg-white border border-[#e0e0e0] flex items-center justify-center"
      >
        <Popover
          buttonContent={
            <div className={`${iconWrapper}`}>
              <DocumentTextIcon className={`${iconStyle}`} />
            </div>
          }
          popoverContent={
            <div className="flex flex-wrap">
              <ColorToolbar
                onColorChange={onTextColorChange}
                activeColor={textColor}
              />
            </div>
          }
          classes="w-[280px] bottom-[29px] left-[-100px]"
        />

        <Popover
          buttonContent={
            <div className={`${iconWrapper}`}>
              <PaintBrushIcon className={`${iconStyle}`} />
            </div>
          }
          popoverContent={
            <div className="flex flex-wrap">
              <ColorToolbar onColorChange={onColorChange} activeColor={color} />
            </div>
          }
          classes="w-[280px] bottom-[29px] left-[-100px]"
        />

        {nodesList?.length > 1 && (
          <div className="flex items-center justify-center gap-2">
            {Number(nodeObject?.style?.zIndex) < findMaxZIndex(nodesList) && (
              <>
                {/* <div
                className={`${iconWrapper} order-1`}
                onClick={() => bringNodeToFront(id)}
                data-tooltip-id="move-up-node-tooltip"
              >
                <ChevronUpIcon className={`${iconStyle}`} />
              </div> */}

                <div
                  className={`${iconWrapper} order-3`}
                  onClick={() => bringNodeToFront(id, true)}
                  data-tooltip-id="move-absolute-top-node-tooltip"
                >
                  <ChevronDoubleUpIcon className={`${iconStyle}`} />
                </div>
              </>
            )}

            {Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList) && (
              <>
                {/* <div
                className={`${iconWrapper} order-2`}
                onClick={() => sendNodeToBack(id)}
                data-tooltip-id="move-down-node-tooltip"
              >
                <ChevronDownIcon className={`${iconStyle}`} />
              </div> */}

                <div
                  className={`${iconWrapper} order-4`}
                  onClick={() => sendNodeToBack(id, true)}
                  data-tooltip-id="move-absolute-bottom-node-tooltip"
                >
                  <ChevronDoubleDownIcon className={`${iconStyle}`} />
                </div>
              </>
            )}
          </div>
        )}

        <div className={`${iconWrapper}`} data-tooltip-id="delete-node-tooltip">
          <TrashIcon onClick={onDelete} className={`${iconStyle}`} />
        </div>
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="edit-node-tooltip" place="top">
        Edit Setting
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
      <NodeLabel
        placeholder={data.type}
        fontSize={fontSize}
        value={`${nodeObject?.data?.label || ""}`}
        onChange={onChangeShapeLabel}
        textareaRef={textareaRef}
        textColor={textColor}
      />
    </>
  );
}

export default memo(CustomShapeNode);
