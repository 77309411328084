import axios from "axios";

const configureAxiosDefaults = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.put["Content-Type"] = "application/json";
};

configureAxiosDefaults();

const handleTokenExpire = () => {
  localStorage.clear();
  window.location.reload();
};

const tokenExpireProcess = () => {
  handleTokenExpire();
};

export const getRequest = async ({
  url,
  headers,
  params,
}: {
  url: string;
  headers?: any;
  params?: any;
}) =>
  await new Promise<any>((resolve, reject) => {
    try {
      if (!headers) headers = {};
      axios
        .get(url, {
          headers,
          params,
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) tokenExpireProcess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const postRequest = async ({
  url,
  payload,
  headers,
  signal
}: {
  url: string;
  payload: any;
  headers?: any;
  signal?: AbortSignal
}) =>
  await new Promise<any>((resolve, reject) => {
    try {
      if (!headers) headers = {};
      axios
        .post(url, payload, {
          headers,
          signal
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err?.response?.status === 401) tokenExpireProcess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const putRequest = async ({
  url,
  payload,
  headers,
}: {
  url: string;
  payload: any;
  headers: any;
}) =>
  await new Promise<any>((resolve, reject) => {
    try {
      if (!headers) headers = {};
      axios
        .put(url, payload, {
          headers,
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) tokenExpireProcess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const patchRequest = async ({
  url,
  payload,
  headers,
}: {
  url: string;
  payload: any;
  headers: any;
}) =>
  await new Promise<any>((resolve, reject) => {
    try {
      if (!headers) headers = {};
      axios
        .patch(url, payload, {
          headers,
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) tokenExpireProcess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });

export const deleteRequest = async ({
  url,
  data,
  headers,
}: {
  url: string;
  data?: any;
  headers: any;
}) =>
  await new Promise<any>((resolve, reject) => {
    try {
      if (!headers) headers = {};
      axios
        .delete(url, {
          data,
          headers,
        })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => {
          if (err.response.status === 401) tokenExpireProcess();
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
  