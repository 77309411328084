import { useCallback, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

const TABS_REQUIRING_SAVE = new Set(['documents', 'diagrams']);

export const useSaveCurrentWork = () => {
  const promiseResolve = useRef<(() => void) | null>(null);
  const promiseReject = useRef<((value: Error) => void) | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get('active');

  const handleSaveComplete = useCallback((event: CustomEvent) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    const isSuccess = event.detail.success;

    if (isSuccess) {
      promiseResolve.current?.();
    } else {
      const error = event.detail.error;

      promiseReject?.current?.(error instanceof Error ? error : new Error('Unknown error'));
    }
  }, []);

  const saveCurrentWork = useCallback(async (): Promise<void> => {
    if (!activeTab || !TABS_REQUIRING_SAVE.has(activeTab)) return;

    return new Promise((resolve, reject) => {
      promiseResolve.current = resolve;
      promiseReject.current = reject;

      // Add one-time listener for the save completion event
      document.addEventListener(`${activeTab}:save-complete`, handleSaveComplete as EventListener, { once: true });

      // Dispatch the save request event
      document.dispatchEvent(new Event(`${activeTab}:save`));

      // Timeout safety net
      timeoutRef.current = setTimeout(() => {
        reject(new Error('Save timeout'));
      }, 5000);
    });
  }, [activeTab, handleSaveComplete]);

  useEffect(() => {
    return () => {
      document.removeEventListener(`${activeTab}:save-complete`, handleSaveComplete as EventListener);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      promiseResolve.current = null;
      promiseReject?.current?.(new Error());
      promiseReject.current = null;
    };
  }, [activeTab, handleSaveComplete]);

  return saveCurrentWork;
};

export const useSaveListener = (type: string, saveHandler: () => Promise<any>) => {
  useEffect(() => {
    const handleSaveRequest = async () => {
      try {
        const res = await saveHandler();
        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus === 'rejected') {
          throw new Error('Request rejected');
        }

        document.dispatchEvent(
          new CustomEvent(`${type}:save-complete`, {
            detail: { success: true },
          })
        );
      } catch (error) {
        document.dispatchEvent(
          new CustomEvent(`${type}:save-complete`, {
            detail: { success: false, error },
          })
        );
      }
    };

    document.addEventListener(`${type}:save`, handleSaveRequest);

    return () => {
      document.removeEventListener(`${type}:save`, handleSaveRequest);
    };
  }, [type, saveHandler]);
};
