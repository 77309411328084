import { createSlice } from "@reduxjs/toolkit";
import {
  getTbarArgumentsList,
  createTbarArgument,
  updateTbarArgument,
  deleteTbarArgument
} from "./tbarsArgumentsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  tBarArgumentsList: any[];
  createTbarArgumentsRes: any;
  updateTbarArgumentsRes: any;
  tbarArgumentDeleteRes: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  tBarArgumentsList: [],
  createTbarArgumentsRes: null,
  updateTbarArgumentsRes: null,
  tbarArgumentDeleteRes: null
};
const tBarsSlice = createSlice({
  name: "tBars",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get T-Bars Arguments
    builder.addCase(getTbarArgumentsList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getTbarArgumentsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.tBarArgumentsList = action.payload?.data;
    });
    builder.addCase(getTbarArgumentsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create T-Bars Arguments
    builder.addCase(createTbarArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createTbarArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createTbarArgumentsRes = action.payload?.data;
    });
    builder.addCase(createTbarArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update T-Bars Arguments
    builder.addCase(updateTbarArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateTbarArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateTbarArgumentsRes = action.payload?.data;
    });
    builder.addCase(updateTbarArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete T-Bars Arguments
    builder.addCase(deleteTbarArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteTbarArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.tbarArgumentDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteTbarArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default tBarsSlice.reducer;
