// Node groups collection for AWS

export const aws_groups = [
  {
    name: "aws_cloud_1",
    style: {
      group_icon_bg_color: "#164e63",
      group_border_style: "dashed",
      group_bg_color: "#f4fcff",
    },
    icon: "resource-groups",
    subdirectory: "general",
  },
  {
    name: "aws_cloud_2",
    style: {
      group_icon_bg_color: "#4c1d95",
      group_border_style: "solid",
      group_bg_color: "#f7f2ff",
    },
    icon: "resource-groups",
    subdirectory: "general",
  },
];
