import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { DetailsContext, TabType } from "./context";
import { useAppSelector, useAppDispatch } from "src/hooks/store";
import {
  setSelectedDocument,
  setChangedDocument,
} from "src/redux/projects/projectsSlice";
import {
  createProjectDocument,
  deleteProjectDocument,
  getProjectAllDocuments,
  getProjectById,
  updateProject,
  updateProjectDocument,
} from "src/redux/projects/projectsApi";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { createTemplate } from "src/redux/templates/templatesApi";
import { useMultiSelectedOption } from "src/hooks/useMultiSelectedOption";
import { useNotification } from "../../components/apiNotification";

const SUPPORTED_TABS: TabType[] = [
  "documents",
  "diagrams",
  "t-chart",
  "pros-cons",
  "decision-matrix",
  "swot",
  "settings",
];

function ProjectDetailsProvider({
  projectId,
  children,
}: PropsWithChildren<{ projectId: string }>) {
  const editorRef = useRef<any | null>(null);

  const [hasTextEditor, setHasTextEditor] = useState(false);
  const [projectByIdLoader, setProjectByIdLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  const {
    getProjectAllDocumentsRes: allDocuments,
    selectedDocument,
    projectsByIdRes,
  } = useAppSelector((state) => state.projects);
  const [multiSelectedOption, switchMultiSelectedOption] =
    useMultiSelectedOption(SUPPORTED_TABS);

  useEffect(() => {
    const fetchData = async () => {
      setProjectByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProjectById({
              accessToken,
              id: projectId,
            })
          );

        setProjectByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setProjectByIdLoader(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch, projectId]);

  const selectDocument = (document: any) => {
    dispatch(setSelectedDocument(document));
  };

  const getAllDocuments = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error("No access token found");
      }

      await dispatch(
        getProjectAllDocuments({
          accessToken,
          project_id: projectId,
        })
      );
    } catch (error) {
      console.error("Error getting all documents: ", error);

      throw error;
    }
  }, [projectId, getAccessTokenSilently, dispatch]);

  const createDocument = async (body: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error("No access token found");
      }

      const res = await dispatch(
        createProjectDocument({
          body,
          accessToken,
          project_id: projectId,
        })
      );

      return res;
    } catch (error) {
      console.error("Error creating document: ", error);

      throw error;
    }
  };

  const updateDocument = async (body: any, selectedDocument: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error("No access token found");
      }

      const res = await dispatch(
        updateProjectDocument({
          body,
          accessToken,
          project_id: selectedDocument?.project_id || body?.project_id,
          document_id: selectedDocument?.id || body?.id,
        })
      );

      return res;
    } catch (error) {
      console.error("Error updating document: ", error);

      throw error;
    }
  };

  const deleteDocument = async (selectedDocument: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error("No access token found");
      }

      await dispatch(
        deleteProjectDocument({
          accessToken,
          project_id: selectedDocument?.project_id,
          document_id: selectedDocument?.id,
        })
      );
    } catch (error) {
      console.error("Error deleting document:", error);

      throw error;
    }
  };

  const toggleAutoSave = async () => {
    const body = {
      title: projectsByIdRes?.title,
      status: projectsByIdRes?.status,
      category: projectsByIdRes?.category,
      complexity: projectsByIdRes?.complexity,
      description: projectsByIdRes?.description,
      autosave: !projectsByIdRes?.autosave,
    };

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error("No access token found");
      }

      await dispatch(
        updateProject({
          body,
          accessToken,
          id: projectsByIdRes?.id,
        })
      );
    } catch (error) {
      console.error("Error toggling auto save:", error);

      throw error;
    }
  };

  const setDocumentHtml = (documentValue: string) => {
    dispatch(setChangedDocument(documentValue));
  };

  const saveProjectAsTemplate = async (body: any) => {
    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error("No access token found");
      }

      await dispatch(
        createTemplate({
          body,
          accessToken,
          id: projectId,
        })
      ).then((res) => {
        if (res?.payload?.data) {
          addNotification(
            "Project was saved as a Template",
            "We saved this project in your private Templates. Click the button to see the details.",
            "Show Template",
            () => navigate(`/template/${res?.payload?.data?.id}`),
            <Square3Stack3DIcon
              aria-hidden="true"
              className="h-6 w-6 text-gray-400"
            />
          );
        }
      });
    } catch (error) {
      console.error("Error getting access token:", error);

      throw error;
    }
  };

  const setEditorRef = (editor: any | null) => {
    editorRef.current = editor;
    setHasTextEditor(!!editor);
  };

  return (
    <DetailsContext.Provider
      value={{
        type: "project",
        tabs: SUPPORTED_TABS,
        loader: projectByIdLoader,
        project: projectsByIdRes,
        allDocuments,
        selectedDocument,
        autosave: !!projectsByIdRes?.autosave,
        multiSelectedOption,
        editorRef,
        hasTextEditor,
        selectDocument,
        getAllDocuments,
        createDocument,
        updateDocument,
        deleteDocument,
        toggleAutoSave,
        setDocumentHtml,
        saveProjectAsTemplate,
        switchMultiSelectedOption,
        setEditorRef,
      }}
    >
      {children}
    </DetailsContext.Provider>
  );
}

export default ProjectDetailsProvider;
