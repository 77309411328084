import {
  ArrowTopRightOnSquareIcon,
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
  ChevronDownIcon,
  SquaresPlusIcon,
  TrashIcon,
  RectangleStackIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeProps,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
  useStoreApi,
} from "@xyflow/react";
import { memo, useEffect, useRef, useMemo, useCallback } from "react";

import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import { getRelativeNodesBounds } from "./utils";
import NodeIconsDropdown from "./NodeIconsDropdown";
import useDetachNodes from "./useDetachNodes";
import Popover from "src/components/popover";

const lineStyle = { borderColor: "white" };

function GroupNode({ id, data }: NodeProps) {
  const store = useStoreApi();
  const { deleteElements, setNodes, getNodes } = useReactFlow();
  const detachNodes = useDetachNodes();
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const nodesList = getNodes();

  const { minWidth, minHeight, hasChildNodes, childNodesList } = useStore(
    useCallback(
      (store) => {
        const childNodes = Array.from(store.nodeLookup.values()).filter(
          (n) => n.parentId === id
        );
        const rect = getRelativeNodesBounds(childNodes);

        return {
          minWidth: rect.x + rect.width,
          minHeight: rect.y + rect.height,
          hasChildNodes: childNodes.length > 0,
          childNodesList: childNodes,
        };
      },
      [id]
    ),
    isEqual
  );

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  useEffect(() => {
    adjustTextareaHeight();
  }, [data?.label]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [deleteElements, id]);

  const onDetach = useCallback(() => {
    const childNodeIds = Array.from(store.getState().nodeLookup.values())
      .filter((n) => n.parentId === id)
      .map((n) => n.id);

    detachNodes(childNodeIds, id);
  }, [detachNodes, id, store]);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        // Any resize logic that might cause the ResizeObserver warning can be placed here.
      }, 100),
    []
  );

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [id, handleResize]);

  const onChangeGroupIcon = useCallback(
    (url: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, icon_url: url },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        const newZIndex = absolute
          ? maxZIndex + 1000
          : greaterThenSelectedNode + 300;

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        // Update child nodes' zIndex
        let currentZIndex = newZIndex + 1; // Start from newZIndex + 1
        childNodesList.forEach((child) => {
          const childIndex = newNodes.findIndex((n) => n.id === child.id);
          if (childIndex !== -1) {
            newNodes[childIndex].style = {
              ...newNodes[childIndex].style,
              zIndex: currentZIndex,
            };
            currentZIndex += 1; // Increment for the next child
          }
        });

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, childNodesList]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        // Determine the new zIndex
        let newZIndex = absolute ? minZIndex - 300 : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = nds.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        // Update child nodes' zIndex
        let currentZIndex = newZIndex + 1;
        childNodesList.forEach((child) => {
          const childIndex = newNodes.findIndex((n) => n.id === child.id);
          if (childIndex !== -1) {
            newNodes[childIndex].style = {
              ...newNodes[childIndex].style,
              zIndex: currentZIndex,
            };
            currentZIndex += 1;
          }
        });

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes, childNodesList]
  );

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const onChangeGroupLabel = useCallback(
    (value: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? {
                ...node,
                data: { ...node.data, label: value },
              }
            : node
        )
      );
    },
    [setNodes, id]
  );

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  return (
    <div
      // onMouseEnter={handleNodeMouseEnter}
      id={id}
      className="rounded-md h-full"
      style={{
        border: `${data?.border_style} 2px ${data?.color}`,
        background: `${data?.background}`,
      }}
    >
      <div className="w-full flex items-center mb-2 h-[54px]">
        <div
          className={`rounded-br-md mr-2 p-3`}
          style={{ background: `${data?.color}` }}
        >
          <img
            className="h-[30px]"
            src={`${data?.icon_url}`}
            alt={`${data?.label}`}
          />
        </div>
        <div className="w-full relative">
          <textarea
            ref={textareaRef}
            className={`text-[${data?.color}] text-lg cursor-text w-full capitalize bg-transparent border-none outline-none focus:outline-none focus:ring-0`}
            placeholder="Type Group Name"
            value={`${data?.label}`}
            rows={1}
            onChange={(e) => onChangeGroupLabel(e.target.value)}
            style={{
              overflow: "hidden",
              resize: "none",
              height: "44px",
              position: "absolute",
              left: "-6px",
              top: "-25px",
            }}
          />
        </div>
      </div>

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        handleStyle={{
          width: "12px", // Resizer handle size for easy grabbing
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />
      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag nowheel absolute p-[4px] top-0 left-0 bg-white border border-[#e0e0e0] flex items-center justify-center"
      >
        <div className="p-[4px]">
          <Popover
            buttonContent={
              <div className={`${iconWrapper}`}>
                <SquaresPlusIcon className={`${iconStyle}`} />
              </div>
            }
            popoverContent={
              <NodeIconsDropdown
                onChangeNodeIcon={onChangeGroupIcon}
                nodeIcon={`${data?.icon_url}`}
              />
            }
            classes="w-[400px] bottom-[40px] left-[-110px]"
          />
        </div>

        {nodesList?.length > 1 && (
          <div className="p-[4px]">
            <Popover
              buttonContent={
                <div
                  className={`${iconWrapper}`}
                  data-tooltip-id="move-up-down-tooltip"
                >
                  <RectangleStackIcon className={`${iconStyle}`} />
                </div>
              }
              popoverContent={
                <div className="flex items-center justify-center gap-2">
                  {Number(nodeObject?.style?.zIndex) <
                    findMaxZIndex(nodesList) && (
                    <>
                      {/* <div
                        className={`${iconWrapper} order-1`}
                        onClick={() => bringNodeToFront(id)}
                        data-tooltip-id="move-up-node-tooltip"
                      >
                        <ChevronUpIcon className={`${iconStyle}`} />
                      </div> */}

                      <div
                        className={`${iconWrapper} order-3`}
                        onClick={() => bringNodeToFront(id, true)}
                        data-tooltip-id="move-absolute-top-node-tooltip"
                      >
                        <ChevronDoubleUpIcon className={`${iconStyle}`} />
                      </div>
                    </>
                  )}

                  {Number(nodeObject?.style?.zIndex) >
                    findMinZIndex(nodesList) && (
                    <>
                      {/* <div
                        className={`${iconWrapper} order-2`}
                        onClick={() => sendNodeToBack(id)}
                        data-tooltip-id="move-down-node-tooltip"
                      >
                        <ChevronDownIcon className={`${iconStyle}`} />
                      </div> */}

                      <div
                        className={`${iconWrapper} order-4`}
                        onClick={() => sendNodeToBack(id, true)}
                        data-tooltip-id="move-absolute-bottom-node-tooltip"
                      >
                        <ChevronDoubleDownIcon className={`${iconStyle}`} />
                      </div>
                    </>
                  )}
                </div>
              }
              classes={`w-auto bottom-[40px] 
                ${
                  Number(nodeObject?.style?.zIndex) <
                    findMaxZIndex(nodesList) ||
                  Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList)
                    ? "left-[-45px]"
                    : "left-[-90px]"
                }`}
            />
          </div>
        )}

        <div className={`${iconWrapper}`} data-tooltip-id="delete-node-tooltip">
          <TrashIcon onClick={onDelete} className={`${iconStyle}`} />
        </div>

        {hasChildNodes && (
          <div
            className={`${iconWrapper}`}
            data-tooltip-id="ungroup-node-tooltip"
          >
            <ArrowTopRightOnSquareIcon
              onClick={onDetach}
              className={`${iconStyle}`}
            />
          </div>
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="ungroup-node-tooltip" place="top">
        Ungroup
      </Tooltip>

      <Handle
        className="w-[100px] h-[100px] text-lg"
        type="target"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
  childNodesList: any[];
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes &&
    prev.childNodesList === next.childNodesList
  );
}

export default memo(GroupNode);
