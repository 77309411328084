import { SparklesIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import Toolbar from 'src/components/toolbar';

interface ProsConsBarProps {
  areProsConsReady: boolean;
  generateSuggestions: () => Promise<void>;
}

export function ProsConsToolbar({ areProsConsReady, generateSuggestions }: ProsConsBarProps) {
  const [suggestionsLoader, setSuggestionsLoader] = useState(false);
  // const [aiAssistantEnabled, setAiAssistantEnabled] = useState(false);
  // const [selectedAiChat, setSelectedAiChat] = useState<string>()
  // const [selectedDocs, setSelectedDocs] = useState<Option[]>([]);

  // const validateList: ActionProps[] = [
  //   {
  //     id: 1,
  //     name: '/Write a summary',
  //     onActionClick: noop,
  //   },
  // ];

  const handleGenerateSuggestions = async () => {
    setSuggestionsLoader(true);

    try {
      await generateSuggestions();
    } finally {
      setSuggestionsLoader(false);
    }
  };

  return (
    <Toolbar.Container className='bg-white'>
      <Toolbar>
        <Toolbar.Right
          visibleItems={[
            // new Toolbar.ChatSelectorItem({ id: 1, selected: selectedAiChat, setSelected: setSelectedAiChat }),
            // new Toolbar.DocumentsSelectorItem({
            //   id: 2,
            //   multiple: true,
            //   selected: selectedDocs,
            //   setSelected: setSelectedDocs,
            //   dropdownPosition: 'left'
            // }),
            // new Toolbar.ValidateItem({ id: 3, actions: validateList }),
            new Toolbar.ButtonItem({
              id: 4,
              iconComponent: SparklesIcon,
              name: 'Generate with AI',
              width: 150,
              loading: suggestionsLoader,
              disabled: !areProsConsReady,
              onActionClick: handleGenerateSuggestions,
            }),
          ]}
          hiddenItems={[]}
        />
      </Toolbar>
    </Toolbar.Container>
  );
}
