import { useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
interface Option {
  value: string;
  title?: string;
  icon?: any;
}

interface SelectFieldProps {
  options: Option[];
  value?: string; // Optional default value prop
  onSelect: (value: string) => void;
  classes?: string;
  containerClasses?: string;
  label?: string;
}

const SelectField: React.FC<SelectFieldProps> = ({ options, value, onSelect, label, classes, containerClasses }) => {
  // State to track the selected option
  const [selectedOption, setSelectedOption] = useState(value || options[0].value);

  const longestTitle = options.reduce((longest, option) => {
    const title = option.title || option.value;

    return title.length > longest.length ? title : longest;
  }, label || 'All');

  // Handle selection change
  const handleSelect = (e: any, value: string) => {
    e.stopPropagation();
    setSelectedOption(value);
    onSelect(value); // Pass the selected value to the parent via onSelect
  };

  const SelectedIcon = options.find((option) => option.value === selectedOption)?.icon;
  const selectedTitle = options.find((option) => option.value === selectedOption)?.title || value;

  return (
    <Menu
      as='div'
      className={`relative inline-block text-left w-full ${containerClasses}`}
    >
      <div>
        <MenuButton
          className={`inline-flex w-full justify-between items-center gap-1 rounded-md bg-white px-3 py-2 text-xs text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${classes}`}
        >
          {selectedOption === 'all' ? (
            <div
              data-title={longestTitle}
              className='relative capitalize after:content-[attr(data-title)] after:invisible pl-6'
            >
              <span className='absolute top-0 left-0'>{label || 'All'}</span>
            </div>
          ) : (
            <div className='flex items-center'>
              {SelectedIcon && <SelectedIcon className='h-4 w-4 text-gray-400 group-hover:text-gray-500 mr-2' />}
              <div
                data-title={longestTitle}
                className='relative capitalize after:content-[attr(data-title)] after:invisible'
              >
                <span className='absolute top-0 left-0'>{selectedTitle}</span>
              </div>
            </div>
          )}{' '}
          {/* Show selected value */}
          <ChevronDownIcon
            aria-hidden='true'
            className='h-5 w-5 text-gray-400'
          />
        </MenuButton>
      </div>

      <MenuItems className='absolute right-0 z-10 mt-2 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
        {options.map((data, index) => (
          <MenuItem key={index}>
            {({ active }) => (
              <button
                onClick={(e) => handleSelect(e, data.value)}
                className={`${
                  active ? 'bg-gray-100 text-gray-600' : 'text-gray-500'
                } group flex items-center gap-2 px-4 py-2 text-xs w-full text-left`}
              >
                {data.icon && <data.icon className='h-4 w-4 text-gray-500 group-hover:text-gray-600' />}
                {data.title || data.value}
              </button>
            )}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};

export default SelectField;

// import React, { SelectHTMLAttributes } from "react";

// interface CommonSelectProps {
//   options: any;
//   id: string;
//   rest: any;
// }

// type SelectFieldProps = CommonSelectProps &
//   SelectHTMLAttributes<HTMLSelectElement>;

// const SelectField: React.FC<SelectFieldProps> = ({ options, id, ...rest }) => {
//   return (
//     <select
//       id={id}
//       className="block capitalize w-full bg-white rounded-md border-0 py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-xs sm:leading-6"
//       {...rest}
//     >
//       {options.map((data: { value: string, icon: any }, index: number) => (
//         <option key={index} value={data.value} className="capitalize">
//           {<data.icon className="h-6" />}
//           <span className="w-5 h-5 bg-red-600"></span>
//           {data.value}
//         </option>
//       ))}
//     </select>
//   );
// };

// export default SelectField;
