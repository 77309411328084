import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../utils/api";

/****************************************/
/*************** Criteria ***************/
/****************************************/

export const getDecisionMatrixCriteriasList = createAsyncThunk(
  "DecisionMatrixCriteriasList/get",
  async ({ accessToken, matrix_id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/matrixCriterias?matrix_id=${matrix_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createDecisionMatrixCriteria = createAsyncThunk(
  "DecisionMatrixCriteria/create",
  async ({ body, accessToken, matrix_id, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/matrixCriteria?matrix_id=${matrix_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateDecisionMatrixCriteria = createAsyncThunk(
  "DecisionMatrixCriteria/udate",
  async ({ body, accessToken, matrix_id, criteria_id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/matrixCriteria?matrix_id=${matrix_id}&criteria_id=${criteria_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteDecisionMatrixCriteria = createAsyncThunk(
  "DecisionMatrixCriteria/Delete",
  async ({ accessToken, matrix_id, criteria_id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/matrixCriteria?matrix_id=${matrix_id}&criteria_id=${criteria_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

/****************************************/
/********* Options / Concepts ***********/
/****************************************/

export const getDecisionMatrixOptionsList = createAsyncThunk(
  "DecisionMatrixOptionsList/get",
  async ({ accessToken, matrix_id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/matrixConcepts?matrix_id=${matrix_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createDecisionMatrixOption = createAsyncThunk(
  "DecisionMatrixOption/create",
  async ({ body, accessToken, matrix_id, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/matrixConcept?matrix_id=${matrix_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateDecisionMatrixOption = createAsyncThunk(
  "DecisionMatrixOption/udate",
  async ({ body, accessToken, option_id, matrix_id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/matrixConcept?matrix_id=${matrix_id}&concept_id=${option_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteDecisionMatrixOption = createAsyncThunk(
  "DecisionMatrixOption/Delete",
  async ({ accessToken, option_id, matrix_id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/matrixConcept?matrix_id=${matrix_id}&concept_id=${option_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

/****************************************/
/********* Concepts User Rating ***********/
/****************************************/

export const getOptionRatings = createAsyncThunk(
  "getOptionRatings/get",
  async ({ accessToken, matrix_id, criteria_id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/matrixUserRating?matrix_id=${matrix_id}&criteria_id=${criteria_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateOptionRating = createAsyncThunk(
  "updateOptionRating/udate",
  async ({ body, accessToken, matrix_id, criteria_id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/matrixUserRating?matrix_id=${matrix_id}&criteria_id=${criteria_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
