// Node groups collection for AWS

export const kubernetes_groups = [
  {
    name: "node",
    style: {
      group_icon_bg_color: "#bfdbfe",
      group_border_style: "solid",
      group_bg_color: "#f7fbff",
    },
    icon: "node",
    subdirectory: "infrastructure",
  },
  {
    name: "controll plane",
    style: {
      group_icon_bg_color: "#bfdbfe",
      group_border_style: "solid",
      group_bg_color: "#f7fbff",
    },
    icon: "control-plane",
    subdirectory: "infrastructure",
  },
  {
    name: "namespace",
    style: {
      group_icon_bg_color: "#bfdbfe",
      group_border_style: "solid",
      group_bg_color: "#f7fbff",
    },
    icon: "name-space",
    subdirectory: "resources",
  },
];
