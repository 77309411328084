// misc_nodes.js

export const misc_nodes = [
  {
    name: "Security",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/misc_clean/security/attention.svg",
    items: [
      "account",
      "attention",
      "cctv",
      "computer",
      "cookies",
      "download",
      "find",
      "firewall",
      "ftp",
      "key",
      "laptop",
      "lock",
      "money",
      "network",
      "padlock",
      "password",
      "pattern",
      "problem",
      "protection",
      "setting",
      "shield",
      "spam",
      "spy",
      "transfer",
      "umbrella",
      "unlock",
      "user",
      "virus",
      "vpn",
      "warning"
    ],
  },
  {
    name: "Communication",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/misc_clean/communication/browser.svg",
    items: [
      "browser",
      "chat",
      "computer",
      "connectivity",
      "connector",
      "email",
      "fax",
      "head",
      "headphone",
      "internet",
      "landline",
      "laptop",
      "link",
      "location",
      "mailbox",
      "megaphone",
      "microphone",
      "modem",
      "network",
      "pager",
      "radio",
      "satellite",
      "server",
      "share",
      "smartphone",
      "speaker",
      "tv",
      "webcam",
      "wifi"
    ],
  },
  {
    name: "Hardware",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/misc_clean/hardware/computer.svg",
    items: [
      "battery",
      "bluetooth",
      "cable",
      "case",
      "cd",
      "chip",
      "computer",
      "cooling",
      "cpu",
      "diode",
      "download",
      "earphone",
      "fan",
      "folder",
      "ftp",
      "gpu",
      "joystick",
      "lan",
      "laptop",
      "mainboard",
      "modem",
      "monitor",
      "network",
      "plug",
      "printer",
      "resistance",
      "router",
      "search",
      "server",
      "shield",
      "shutdown",
      "smartphone",
      "ssd",
      "turntable",
      "upload",
      "vga"
    ],
  }
];
