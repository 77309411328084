import { createContext } from 'react';

export type DrawerSizes = {
  width: number;
  insetBlock: number;
  insetInline: number;
};

export const DrawerContext = createContext<DrawerSizes>({
  width: 0,
  insetBlock: 0,
  insetInline: 0,
});
