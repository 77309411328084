import React, { ReactNode, useState } from 'react';
import './movingcomp.css';
import { classNames } from 'src/utils/utilities';

interface MovingCompProp {
  children: ReactNode;
  setWidth: Function;
  width: number;
  side: string;
}

const MovingComp = ({ children, setWidth, width, side }: MovingCompProp) => {
  const [isResizing, setIsResizing] = useState(false);

  const maxWidthSize = document.getElementById('project_detail_id')?.clientWidth || 1000;

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsResizing(true);

    const initialX = event.clientX;

    const handleMouseMove = (event: MouseEvent) => {
      const deltaX = side === 'right' ? initialX - event.clientX : event.clientX - initialX;
      const newWidth = Math.min(Math.max(20, width + deltaX), maxWidthSize - 15);

      setWidth(newWidth);
    };

    const handleMouseUp = () => {
      setIsResizing(false);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div className='resizable-container'>
      <div
        className='resizable-div bg-white z-[10]'
        style={{ width: `${width === 300 ? maxWidthSize / 3 : width}px` }}
      >
        <div
          className={classNames('resizer', `resizer--${side}`, isResizing && 'resizer--active')}
          style={{ left: side === 'right' ? 0 : 'auto' }}
          onMouseDown={handleMouseDown}
        />
        {children}
      </div>
    </div>
  );
};

export default MovingComp;
