import React, { useState, useEffect, WheelEvent } from "react";
import { classNames } from "../../../utils/utilities";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { iconsList } from "./utils";
import NodeIconsBoxForDropdown from "./NodeIconsBoxForDropdown";

import Azure from "../flow/assets/vendor_icons/azure.svg";
import Kubernetes from "../flow/assets/vendor_icons/kubernetes.svg";
import DevOps from "../flow/assets/vendor_icons/devops.svg";

import "./NodeIconsDropdown.css";

const servicesList = [
  { id: 1, name: "Azure", icon: Azure },
  { id: 2, name: "Kubernetes", icon: Kubernetes },
  { id: 3, name: "DevOps", icon: DevOps },
];

interface NodeIconsDropdownProps {
  onChangeNodeIcon: Function;
  nodeIcon: string;
}

function NodeIconsDropdown({
  onChangeNodeIcon,
  nodeIcon,
}: NodeIconsDropdownProps) {
  const [currentService, setCurrentService] = useState(1);
  const [search, setSearch] = useState("");
  const [iconsCompleteList, setIconsCompleteList] = useState([]);
  const [iconsNonfilterList, setIconsNonfilterList] = useState([]);

  useEffect(() => {
    const iconsData: any = iconsList(
      `${(currentService === 1 && "azure") || (currentService === 2 && "kubernetes") || (currentService === 3 && "devops")}`
    );

    // Nodes List
    setIconsCompleteList(iconsData);
    setIconsNonfilterList(iconsData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService]);

  const filterIconsByName = async (value: string) => {
    const dataList = [...iconsCompleteList];

    setSearch(value);

    const filterList: any = dataList
      .map((category: any) => ({
        ...category,
        data: category.data.filter((item: any) =>
          item.name.toLowerCase().includes(search.toLowerCase())
        ),
      }))
      .filter((category: any) => category.data.length > 0);

    if (value.length > 0) {
      setIconsCompleteList(filterList);
    } else {
      setIconsCompleteList(iconsNonfilterList);
    }
  };

  // Prevent event propagation for all mouse events
  const preventPropagation = (e: React.MouseEvent | WheelEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className="prevent-flow-zoom"
      onWheel={preventPropagation}
      onMouseDown={preventPropagation}
      onClick={preventPropagation}
    >
      <div className="relative rounded-md shadow-sm w-full mb-3">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          onChange={(e) => filterIconsByName(e.target.value)}
          value={search}
          type="text"
          name="search"
          id="search"
          style={{ boxShadow: "none" }}
          className="text-xs w-full py-3 pl-8 pr-2 text-customDarkBlue border-0 border-b border-zinc-200 placeholder:text-gray-400 focus:border-0 focus:outline-none"
          placeholder="Search..."
          autoComplete="off"
          autoCorrect="off"
        />
      </div>

      <div className="flex">
        <nav
          aria-label="Tabs"
          className="w-[170px] mr-3"
          onWheel={preventPropagation}
        >
          {servicesList.map((service: any) => (
            <div
              key={service.id}
              onClick={(e) => {
                preventPropagation(e);
                setCurrentService(service.id);
              }}
              className={classNames(
                service.id === currentService ? "bg-zinc-100" : "",
                "flex rounded-sm w-full mb-1 py-2 px-4 text-xs font-medium cursor-pointer hover:bg-zinc-100 text-zinc-900"
              )}
            >
              <span className="mr-2">
                <img
                  src={service.icon}
                  alt={service.name}
                  className="h-[15px]"
                />
              </span>
              {service.name}
            </div>
          ))}
        </nav>

        <div
          className="w-[350px] mb-3 px-3 h-[150px_!important] overflow-y-auto custom-scrollbar"
          onWheel={preventPropagation}
        >
          {iconsCompleteList.length > 0 &&
            iconsCompleteList.map((iconDataList: any, index: number) => (
              <NodeIconsBoxForDropdown
                key={index}
                iconDataList={iconDataList}
                onChangeNodeIcon={onChangeNodeIcon}
                selectedNodeIcon={nodeIcon}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(NodeIconsDropdown);