import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../utils/api";

export const getDiagramsList = createAsyncThunk(
  "getDiagramsList/get",
  async ({ accessToken, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/diagrams?project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getDiagramById = createAsyncThunk(
  "getDiagramById/get",
  async ({ accessToken, project_id, diagram_id }: any) => {
    try {
      const response = await getRequest({
        url: `/diagram?project_id=${project_id}&diagram_id=${diagram_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createDiagram = createAsyncThunk(
  "createDiagram/Create",
  async ({ body, accessToken, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/diagram?project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateDiagram = createAsyncThunk(
  "updateDiagram/Update",
  async ({ body, accessToken, project_id, diagram_id }: any) => {
    try {
      const response = await putRequest({
        url: `/diagram?project_id=${project_id}&diagram_id=${diagram_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteDiagram = createAsyncThunk(
  "deleteDiagram/Delete",
  async ({ accessToken, project_id, diagram_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/diagram?project_id=${project_id}&diagram_id=${diagram_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
