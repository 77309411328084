// amazon_aws.js

export const software_nodes = [
  {
    name: "Languages",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      "golang",
      "html-5",
      "javascript",
      "nextjs",
      "nodejs",
      "python",
      "react-native",
      "react",
      "typescript",
    ],
  }
];
