import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../utils/api";

export const getProsConsArgumentsList = createAsyncThunk(
  "prosConsArgumentsList/get",
  async ({ accessToken, pnc_id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/pncArguments?pnc_id=${pnc_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createProsConsArgument = createAsyncThunk(
  "prosConsArgument/create",
  async ({ body, accessToken, pnc_id, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/pncArgument?pnc_id=${pnc_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateProsConsArgument = createAsyncThunk(
  "prosConsArgument/udate",
  async ({ body, accessToken, argument_id, pnc_id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/pncArgument?pnc_id=${pnc_id}&argument_id=${argument_id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteProsConsArgument = createAsyncThunk(
  "prosConsArgument/Delete",
  async ({ accessToken, argument_id, pnc_id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/pncArgument?pnc_id=${pnc_id}&argument_id=${argument_id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
