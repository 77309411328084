import { useState } from 'react';
import CustomModal from '../../../components/customModal';
import ArgumentForm from './argumentForm';
import ArgumentBox, { BaseArgumentBoxProps } from '../../../components/argumentBox';
import RoundButton from 'src/components/roundButton';
import ConfirmationModal from '../../../components/confirmationModal';
import { RatioBar } from 'src/components/progressBar';

interface AnalysisProps {
  detailsData: any;
  optionA: any;
  optionB: any;
  optionAList: any[] | null;
  optionBList: any[] | null;
  updateArgument: (body: any, selectedOption: any, selectedArgument: any) => Promise<void>;
  createArgument: (body: any, selectedOption: any) => Promise<void>;
  deleteArgument: (selectedOption: any, selectedArgument: any) => Promise<void>;
}

export default function Analysis({
  optionA,
  optionB,
  optionAList,
  optionBList,
  updateArgument,
  createArgument,
  deleteArgument,
}: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [argumentFormLoader, setArgumentFormLoader] = useState(false);
  const [selectedArgument, setSelectedArgument] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [argumentDeleteLoader, setArgumentDeleteLoader] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [submittedSuggestionArgument, setSubmittedSuggestionArgument] = useState<string | null>(null);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedArgument(null);
    setSelectedOption(null);
  };

  const onOpenModal = (option: any) => {
    setSelectedOption(option);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, option: any) => {
    setSelectedArgument(data);
    setSelectedOption(option);
    setOpenFormModal(true);
  };

  const updatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);

      try {
        await updateArgument(body, selectedOption, selectedArgument);
        onCloseModal();
      } finally {
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);

      try {
        await createArgument(body, selectedOption);
        onCloseModal();
      } finally {
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingArgument = (option = selectedOption, argument = selectedArgument) => {
    const fetchData = async () => {
      setArgumentDeleteLoader(true);

      try {
        await deleteArgument(option, argument);
        closeConfirmationModal();
      } finally {
        setArgumentDeleteLoader(false);
      }
    };

    fetchData();
  };

  const submittingAiSuggestionArgument = (option: any, argument: any) => {
    const fetchData = async () => {
      setSubmittedSuggestionArgument(argument?.id);

      try {
        const { id, argumentName, argumentWeight, explanation } = argument;

        await createArgument(
          {
            id,
            argument_name: argumentName,
            argument_weight: argumentWeight,
            description: explanation,
            source: 'ai',
          },
          option
        );
      } finally {
        setSubmittedSuggestionArgument(null);
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedArgument) {
      updatingArgument(body);
    } else {
      creatingArgument(body);
    }
  };

  const deleteConfirmationModal = (argument: any, option: string) => {
    setSelectedArgument(argument);
    setSelectedOption(option);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedArgument(null);
    setSelectedOption(null);
  };

  return (
    <>
      <div className='flex items-center justify-center'>
        <RatioBar
          optionAArguments={optionAList}
          optionBArguments={optionBList}
        />
      </div>

      <div className='grid grid-cols-2'>
        {/** Pros Column **/}
        <div className='border-r border-gray-200'>
          <div className='bg-white rounded-2xl overflow-hidden mt-4 mr-4 shadow-md'>
            <div className='pt-6 px-6 pb-4'>
              <div className='text-center'>
                <span className='font-semibold text-base'>{optionA?.option_title}</span>
              </div>
            </div>
            <div className='border-t border-gray-200 p-4'>
              <div
                onClick={() => onOpenModal(optionA)}
                className='mb-3 w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400'
              >
                <RoundButton onClickBtn={() => console.log('clicking...')} />
              </div>
              <div className='relative w-[calc(100%+1rem)] pr-2'>
                <div
                  className='overflow-y-auto pr-2'
                  style={{ height: 'calc(100vh - 326px)' }}
                >
                  <ul className='grid grid-cols-1'>
                    {optionAList?.map((argument: any) => {
                      const source = argument?.source || 'user';

                      const sharedProps: BaseArgumentBoxProps = {
                        name: argument.argumentName,
                        value: argument.argumentWeight,
                        color: 'green',
                        status: submittedSuggestionArgument === argument?.id ? 'creating' : null,
                      };

                      if (source === 'user') {
                        return (
                          <ArgumentBox
                            key={argument?.id}
                            {...sharedProps}
                            variant='user'
                            description={argument.description}
                            onEdit={() => onOpenEditModal(argument, optionA)}
                            onDelete={() => deleteConfirmationModal(argument, optionA)}
                          />
                        );
                      }

                      return (
                        <ArgumentBox
                          key={argument?.id}
                          {...sharedProps}
                          variant='ai'
                          description={argument.explanation}
                          onThumbDown={() => deleteArgument(optionA, argument)}
                          onThumbUp={() => submittingAiSuggestionArgument(optionA, argument)}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/** Cons Column **/}
        <div className='bg-white rounded-2xl overflow-hidden mt-4 ml-4 shadow-md'>
          <div className='pt-6 px-6 pb-4'>
            <div className='text-center'>
              <span className='font-semibold text-base'>{optionB?.option_title}</span>
            </div>
          </div>
          <div className='border-t border-gray-200 p-4'>
            <div
              onClick={() => onOpenModal(optionB)}
              className='mb-3 w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400'
            >
              <RoundButton onClickBtn={() => console.log('clicking...')} />
            </div>

            <div className='relative w-[calc(100%+1rem)] pr-2'>
              <div
                className='overflow-y-auto pr-2'
                style={{ height: 'calc(100vh - 326px)' }}
              >
                <ul className='grid grid-cols-1'>
                  {optionBList?.map((argument: any) => {
                    const source = argument?.source || 'user';

                    const sharedProps: BaseArgumentBoxProps = {
                      name: argument.argumentName,
                      value: argument.argumentWeight,
                      color: 'orange',
                      status: submittedSuggestionArgument === argument?.id ? 'creating' : null,
                    };

                    if (source === 'user') {
                      return (
                        <ArgumentBox
                          key={argument?.id}
                          {...sharedProps}
                          variant='user'
                          description={argument.description}
                          onEdit={() => onOpenEditModal(argument, optionB)}
                          onDelete={() => deleteConfirmationModal(argument, optionB)}
                        />
                      );
                    }

                    return (
                      <ArgumentBox
                        key={argument?.id}
                        {...sharedProps}
                        variant='ai'
                        description={argument.explanation}
                        onThumbDown={() => deleteArgument(optionB, argument)}
                        onThumbUp={() => submittingAiSuggestionArgument(optionB, argument)}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedArgument ? 'Update' : 'Add'} Argument`}
        size='max-w-lg'
      >
        <ArgumentForm
          onCloseModal={() => onCloseModal()}
          loading={argumentFormLoader}
          onSubmitData={onSubmitForm}
          editData={selectedArgument}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingArgument}
        deleteLoader={argumentDeleteLoader}
        description={
          <span>
            Are you sure you want to delete an argument: <strong>{selectedArgument?.argumentName ?? ''}</strong>?
          </span>
        }
      />
    </>
  );
}
