import React, { createContext, useContext, useState, ReactNode } from "react";
import { InboxIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";
import './NotificationStyles.css';

// Define the type for a notification
interface Notification {
  id: number;
  title: string;
  message: string;
  buttonText: string;
  show: boolean;
  onClickBtn: Function;
  icon?: ReactNode;
}

// Define the context type
interface NotificationContextType {
  addNotification: (
    title: string,
    message: string,
    buttonText: string,
    onClickBtn: Function,
    icon?: ReactNode,
  ) => void;
}

// Create the Notification Context
const NotificationContext = createContext<NotificationContextType | undefined>(
  undefined
);

// Define the NotificationProvider props
interface NotificationProviderProps {
  children: ReactNode;
}

// Notification Provider component
export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  // Function to add a notification
  const addNotification = (
    title: string,
    message: string,
    buttonText: string,
    onClickBtn: Function,
    icon?: ReactNode,
  ) => {
    const id = Date.now();
    setNotifications((prev) => [
      ...prev,
      {
        id,
        title,
        message,
        buttonText,
        onClickBtn,
        show: true,
        icon,
      },
    ]);

    // Automatically remove the notification after 8 seconds
    setTimeout(() => {
      removeNotification(id);
    }, 8000);
  };

  // Function to remove a notification
  const removeNotification = (id: number) => {
    setNotifications((prev) =>
      prev.map((n) => (n.id === id ? { ...n, show: false } : n))
    );
  };

  return (
    <NotificationContext.Provider value={{ addNotification }}>
      {children}

      {/* Notification UI */}
      <div
        aria-live="assertive"
        className="pointer-events-none z-50 fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((notification) => {
            return (
              <Transition key={notification.id} show={notification.show}>
                <div className="relative pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition">
                  <div className="h-1 w-full bg-blue-500 animate-progress" />
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        {notification.icon ? notification.icon : (
                          <InboxIcon
                            aria-hidden="true"
                            className="h-6 w-6 text-gray-400"
                          />
                        )}
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          {notification.title}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          {notification.message}
                        </p>

                        {notification.onClickBtn && (
                          <div className="mt-3 flex space-x-7">
                            <button
                              type="button"
                              onClick={() => {
                                notification.onClickBtn();
                                removeNotification(notification.id);
                              }}
                              className="rounded-md bg-white text-sm font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                              {notification.buttonText}
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => removeNotification(notification.id)}
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon aria-hidden="true" className="h-5 w-5" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            );
          })}
        </div>
      </div>
    </NotificationContext.Provider>
  );
};

// Custom hook to use the notification context
export const useNotification = (): NotificationContextType => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
