import { ClipboardDocumentIcon, ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import copy from 'clipboard-copy';
import { showMessage } from '../notifications/showMessages';

interface BlockActionProps {
  textToCopy: string;
  addItemToTextEditor: () => void;
}

const BlockAction = ({ textToCopy, addItemToTextEditor }: BlockActionProps) => {
  const handleCopyClick = async () => {
    try {
      await copy(`${textToCopy}`);
      showMessage('Code Copied', 'success');
    } catch (error) {
      console.error('Copy to clipboard failed:', error);
    }
  };

  return (
    <div className='absolute -top-3 right-3 bg-zinc-900 border border-zinc-700 rounded-2xl py-1 px-3 items-center hidden group-hover:flex'>
      <ClipboardDocumentIcon
        onClick={() => handleCopyClick()}
        className='h-5 mb-0 mr-1 cursor-pointer text-white hover:opacity-80'
      />

      <ArrowRightOnRectangleIcon
        onClick={addItemToTextEditor}
        className='h-5 mb-0 cursor-pointer text-white hover:opacity-80'
      />
    </div>
  );
};

export default BlockAction;
