import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../utils/api";

export const getProjectsList = createAsyncThunk(
  "getProjectsList/get",
  async (accessToken: string) => {
    try {
      const response = await getRequest({
        url: "/projects",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getProjectById = createAsyncThunk(
  "getProjectById/get",
  async ({ accessToken, id }: any) => {
    try {
      const response = await getRequest({
        url: `/project?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createProject = createAsyncThunk(
  "createProject/Create",
  async ({ body, accessToken }: any) => {
    try {
      const response = await postRequest({
        url: `/project`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateProject = createAsyncThunk(
  "updateProject/Update",
  async ({ body, accessToken, id }: any) => {
    try {
      const response = await putRequest({
        url: `/project?id=${id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getProjectDocument = createAsyncThunk(
  "getProjectDocument/get",
  async ({ accessToken, project_id, document_id }: any) => {
    try {
      const response = await getRequest({
        url: `/document?project_id=${project_id}&document_id=${document_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getProjectAllDocuments = createAsyncThunk(
  "getProjectAllDocuments/get",
  async ({ accessToken, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/documents?project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createProjectDocument = createAsyncThunk(
  "createProjectDocument/Create",
  async ({ body, accessToken, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/document?project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateProjectDocument = createAsyncThunk(
  "updateProjectDocument/Update",
  async ({ body, accessToken, project_id, document_id }: any) => {
    try {
      const response = await putRequest({
        url: `/document?project_id=${project_id}&document_id=${document_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteProjectDocument = createAsyncThunk(
  "deleteProjectDocument/Update",
  async ({ accessToken, project_id, document_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/document?project_id=${project_id}&document_id=${document_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteProject = createAsyncThunk(
  "deleteProject/Delete",
  async ({ accessToken, id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/project?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
