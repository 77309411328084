import React, { Key, useEffect, useRef, useState } from 'react';

import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import MenuSubItemsList from './menuSubItemsList';
import Tooltip from '../tooltipWrapper';
import { useAppSelector } from '../../hooks';

interface SidebarProps {
  classNames: Function;
  navigationsList: Array<any>;
  title: string;
  collapseSidebar: boolean;
}

interface MenuItemProps {
  classNames: Function;
  collapseSidebar: boolean;
  item: any;
}

function MenuItem({ classNames, collapseSidebar, item }: Readonly<MenuItemProps>) {
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const subMenuRef = useRef<HTMLDivElement | null>(null);

  const { currentPage } = useAppSelector((state) => state.profile);
  const [isSubMenuFloating, setIsSubMenuFloating] = useState(false);

  const hasChildren = item.childrens && item.childrens?.length > 0;

  const isSelected = !!(
    currentPage &&
    (currentPage.includes(item.routeKey) || item.childrens?.some((child: any) => currentPage.includes(child.routeKey)))
  );

  useEffect(() => {
    setIsSubMenuFloating(false);
  }, [collapseSidebar]);

  const handleMouseEnter = () => {
    if (collapseSidebar) {
      setIsSubMenuFloating(hasChildren);
    }
  };

  const handleMouseLeave = () => {
    if (collapseSidebar) {
      setIsSubMenuFloating(
        hasChildren && !!(linkRef.current?.matches(':hover') || subMenuRef.current?.matches(':hover'))
      );
    }
  };

  return (
    <li
      key={item.name}
      className={classNames(
        'relative mb-0',
        collapseSidebar && 'ml-0',
        isSelected && !isSubMenuFloating && hasChildren && 'bg-slate-100 rounded-[10px]'
      )}
    >
      <Tooltip
        text={collapseSidebar && !isSubMenuFloating ? <span className='whitespace-nowrap'>{item.name}</span> : ''}
        position='right'
      >
        <Link
          ref={linkRef}
          to={item?.href}
          className={classNames(
            isSelected
              ? 'bg-[rgba(10,_15,_41,_0.04)] text-[#2d61d2]'
              : 'text-[#14151A] hover:bg-[rgba(10,_15,_41,_0.04)]',
            `flex items-center rounded-[10px] py-[6px] px-[8px] text-xs leading-5 font-medium cursor-pointer ${
              collapseSidebar ? ' justify-center' : 'justify-between'
            }`
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className='flex items-center justify-center'>
            <span
              className={classNames(
                collapseSidebar ? '' : 'mr-[8px]',
                isSelected ? 'text-[#2d61d2]' : 'text-[#6f717c]'
              )}
            >
              <item.icon className='w-[17px] h-[17px]' />
            </span>

            {!collapseSidebar && <span>{item.name}</span>}
          </div>

          {hasChildren && !collapseSidebar && (
            <ChevronRightIcon
              className={classNames(
                'shrink-0 h-[14px]',
                item.href === currentPage?.href ? 'text-[#2d61d2]' : 'hover:text-[#14151A]',
                `transition-all rotate-${isSelected ? 90 : 0}`
              )}
            />
          )}
        </Link>
      </Tooltip>

      {/* Sub Menu */}
      {hasChildren && (collapseSidebar ? isSubMenuFloating : isSelected) && (
        <div
          ref={subMenuRef}
          className={classNames(isSubMenuFloating && 'pl-[9px] absolute left-full top-[0] bg-transparent')}
          onMouseLeave={handleMouseLeave}
        >
          <div
            className={classNames(
              'rounded-[10px]',
              isSubMenuFloating ? 'bg-white shadow-lg w-[200px] border border-zinc-200' : 'bg-slate-100'
            )}
          >
            {isSubMenuFloating && (
              <h5 className='mx-3 px-2 py-3 text-sm text-[#14151A] font-semibold border-b border-gray-300'>
                {item.name}
              </h5>
            )}
            <ul className={classNames('w-full space-y-1', isSubMenuFloating ? ' p-3' : 'p-3 pl-6')}>
              {item.childrens.map((subitem: { id: Key | null | undefined; title: string; menuList: any[][] }) => (
                <MenuSubItemsList
                  key={subitem.id}
                  classNames={classNames}
                  collapseSidebar={collapseSidebar}
                  data={subitem}
                />
              ))}
            </ul>
          </div>
        </div>
      )}
    </li>
  );
}

export default function MenuItemsList({ classNames, navigationsList, title, collapseSidebar }: SidebarProps) {
  return (
    <li className='mb-0'>
      {!collapseSidebar && (
        <div className='text-xs ml-[8px] mb-[5px] font-semibold leading-6 text-[rgba(15,_19,_36,_0.60)] mb- uppercase'>
          {title}
        </div>
      )}

      <ul className={`space-y-1 pl-0`}>
        {navigationsList.map((item: any) => (
          <MenuItem
            key={item.name}
            item={item}
            collapseSidebar={collapseSidebar}
            classNames={classNames}
          />
        ))}
      </ul>
    </li>
  );
}
