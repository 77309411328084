import { ChevronDownIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { noop } from 'lodash';
import { memo, useState } from 'react';
import CustomButton from 'src/components/customButton';
import ItemWithDropdown, { ActionProps } from 'src/components/itemWithDropdown';
import Toolbar from 'src/components/toolbar';

interface TChartBarProps {
  isTChartReady: boolean;
  generateArgumentsSuggestions: () => Promise<void>;
}

function TChartBar({ isTChartReady, generateArgumentsSuggestions }: TChartBarProps) {
  const [argumentsSuggestionsLoader, setArgumentsSuggestionsLoader] = useState(false);
  // const [aiAssistantEnabled, setAiAssistantEnabled] = useState(false);
  // const [selectedAiChat, setSelectedAiChat] = useState<string>();
  // const [selectedDocs, setSelectedDocs] = useState<Option[]>([]);

  const validateList: ActionProps[] = [
    {
      id: 1,
      name: '/Write a summary',
      onActionClick: noop,
    },
  ];

  const handleGenerateArgumentsSuggestions = async () => {
    setArgumentsSuggestionsLoader(true);

    try {
      await generateArgumentsSuggestions();
    } finally {
      setArgumentsSuggestionsLoader(false);
    }
  };

  return (
    <Toolbar.Container className='bg-white'>
      <Toolbar>
        {/* <Toolbar.Left>
          <Toolbar.Switch
            enabled={aiAssistantEnabled}
            setEnabled={() => setAiAssistantEnabled(!aiAssistantEnabled)}
          >
            Assistant
          </Toolbar.Switch>
        </Toolbar.Left> */}
        <Toolbar.Right
          visibleItems={[
            // new Toolbar.ChatSelectorItem({ id: 1, selected: selectedAiChat, setSelected: setSelectedAiChat }),
            // new Toolbar.DocumentsSelectorItem({
            //   id: 2,
            //   multiple: true,
            //   selected: selectedDocs,
            //   setSelected: setSelectedDocs,
            //   dropdownPosition: 'left',
            // }),
            {
              id: 3,
              actions: validateList,
              name: 'Validate',
              width: 105,
              onActionClick: () => {},
              renderInToolbar: ({ name, actions, onActionClick }) => (
                <div className='shrink-0 relative'>
                  <ItemWithDropdown
                    title={name}
                    actionsData={actions}
                  >
                    <CustomButton
                      text={
                        <div className='flex items-center'>
                          <span>{name}</span>
                          <ChevronDownIcon className='h-4 ml-2 stroke-gray-400' />
                        </div>
                      }
                      type='button'
                      outlined={false}
                      onClickBtn={onActionClick as Function}
                      buttonType='secondary'
                      buttonSize={'md'}
                    />
                  </ItemWithDropdown>
                </div>
              ),
            },
            {
              id: 4,
              name: 'Generate with AI',
              width: 150,
              onActionClick: handleGenerateArgumentsSuggestions,
              renderInToolbar: ({ name, onActionClick }) => (
                <CustomButton
                  beforeIcon={<SparklesIcon className='h-4 mr-1' />}
                  text={name}
                  type='button'
                  onClickBtn={onActionClick as Function}
                  buttonType='secondary'
                  outlined={false}
                  buttonSize={'md'}
                  disabled={!isTChartReady}
                  loading={argumentsSuggestionsLoader}
                />
              ),
            },
          ]}
          hiddenItems={[]}
        />
      </Toolbar>
    </Toolbar.Container>
  );
}

export default memo(TChartBar);

TChartBar.displayName = 'TChartBar';
