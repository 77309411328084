import React from "react";
import { BookmarkIcon } from "@heroicons/react/24/outline";

import HtmlRenderer from "../HTMLRenderer";
import CustomButton from "../customButton";
import { getIconObjectByName } from "../../utils/documentComponentsIcons";

interface DocumentComponentDetailProps {
  data: any;
  onPined: Function;
}

const DocumentComponentDetail = ({
  data,
  onPined,
}: DocumentComponentDetailProps) => {
  const iconObject = getIconObjectByName(data?.icon);
  return (
    <div>
      <div className="flex items-center justify-between mb-3">
        <div className="flex items-center">
          <img
            src={`${iconObject?.icon || ""}`}
            className="h-8 mr-2"
            alt={data?.title}
          />
          <span className="text-md text-customDarkBlue line-clamp-1">
            {data?.title}
          </span>
        </div>

        <CustomButton
          text={data?.is_favorite ? "UnPin" : "Pin"}
          beforeIcon={
            <BookmarkIcon
              className={`h-4 mr-1 ${data?.is_favorite ? "fill-white" : ""}`}
            />
          }
          onClickBtn={() => onPined(data?.id)}
          type="button"
          buttonType='primary'
        />
      </div>

      <div className="text-xs text-zinc-500 line-clamp-1 mb-4">
        {data?.description}
      </div>

      <div className="rounded-xl shadow-md w-full p-5 bg-white">
        <HtmlRenderer htmlString={data?.content} />
      </div>
    </div>
  );
};

export default DocumentComponentDetail;
