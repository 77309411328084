import { CubeIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { ActionGroupProps } from 'src/components/itemWithDropdown';
import type { ConversationContext } from 'src/type';
import { DefaultDropdownRenderer } from 'src/components/toolbar';

const specialChatAssistants = {
  document: {
    icon: DocumentTextIcon,
    validations: {
      limitations: {
        agentName: 'documents-limitations-checker',
        callToAction: 'Check for limitations',
        chatTitle: 'Document Limitations Checker',
        chatDescription: 'Evaluating technology and service offerings limitations in the provided document',
        userMessage: 'Check for technology and service offerings limitations in:',
      },
      improvements: {
        agentName: 'documents-improvment-checker',
        callToAction: 'Check for improvements',
        chatTitle: 'Document Improvements Checker',
        chatDescription: 'Exploring potential improvements in the provided document',
        userMessage: 'Explore potential improvements in:',
      },
    },
  },
  diagram: {
    icon: CubeIcon,
    validations: {
      limitations: {
        agentName: 'diagram-validator',
        callToAction: 'Check for limitations',
        chatTitle: 'Diagram Limitations Checker',
        chatDescription: 'Validating the integrity of the provided diagram from technical perspective',
        userMessage: 'Validate the integrity of the provided diagram:',
      },
      security: {
        agentName: 'diagram-validator',
        callToAction: 'Security Recommendations',
        chatTitle: 'Diagram Security Recommendations',
        chatDescription: 'Validating the integrity of the provided diagram from security perspective',
        userMessage: 'Validate the integrity of the provided diagram:',
      },
      cost: {
        agentName: 'diagram-validator',
        callToAction: 'Cost Optimization',
        chatTitle: 'Diagram Cost Optimization',
        chatDescription: 'Validating the integrity of the provided diagram from cost perspective',
        userMessage: 'Validate the integrity of the provided diagram:',
      },
      disasterRecovery: {
        agentName: 'diagram-validator',
        callToAction: 'Disaster Recovery',
        chatTitle: 'Diagram Disaster Recovery',
        chatDescription: 'Validating the integrity of the provided diagram from disaster recovery perspective',
        userMessage: 'Validate the integrity of the provided diagram:',
      },
      testValidator1: {
        agentName: 'diagram-validator',
        callToAction: 'Test validator 1',
        chatTitle: 'Diagram Security Recommendations',
        chatDescription: 'Validating the integrity of the provided diagram from security perspective',
        userMessage: 'Validate the integrity of the provided diagram:',
        dropdownGroup: 'Test group',
      },
      testValidator2: {
        agentName: 'diagram-validator',
        callToAction: 'Test validator 2',
        chatTitle: 'Diagram Security Recommendations',
        chatDescription: 'Validating the integrity of the provided diagram from security perspective',
        userMessage: 'Validate the integrity of the provided diagram:',
        dropdownGroup: 'Test group',
      },
    },
  },
};

export type SpecialChatAssistantType = typeof specialChatAssistants;

export function getSpecialChatAssistant<
  G extends keyof SpecialChatAssistantType,
  V extends keyof SpecialChatAssistantType[G]['validations'] & string,
>(type: `${G}:${V}`): ConversationContext | null {
  const [group, validator] = type.split(':') as [G, V];
  const groupData = specialChatAssistants[group];
  const validatorData = (groupData as any)?.['validations']?.[validator];

  if (!validatorData) return null;

  return {
    ...validatorData,
    groupName: group,
    validatorName: validator,
    chatType: type,
    icon: groupData.icon,
  } as ConversationContext;
}

export function getValidateList<G extends keyof SpecialChatAssistantType>(
  groupName: G,
  callback: (context: ConversationContext) => void
): Array<ActionGroupProps> {
  const { validations } = specialChatAssistants[groupName];
  const validationEntries = Object.entries(validations);

  // Process entries in order, building groups as we go
  const result: ActionGroupProps[] = [];
  const groupsInProgress: Record<string, ActionGroupProps> = {};

  validationEntries.forEach(([validatorName, context]) => {
    const dropdownGroup = 'dropdownGroup' in context ? context.dropdownGroup : null;
    const type = `${groupName}:${validatorName}`;
    const conversationContext = getSpecialChatAssistant(type as any) as ConversationContext;
    const action = {
      id: result.length + 1,
      name: context.callToAction,
      onActionClick: () => callback(conversationContext),
    };

    if (!dropdownGroup) {
      result.push(action);

      return;
    }

    if (!groupsInProgress[dropdownGroup]) {
      groupsInProgress[dropdownGroup] = {
        id: result.length + 1,
        name: dropdownGroup,
        actions: [],
        renderInToolbar: () => null,
        renderInDropdown: DefaultDropdownRenderer,
      };
      result.push(groupsInProgress[dropdownGroup]);
    }

    groupsInProgress[dropdownGroup].actions?.push(action);
  });

  return result;
}

export function isValidationChat(chatType: string): boolean {
  return !(chatType === 'chat' || chatType === 'quick-chat');
}
