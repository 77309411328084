import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface SearchFieldProps {
  onSearchField: Function;
  searchValue: string;
  classes?: string;
}

export default function SearchField({
  onSearchField,
  searchValue,
  classes,
}: SearchFieldProps) {
  return (
    <div className="relative rounded-md shadow-sm w-full">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <MagnifyingGlassIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
      </div>
      <input
        onChange={(e) => onSearchField(e.target.value)}
        value={searchValue}
        type="text"
        name="search"
        id="search"
        className={`block text-xs w-full rounded-md border-0 py-1 pl-8 text-customDarkBlue ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:leading-6 ${classes || ''}`}
        placeholder="Search..."
        autoComplete="off"
        autoCorrect="off"
      />
    </div>
  );
}
