import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { Range } from '@ckeditor/ckeditor5-engine';

class RangeManager {
  private storedRanges: Range[] = [];

  setStoredRanges(ranges: Range[]) {
    this.storedRanges = ranges;
  }

  getStoredRanges(): Range[] {
    return this.storedRanges;
  }

  clearStoredRanges() {
    this.storedRanges = [];
  }
}

class HighlightSelectionCommand extends Command {
  private rangeManager: RangeManager | null = null;

  setRangeManager(rangeManager: RangeManager) {
    this.rangeManager = rangeManager;
  }

  override execute() {
    const {
      editor,
      editor: {
        model: {
          document: { selection },
        },
      },
    } = this;

    if (!selection || selection.isCollapsed) {
      return;
    }

    editor.model.change((writer) => {
      const ranges = Array.from(selection.getRanges());
      const storedRanges = [];

      for (const range of ranges) {
        const markerName = `highlightedSelection:${range.start.path.join('_')}`;

        writer.addMarker(markerName, {
          range,
          usingOperation: false,
          affectsData: false,
        });

        storedRanges.push(range);
      }

      this.rangeManager?.setStoredRanges(storedRanges);
    });
  }
}

class RemoveHighlightCommand extends Command {
  private rangeManager: RangeManager | null = null;

  setRangeManager(rangeManager: RangeManager) {
    this.rangeManager = rangeManager;
  }

  override execute() {
    const { editor } = this;

    editor.model.change((writer) => {
      const markers = Array.from(editor.model.markers).filter(({ name }) => name.startsWith('highlightedSelection:'));

      for (const marker of markers) {
        writer.removeMarker(marker.name);
      }

      this.rangeManager?.clearStoredRanges();
    });
  }
}

class BaseInsertCommand extends Command {
  protected rangeManager: RangeManager | null = null;

  setRangeManager(rangeManager: RangeManager) {
    this.rangeManager = rangeManager;
  }

  protected highlightInsertedContent(range: Range, writer: any) {
    const { editor } = this;

    // Remove existing markers
    const markers = Array.from(editor.model.markers).filter(({ name }) => name === 'highlightedInsertion');

    for (const marker of markers) {
      writer.removeMarker(marker.name);
    }

    writer.addMarker('highlightedInsertion', {
      range,
      usingOperation: false,
      affectsData: false,
    });

    // Defer the listener registration to the next tick
    setTimeout(() => {
      document.addEventListener('click', () => {
        editor.model.change((writer) => {
          const markers = Array.from(editor.model.markers).filter(({ name }) => name === 'highlightedInsertion');

          for (const marker of markers) {
            writer.removeMarker(marker.name);
          }
        });
      }, { once: true });
    }, 0);
  }
}

class ReplaceCommand extends BaseInsertCommand {
  override execute({ html: newHtml }: { html: string }) {
    const { editor } = this;
    const storedRanges = this.rangeManager?.getStoredRanges();

    if (!storedRanges || storedRanges.length === 0) {
      return;
    }

    try {
      editor.model.change((writer) => {
        const viewFragment = editor.data.processor.toView(newHtml);
        const modelFragment = editor.data.toModel(viewFragment);

        for (const range of storedRanges) {
          writer.remove(range);
        }

        const insertPosition = storedRanges[0].start;
        const insertedContentRange = editor.model.insertContent(modelFragment, insertPosition);

        this.highlightInsertedContent(insertedContentRange, writer);
      });

      this.rangeManager?.clearStoredRanges();

      return true;
    } catch (error) {
      console.error('Error replacing highlighted content:', error);
      return false;
    }
  }
}

class InsertAboveCommand extends BaseInsertCommand {
  override execute({ html: newHtml }: { html: string }) {
    const { editor } = this;
    const storedRanges = this.rangeManager?.getStoredRanges();

    if (!storedRanges || storedRanges.length === 0) {
      return;
    }

    try {
      editor.model.change((writer) => {
        const viewFragment = editor.data.processor.toView(newHtml);
        const modelFragment = editor.data.toModel(viewFragment);

        const insertPosition = storedRanges[0].start;
        const insertedContentRange = editor.model.insertContent(modelFragment, insertPosition);

        this.highlightInsertedContent(insertedContentRange, writer);
      });

      return true;
    } catch (error) {
      console.error('Error inserting content above:', error);
      return false;
    }
  }
}

class InsertBelowCommand extends BaseInsertCommand {
  override execute({ html: newHtml }: { html: string }) {
    const { editor } = this;
    const storedRanges = this.rangeManager?.getStoredRanges();

    if (!storedRanges || storedRanges.length === 0) {
      return;
    }

    try {
      editor.model.change((writer) => {
        const viewFragment = editor.data.processor.toView(newHtml);
        const modelFragment = editor.data.toModel(viewFragment);

        const insertPosition = storedRanges[storedRanges.length - 1].end;
        const insertedContentRange = editor.model.insertContent(modelFragment, insertPosition);

        this.highlightInsertedContent(insertedContentRange, writer);
      });

      return true;
    } catch (error) {
      console.error('Error inserting content below:', error);
      return false;
    }
  }
}

export class SelectionHighlightPlugin extends Plugin {
  private rangeManager = new RangeManager();

  static get requires() {
    return [HighlightSelectionCommand, RemoveHighlightCommand, ReplaceCommand, InsertAboveCommand, InsertBelowCommand];
  }

  init() {
    const { editor } = this;

    const commands = [
      { name: 'selection:highlight', command: new HighlightSelectionCommand(editor) },
      { name: 'selection:unhighlight', command: new RemoveHighlightCommand(editor) },
      { name: 'selection:replace', command: new ReplaceCommand(editor) },
      { name: 'selection:insertAbove', command: new InsertAboveCommand(editor) },
      { name: 'selection:insertBelow', command: new InsertBelowCommand(editor) },
    ];

    for (const { name, command } of commands) {
      command.setRangeManager(this.rangeManager);

      editor.commands.add(name, command);
    }

    // Add conversion for the 'highlightedSelection' marker to a class
    editor.conversion.for('downcast').markerToHighlight({
      model: 'highlightedSelection',
      view: {
        classes: 'highlighted-selection',
      },
    });

    // Add conversion for the 'highlightedInsertion' marker to a class
    editor.conversion.for('downcast').markerToHighlight({
      model: 'highlightedInsertion',
      view: {
        classes: 'highlighted-insertion',
      },
    });
  }
}
