import { useState } from 'react';
import CustomModal from '../../../components/customModal';
import CriteriaForm from './criteriaForm';
import OptionForm from './optionForm';
import RoundButton from 'src/components/roundButton';
import LabelWithActions from 'src/components/labelWithActions';
import ConfirmationModal from '../../../components/confirmationModal';
import SelectField from 'src/components/formFields/selectField';

const SELECT_OPTIONS_LIST = [
  { title: 'Please select', value: 'null' },
  { title: '(0) Average', value: '0' },
  { title: '(-1) Worse Than Average', value: '-1' },
  { title: '(1) Better Than Average', value: '1' },
];

interface AnalysisProps {
  criteriaList: any[];
  optionList: any[];
  updateCriteria: (body: any, criteriaId: string) => Promise<void>;
  updateOption: (body: any) => Promise<void>;
  createCriteria: (body: any) => Promise<void>;
  createOption: (body: any) => Promise<void>;
  deleteCriteria: (criteriaId: string) => Promise<void>;
  deleteOption: (optionId: string) => Promise<void>;
  updateRating: (body: any, criteriaId: string) => Promise<void>;
}

export default function Analysis({
  criteriaList,
  optionList,
  updateCriteria,
  updateOption,
  createCriteria,
  createOption,
  deleteCriteria,
  deleteOption,
  updateRating,
}: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [formLoader, setFormLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [formType, setFormType] = useState('');
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [confirmationLoader, setConfirmationLoader] = useState(false);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedData(null);
  };

  const onOpenModal = (type: string) => {
    setFormType(type);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, type: string) => {
    setFormType(type);
    setSelectedData(data);
    setOpenFormModal(true);
  };

  const updatingRequest = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);

      try {
        if (formType === 'criteria') {
          await updateCriteria(body, selectedData?.id);
        }

        if (formType === 'option') {
          await updateOption(body);
        }

        onCloseModal();
      } finally {
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingRequest = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);

      try {
        if (formType === 'criteria') {
          await createCriteria(body);
        }

        if (formType === 'option') {
          await createOption(body);
        }

        onCloseModal();
      } finally {
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingRequest = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);

      try {
        if (formType === 'criteria') {
          await deleteCriteria(selectedData?.id);
        }

        if (formType === 'option') {
          await deleteOption(selectedData?.id);
        }

        deletingModalClose();
      } finally {
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const updatingUserRatingRequest = (value: string, optionId: string, criteriaId: string) => {
    const fetchData = async () => {
      const payload = {
        concept_id: optionId,
        user_rating: parseInt(value),
      };

      setFormLoader(true);

      try {
        await updateRating(payload, criteriaId);
      } finally {
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedData) {
      updatingRequest(body);
    } else {
      creatingRequest(body);
    }
  };

  const deletingModalOpen = (data: any, type: string) => {
    setSelectedData(data);
    setFormType(type);
    setDeleteConfirm(true);
  };

  const deletingModalClose = () => {
    setConfirmationLoader(false);
    setDeleteConfirm(false);
    setSelectedData(null);
    setFormType('');
  };

  const onChangeOptionRating = (value: string, optionId: string, criteriaId: string) => {
    updatingUserRatingRequest(value, optionId, criteriaId);
  };

  const gettingOptionValue = (option_concepts: any, optionId: string) => {
    if (option_concepts?.length > 0) {
      const findObject = option_concepts.find((data: any) => data.id === optionId);

      return typeof findObject?.user_rating === 'number' ? String(findObject.user_rating).toLowerCase() : 'null';
    }

    return 'null';
  };

  const calculatingEachOption = (conceptId: string) => {
    if (criteriaList?.length > 0) {
      let countOption = 0;

      criteriaList.forEach((data) => {
        if (data?.concepts?.length > 0) {
          data?.concepts.forEach((concept: { user_rating: number; id: string }) => {
            if (concept.id === conceptId) {
              countOption = countOption + data.criteria.criteria_multiplier * concept.user_rating;
            }
          });
        }
      });

      return countOption;
    }
  };

  return (
    <>
      <div className='-my-6 h-full'>
        <table className='h-full divide-y divide-gray-200 w-full'>
          <thead>
            <tr className='divide-x divide-gray-200'>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-4 text-left text-xs font-semibold text-customDarkBlue'
              ></th>
              <th
                scope='col'
                colSpan={3}
                className='px-4 py-3.5 text-xs font-semibold text-customDarkBlue text-center'
              >
                <div className='flex items-center justify-center'>
                  <div className='mr-3'>
                    <RoundButton onClickBtn={() => onOpenModal('option')} />
                  </div>
                  <span>Add an option</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='h-full divide-y divide-gray-200 bg-white'>
            <tr className='divide-x divide-gray-200 hover:bg-zinc-100'>
              <td
                style={{
                  width: optionList?.length > 0 ? 'auto' : '200px',
                }}
                className='p-4 text-xs font-medium text-customDarkBlue flex items-center'
              >
                <RoundButton onClickBtn={() => onOpenModal('criteria')} />
                <span className='ml-2'>Criteria</span>
              </td>

              {optionList?.length > 0 ? (
                optionList.map((option) => {
                  return (
                    <td
                      key={option.id}
                      className='p-4 text-xs bg-gray-100 font-semibold text-customDarkBlue w-auto text-center'
                    >
                      <LabelWithActions
                        label={option.title}
                        onEdit={() => onOpenEditModal(option, 'option')}
                        onDelete={() => deletingModalOpen(option, 'option')}
                      />
                    </td>
                  );
                })
              ) : (
                <td className='p-4 bg-gray-100 w-full' />
              )}
            </tr>

            {criteriaList?.map((criteria) => {
              return (
                <tr
                  key={criteria?.criteria?.id}
                  className='divide-x divide-gray-200'
                >
                  <td className='p-4 text-xs font-medium text-gray-700 w-60'>
                    <LabelWithActions
                      label={
                        <div>
                          <span className='inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2'>
                            {criteria?.criteria?.criteria_multiplier}
                          </span>
                          {criteria?.criteria?.title}
                        </div>
                      }
                      onEdit={() => onOpenEditModal(criteria?.criteria, 'criteria')}
                      onDelete={() => deletingModalOpen(criteria?.criteria, 'criteria')}
                    />
                  </td>

                  {optionList?.map((option) => {
                    return (
                      <td
                        key={option.id}
                        className='p-4 text-xs text-customDarkBlue w-auto text-center'
                      >
                        <SelectField
                          options={SELECT_OPTIONS_LIST}
                          value={gettingOptionValue(criteria?.concepts, option.id)}
                          onSelect={(value) => onChangeOptionRating(value, option?.id, criteria?.criteria?.id)}
                          classes="w-full py-1.5 text-customDarkBlue"
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            <tr className='h-full divide-x divide-gray-200'>
              <td />
              {optionList?.map((option) => {
                return <td key={option.id} />;
              })}
            </tr>

            <tr className='h-16 divide-x divide-gray-200'>
              <td className='p-4 text-xs font-medium text-customDarkBlue w-auto'>Result</td>
              {optionList?.map((option) => {
                return (
                  <td
                    key={option.id}
                    className='p-4 text-xs text-customDarkBlue w-auto text-center font-bold'
                  >
                    {calculatingEachOption(option.id)}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedData ? 'Update' : 'Add'} ${
          (formType === 'criteria' && 'Criteria') || (formType === 'option' && 'Option') || ''
        }`}
        size='max-w-lg'
      >
        {formType === 'criteria' && (
          <CriteriaForm
            onCloseModal={() => onCloseModal()}
            loading={formLoader}
            onSubmitData={onSubmitForm}
            editData={selectedData}
          />
        )}

        {formType === 'option' && (
          <OptionForm
            onCloseModal={() => onCloseModal()}
            loading={formLoader}
            onSubmitData={onSubmitForm}
            editData={selectedData}
          />
        )}
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingRequest}
        deleteLoader={confirmationLoader}
      />
    </>
  );
}
