import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDialogAutoFocus } from 'src/hooks/useDialogAutoFocus';
interface CustomModalProps {
  open: boolean;
  onCloseModal: () => void;
  title?: any;
  children: ReactNode;
  size: string;
}

export default function CustomModal({ open, onCloseModal, title, children, size }: CustomModalProps) {
  const { setRef } = useDialogAutoFocus();

  return (
    <Transition
      show={open}
      as={Fragment}
    >
      <Dialog
        ref={setRef}
        as='div'
        className='relative z-50'
        onClose={onCloseModal}
      >
        <TransitionChild
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </TransitionChild>

        <div className='fixed inset-0 z-50 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel
                className={`relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full ${size} sm:p-6`}
              >
                {title && (
                  <div className='flex items-center justify-between mb-4 pb-4 border-b'>
                    <h3 className='mb-0'>{title}</h3>
                    <button
                      type='button'
                      className='rounded-md bg-white text-gray-400 hover:text-customLightBlue focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                      onClick={() => onCloseModal()}
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon
                        className='h-6 w-6'
                        aria-hidden='true'
                      />
                    </button>
                  </div>
                )}

                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
