import React, { useState, useEffect, useCallback } from "react";
import { classNames } from "../../../utils/utilities";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Azure from "../flow/assets/vendor_icons/azure.svg";
import AWS from "../flow/assets/vendor_icons/aws2.svg";
import GCP from "../flow/assets/vendor_icons/gcp.svg";
import Misc from "../flow/assets/vendor_icons/gcp.svg";
import { iconsListForEdge, objectAnimatedArray } from "./utils";
import EdgeIconBox from "./edgeIconBox";
import debounce from "lodash/debounce";

const servicesList = [
  { id: 1, name: "Packet", icon: Azure },
  { id: 2, name: "Protocol", icon: Azure },
  { id: 3, name: "Identity", icon: Azure },
  { id: 4, name: "Azure", icon: Azure },
  { id: 5, name: "AWS", icon: AWS },
  { id: 6, name: "GCP", icon: GCP },
  { id: 7, name: "Misc", icon: Misc },
];

interface EdgeIconsDropdownProps {
  onChangeEdgeImage: Function;
  addObjectChange: Function;
  animatedCircleColor: string;
  edgeImage: string;
}

export default function EdgeIconsDropdown({
  onChangeEdgeImage,
  addObjectChange,
  animatedCircleColor,
  edgeImage,
}: EdgeIconsDropdownProps) {
  const [currentService, setCurrentService] = useState(1);
  const [search, setSearch] = useState("");
  const [iconsCompleteList, setIconsCompleteList] = useState([]);
  const [iconsNonfilterList, setIconsNonfilterList] = useState([]);

  useEffect(() => {
    const iconsData: any = iconsListForEdge(
      `${(currentService === 2 && "azure") || (currentService === 3 && "azure") || (currentService === 4 && "azure")}`
    );

    setIconsCompleteList(iconsData);
    setIconsNonfilterList(iconsData);
  }, [currentService]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filterIconsByName = useCallback(
    debounce((value: string) => {
      const dataList = [...iconsNonfilterList];

      const filterList: any = dataList
        .map((category: any) => ({
          ...category,
          data: category.data.filter((item: any) =>
            item.name.toLowerCase().includes(value.toLowerCase())
          ),
        }))
        .filter((category: any) => category.data.length > 0);

      setIconsCompleteList(value.length > 0 ? filterList : iconsNonfilterList);
    }, 300),
    [iconsNonfilterList]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
    filterIconsByName(value);
  };

  return (
    <div>
      <div className="relative rounded-md shadow-sm w-full mb-3">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          onChange={handleSearchChange}
          value={search}
          type="text"
          name="search"
          id="search"
          style={{ boxShadow: "none" }}
          className={`text-xs w-full py-3 pl-8 pr-2 text-customDarkBlue border-0 border-b border-zinc-200 placeholder:text-gray-400 focus:border-0 focus:outline-none`}
          placeholder="Search..."
          autoComplete="off"
          autoCorrect="off"
        />
      </div>

      <div className="flex">
        <nav aria-label="Tabs" className="w-[170px] mr-3">
          {servicesList.map((service) => (
            <div
              key={service.id}
              onClick={() => setCurrentService(service.id)}
              className={classNames(
                service.id === currentService
                  ? "bg-zinc-100 rounded-[7px] border border-[#2d61d2]"
                  : "",
                "flex rounded-sm w-full mb-1 py-2 px-4 text-xs font-medium cursor-pointer hover:bg-zinc-100 text-zinc-900"
              )}
            >
              <span className="mr-2">
                <img
                  src={service.icon}
                  alt={service.name}
                  className="h-[15px]"
                />
              </span>
              {service.name}
            </div>
          ))}
        </nav>

        <div className="w-[350px] overflow-hidden mb-3 p-3 hover:overflow-y-auto">
          {currentService === 1 ? (
            <div className="grid grid-cols-6 gap-2">
              {objectAnimatedArray.map((edge, index) => (
                <div key={index} className="relative">
                  <button
                    className={`w-[25px] h-[25px] rounded-full p-1 border border-zinc-200 transition-all ${animatedCircleColor === edge.color ? "scale-125 border-4 border-zinc-100" : ""} hover:scale-125`}
                    style={{
                      background: edge.color,
                      outline:
                        animatedCircleColor === edge.color
                          ? `solid ${edge.color} 1px`
                          : "none",
                    }}
                    onClick={() => addObjectChange(edge)}
                  />
                </div>
              ))}
            </div>
          ) : (
            <>
              {iconsCompleteList.length > 0 &&
                iconsCompleteList.map((iconDataList: any, index: number) => (
                  <EdgeIconBox
                    key={index}
                    iconDataList={iconDataList}
                    onChangeEdgeImage={onChangeEdgeImage}
                    selectedEdgeImage={edgeImage}
                  />
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
