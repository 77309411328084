import { Link } from "react-router-dom";
import SubscriptionButton from "../subscriptionButton";
import VisitSection from "../visitSection";

export const tiers = [
  {
    name: "Starter" as const,
    slug: "starter",
    description: "Everything you need to start selling.",
    priceMonthly: 99,
    href: "#",
    buttonText: "Basic Plan",
    priceId: process.env.REACT_APP_STRIPE_BASIC || "",
    highlights: [
      { description: "Up to 3 team members" },
      { description: "Up to 5 deal progress boards" },
      { description: "Source leads from select platforms" },
      { description: "RadiantAI integrations", disabled: true },
      { description: "Competitor analysis", disabled: true },
    ],
    features: [
      {
        section: "Sourcing",
        name: "Accounts",
        value: 3,
      },
      {
        section: "Sourcing",
        name: "Deal progress boards",
        value: 5,
      },
      {
        section: "Sourcing",
        name: "Sourcing platforms",
        value: "Select",
      },
      {
        section: "Sourcing",
        name: "Contacts",
        value: 100,
      },
      {
        section: "Sourcing",
        name: "AI assisted outreach",
        value: false,
      },
      {
        section: "Analysis",
        name: "Competitor analysis",
        value: false,
      },
      {
        section: "Analysis",
        name: "Dashboard reporting",
        value: false,
      },
      {
        section: "Analysis",
        name: "Community insights",
        value: false,
      },
      {
        section: "Analysis",
        name: "Performance analysis",
        value: false,
      },
      {
        section: "Support",
        name: "Email support",
        value: true,
      },
      {
        section: "Support",
        name: "24 / 7 call center support",
        value: false,
      },
      {
        section: "Support",
        name: "Dedicated account manager",
        value: false,
      },
    ],
  },
  {
    name: "Growth" as const,
    slug: "growth",
    description: "All the extras for your growing team.",
    priceMonthly: 149,
    href: "#",
    buttonText: "Advanced Plan",
    priceId: process.env.REACT_APP_STRIPE_ADVANCED || "",
    highlights: [
      { description: "Up to 10 team members" },
      { description: "Unlimited deal progress boards" },
      { description: "Source leads from over 50 verified platforms" },
      { description: "RadiantAI integrations", disabled: false },
      { description: "5 competitor analyses per month" },
    ],
    features: [
      {
        section: "Sourcing",
        name: "Accounts",
        value: 10,
      },
      {
        section: "Sourcing",
        name: "Deal progress boards",
        value: "Unlimited",
      },
      {
        section: "Sourcing",
        name: "Sourcing platforms",
        value: "100+",
      },
      {
        section: "Sourcing",
        name: "Contacts",
        value: 1000,
      },
      {
        section: "Sourcing",
        name: "AI assisted outreach",
        value: true,
      },
      {
        section: "Analysis",
        name: "Competitor analysis",
        value: "5 / month",
      },
      {
        section: "Analysis",
        name: "Dashboard reporting",
        value: true,
      },
      {
        section: "Analysis",
        name: "Community insights",
        value: true,
      },
      {
        section: "Analysis",
        name: "Performance analysis",
        value: true,
      },
      {
        section: "Support",
        name: "Email support",
        value: true,
      },
      {
        section: "Support",
        name: "24 / 7 call center support",
        value: true,
      },
      {
        section: "Support",
        name: "Dedicated account manager",
        value: false,
      },
    ],
  },
  {
    name: "Enterprise" as const,
    slug: "enterprise",
    description: "Added flexibility to close deals at scale.",
    priceMonthly: 299,
    href: "#",
    buttonText: "Pro Plan",
    priceId: process.env.REACT_APP_STRIPE_PRO || "",
    highlights: [
      { description: "Unlimited active team members" },
      { description: "Unlimited deal progress boards" },
      { description: "Source leads from over 100 verified platforms" },
      { description: "RadiantAI integrations", disabled: false },
      { description: "Unlimited competitor analyses", disabled: false },
    ],
    features: [
      {
        section: "Sourcing",
        name: "Accounts",
        value: "Unlimited",
      },
      {
        section: "Sourcing",
        name: "Deal progress boards",
        value: "Unlimited",
      },
      {
        section: "Sourcing",
        name: "Sourcing platforms",
        value: "100+",
      },
      {
        section: "Sourcing",
        name: "Contacts",
        value: "Unlimited",
      },
      {
        section: "Sourcing",
        name: "AI assisted outreach",
        value: true,
      },
      {
        section: "Analysis",
        name: "Competitor analysis",
        value: "Unlimited",
      },
      {
        section: "Analysis",
        name: "Dashboard reporting",
        value: true,
      },
      {
        section: "Analysis",
        name: "Community insights",
        value: true,
      },
      {
        section: "Analysis",
        name: "Performance analysis",
        value: true,
      },
      {
        section: "Support",
        name: "Email support",
        value: true,
      },
      {
        section: "Support",
        name: "24 / 7 call center support",
        value: true,
      },
      {
        section: "Support",
        name: "Dedicated account manager",
        value: true,
      },
    ],
  },
];

// function Header() {
//   return (
//     <div className="mb-16 w-full max-w-7xl mx-auto">
//       <img src={mainLogo} alt="Radiant Logo" className="h-[50px] mb-[50px]" />
//       <h1 className="text-4xl font-medium tracking-tighter text-gray-950 data-[dark]:text-white sm:text-6xl">
//         Pricing that grows with your team size.
//       </h1>
//       <p className="mt-6 max-w-3xl text-2xl font-medium text-gray-500">
//         Companies all over the world have closed millions of deals with Radiant.
//         Sign up today and start selling smarter.
//       </p>
//     </div>
//   );
// }

export function PricingCards() {
  return (
    <div>
      <div className="w-[90%] mx-auto mb-8 -mt-[20px]">
        <VisitSection />
      </div>
      <div className="relative pb-[60px]">
        <div className="absolute inset-x-2 bottom-0 top-48 rounded-[40px] ring-1 ring-inset ring-black/5 bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#B0C2EB] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff] sm:bg-[linear-gradient(145deg,var(--tw-gradient-stops))]" />
        <div className="relative w-[90%] mx-auto">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
            {tiers.map((tier, tierIndex) => (
              <PricingCard key={tierIndex} tier={tier} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function PricingCard({ tier }: { tier: (typeof tiers)[number] }) {
  return (
    <div className="-m-2 grid grid-cols-1 rounded-[30px] shadow-[inset_0_0_2px_1px_#ffffff4d] ring-1 ring-black/5 max-lg:mx-auto max-lg:w-full max-lg:max-w-md">
      <div className="grid grid-cols-1 rounded-[30px] p-2 shadow-md shadow-black/5">
        <div className="rounded-3xl bg-white p-10 pb-9 shadow-2xl ring-1 ring-black/5">
          <div>{tier.name}</div>
          <p className="mt-2 text-sm/6 text-gray-950/75">{tier.description}</p>
          <div className="mt-8 flex items-center gap-4">
            <div className="text-5xl font-medium text-gray-950">
              ${tier.priceMonthly}
            </div>
            <div className="text-sm/5 text-gray-950/75">
              <p>USD</p>
              <p>per month</p>
            </div>
          </div>
          <div className="mt-8">
            <Link to={tier.href}>
              <SubscriptionButton
                priceId={tier.priceId}
                label={tier.buttonText}
              />
            </Link>
          </div>
          <div className="mt-8">
            <h3 className="text-sm/6 font-medium text-gray-950">
              Start selling with:
            </h3>
            <ul className="mt-3 space-y-3">
              {tier.highlights.map((props, featureIndex) => (
                <FeatureItem key={featureIndex} {...props} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FeatureItem({
  description,
  disabled = false,
}: {
  description: string;
  disabled?: boolean;
}) {
  return (
    <li
      data-disabled={disabled ? true : undefined}
      className="flex items-start gap-4 text-sm/6 text-gray-950/75 data-[disabled]:text-gray-950/25"
    >
      <span className="inline-flex h-6 items-center">
        <PlusIcon className="size-[0.9375rem] shrink-0 fill-gray-950/25" />
      </span>
      {disabled && <span className="sr-only">Not included:</span>}
      {description}
    </li>
  );
}

export function PlusIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg viewBox="0 0 15 15" aria-hidden="true" {...props}>
      <path clipRule="evenodd" d="M8 0H7v7H0v1h7v7h1V8h7V7H8V0z" />
    </svg>
  );
}
