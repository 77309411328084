import { createSlice } from "@reduxjs/toolkit";
import {
  // Criterias
  getDecisionMatrixCriteriasList,
  createDecisionMatrixCriteria,
  updateDecisionMatrixCriteria,
  deleteDecisionMatrixCriteria,

  // Options
  getDecisionMatrixOptionsList,
  createDecisionMatrixOption,
  updateDecisionMatrixOption,
  deleteDecisionMatrixOption,

  // Options User Rating
  getOptionRatings,
  updateOptionRating,
} from "./decisionMatrixAnalysisApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  // Criterias
  decisionMatrixCriteriasList: any[];
  createDecisionMatrixCriteriasRes: any;
  updateDecisionMatrixCriteriasRes: any;
  decisionMatrixCriteriaDeleteRes: any;

  // Options
  decisionMatrixOptionsList: any[];
  createDecisionMatrixOptionsRes: any;
  updateDecisionMatrixOptionsRes: any;
  decisionMatrixOptionDeleteRes: any;

  // Options Rating
  optionRatingsList: any[];
  updateOptionRatingRes: any;
}

const initialState: initialStateType = {
  error: null,
  message: null,
  // Criterias
  decisionMatrixCriteriasList: [],
  createDecisionMatrixCriteriasRes: null,
  updateDecisionMatrixCriteriasRes: null,
  decisionMatrixCriteriaDeleteRes: null,

  // Options
  decisionMatrixOptionsList: [],
  createDecisionMatrixOptionsRes: null,
  updateDecisionMatrixOptionsRes: null,
  decisionMatrixOptionDeleteRes: null,

  // Options Rating
  optionRatingsList: [],
  updateOptionRatingRes: null,
};

const decisionMatrixAnalysisSlice = createSlice({
  name: "decisionMatrixAnalysis",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /****************************************/
    /*************** Criteria ***************/
    /****************************************/

    // Get Decision Matrix Criterias
    builder.addCase(getDecisionMatrixCriteriasList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(
      getDecisionMatrixCriteriasList.fulfilled,
      (state, action) => {
        state.error = null;
        state.message = action.payload?.message;
        state.decisionMatrixCriteriasList = action.payload?.data;
      }
    );
    builder.addCase(
      getDecisionMatrixCriteriasList.rejected,
      (state, action) => {
        state.error = action.error.message || "Something went wrong";
      }
    );

    // Create Decision Matrix Criteria
    builder.addCase(createDecisionMatrixCriteria.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createDecisionMatrixCriteria.fulfilled, (state, action) => {
      // Add new data in list

      if (state.decisionMatrixCriteriasList?.length > 0) {
        state.decisionMatrixCriteriasList = [
          ...state.decisionMatrixCriteriasList,
          { criteria: action.payload?.data?.details },
        ];
      } else {
        state.decisionMatrixCriteriasList = [
          { criteria: action.payload?.data?.details },
        ];
      }

      state.createDecisionMatrixCriteriasRes = action.payload?.data?.details;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(createDecisionMatrixCriteria.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Decision Matrix Criteria
    builder.addCase(updateDecisionMatrixCriteria.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateDecisionMatrixCriteria.fulfilled, (state, action) => {
      // Update data in List
      const previousDecisionMatrixCriteriasList = [
        ...state.decisionMatrixCriteriasList,
      ];

      const previousIndex = previousDecisionMatrixCriteriasList?.findIndex(
        (data) => data?.criteria?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousDecisionMatrixCriteriasList[previousIndex].criteria =
          action.payload?.data?.details;
        state.decisionMatrixCriteriasList = previousDecisionMatrixCriteriasList;
      }

      state.updateDecisionMatrixCriteriasRes = action.payload?.data?.details;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(updateDecisionMatrixCriteria.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Decision Matrix Criteria
    builder.addCase(deleteDecisionMatrixCriteria.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteDecisionMatrixCriteria.fulfilled, (state, action) => {
      // Delete data from list
      const filterData = state.decisionMatrixCriteriasList?.filter(
        (data) => data?.criteria?.id !== action.payload?.data?.id
      );
      state.decisionMatrixCriteriasList = filterData;
      state.decisionMatrixCriteriaDeleteRes = action.payload?.data;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(deleteDecisionMatrixCriteria.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    /****************************************/
    /********* Options / Concepts ***********/
    /****************************************/

    // Get Decision Matrix Options
    builder.addCase(getDecisionMatrixOptionsList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getDecisionMatrixOptionsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.decisionMatrixOptionsList = action.payload?.data;
    });
    builder.addCase(getDecisionMatrixOptionsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Decision Matrix Option
    builder.addCase(createDecisionMatrixOption.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createDecisionMatrixOption.fulfilled, (state, action) => {
      // Add new data in list

      if (state.decisionMatrixOptionsList?.length > 0) {
        state.decisionMatrixOptionsList = [
          ...state.decisionMatrixOptionsList,
          action.payload?.data?.details,
        ];
      } else {
        state.decisionMatrixOptionsList = [action.payload?.data?.details];
      }

      state.createDecisionMatrixOptionsRes = action.payload?.data?.details;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(createDecisionMatrixOption.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Decision Matrix Option
    builder.addCase(updateDecisionMatrixOption.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateDecisionMatrixOption.fulfilled, (state, action) => {
      // Update data in List
      const previousDecisionMatrixOptionsList = [
        ...state.decisionMatrixOptionsList,
      ];

      const previousIndex = previousDecisionMatrixOptionsList?.findIndex(
        (data) => data?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousDecisionMatrixOptionsList[previousIndex] = action.payload?.data?.details;
        state.decisionMatrixOptionsList = previousDecisionMatrixOptionsList;
      }

      state.updateDecisionMatrixOptionsRes = action.payload?.data?.details;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(updateDecisionMatrixOption.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Decision Matrix Option
    builder.addCase(deleteDecisionMatrixOption.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteDecisionMatrixOption.fulfilled, (state, action) => {
      // Delete data from list
      const filterData = state.decisionMatrixOptionsList?.filter(
        (data) => data.id !== action.payload?.data?.id
      );

      state.decisionMatrixOptionsList = filterData;
      state.decisionMatrixOptionDeleteRes = action.payload?.data;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(deleteDecisionMatrixOption.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    /****************************************/
    /*********** Options Ratings ************/
    /****************************************/

    // Get Options Ratings
    builder.addCase(getOptionRatings.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getOptionRatings.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.optionRatingsList = action.payload?.data;
    });
    builder.addCase(getOptionRatings.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Options Ratings
    builder.addCase(updateOptionRating.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateOptionRating.fulfilled, (state, action) => {
      // Update data in List
      const previousList = [...state.optionRatingsList];

      // Previous Criterias List
      const previousCriteriaList = [...state.decisionMatrixCriteriasList];

      const previousIndex = previousList?.findIndex(
        (data) => data?.id === action.payload?.data?.id
      );

      // Find Criteria Index
      const previousCeriteriaIndex = previousCriteriaList?.findIndex(
        (data) => data?.criteria?.id === action.payload?.data?.criteria_id
      );

      const conceptObject = {
        criteria_id: action.payload?.data?.criteria_id,
        id: action.payload?.data?.concept_id,
        user_rating: action.payload?.data?.user_rating,
      };

      if (previousIndex !== -1) {
        previousList[previousIndex] = action.payload?.data;
        state.optionRatingsList = previousList;
      } else {
        state.optionRatingsList = [...previousList, action.payload?.data];
      }

      if (previousCeriteriaIndex !== -1) {
        // Assign Criteria in Object
        const criteriaObj: any = previousCriteriaList[previousCeriteriaIndex];

        // Check if have concepts array
        if (criteriaObj?.concepts?.length > 0) {
          // Find Concept index
          const conceptIndex = criteriaObj?.concepts?.findIndex(
            (data: any) => data.id === action.payload?.data?.concept_id
          );

          if (conceptIndex !== -1) {
            criteriaObj.concepts[conceptIndex] = conceptObject;

            previousCriteriaList[previousCeriteriaIndex] = criteriaObj;

            state.decisionMatrixCriteriasList = previousCriteriaList;
          } else {
            criteriaObj.concepts.push(conceptObject);

            previousCriteriaList[previousCeriteriaIndex] = criteriaObj;

            state.decisionMatrixCriteriasList = previousCriteriaList;
          }
        } else {
          criteriaObj.concepts = [];

          criteriaObj.concepts.push(conceptObject);

          previousCriteriaList[previousCeriteriaIndex] = criteriaObj;

          state.decisionMatrixCriteriasList = previousCriteriaList;
        }
      }

      state.updateOptionRatingRes = conceptObject;

      state.error = null;
      state.message = action.payload?.message;
    });
    builder.addCase(updateOptionRating.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default decisionMatrixAnalysisSlice.reducer;
