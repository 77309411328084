import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import CustomButton from '../../../components/customButton';
import TextAreaField from '../../../components/formFields/textareaField';
import SelectField from '../../../components/formFields/selectField';
import InputField from '../../../components/formFields/inputField';
import { categoriesList, classNames, getCategoryTitle, getCategoryValue } from '../../../utils/utilities';
import RadioGroup from '../../../components/formFields/radioGroup';
import { DRAWER_WIDTH } from 'src/config';
import { Category } from 'src/type';
import { DrawerContext } from 'src/contexts/drawer';
import KeyboardShortcut from 'src/components/KeyboardShortcut';

interface SaveProjectAsTemplateProps {
  onCloseModal: () => void;
  onSubmitForm: Function;
  loader: boolean;
  projectData: any;
  btnText?: string;
}

export default function SaveProjectAsTemplate({
  onCloseModal,
  onSubmitForm,
  loader,
  projectData,
  btnText = 'Save',
}: SaveProjectAsTemplateProps) {
  const [selectedComplexity, setSelectedComplexity] = useState('low');
  const [selectedCategory, setSelectedCategory] = useState('Cloud Architecture');

  const { insetBlock } = useContext(DrawerContext);

  const validationObject = {
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: projectData?.title || '',
      description: projectData?.description || '',
      category: projectData?.category || 'Personal',
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        complexity: selectedComplexity,
        category: values?.category,
        description: values?.description,
      };

      onSubmitForm(payloadObj);
    },
  });

  const complexityList = [
    { id: 1, value: 'low', title: 'Low' },
    { id: 2, value: 'medium', title: 'Medium' },
    { id: 3, value: 'complex', title: 'Complex' },
  ];

  const eachFieldRow =
    'items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200';

  useEffect(() => {
    if (projectData?.complexity) {
      setSelectedComplexity(projectData?.complexity);
      setSelectedCategory(projectData?.category || 'Cloud Architecture');
    }
  }, [projectData]);

  return (
    <form
      className={classNames(
        "flex flex-col bg-white shadow-xl",
        insetBlock > 0 && "rounded-b-lg"
      )}
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className="overflow-y-scroll"
        style={{
          height: `calc(100vh - ${164 + 2 * insetBlock}px)`,
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div className="flex items-start">
            <label
              htmlFor="title"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Title
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
              type="text"
              id="title"
              {...formik.getFieldProps("title")}
              placeholder="Enter title"
            />

            {formik.touched.title && formik.errors.title ? (
              <div className="text-red-600 text-xs">{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Complexity */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <label
              htmlFor="complexity"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Complexity
            </label>
          </div>

          <div className="sm:col-span-2">
            <RadioGroup
              onChangeRadio={setSelectedComplexity}
              value={selectedComplexity}
              radioList={complexityList}
            />

            {formik.touched.complexity && formik.errors.complexity ? (
              <div className="text-red-600 text-xs">
                {formik.errors.complexity}
              </div>
            ) : null}
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="category"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Category
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectField
              options={categoriesList}
              value={getCategoryValue(selectedCategory)}
              onSelect={(categoryValue) =>
                setSelectedCategory(getCategoryTitle(categoryValue as Category))
              }
            />

            {formik.touched.category && formik.errors.category ? (
              <div className="text-red-600 text-xs">
                {formik.errors.category}
              </div>
            ) : null}
          </div>
        </div>

        {/* Description */}
        <div className={eachFieldRow}>
          <div className="flex items-center">
            <label
              htmlFor="description"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Description
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextAreaField
              id="description"
              {...formik.getFieldProps("description")}
              placeholder="Enter a brief description."
            />

            {formik.touched.description && formik.errors.description ? (
              <div className="text-red-600 text-xs">
                {formik.errors.description}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* Action buttons */}
      <div
        className={classNames(
          "bg-white border-t border-gray-200 px-4 py-5 sm:px-6",
          insetBlock > 0 ? "rounded-b-lg" : "fixed bottom-0"
        )}
        style={{ width: `${DRAWER_WIDTH - 40}px` }}
      >
        <div className="flex justify-end space-x-3">
          <CustomButton
            text="Cancel"
            type="button"
            onClickBtn={onCloseModal}
            btnStyle="mr-2"
            buttonType="secondary"
            buttonSize='md'
          />

          <CustomButton
            type="submit"
            onClickBtn={() => console.log("Clicking...")}
            text={btnText}
            loading={loader}
            beforeIcon={
              <KeyboardShortcut.ControlEnter
                className="mr-2"
                onTrigger={formik.handleSubmit}
              />
            }
            buttonType="primary"
            buttonSize='md'
          />
        </div>
      </div>
    </form>
  );
}
