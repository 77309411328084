import React from "react";
import { BookmarkIcon } from "@heroicons/react/24/outline";
import { getIconObjectByName } from "../../utils/documentComponentsIcons";

interface DocumentComponentBoxProps {
  data: any;
}

export default function DocumentComponentBox({
  data,
}: DocumentComponentBoxProps) {
  const iconObject = getIconObjectByName(data.icon);
  return (
    <>
      <div className="relative bg-white p-8 h-full rounded-xl border border-zinc-200 cursor-pointer">
        <div className="flex flex-1 flex-col">
          <div className="absolute top-[10px] right-[10px]">
            <img
              src={`${iconObject?.icon || ""}`}
              className="h-8 mr-1"
              alt={data?.title}
            />
          </div>

          <div className="my-2">
            <div className="text-sm line-clamp-1">{data?.title}</div>
          </div>

          <p className="text-sm text-gray-600 mb-3 line-clamp-2">
            {data?.short_description}
          </p>

          <div className="text-sm/5 text-gray-700">
            <span className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
              {data?.category}
            </span>
          </div>

          <div className="flex items-center justify-between mt-6">
            <div className="flex items-center gap-3">
              <img
                alt=""
                src="https://cdn.sanity.io/images/ssqh4ksj/production/cd1ee59e9e4c2ff30c303de6c7d1066c057419d5-7952x5304.jpg?rect=2370,0,5304,5304&amp;w=64&amp;h=64&amp;auto=format"
                className="aspect-square size-6 rounded-full object-cover"
              />
              <div className="text-sm/5 text-gray-700">Marcus Eldridge</div>
            </div>

            <div>
              <BookmarkIcon
                className={`h-6 mr-1 transition-all ${data?.is_favorite ? " fill-blue-500 stroke-blue-500" : " stroke-zinc-400"} hover:scale-125`}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
