import { classNames } from 'src/utils/utilities';
import FieldRow from '../../../../components/fieldRow';
import { PencilIcon } from '@heroicons/react/24/outline';

interface DetailsOfAllDecisionsProps {
  detailsData: any;
  editEventName?: string;
}

export default function DetailsOfAllDecisions({ detailsData, editEventName }: DetailsOfAllDecisionsProps) {
  return (
    <div>
      <div className='px-3 w-full h-toolbar border-b border-zinc-100 flex items-center justify-between'>
        <div className='w-full flex items-center gap-2'>
          <span className='block text-base text-customLightBlue'>Details</span>
          {editEventName && (
            <button
              type='button'
              onClick={() => document.dispatchEvent(new CustomEvent(editEventName))}
              className={classNames(
                `ml-auto p-2 rounded cursor-pointer bg-transparent text-customDarkBlue`,
                'flex items-center justify-center transition-colors hover:bg-zinc-100 hover:text-customDarkBlue'
              )}
            >
              <PencilIcon className='size-4' />
            </button>
          )}
        </div>
      </div>
      <dl className='divide-y divide-gray-100'>
        <FieldRow
          title='Title'
          value={detailsData?.title}
        />

        <FieldRow
          title='Description'
          value={detailsData?.description}
        />

        <FieldRow
          title='Status'
          value={detailsData?.status}
        />

        <FieldRow
          title='Category'
          value={detailsData?.category}
        />

        <FieldRow
          title='Assumptions'
          value={detailsData?.assumptions}
        />

        <FieldRow
          title='Implications'
          value={detailsData?.implications}
        />

        <FieldRow
          title='Final Decision'
          value={detailsData?.final_decision}
        />
      </dl>
    </div>
  );
}
