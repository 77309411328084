import React from "react";
import StatusLabel from "../statusLabel";

interface FieldRowProps {
  title: string;
  value: string;
}

export default function FieldRow({ value, title }: FieldRowProps) {
  return (
    <div className="px-3 py-3">
      <div className="text-xs font-bold leading-6 text-customLightBlue mb-2">{title}</div>
      <div className="text-xs leading-6 text-customDarkBlue w-full">
          {title === "Status" ? <StatusLabel status={value} /> : value}
      </div>
    </div>
  );
}
