import { ChangeEvent, ComponentPropsWithoutRef, useRef } from 'react';
import { classNames } from 'src/utils/utilities';

interface AutoResizeTextareaProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export default function AutoResizeTextarea({
  value,
  onChange,
  placeholder,
  className,
  disabled,
  ...props
}: AutoResizeTextareaProps & Omit<ComponentPropsWithoutRef<'textarea'>, keyof AutoResizeTextareaProps>) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);

    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  return (
    <div
      className={classNames(
        'py-2.5 pl-3 pr-1.5 rounded-md border-0 bg-white text-customDarkBlue',
        'shadow-sm ring-1 ring-inset ring-gray-300',
        disabled ? 'opacity-80' : 'transition-colors focus-within:ring-blue-600 focus-within:ring-2',
        className
      )}
    >
      <div className={classNames('max-h-[5rem] overflow-y-auto')}>
        <textarea
          ref={textareaRef}
          value={value}
          onChange={handleChange}
          className={classNames(
            'block w-full h-full p-0 pr-1.5 border-none border-0',
            '!outline-none !shadow-none !ring-0 bg-transparent text-customDarkBlue resize-none',
            'text-xs',
            'disabled:text-gray-500 disabled:cursor-not-allowed'
          )}
          placeholder={placeholder}
          rows={1}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
}
