import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch } from '../../../../hooks';
import { getTbarsList, createTbar } from '../../../../redux/tbars/tbarsApi';
import CustomDrawer from '../../../../components/customDrawer';
import TBarForm from '../../../T-bar/TBarForm';
import CompleteEmptyPage from '../../../../components/completeEmptyPage';
import { getLastIdFromUrl } from '../../../../utils/utilities';

import tChartSVG from '../../../../assets/images/Illustration-06.svg';

const TChartEmpty = () => {
  const [open, setOpen] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const creatingTbar = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createTbar({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getTbarsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitTbar = async (body: any) => {
    creatingTbar(body);
  };

  return (
    <>
      <div
        className='flex items-center justify-center'
        style={{ height: 'calc(100vh - 140px)' }}
      >
        <CompleteEmptyPage
          title="You don't have any T-Chat"
          subTitle='Get started by creating a new T-Chat'
          btnText='Create T-Chat'
          onClickBtn={onOpenCreateModal}
          icon={tChartSVG}
        />
      </div>

      <CustomDrawer.TChart
        open={open}
        onCloseModal={onCloseModal}
      >
        <TBarForm
          onCloseModal={onCloseModal}
          editData={null}
          onSubmitForm={onSubmitTbar}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer.TChart>
    </>
  );
};

export default TChartEmpty;
