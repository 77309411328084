import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../../../hooks";
import { createDiagram } from "../../../../redux/diagrams/diagramsApi";
import CompleteEmptyPage from "../../../../components/completeEmptyPage";
import { getLastIdFromUrl } from "../../../../utils/utilities";
import CustomModal from '../../../../components/customModal';
import DiagramForm from '../diagrams/diagramForm';

import diagramsSVG from "../../../../assets/images/Illustration-10.svg";

const DiagramsEmpty = () => {
  const [open, setOpen] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const creatingDiagram = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            createDiagram({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );

          onCloseModal();
          setFormLoader(false);
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };
    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    const createBody = {
      title: body?.title,
      diagram_type: "Block Diagram",
      diagram_status: "Draft",
      category: "Infrastructure",
      flow: "",
    };

    creatingDiagram(createBody);
  };

  return (
    <>
      <div
        className="flex items-center justify-center"
        style={{ height: "calc(100vh - 140px)" }}
      >
        <CompleteEmptyPage
          title="You don't have any Diagram"
          subTitle="Get started by creating a new Diagram"
          btnText="Create Diagram"
          onClickBtn={onOpenCreateModal}
          icon={diagramsSVG}
        />
      </div>

      <CustomModal
        open={open}
        onCloseModal={() => onCloseModal()}
        title="Add Diagram"
        size="max-w-lg"
      >
        <DiagramForm
          onCloseModal={() => onCloseModal()}
          loading={formLoader}
          onSubmitData={onSubmitForm}
          editData={null}
        />
      </CustomModal>
    </>
  );
};

export default DiagramsEmpty;
