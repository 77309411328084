import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { DRAWER_WIDTH } from 'src/config';
import { classNames } from 'src/utils/utilities';
import { DrawerContext, DrawerSizes } from 'src/contexts/drawer';
import { decisionMatrixIcon, prosConsIcon, swotAnalysisIcon, tChartIcon } from 'src/assets/icons';
import { useDialogAutoFocus } from 'src/hooks/useDialogAutoFocus';

interface CustomDrawerProps {
  open: boolean;
  onCloseModal: () => void;
  title: any;
  description: string;
  children: ReactNode;
  headerClassName?: string;
  distanced?: boolean;
  drawerSizes?: Partial<DrawerSizes>;
}

type SpecificDrawerProps = Omit<CustomDrawerProps, 'title' | 'description' | 'distanced'> & {
  isEditMode?: boolean;
  mode?: 'create' | 'update' | 'publish' | null;
};

function CustomDrawer({
  open,
  onCloseModal,
  title,
  description,
  children,
  headerClassName,
  distanced = false,
  drawerSizes = {},
}: CustomDrawerProps) {
  const { setRef } = useDialogAutoFocus();

  const { width = DRAWER_WIDTH, insetBlock = distanced ? 24 : 0, insetInline = distanced ? 24 : 0 } = drawerSizes;

  return (
    <DrawerContext.Provider value={{ width, insetBlock, insetInline }}>
      <Transition
        show={open}
        as={Fragment}
      >
        <Dialog
          as='div'
          ref={setRef}
          className='relative z-50'
          onClose={onCloseModal}
        >
          <TransitionChild
            as={Fragment}
            enter='ease-in-out duration-500'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-500'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
          </TransitionChild>

          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div
                className='pointer-events-none fixed flex pl-10'
                style={{ maxWidth: `${width}px`, width: '100%', insetBlock, insetInlineEnd: insetInline }}
              >
                <TransitionChild
                  as={Fragment}
                  enter='transform transition ease-in-out duration-500 sm:duration-700'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform transition ease-in-out duration-500 sm:duration-700'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <DialogPanel className='pointer-events-auto relative w-full'>
                    {/* Header */}
                    <div
                      className={classNames(
                        'bg-gradient-to-r from-[#d9ebf8] to-[#f8edf8] px-4 py-6 sm:px-6',
                        insetBlock > 0 && 'rounded-t-lg',
                        headerClassName
                      )}
                    >
                      <div className='flex items-start justify-between space-x-3'>
                        <div className='space-y-1'>
                          <DialogTitle className='text-base font-semibold leading-6 text-customDarkBlue'>
                            {title}
                          </DialogTitle>
                          <p className='text-xs text-customLightBlue'>{description}</p>
                        </div>
                        <div className='flex h-7 items-center'>
                          <button
                            type='button'
                            className='relative text-gray-400 hover:text-customLightBlue'
                            onClick={onCloseModal}
                          >
                            <span className='absolute -inset-2.5' />
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon
                              className='h-6 w-6'
                              aria-hidden='true'
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    {children}
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </DrawerContext.Provider>
  );
}

export default Object.assign(CustomDrawer, {
  TChart: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => (
    <CustomDrawer
      title={
        <div className='flex items-center'>
          <span className='mr-2'>{tChartIcon}</span>
          <span>T-Chart</span>
        </div>
      }
      description={
        isEditMode
          ? 'Update T-Bar'
          : 'Create new T-Chart, provide title and options that you will try to analyze and compare. Options are mandatory fields.'
      }
      distanced
      {...props}
    />
  ),
  ProCon: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => (
    <CustomDrawer
      title={
        <div className='flex items-center'>
          <span className='mr-2'>{prosConsIcon}</span>
          <span>Pros & Cons</span>
        </div>
      }
      description={
        isEditMode
          ? 'Update Pros-Cons'
          : 'Create new Pros-Cons, provide title and options that you will try to analyze and compare. Options are mandatory fields.'
      }
      distanced
      {...props}
    />
  ),
  DecisionMatrix: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => (
    <CustomDrawer
      title={
        <div className='flex items-center'>
          <span className='mr-2'>{decisionMatrixIcon}</span>
          <span>Decision Matrix</span>
        </div>
      }
      description={
        isEditMode
          ? 'Update Decision Matrix Information.'
          : 'Create new Decision Matrix, provide title and options that you will try to analyze and compare. Options are mandatory fields.'
      }
      distanced
      {...props}
    />
  ),
  Swot: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => (
    <CustomDrawer
      title={
        <div className='flex items-center'>
          <span className='mr-2'>{swotAnalysisIcon}</span>
          <span>SWOT Analysis</span>
        </div>
      }
      description={
        isEditMode
          ? 'Update SWOT Analysis'
          : 'Create new SWOT, provide title and options that you will try to analyze and compare. Options are mandatory fields.'
      }
      distanced
      {...props}
    />
  ),
  Project: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => (
    <CustomDrawer
      title='Project'
      description={
        isEditMode
          ? 'Update Project'
          : 'Create new Project, provide Title, Status and Category. Title is mandatory field.'
      }
      distanced
      {...props}
    />
  ),
  AiAssistant: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => {
    const editMode = (() => {
      if (isEditMode) return 'update';

      if (mode) return mode;

      return 'create';
    })();

    return (
      <CustomDrawer
        title={
          <div className='flex items-center'>
            <span>{{ create: 'Create', update: 'Update', publish: 'Publish' }[editMode]} AI Assistant</span>
          </div>
        }
        description={
          {
            update: 'Update Your AI Assistant.',
            create: 'Create new AI Assistant, provide all the data during create your Assistant.',
            publish: 'Publish Your AI Assistant.',
          }[editMode]
        }
        distanced
        {...props}
      />
    );
  },
  SaveProjectAsTemplate: ({ isEditMode = false, mode, ...props }: SpecificDrawerProps) => (
    <CustomDrawer
      title='Save as Template'
      description='Save project as template'
      distanced
      {...props}
    />
  ),
});
