// amazon_aws.js

export const devops_nodes = [
  {
    name: "Other",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",

    items: [
      "icons8-github",
      "jenkins",
      "pipeline",
      "release-pipeline",
      "gitlab",
    ],
  },
];