// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconOne from "!!raw-loader!../../assets/component_icons/001-component-requirement.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconTwo from "!!raw-loader!../../assets/component_icons/002-component-diagram.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconThree from "!!raw-loader!../../assets/component_icons/003-component-clipboard.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconFour from "!!raw-loader!../../assets/component_icons/004-component-steps.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconFive from "!!raw-loader!../../assets/component_icons/005-component-steps-1.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconSix from "!!raw-loader!../../assets/component_icons/006-component-value-chain.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconSeven from "!!raw-loader!../../assets/component_icons/007-component-steps-2.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconEight from "!!raw-loader!../../assets/component_icons/008-component-motivation.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconNine from "!!raw-loader!../../assets/component_icons/009-component-step-by-step.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconTen from "!!raw-loader!../../assets/component_icons/010-component-timeline.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconEleven from "!!raw-loader!../../assets/component_icons/011-component-timeline-1.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconTwelve from "!!raw-loader!../../assets/component_icons/012-component-marketing-automation.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconThirteen from "!!raw-loader!../../assets/component_icons/013-component-planning.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconFourteen from "!!raw-loader!../../assets/component_icons/014-component-steps-3.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconFivteen from "!!raw-loader!../../assets/component_icons/015-component-steps-4.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconSixteen from "!!raw-loader!../../assets/component_icons/016-component-hexagonal.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawComponentIconSeventeen from "!!raw-loader!../../assets/component_icons/017-component-cloud-server.svg";

// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconOne from "!!raw-loader!../../assets/category_icons/001-category-devops.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconTwo from "!!raw-loader!../../assets/category_icons/002-category-cyber-security.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconThree from "!!raw-loader!../../assets/category_icons/003-category-azure.svg";
// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconFour from "!!raw-loader!../../assets/category_icons/004-category-google-cloud.svg";

// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconFive from "!!raw-loader!../../assets/category_icons/005-category-aws.svg";

// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconSix from "!!raw-loader!../../assets/category_icons/006-category-software-development.svg";

// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconSeven from "!!raw-loader!../../assets/category_icons/007-category-strategy-development.svg";

// eslint-disable-next-line import/no-webpack-loader-syntax
import rawCategoryIconEight from "!!raw-loader!../../assets/category_icons/008-category-cooperation.svg";


import componentIconOne from "../../assets/component_icons/001-component-requirement.svg";
import componentIconTwo from "../../assets/component_icons/002-component-diagram.svg";
import componentIconThree from "../../assets/component_icons/003-component-clipboard.svg";
import componentIconFour from "../../assets/component_icons/004-component-steps.svg";
import componentIconFive from "../../assets/component_icons/005-component-steps-1.svg";
import componentIconSix from "../../assets/component_icons/006-component-value-chain.svg";
import componentIconSeven from "../../assets/component_icons/007-component-steps-2.svg";
import componentIconEight from "../../assets/component_icons/008-component-motivation.svg";
import componentIconNine from "../../assets/component_icons/009-component-step-by-step.svg";
import componentIconTen from "../../assets/component_icons/010-component-timeline.svg";
import componentIconEleven from "../../assets/component_icons/011-component-timeline-1.svg";
import componentIconTwelve from "../../assets/component_icons/012-component-marketing-automation.svg";
import componentIconThirteen from "../../assets/component_icons/013-component-planning.svg";
import componentIconFourteen from "../../assets/component_icons/014-component-steps-3.svg";
import componentIconFivteen from "../../assets/component_icons/015-component-steps-4.svg";
import componentIconSixteen from "../../assets/component_icons/016-component-hexagonal.svg";
import componentIconSeventeen from "../../assets/component_icons/017-component-cloud-server.svg";

import categoryIconOne from "../../assets/category_icons/001-category-devops.svg";
import categoryIconTwo from "../../assets/category_icons/002-category-cyber-security.svg";
import categoryIconThree from "../../assets/category_icons/003-category-azure.svg";

export const componentsIcons = [
  { name: "001-component-requirement", icon: componentIconOne },
  { name: "002-component-diagram", icon: componentIconTwo },
  { name: "003-component-clipboard", icon: componentIconThree },
  { name: "004-component-steps", icon: componentIconFour },
  { name: "005-component-steps-1", icon: componentIconFive },
  { name: "006-component-value-chain", icon: componentIconSix },
  { name: "007-component-steps-2", icon: componentIconSeven },
  { name: "008-component-motivation", icon: componentIconEight },
  { name: "009-component-step-by-step", icon: componentIconNine },
  { name: "010-component-timeline", icon: componentIconTen },
  { name: "011-component-timeline-1", icon: componentIconEleven },
  { name: "012-component-marketing-automation", icon: componentIconTwelve },
  { name: "013-component-planning", icon: componentIconThirteen },
  { name: "014-component-steps-3", icon: componentIconFourteen },
  { name: "015-component-steps-4", icon: componentIconFivteen },
  { name: "016-component-hexagonal", icon: componentIconSixteen },
  { name: "017-component-cloud-server", icon: componentIconSeventeen },
];

export const rawComponentsIcons = [
  { name: "001-component-requirement", icon: rawComponentIconOne },
  { name: "002-component-diagram", icon: rawComponentIconTwo },
  { name: "003-component-clipboard", icon: rawComponentIconThree },
  { name: "004-component-steps", icon: rawComponentIconFour },
  { name: "005-component-steps-1", icon: rawComponentIconFive },
  { name: "006-component-value-chain", icon: rawComponentIconSix },
  { name: "007-component-steps-2", icon: rawComponentIconSeven },
  { name: "008-component-motivation", icon: rawComponentIconEight },
  { name: "009-component-step-by-step", icon: rawComponentIconNine },
  { name: "010-component-timeline", icon: rawComponentIconTen },
  { name: "011-component-timeline-1", icon: rawComponentIconEleven },
  { name: "012-component-marketing-automation", icon: rawComponentIconTwelve },
  { name: "013-component-planning", icon: rawComponentIconThirteen },
  { name: "014-component-steps-3", icon: rawComponentIconFourteen },
  { name: "015-component-steps-4", icon: rawComponentIconFivteen },
  { name: "016-component-hexagonal", icon: rawComponentIconSixteen },
  { name: "017-component-cloud-server", icon: rawComponentIconSeventeen },
];

export const categoriesIcons = [
  { name: "001-category-devops", icon: categoryIconOne },
  { name: "002-category-cyber-security", icon: categoryIconTwo },
  { name: "003-category-azure", icon: categoryIconThree },
  { name: "004-category-google-cloud", icon: rawCategoryIconFour },
  { name: "005-category-aws", icon: rawCategoryIconFive },
  { name: "006-category-software-development", icon: rawCategoryIconSix },
  { name: "007-category-strategy-development", icon: rawCategoryIconSeven },
  { name: "008-category-cooperation", icon: rawCategoryIconEight },
];

export const rawCategoriesIcons = [
  { name: "001-category-devops", icon: rawCategoryIconOne },
  { name: "002-category-cyber-security", icon: rawCategoryIconTwo },
  { name: "003-category-azure", icon: rawCategoryIconThree },
  { name: "004-category-google-cloud", icon: rawCategoryIconFour },
  { name: "005-category-aws", icon: rawCategoryIconFive },
  { name: "006-category-software-development", icon: rawCategoryIconSix },
  { name: "007-category-strategy-development", icon: rawCategoryIconSeven },
  { name: "008-category-cooperation", icon: rawCategoryIconEight },
];

export const getIconObjectByName = (name: string) => {
  return componentsIcons.find((component) => component.name === name);
};

export const getRawIconObjectByName = (name: string) => {
  return rawComponentsIcons.find((component) => component.name === name);
};

export const getCategoryIconObjectByName = (name: string) => {
  return categoriesIcons.find((component) => component.name === name);
};

export const getCategoryRawIconObjectByName = (name: string) => {
  return rawCategoriesIcons.find((component) => component.name === name);
};
