import { memo } from 'react';
import linkedInIcon from '../../assets/images/linkedin-logo.png';
import { useLocation } from 'react-router-dom';

interface SpecificShareProps {
  text?: string;
}

interface ShareButtonProps {
  shareUrl: string;
  imgUrl: string;
}

function ShareButton({ shareUrl, imgUrl }: ShareButtonProps) {
  const handleShare = () => {
    const width = 600;
    const height = 600;
    const left = window.screenX + (window.innerWidth - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(shareUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
  };

  return (
    <button
      className='cursor-pointer mr-2 hover:scale-110'
      onClick={handleShare}
    >
      <img
        className='h-[15px]'
        src={imgUrl}
        alt='LinkedIn Logo'
      />
    </button>
  );
}

const LinkedInShare = memo<SpecificShareProps>(({ text }) => {
  const { pathname } = useLocation();

  const appUrl = `https://app.solutionpilot.ai/` + pathname.slice(1);
  const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(appUrl)}&text=${encodeURIComponent(text ?? '')}`;

  return (
    <ShareButton
      shareUrl={shareUrl}
      imgUrl={linkedInIcon}
    />
  );
});

export default Object.assign(ShareButton, {
  displayName: 'ShareButton',
  LinkedIn: LinkedInShare,
});
