import {
  Cog6ToothIcon as CogIcon,
  LifebuoyIcon,
  // DocumentTextIcon,
  PowerIcon,
  // BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import { FormEvent, Fragment, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition,
} from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../../hooks";

import { getProfileData } from "../../redux/profile/profileApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { DROPDOWN_TRANSITION_CLASSES } from "src/config";
import { classNames } from "src/utils/utilities";
import { useAccessToken } from "../../contexts/AccessTokenContext";

export default function LoginUserInfo({ collapseSidebar }: any) {
  const { user, logout } = useAuth0();
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);

  const { accessToken, userTokenInfo } = useAccessToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (accessToken) await dispatch(getProfileData(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [accessToken, dispatch]);

  const logoutWithRedirect = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const userNavigation = [
    {
      name: "Account Settings",
      icon: CogIcon,
      href: "/profile",
    },
    {
      name: "Support",
      icon: LifebuoyIcon,
      href: "#",
    },
    // {
    //   name: "Documentation",
    //   icon: DocumentTextIcon,
    //   href: "#",
    // },
    // {
    //   name: "Training",
    //   icon: BuildingLibraryIcon,
    //   href: "#",
    // },
  ];

  const classes = {
    menuItem: "px-1",
    link: (focus: boolean) =>
      classNames(
        "w-full px-4 py-3 rounded-md transition-colors bg-transparent hover:bg-transparent hover:no-underline",
        "flex items-center text-xs",
        focus ? "bg-gray-100 text-customDarkBlue" : "text-gray-700"
      ),
    icon: classNames(
      "mr-2 h-[17px] transition-colors text-gray-700 [[data-focus]_&]:text-customDarkBlue"
    ),
  };

  const accountStatus = userTokenInfo?.app_meta?.account_status;

  return (
    <Menu
      as="div"
      className={`relative ${collapseSidebar ? "flex items-center justify-center z-[300]" : ""}`}
    >
      <MenuButton
        className={`${collapseSidebar ? "" : "p-1.5"} flex items-center bg-transparent hover:bg-transparent`}
      >
        <img
          className="h-8 w-8 rounded-full"
          src={user?.picture}
          alt={profileData?.email}
        />
        {!collapseSidebar && (
          <span className="hidden lg:flex lg:items-center">
            {profileData && (
              <div className="ml-3 text-left">
                <span className="block text-xs font-semibold text-customDarkBlue leading-4 mb-[3px]">
                  {`${profileData?.first_name} ${profileData?.last_name}`}
                  {accountStatus?.status === "trialing" && (
                    <span className="ml-[5px] bg-purple-100 text-purple-700 text-xs font-semibold px-[10px] py-[2px] rounded-[5px]">
                      Trial
                    </span>
                  )}

                  {accountStatus?.status === "active" && (
                    <span className="ml-[5px] bg-green-100 text-green-700 text-xs font-semibold px-[10px] py-[2px] rounded-[5px]">
                      Active
                    </span>
                  )}
                </span>
                <span className="block text-xs font-normal text-customLightBlue line-clamp-1">
                  {profileData?.email}
                </span>
              </div>
            )}
          </span>
        )}
      </MenuButton>

      <Transition as={Fragment} {...DROPDOWN_TRANSITION_CLASSES}>
        <MenuItems
          className={classNames(
            `absolute left-[25px] bottom-[45px] mt-2.5 w-56 pb-1 origin-top-right`,
            "border border-zinc-200 rounded-2xl bg-white",
            "shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none",
            "divide-y divide-gray-300"
          )}
        >
          <div className="mb-2 pt-4 flex flex-col items-center ">
            <img
              className="h-16 w-16 rounded-full mb-1 mx-auto"
              src={user?.picture}
              alt={profileData?.email}
            />
            {(profileData?.first_name || profileData?.last_name) && (
              <span className="block mt-1 text-xs/[1] text-gray-700">
                {`Hi, ${profileData.first_name} ${profileData.last_name}`}{" "}
                {accountStatus?.status === "trialing" && (
                  <span className="bg-purple-100 text-purple-700 text-xs font-semibold px-[10px] py-[2px] rounded-[5px]">
                    Trial
                  </span>
                )}
              </span>
            )}

            {profileData?.occupation && (
              <span className="block mt-2 text-sm text-customDarkBlue">
                {profileData.occupation}
              </span>
            )}
          </div>
          <div className="py-1">
            {userNavigation.map(({ name, href, icon: Icon }) => (
              <MenuItem key={href} as="div" className={classes.menuItem}>
                {({ focus }) => (
                  <Link
                    to={href}
                    className={`${classes.link(focus)} hover:bg-zinc-100`}
                  >
                    <Icon className={classes.icon} />
                    <span>{name}</span>
                  </Link>
                )}
              </MenuItem>
            ))}
          </div>
          <form method="POST" onSubmit={logoutWithRedirect} className="py-1">
            <MenuItem as="div" className={classes.menuItem}>
              {({ focus }) => (
                <button
                  type="submit"
                  className={`${classes.link(focus)} hover:bg-zinc-100`}
                >
                  <PowerIcon className={classes.icon} />
                  <span>Sign Out</span>
                </button>
              )}
            </MenuItem>
          </form>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
