import React from "react";
import DiagramServicesList from "../../../components/diagramServicesList";

// Clouds
import Azure from '../flow/assets/vendor_icons/azure.svg';
import Kubernetes from '../flow/assets/vendor_icons/kubernetes.svg';
import DevOps from '../flow/assets/vendor_icons/devops.svg';
import Software from '../flow/assets/vendor_icons/software.svg';
import Vendors from '../flow/assets/vendor_icons/vendors.svg';
import AWS from '../flow/assets/vendor_icons/aws.svg';
import GCP from '../flow/assets/vendor_icons/gcp.svg';
import Misc from '../flow/assets/vendor_icons/gcp.svg';

const tabsItemsList: any = [
  { id: 1, name: "Azure", icon: Azure },
  { id: 2, name: "AWS", icon: AWS },
  { id: 3, name: "GCP", icon: GCP },
  { id: 4, name: "Kubernetes", icon: Kubernetes },
  { id: 5, name: "DevOps", icon: DevOps },
  { id: 6, name: "Software", icon: Software },
  { id: 7, name: "Vendors", icon: Vendors },
  { id: 8, name: "Misc", icon: Misc },
];

interface IconsCategoryTabsProps {
    setCurrentTab: Function;
    currentTab: number;
}

export default function IconsCategoryTabs({
  setCurrentTab,
  currentTab,
}: IconsCategoryTabsProps) {
  return (
    <DiagramServicesList
      options={tabsItemsList}
      onSelect={setCurrentTab}
      value={currentTab}
    />
    // <nav className="flex" aria-label="Tabs">
    //   {tabsItemsList.map((tab: any, index: number) => (
    //     <>
    //       <Tooltip
    //         className="z-[50]"
    //         id={`flow-category-tooltip-${index}`}
    //         place="top"
    //       >
    //         {tab.name}
    //       </Tooltip>

    //       <span
    //         data-tooltip-id={`flow-category-tooltip-${index}`}
    //         key={tab.id}
    //         onClick={() => setCurrentTab(tab.id)}
    //         className={classNames(
    //           tab.id === currentTab
    //             ? "text-amber-500 border-amber-500"
    //             : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
    //           "whitespace-nowrap border-b-2 p-2 mx-1 text-xs font-medium cursor-pointer"
    //         )}
    //       >
    //         <img src={tab.icon} alt={tab.name} className="h-[20px]" />
    //       </span>
    //     </>
    //   ))}
    // </nav>
  );
}
