interface RouteNode {
  name: string;
  [key: string]: RouteNode | string;
}

const routeHierarchy = (() => {
  const initialRoutes = {
    login: { name: 'login' },
    signup: { name: 'signup' },
    home: { name: 'home' },
    community: { name: 'community' },
    profile: { name: 'profile' },
    communityTemplates: {
      name: 'community-templates',
      details: {
        name: 'details',
        documentView: { name: 'document-view' },
      },
    },
    communityAiAssistants: {
      name: 'community-ai-assistants',
      details: {
        name: 'details',
      },
    },
    projects: {
      name: 'projects',
      details: {
        name: 'details',
      },
    },
    privateAiAssistants: {
      name: 'private-ai-assistants',
      details: {
        name: 'details',
      },
    },
    privateTemplates: {
      name: 'private-templates',
      details: {
        name: 'details',
        edit: { name: 'edit' },
        documentView: { name: 'document-view' },
      },
    },
    documentComponents: { name: 'document-components' },
    playground: { name: 'playground' },
  };

  function getChildRoutes(route: RouteNode) {
    const { name, ...childRoutes } = route;
    return childRoutes as typeof initialRoutes;
  }

  function normalizeRoutes(routes: typeof initialRoutes, parentName: string = '') {
    for (const value of Object.values(routes)) {
      if (typeof value === 'object' && value !== null) {
        const currentName = parentName ? `${parentName}:${value.name}` : value.name;
        value.name = currentName;

        // Process child routes recursively
        const childRoutes = getChildRoutes(value);
        if (Object.keys(childRoutes).length > 0) {
          normalizeRoutes(childRoutes, currentName);
        }
      }
    }

    return routes as typeof initialRoutes;
  }

  return normalizeRoutes(initialRoutes);
})();

export default routeHierarchy;
