import React from "react";

interface ComplexityStatusProps {
  status: string;
}

const ComplexityStatus = ({ status }: ComplexityStatusProps) => {
  return (
    <span
      className={`inline-flex capitalize items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center 
				${
					(status === "low" &&
						"bg-yellow-50 text-yellow-700 ring-yellow-600/20") ||
					(status === "medium" &&
						"bg-blue-50 text-blue-700 ring-blue-600/20") ||
					(status === "complex" &&
						"bg-red-50 text-red-700 ring-red-600/20")
				}`}
    >
      {status}
    </span>
  );
};

export default ComplexityStatus;
