import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import CustomButton from "../customButton";
import { useAccessToken } from "src/contexts/AccessTokenContext";

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
);

interface SubscriptionButtonProps {
  priceId: string;
  label: string;
}

const SubscriptionButton = ({ priceId, label }: SubscriptionButtonProps) => {
  const { accessToken, userTokenInfo } = useAccessToken();

  const customerID = userTokenInfo?.app_meta?.stripe_customer_id;

  const handleSubscribe = async () => {
    try {
      // 1. Call your backend to create a Checkout Session
      const response = await fetch(
        `https://api.solutionpilot.ai/api/paymentSession?price_id=price_1QKnGhGeDzp3s1S0lOc2eLiP&stripe_customer_id=${customerID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const { sessionId } = await response.json();

      // 2. Get Stripe instance
      const stripe = await stripePromise;


      // 3. Redirect to Checkout using the session ID
      if (!stripe) return;
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });

      if (result.error) {
        console.error("Error:", result.error);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  return (
    <CustomButton
      text={label}
      onClickBtn={() => handleSubscribe()}
      type={"button"}
    />
  );
};

export default SubscriptionButton;
