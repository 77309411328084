import React from "react";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import ComplexityStatus from '../complexityStatus';

interface DocumentBoxProps {
  data: any;
  onShow: Function;
  onUse: Function;
}

export default function DocumentBox({ data, onShow, onUse }: DocumentBoxProps) {

  return (
    <div
      onClick={() => onShow()}
      className="cursor-pointer relative w-full bg-white shadow-md border transition-all border-zinc-100 bg-zinc-0 rounded-xl hover:shadow-2xl"
    >
      <div className="px-6 pt-6 pb-16">
        <div className="flex items-center mb-4 text-xs">
          <DocumentTextIcon className="h-5 mr-2 text-zinc-500" />
          <h3 className="text-xs font-semibold text-customDarkBlue line-clamp-1">
            {data?.title}
          </h3>
        </div>

        <div className="flex items-center justify-between text-xs">
          <div>Complexity</div>
          <div>
            <ComplexityStatus status={data?.complexity} />
          </div>
        </div>
      </div>

      <div className="flex border-t border-zinc-100 text-xs absolute w-full bottom-0">
        <div
          className="w-[50%] flex items-center justify-center border-r border-zinc-100 p-3 rounded-bl-xl cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={() => onShow()}
        >
          Show
        </div>
        <div
          className="w-[50%] flex items-center justify-center p-3 rounded-br-xl cursor-pointer hover:bg-blue-500 hover:text-white"
          onClick={(e: any) => {
            e.stopPropagation();
            onUse(data);
          }}
        >
          Use
        </div>
      </div>
    </div>
  );
}
