import CustomButton from "../customButton";

export default function VisitSection() {
  return (
    <div className="px-10 py-4 border border-zinc-200 rounded-[30px] md:flex md:items-center md:justify-between md:space-x-5">
      <div className="flex items-start space-x-5">
        <div className="pt-1.5">
          <h1 className="text-2xl font-bold text-gray-900">Ricardo Cooper</h1>
          <p className="text-sm font-medium text-gray-500">
            Applied for{" "}
            <span className="text-gray-900">Front End Developer</span> on{" "}
            <time dateTime="2020-08-25">August 25, 2020</time>
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <CustomButton
          text="Read More"
          type="button"
          onClickBtn={() =>
            window.open("https://solutionpilot.ai/pricing", "_blank")
          }
        />
      </div>
    </div>
  );
}
