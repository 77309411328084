import React from "react";
import { Tooltip } from "react-tooltip";

interface EdgeIconBoxProps {
  iconDataList: any;
  onChangeEdgeImage: Function;
  selectedEdgeImage: string;
}

const EdgeIconBox = ({
  iconDataList,
  onChangeEdgeImage,
  selectedEdgeImage,
}: EdgeIconBoxProps) => {
  return (
    <div className="mb-1 pb-1 border-b border-zinc-100">
      <div className="opacity-100 h-[100%] mt-2 flex items-center justify-start flex-wrap">
        {iconDataList.data.length > 0 &&
          iconDataList.data.map((item: any) => (
            <div
              key={item.id}
              className="cursor-pointer"
              onClick={() =>
                onChangeEdgeImage({ url: item?.icon, name: item?.name })
              }
            >
              <div
                data-tooltip-id={`edge-icon-tooltip-${item.id}`}
                className={`${selectedEdgeImage === item?.icon ? "rounded-md border-2 border-green-400 bg-white shadow-lg" : ""} transition-all hover:scale-125`}
              >
                <div className="m-[5px]">
                  <img src={item.icon} alt="iconsList" className="h-[26px]" />
                </div>
              </div>

              <Tooltip
                className="z-[50]"
                id={`edge-icon-tooltip-${item.id}`}
                place="top"
              >
                {item.name}
              </Tooltip>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EdgeIconBox;
