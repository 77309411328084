import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks";

interface MenuSubItemsListProps {
  classNames: Function;
  collapseSidebar: boolean;
  data: any;
}

export default function MenuSubItemsList({
  classNames,
  collapseSidebar,
  data,
}: MenuSubItemsListProps) {
  const { currentPage } = useAppSelector((state) => state.profile);

  return (
    <li key={data.name} className={collapseSidebar ? "ml-0" : "-ml-[14px]"}>
      <Link
        to={data?.href}
        className={classNames(
          currentPage?.includes(data.routeKey)
            ? "bg-[rgba(10,_15,_41,_0.04)] text-[#2d61d2]"
            : "text-[#14151A] hover:bg-[rgba(10,_15,_41,_0.04)]",
          `flex gap-x-3 rounded-[10px] p-2 text-xs leading-5 font-medium cursor-pointer items-center ${
            collapseSidebar ? "items-center justify-start" : ""
          }`
        )}
      >
        <span
          className={classNames(
            currentPage?.includes(data.routeKey)
              ? "text-[#2d61d2]"
              : "hover:text-[#14151A]",
            "text-[#0F1324] opacity-60"
          )}
        >
          <data.icon className="w-[17px] h-[17px]" />
        </span>
        <span>{data.name}</span>{" "}
      </Link>
    </li>
  );
}
