import React from "react";

interface TableHeaderProps {
  name: string;
}

export default function TableHeader({ name }: TableHeaderProps) {
  return (
    <th
      scope="col"
      className="px-6 py-4 text-left text-xs font-semibold text-customLightBlue opacity-70"
    >
      {name}
    </th>
  );
}
