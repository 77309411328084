import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import InputField from "../../components/formFields/inputField";
import CustomButton from "../../components/customButton";
import { getProfileData, updateProfile } from "../../redux/profile/profileApi";

export default function Account() {
  const [loader, setLoader] = useState(false);

  const { getAccessTokenSilently, user } = useAuth0();
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector<any>((state: any) => state.profile);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProfileData(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  const validationObject = {
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    occupation: yup.string().required("Occupation is required"),
    company: yup.string(),
  };

  const validationSchema = yup.object().shape(validationObject);


  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: profileData?.first_name || "",
      last_name: profileData?.last_name || "",
      occupation: profileData?.occupation || "",
      company: profileData?.company || "",
    },
    validationSchema,
    onSubmit: (values: any) => {
      values.status = "active";
      onUpdateUser(values);
    },
  });

  const onUpdateUser = (body: any) => {
    const fetchData = async () => {
      setLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(updateProfile({ body, accessToken }));
          await dispatch(getProfileData(accessToken));
        }
        setLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setLoader(false);
      }
    };

    fetchData();
  };

  return (
    <div className="flex py-6 px-9">
      <div className="w-96">
        <h3 className="font-semibold text-base mb-2">Personal Information</h3>
        <p className="text-xs">
          Use a permanent email address where you can receive emails.
        </p>
      </div>
      <div className="w-full">
        <form
          className="flex flex-col px-6 rounded-lg"
          onSubmit={formik.handleSubmit}
        >
          {/* container */}
          <div>
            <div className="mb-4">
              <img
                className="h-20 w-20 rounded-lg bg-gray-50"
                src={user?.picture}
                alt=""
              />
            </div>
            {/* First Name */}
            <div className="mb-4">
              <div>
                <label
                  htmlFor="first_name"
                  className="block text-xs font-medium leading-6 text-customDarkBlue sm:mt-1.5 mb-1"
                >
                  First Name
                </label>
              </div>
              <div className="sm:col-span-2">
                <InputField
                  type="text"
                  id="first_name"
                  {...formik.getFieldProps("first_name")}
                />

                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="text-red-600 text-xs">{formik.errors.first_name}</div>
                ) : null}
              </div>
            </div>

            {/* Last Name */}
            <div className="mb-4">
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-xs font-medium leading-6 text-customDarkBlue sm:mt-1.5 mb-1"
                >
                  Last Name
                </label>
              </div>
              <div className="sm:col-span-2">
                <InputField
                  type="text"
                  id="last_name"
                  {...formik.getFieldProps("last_name")}
                />

                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="text-red-600 text-xs">{formik.errors.last_name}</div>
                ) : null}
              </div>
            </div>

            {/* Occupation */}
            <div className="mb-4">
              <div>
                <label
                  htmlFor="occupation"
                  className="block text-xs font-medium leading-6 text-customDarkBlue sm:mt-1.5 mb-1"
                >
                  Occupation
                </label>
              </div>
              <div className="sm:col-span-2">
                <InputField
                  type="text"
                  id="occupation"
                  {...formik.getFieldProps("occupation")}
                />

                {formik.touched.occupation && formik.errors.occupation ? (
                  <div className="text-red-600 text-xs">{formik.errors.occupation}</div>
                ) : null}
              </div>
            </div>

             {/* Company */}
             <div className="mb-4">
              <div>
                <label
                  htmlFor="company"
                  className="block text-xs font-medium leading-6 text-customDarkBlue sm:mt-1.5 mb-1"
                >
                  Company
                </label>
              </div>
              <div className="sm:col-span-2">
                <InputField
                  type="text"
                  id="company"
                  {...formik.getFieldProps("company")}
                />

                {formik.touched.company && formik.errors.company ? (
                  <div className="text-red-600 text-xs">{formik.errors.company}</div>
                ) : null}
              </div>
            </div>
          </div>

          {/* Action button */}
          <div className="flex justify-end">
            <CustomButton
              type="submit"
              onClickBtn={() => console.log("Clicking...")}
              text="Update"
              loading={loader}
              buttonType="primary"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
