import { useState, useEffect, useCallback, SetStateAction } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Analysis, { type Side } from './Analysis';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getSwotById } from '../../redux/swot/swotApi';
import { getLastIdFromUrl, parseJSONFromAi } from '../../utils/utilities';
import WrapperLoader from '../../components/wrapperLoader';
import { SwotToolbar } from './Toolbar';
import {
  createSwotArgument,
  deleteSwotArgument,
  getSwotArgumentsList,
  updateSwotArgument,
} from 'src/redux/swotArguments/swotArgumentsApi';
import { nanoid } from '@reduxjs/toolkit';
import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';
import * as yup from 'yup';

const AiReplySchema = (() => {
  const argumentSchema = yup.object().shape({
    argument: yup.string().required(),
    argument_weight: yup.number().oneOf([1, 2, 3]).required(),
    explanation: yup.string().required(),
  });

  return yup.object().shape({
    strengths: yup.array().required().of(argumentSchema),
    weaknesses: yup.array().required().of(argumentSchema),
    opportunities: yup.array().required().of(argumentSchema),
    threats: yup.array().required().of(argumentSchema),
  });
})();

export default function SWOTDetails() {
  const [swotByIdLoader, setSwotByIdLoader] = useState(false);
  const [strengthsList, setStrengthsList] = useState<any[] | null>(null);
  const [weaknessesList, setWeaknessesList] = useState<any[] | null>(null);
  const [opportunitiesList, setOpportunitiesList] = useState<any[] | null>(null);
  const [threatsList, setThreatsList] = useState<any[] | null>(null);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { swotByIdRes, selectedSwot } = useAppSelector((state) => state.swot);

  const isSwotReady = !!(
    !swotByIdLoader &&
    swotByIdRes &&
    strengthsList &&
    weaknessesList &&
    opportunitiesList &&
    threatsList
  );

  useEffect(() => {
    if (selectedSwot?.id) {
      const fetchData = async () => {
        setSwotByIdLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();

          if (!accessToken) {
            throw new Error('No access token');
          }

          await dispatch(
            getSwotById({
              accessToken,
              id: selectedSwot?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        } catch (error) {
          console.error('Error getting access token:', error);
        } finally {
          setSwotByIdLoader(false);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSwot?.id]);

  useEffect(() => {
    if (!selectedSwot?.id) return;

    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          getSwotArgumentsList({
            accessToken,
            id: selectedSwot?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;
        const data = res?.payload?.data;

        if (requestStatus !== 'fulfilled') {
          throw new Error('Something went wrong');
        }

        const argumentsList = (Array.isArray(data) ? data : []) as any[];

        const { strengthsList, weaknessesList, opportunitiesList, threatsList } = argumentsList.reduce<{
          strengthsList: any[];
          weaknessesList: any[];
          opportunitiesList: any[];
          threatsList: any[];
        }>(
          (acc, argument) => {
            if (argument.side === 'strength') {
              acc.strengthsList.push({ ...argument, source: 'user' });
            } else if (argument.side === 'weakness') {
              acc.weaknessesList.push({ ...argument, source: 'user' });
            } else if (argument.side === 'opportunity') {
              acc.opportunitiesList.push({ ...argument, source: 'user' });
            } else if (argument.side === 'threat') {
              acc.threatsList.push({ ...argument, source: 'user' });
            }

            return acc;
          },
          { strengthsList: [], weaknessesList: [], opportunitiesList: [], threatsList: [] }
        );

        setStrengthsList(strengthsList);
        setWeaknessesList(weaknessesList);
        setOpportunitiesList(opportunitiesList);
        setThreatsList(threatsList);
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently, selectedSwot?.id]);

  const setArgumentsList = useCallback((side: Side, callback: SetStateAction<any[] | null>) => {
    if (side === 'strength') {
      setStrengthsList(callback);
    }

    if (side === 'weakness') {
      setWeaknessesList(callback);
    }

    if (side === 'opportunity') {
      setOpportunitiesList(callback);
    }

    if (side === 'threat') {
      setThreatsList(callback);
    }
  }, []);

  const updateArgument = useCallback(
    async (body: any, selectedArgument: any, side: Side) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          updateSwotArgument({
            body,
            accessToken,
            argument_id: selectedArgument?.id,
            swot_id: selectedSwot?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus !== 'fulfilled') {
          throw new Error('Request rejected');
        }

        const data = res?.payload?.data?.details;

        if (!data) {
          throw new Error('No data');
        }

        setArgumentsList(side, (current) => {
          if (!current) return null;

          const index = current.findIndex((item: any) => item.id === data.id);

          if (index === -1) {
            return current;
          }

          const updatedList = [...current];
          updatedList.splice(index, 1, data);

          return updatedList;
        });
      } catch (error) {
        console.error('Error updating argument:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedSwot?.id, setArgumentsList]
  );

  const createArgument = useCallback(
    async (argument: any, side: Side) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const { source = 'user', id: argumentId, ...body } = argument;

        const res = await dispatch(
          createSwotArgument({
            body,
            accessToken,
            id: selectedSwot?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus !== 'fulfilled') {
          throw new Error('Request rejected');
        }

        const data = res?.payload?.data?.details;

        if (!data) {
          throw new Error('No data');
        }

        setArgumentsList(side, (current) => {
          if (source === 'user') {
            return [...(current || []), { ...data, source: 'user' }];
          }

          const index = current?.findIndex((item: any) => item.id === argumentId) ?? -1;

          if (index === -1) {
            return current;
          }

          const updatedList = [...(current || [])];
          updatedList.splice(index, 1, { ...data, source: 'user' });

          return updatedList;
        });
      } catch (error) {
        console.error('Error creating argument:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedSwot?.id, setArgumentsList]
  );

  const deleteArgument = useCallback(
    async (selectedArgument: any, side: Side) => {
      if (selectedArgument.source === 'ai') {
        return setArgumentsList(
          side,
          (current) => current?.filter((item: any) => item.id !== selectedArgument.id) || null
        );
      }

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          deleteSwotArgument({
            accessToken,
            argument_id: selectedArgument?.id,
            swot_id: selectedSwot?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus === 'rejected') {
          throw new Error('Request rejected');
        }

        const argumentId = res?.payload?.data?.id;

        if (!argumentId) {
          throw new Error('No argument id');
        }

        setArgumentsList(side, (current) => current?.filter((item: any) => item.id !== argumentId) || null);
      } catch (error) {
        console.error('Error deleting argument:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedSwot?.id, setArgumentsList]
  );

  const generateSuggestions = useCallback(async () => {
    if (!isSwotReady) return;

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const res = await dispatch(
        promptMessage({
          body: {
            prompt: JSON.stringify({
              title: swotByIdRes?.details?.title || '',
              description: swotByIdRes?.details?.swot_description || '',
              category: swotByIdRes?.details?.category || '',
              strengths: strengthsList?.map((item) => item?.argument) ?? [],
              weaknesses: weaknessesList?.map((item) => item?.argument) ?? [],
              opportunities: opportunitiesList?.map((item) => item?.argument) ?? [],
              threats: threatsList?.map((item) => item?.argument) ?? [],
            }),
          },
          accessToken,
          type: 'swot-generator',
        })
      );

      const requestStatus = res?.meta?.requestStatus;
      const reply = res?.payload?.data?.message;

      if (requestStatus !== 'fulfilled') {
        throw new Error('Request rejected');
      }

      const replyData = parseJSONFromAi(reply);

      const {
        strengths: aiStrengthsList,
        weaknesses: aiWeaknessesList,
        opportunities: aiOpportunitiesList,
        threats: aiThreatsList,
      } = await AiReplySchema.validate(replyData);

      setStrengthsList((current) => [
        ...(current || []),
        ...aiStrengthsList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
      setWeaknessesList((current) => [
        ...(current || []),
        ...aiWeaknessesList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
      setOpportunitiesList((current) => [
        ...(current || []),
        ...aiOpportunitiesList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
      setThreatsList((current) => [
        ...(current || []),
        ...aiThreatsList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
    } catch (error) {
      console.error('Error generating arguments suggestions:', error);

      throw error;
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    isSwotReady,
    swotByIdRes,
    strengthsList,
    weaknessesList,
    opportunitiesList,
    threatsList,
  ]);

  return (
    <WrapperLoader
      loading={swotByIdLoader}
      className='h-full bg-customGray'
    >
      <SwotToolbar
        isSwotReady={isSwotReady}
        generateSuggestions={generateSuggestions}
      />
      <div className='px-6 h-[calc(100%-55px)]'>
        <div className='h-full'>
          <Analysis
            detailsData={swotByIdRes}
            strengthsList={strengthsList}
            weaknessesList={weaknessesList}
            opportunitiesList={opportunitiesList}
            threatsList={threatsList}
            updateArgument={updateArgument}
            createArgument={createArgument}
            deleteArgument={deleteArgument}
          />
        </div>
      </div>
    </WrapperLoader>
  );
}
