import React from "react";

const genericList = [
  { id: 1, title: "Personal Development", url: "/community" },
  { id: 2, title: "Business Strategy", url: "/community" },
];

const cloudList = [
  { id: 1, title: "Azure", url: "/community" },
  { id: 2, title: "AWS", url: "/community" },
  { id: 3, title: "GCP", url: "/community" },
];

const platformList = [
  { id: 1, title: "Software Development", url: "/community" },
  { id: 2, title: "Data & Storage", url: "/community" },
  { id: 3, title: "Networking", url: "/community" },
  { id: 4, title: "Devops & CICD", url: "/community" },
  { id: 5, title: "Security & Compliance", url: "/community" },
  { id: 6, title: "AI & Machine Learning", url: "/community" },
  { id: 7, title: "Hybrid & Hyper Cloud", url: "/community" },
];

const CommunityleftMenu = () => {
  return (
    <div className="bg-white p-6 border border-gray-100 shadow-lg ml-2 mr-3 mt-2 rounded-lg">
      <div className="overflow-y-auto" style={{ height: "calc(100vh - 82px)" }}>
        <div className="mb-8">
          <div className="text-xs font-semibold uppercase border-b border-zinc-200 mb-4 pb-2">
            Generic
          </div>
          {genericList?.map((data) => (
            <div
              key={data.id}
              className="cursor-pointer text-xs mb-3 hover:text-amber-500 last:mb-0"
            >
              {data.title}
            </div>
          ))}
        </div>

        <div className="mb-8">
          <div className="text-xs font-semibold uppercase border-b border-zinc-200 mb-4 pb-2">
            CLOUD
          </div>
          {cloudList?.map((data) => (
            <div
              key={data.id}
              className="cursor-pointer text-xs mb-3 hover:text-amber-500 last:mb-0"
            >
              {data.title}
            </div>
          ))}
        </div>

        <div className="mb-8">
          <div className="text-xs font-semibold uppercase border-b border-zinc-200 mb-4 pb-2">
            PLATFORM
          </div>
          {platformList?.map((data) => (
            <div
              key={data.id}
              className="cursor-pointer text-xs mb-3 hover:text-amber-500 last:mb-0"
            >
              {data.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CommunityleftMenu;
