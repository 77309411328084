import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import MainComponent from '../main';
import LoaderPage from 'src/components/loaderPage';
import { DetailsProvider } from 'src/contexts/details/Details.provider';

const AppRoutes = () => {
  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <LoaderPage />;
  }

  if (!isAuthenticated) {
    const pathname = window.location.pathname;
    if (pathname !== '/login' && pathname !== '/signup') {
      loginWithRedirect();
      return <LoaderPage />;
    }
  }

  return (
    <>
      <Routes>
        <Route
          path='*'
          element={
            <DetailsProvider>
              <MainComponent />
            </DetailsProvider>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
