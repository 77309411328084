import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from "../../../../components/customButton";
import InputField from "../../../../components/formFields/inputField";
import TextAreaField from "../../../../components/formFields/textareaField";

interface DocumentFromDiagramFormProps {
  onCloseModal: () => void;
  loading: boolean;
  onSubmitData: Function;
}

export default function DocumentFromDiagramForm({
  onCloseModal,
  loading,
  onSubmitData,
}: DocumentFromDiagramFormProps) {
  const validationSchema = yup.object().shape({
    title: yup.string().required("Title is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmitData(values);
    },
  });

  return (
    <form className="flex h-full flex-col" onSubmit={formik.handleSubmit}>
      <div className="space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 mb-3">
        <div>
          <label className="block text-xs font-medium leading-6 text-customDarkBlue">
            Title
          </label>
        </div>
        <div className="sm:col-span-2">
          <InputField
            type="text"
            id="title"
            {...formik.getFieldProps("title")}
            placeholder="Enter title"
          />
          {formik.touched.title && formik.errors.title ? (
            <div className="text-red-600 text-xs">{formik.errors.title}</div>
          ) : null}
        </div>
      </div>

      {/* Action buttons */}
      <div className="w-full text-right mt-5">
        <CustomButton
          text="Cancel"
          type="button"
          onClickBtn={onCloseModal}
          buttonType="secondary"
          btnStyle="mr-2"
        />
        <CustomButton
          text="Save"
          type="submit"
          loading={loading}
          onClickBtn={() => console.log("Clicking...")}
          buttonType="primary"
        />
      </div>
    </form>
  );
}
