import { Category } from 'src/type';
import { categoriesList } from 'src/utils/utilities';
import { RectangleStackIcon } from '@heroicons/react/24/outline';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface CategoriesLeftSidebarProps {
  setSelectedCategory: (category: Category) => void;
  selectedCategory: Category;
  groupedItems?: Partial<Record<Category, any[]>>;
}

const categoriesListWithAllOption = [
  {
    value: 'all' as Category,
    title: 'All Categories',
    icon: RectangleStackIcon,
  },
  ...categoriesList,
];

export function CategoriesLeftSidebar({
  setSelectedCategory,
  selectedCategory,
  groupedItems,
}: CategoriesLeftSidebarProps) {
  return (
    <div className='mr-5 w-full'>
      <nav
        aria-label='Sidebar'
        className='flex flex-1 flex-col'
      >
        <ul className='-mx-2 space-y-1'>
          {categoriesListWithAllOption.map((item) => {
            const Icon = item.icon;
            const count = groupedItems?.[item.value]?.length || 0;

            return (
              <li
                className='cursor-pointer ml-0 flex items-center w-full'
                key={item.value}
                onClick={() => setSelectedCategory(item.value)}
              >
                <span
                  className={classNames(
                    selectedCategory === item.value
                      ? 'bg-[#f5f5f6] text-[#2d61d2]'
                      : 'text-gray-600 hover:bg-[#f5f5f6] hover:text-[#2d61d2]',
                    'w-[calc(100%_-_8px)] group flex gap-x-3 rounded-[10px] px-[8px] py-[6px] text-xs font-semibold leading-6'
                  )}
                >
                  <Icon
                    aria-hidden='true'
                    className={classNames(
                      selectedCategory === item.value ? 'text-[#2d61d2]' : 'text-gray-400 group-hover:text-[#2d61d2]',
                      'h-5 w-5 shrink-0'
                    )}
                  />
                  {item.title}
                  {count > 0 && (
                    <span
                      aria-hidden='true'
                      className='ml-auto w-7 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200'
                    >
                      {count > 20 ? '20+' : count}
                    </span>
                  )}
                </span>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
