import {
  useState,
  useEffect,
  useRef,
  ElementType,
  MutableRefObject,
  useContext,
  ComponentPropsWithoutRef,
  FC,
  SVGProps,
  ReactNode,
} from 'react';
import {
  ArrowUpIcon as AddToTopIcon,
  ArrowDownIcon as AddToBottomIcon,
  CursorArrowRippleIcon as InsertAtCursorIcon,
  ClipboardIcon as CopyIcon,
  ChatBubbleLeftRightIcon as AddToChatIcon,
  DocumentDuplicateIcon as CopyEntireMessageIcon,
  DocumentPlusIcon as CreateDocumentIcon,
} from '@heroicons/react/24/outline';
import { DetailsContext } from 'src/contexts/details/context';
import type { InputRef } from '../chatGPTInput';
import { copyToClipboard } from 'src/utils/utilities';

interface TooltipProps {
  position: { top: number; right: number };
  selectedText: string;
  onOptionSelect: (option: OptionName) => void;
  tooltipRef: MutableRefObject<HTMLDivElement | null>;
  options: Array<{ name: OptionName; icon: FC<Omit<SVGProps<SVGSVGElement>, 'ref'>>; label: string }>;
}

interface SelectableComponentProps<T extends ElementType> {
  tag?: T;
}

export enum OptionName {
  ADD_TO_TOP = 'add_to_top',
  ADD_TO_BOTTOM = 'add_to_bottom',
  INSERT_AT_CURSOR = 'insert_at_cursor',
  COPY = 'copy',
  COPY_ENTIRE_MESSAGE = 'copy_entire_message',
  PASTE_INTO_CHAT = 'paste_into_chat',
  CREATE_DOCUMENT = 'create_document',
}

const Tooltip = ({ position, onOptionSelect, options, tooltipRef }: Readonly<TooltipProps>) => {
  return (
    <div
      ref={tooltipRef}
      className='absolute bg-white gap-0.5 flex items-center justify-end p-1 rounded-[7px] shadow-[rgba(0,_0,_0,_0.1)_0px_0px_15px_5px]'
      style={{
        top: `${position.top}px`,
        right: `${position.right}px`,
      }}
    >
      {options.map(({ name, icon: Icon, label }) => (
        <button
          key={name}
          onClick={() => onOptionSelect(name)}
          title={label}
          className='cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]'
        >
          <Icon className='w-4 h-4' />
        </button>
      ))}
    </div>
  );
};

const AdvancedCopyAndPaste: FC<{
  children: ReactNode;
  inputRef: MutableRefObject<InputRef | null>;
  onAddDocument?: (data: string) => void;
}> = ({ children, inputRef, onAddDocument }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const currentSelectedElRef = useRef<HTMLElement | null>(null);

  const [selectedText, setSelectedText] = useState<string>('');
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, right: 0 });

  const { editorRef, hasTextEditor } = useContext(DetailsContext);

  const options = [
    {
      name: OptionName.COPY,
      icon: CopyIcon,
      label: 'Copy',
    },
    { name: OptionName.COPY_ENTIRE_MESSAGE, icon: CopyEntireMessageIcon, label: 'Copy Entire Message' },
    { name: OptionName.PASTE_INTO_CHAT, icon: AddToChatIcon, label: 'Add to Chat' },
  ];

  if (onAddDocument) {
    options.push({
      name: OptionName.CREATE_DOCUMENT,
      icon: CreateDocumentIcon,
      label: 'Create Document',
    });
  }

  if (hasTextEditor) {
    options.unshift(
      {
        name: OptionName.ADD_TO_TOP,
        icon: AddToTopIcon,
        label: 'Add to Top',
      },
      {
        name: OptionName.ADD_TO_BOTTOM,
        icon: AddToBottomIcon,
        label: 'Add to Bottom',
      },
      {
        name: OptionName.INSERT_AT_CURSOR,
        icon: InsertAtCursorIcon,
        label: 'Insert at Cursor',
      }
    );
  }

  useEffect(() => {
    const getSelectableParent = (node: Node): HTMLElement | null => {
      let current = node.parentElement;

      while (current) {
        if (current === wrapperRef.current) {
          return null;
        }

        if (current.hasAttribute('data-selectable')) {
          return current;
        }

        current = current.parentElement;
      }

      return null;
    };

    const updateTooltipPosition = (currentSelection: Selection) => {
      if (wrapperRef.current && tooltipRef.current) {
        const range = currentSelection.getRangeAt(0);
        const selectionRect = range.getBoundingClientRect();
        const wrapperRect = wrapperRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current?.getBoundingClientRect();

        const naiveRightOffset = wrapperRect.right - selectionRect.right;

        setTooltipPosition({
          top: selectionRect.top - wrapperRect.top - 50,
          right:
            wrapperRect.width - naiveRightOffset >= tooltipRect.width
              ? naiveRightOffset
              : wrapperRect.width - tooltipRect.width - 16,
        });
      }
    };

    const handleSelectionChange = () => {
      try {
        const selection = document.getSelection();
        const selectedText = selection?.toString();

        if (!selection || !selectedText || selectedText.length < 5) {
          throw new Error('Invalid selection');
        }

        const range = selection.getRangeAt(0);
        const { startContainer, endContainer } = range;

        const startSelectable = getSelectableParent(startContainer);
        const endSelectable = getSelectableParent(endContainer);

        if (!startSelectable || startSelectable !== endSelectable) {
          throw new Error('Selection not within the same selectable area');
        }

        currentSelectedElRef.current = startSelectable;

        setSelectedText(selectedText);
        updateTooltipPosition(selection);
      } catch (error) {
        setSelectedText('');
      }
    };

    document.addEventListener('selectionchange', handleSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, []);

  const getSelectedContentFromDOM = (): string => {
    const selection = window.getSelection();

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const fragment = range.cloneContents();
      const div = document.createElement('div');
      div.appendChild(fragment);
      return div.innerHTML;
    }

    return '';
  };

  const handleOptionSelect = async (option: OptionName) => {
    switch (option) {
      case OptionName.COPY:
        if (selectedText) {
          await copyToClipboard(selectedText);
        }

        break;
      case OptionName.COPY_ENTIRE_MESSAGE:
        const { current: currentSelectedEl } = currentSelectedElRef;

        if (currentSelectedEl?.textContent) {
          await copyToClipboard(currentSelectedEl.textContent);
        }

        break;
      case OptionName.PASTE_INTO_CHAT:
        if (selectedText) {
          inputRef.current?.write(selectedText);
          inputRef.current?.moveCursorToEnd();
        }
        break;
      case OptionName.CREATE_DOCUMENT:
        if (selectedText) {
          onAddDocument?.(getSelectedContentFromDOM());
        }
        break;
      case OptionName.ADD_TO_TOP:
        editorRef.current?.execute('pasteAt:afterTheHeading', { content: getSelectedContentFromDOM() });
        break;
      case OptionName.ADD_TO_BOTTOM:
        editorRef.current?.execute('pasteAt:theBottom', { content: getSelectedContentFromDOM() });
        break;
      case OptionName.INSERT_AT_CURSOR:
        editorRef.current?.execute('pasteAt:cursor', { content: getSelectedContentFromDOM() });
        break;
    }

    setSelectedText('');
  };

  return (
    <div
      ref={wrapperRef}
      className='relative'
    >
      {children}
      {selectedText && (
        <Tooltip
          tooltipRef={tooltipRef}
          position={tooltipPosition}
          selectedText={selectedText}
          onOptionSelect={handleOptionSelect}
          options={options}
        />
      )}
    </div>
  );
};

const SelectableComponent = <T extends ElementType = 'div'>({
  tag,
  children,
  ...props
}: SelectableComponentProps<T> & ComponentPropsWithoutRef<T>) => {
  const Tag = tag || 'div';

  return (
    <Tag
      data-selectable
      {...props}
    >
      {children}
    </Tag>
  );
};

export default Object.assign(AdvancedCopyAndPaste, { Selectable: SelectableComponent });
