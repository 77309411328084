import { Tooltip } from "react-tooltip";
import { useAppSelector } from "../../hooks";
import { formatLatestDate } from "../../utils/utilities";

import chatGPTIcon from "../../assets/icons/chat-gpt-icon.svg";

interface TemplateBoxProps {
  data: any;
  onShow: Function;
  onUse: Function;
  tempType: string;
}

export default function TemplateBox({
  data,
  onShow,
  onUse,
  tempType,
}: TemplateBoxProps) {
  const { profileData } = useAppSelector<any>((state: any) => state.profile);

  return (
    <>
      <div
        onClick={() => onShow()}
        className="p-1 bg-gradient-to-r hover:from-blue-400 hover:via-purple-300 hover:to-purple-500 rounded-2xl transition duration-300 ease-in-out"
      >
        <div className="bg-white p-8 rounded-xl border border-zinc-200 cursor-pointer h-full">
          <div className="h-full flex flex-1 flex-col">
            <div className="text-xs text-gray-700 mb-2">
              {formatLatestDate(data?.updated_at)}
            </div>
            <div
              className="mt-2 text-base/7 font-medium line-clamp-1"
              title={data?.title}
            >
              <div className="text-sm">{data?.title}</div>
            </div>
            <div className="mb-4 mt-2 grow text-sm/6 text-gray-500 line-clamp-3">
              {data?.description}
            </div>

            <div className="text-sm/5 text-gray-700">
              <span className="ml-auto capitalize w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200">
                {data?.category}
              </span>
            </div>

            <div className="flex items-center justify-between mt-6">
              <div className="flex items-center gap-3">
                <img
                  alt=""
                  src="https://cdn.sanity.io/images/ssqh4ksj/production/cd1ee59e9e4c2ff30c303de6c7d1066c057419d5-7952x5304.jpg?rect=2370,0,5304,5304&amp;w=64&amp;h=64&amp;auto=format"
                  className="aspect-square size-6 rounded-full object-cover"
                />
                <div className="text-sm/5 text-gray-700">
                  {data?.published_by === "userTemplate" && (
                    <div
                      className="line-clamp-1"
                      data-tooltip-id="tooltip-content"
                    >
                      {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                    </div>
                  )}

                  <Tooltip id="tooltip-content" place="top">
                    {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
                  </Tooltip>

                  {data?.published_by === "solutionPilotTemplate" && (
                    <div className="line-clamp-1">Solution Pilot</div>
                  )}

                  {data?.published_by === "communityTemplate" && (
                    <div className="line-clamp-1">Community</div>
                  )}
                </div>
              </div>

              <div data-tooltip-id={`${data.id}`}>
                <img alt="Chat GPT" src={chatGPTIcon} className="h-6" />
              </div>

              <Tooltip className="z-[50]" id={`${data.id}`} place="top">
                Chat GPT
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        onClick={() => onShow()}
        className="group overflow-hidden cursor-pointer relative w-full bg-white shadow-sm border transition-all border-zinc-100 bg-zinc-0 rounded-xl hover:shadow-xl top-0 hover:top-1"
      >
        <div className="px-6 py-6">
          <div className="flex items-center mb-4 text-xs text-zinc-500">
            <div className="mr-2">
              <img src={chatGPT} alt="Chat GPT" className="h-8 rounded-md" />
            </div>
            <span>{data?.ai_type}</span>
          </div>

          <span className="inline-block w-full text-md text-customDarkBlue text-ellipsis flex-shrink-0 overflow-x-hidden whitespace-nowrap line-clamp-1">
            {data?.title}
          </span>

          <div className="mb-3 text-xs text-zinc-500 line-clamp-2">
            {data?.description}
          </div>

          <div className="flex items-center justify-between text-xs mb-3">
            <div className="text-zinc-600">Category</div>
            <div>
              <span
                className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset justify-center bg-blue-50 text-blue-700 ring-blue-600/20`}
              >
                {data?.category}
              </span>
            </div>
          </div>

          <div className="flex items-center justify-between mb-2 text-xs">
            <div className="text-zinc-600">Published By</div>
            {data?.published_by === "userTemplate" && (
              <div className="line-clamp-1" data-tooltip-id="tooltip-content">
                {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
              </div>
            )}

            <Tooltip id="tooltip-content" place="top">
              {`${profileData?.first_name || profileData?.last_name ? `${profileData?.first_name} ${profileData?.last_name}` : profileData?.email}`}
            </Tooltip>

            {data?.published_by === "solutionPilotTemplate" && (
              <div className="line-clamp-1">Solution Pilot</div>
            )}

            {data?.published_by === "communityTemplate" && (
              <div className="line-clamp-1">Community</div>
            )}
          </div>
        </div>

        <div className="w-full px-6 absolute bottom-[-100px] group-hover:bottom-[20px] transition-all">
          <CustomButton
            text="Use"
            type="button"
            onClickBtn={(e: any) => {
              e.stopPropagation();
              onUse(data);
            }}
            beforeIcon={
              <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            }
            btnStyle="w-full flex items-center justify-center"
          />
        </div>
      </div> */}
    </>
  );
}
