import { useAppSelector } from 'src/hooks';
import DropdownSelectionOption, {
  DropdownSelectionOptionProps,
  MultipleOptionsProps,
  Option,
  SingleOptionProps,
} from '../dropdownSelectionOption';
import { classNames } from 'src/utils/utilities';

type Props<O> = SingleOptionProps<O> | MultipleOptionsProps<O>;

export type DocumentsSelectorProps<O extends Option> = Props<O> &
  Omit<Partial<DropdownSelectionOptionProps<O>>, keyof Props<O>> & {
    isStyled?: boolean;
  };

export function DocumentsSelector<O extends Option>({
  title,
  multiple,
  selected,
  setSelected,
  isStyled = true,
  buttonClassName,
  ...props
}: DocumentsSelectorProps<O>) {
  const { getProjectAllDocumentsRes } = useAppSelector((state) => state.projects);

  const dynamicProps = {
    multiple,
    selected,
    setSelected,
  } as Props<O>;


  return (
    <DropdownSelectionOption
      title={title}
      optionsList={getProjectAllDocumentsRes}
      onAddingDocument={null}
      btnText='Add Document'
      buttonClassName={
        isStyled
          ? classNames(
              'font-semibold text-xs text-customLightBlue border border-zinc-300 pr-2 pl-3 py-2 rounded-md',
              'transition-colors hover:border-blue-500 hover:text-blue-500',
              buttonClassName
            )
          : ''
      }
      {...dynamicProps}
      {...props}
    />
  );
}
