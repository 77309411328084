import { Command, Editor } from 'ckeditor5/src/core';

import { Plugin } from 'ckeditor5/src/core';
import {
  Position,
  Element,
  ViewElement,
  DiffItemInsert,
  Item,
  DowncastWriter,
  ViewPosition,
} from 'ckeditor5/src/engine';
import type {
  AiSuggestionsFetcher,
  Configuration,
  ElementLifecycleData,
  FetchOptions,
  SerializedSuggestionsState,
} from '../../types/inlineSuggestions';
import { EmitterMixin, EventInfo } from '@ckeditor/ckeditor5-utils';

class SuggestionsState extends EmitterMixin() {
  protected _suggestions: string[] = [];
  protected _currentSuggestionIndex = 0;

  get suggestions() {
    return this._suggestions;
  }

  get currentSuggestionIndex() {
    return this._currentSuggestionIndex;
  }

  get currentSuggestion() {
    const suggestion = this._suggestions[this._currentSuggestionIndex];

    if (typeof suggestion === 'string') {
      return suggestion;
    }

    return null;
  }

  set suggestions(suggestions: string[]) {
    this._suggestions = suggestions;
    this._currentSuggestionIndex = 0;

    this.fire('suggestions:change');
  }

  set currentSuggestionIndex(index: number) {
    this._currentSuggestionIndex = index;
    this.fire('suggestions:change');
  }

  set currentSuggestion(suggestion: string | null) {
    this._suggestions = typeof suggestion === 'string' ? [suggestion] : [];
    this._currentSuggestionIndex = 0;
    this.fire('suggestions:change');
  }

  next() {
    this.currentSuggestionIndex = (this._currentSuggestionIndex + 1) % this._suggestions.length;
  }

  prev() {
    this.currentSuggestionIndex =
      (this._currentSuggestionIndex - 1 + this._suggestions.length) % this._suggestions.length;
  }

  destroy() {
    this.suggestions = [];
    this.currentSuggestionIndex = 0;
  }
}

class SuggestionDOMElement {
  protected readonly MAX_ATTEMPTS = 20;

  protected _htmlElement: HTMLElement | null = null;
  protected cancel: VoidFunction | null = null;
  protected retries = 0;

  get htmlElement() {
    return this._htmlElement;
  }

  getHtmlElement() {
    this.cancel?.();
    this.retries = 0;

    return new Promise<HTMLSpanElement | null>((resolve, reject) => {
      let interval: any;

      interval = setInterval(() => {
        if (++this.retries >= this.MAX_ATTEMPTS) {
          return this.cancel?.();
        }

        this._htmlElement = document.getElementById('suggestion');

        if (!this._htmlElement) return;

        clearInterval(interval);
        resolve(this._htmlElement);
      }, 100);

      this.cancel = () => {
        clearInterval(interval);
        reject();
      };
    });
  }
}

class SuggestionElement extends EmitterMixin() {
  protected editor: Editor;
  protected containerElement: ViewElement | null = null;
  protected element: ViewElement | null = null;
  protected domElement: SuggestionDOMElement;

  constructor(editor: Editor) {
    super();

    this.editor = editor;
    this.domElement = new SuggestionDOMElement();
  }

  protected applyPreviousNodeStyles(
    writer: DowncastWriter,
    viewPosition: ViewPosition,
    suggestionElement: ViewElement
  ): ViewElement {
    const nodeBefore = viewPosition.nodeBefore;

    if (!nodeBefore || !(nodeBefore instanceof ViewElement)) {
      const wrapperElement = writer.createContainerElement('span');
      writer.insert(writer.createPositionAt(wrapperElement, 'end'), suggestionElement);
      
      return wrapperElement;
    }

    // Function to clone an element deeply
    const cloneElementDeep = (element: ViewElement): ViewElement => {
      const clonedElement = writer.createContainerElement(element.name, element.getAttributes());

      for (const child of Array.from(element.getChildren())) {
        if (child.is('element')) {
          writer.insert(writer.createPositionAt(clonedElement, 'end'), cloneElementDeep(child as ViewElement));
        }
      }

      return clonedElement;
    };

    // Clone the nodeBefore element deeply
    const clonedElement = cloneElementDeep(nodeBefore);

    // Insert the suggestion element at the deepest level
    const insertAtDeepest = (element: ViewElement) => {
      if (element.childCount === 0) {
        writer.insert(writer.createPositionAt(element, 'end'), suggestionElement);
      } else {
        insertAtDeepest(element.getChild(element.childCount - 1) as ViewElement);
      }
    };

    insertAtDeepest(clonedElement);

    return clonedElement;
  }

  getPosition(): Position | null {
    if (!this.containerElement) {
      return null;
    }

    const {
      editing: { mapper, view },
    } = this.editor;

    try {
      const viewPosition = view.createPositionAt(this.containerElement, 0);
      return mapper.toModelPosition(viewPosition);
    } catch (error) {
      console.warn('Could not get suggestion element position:', error);
      return null;
    }
  }

  exists(): boolean {
    return !!this.containerElement;
  }

  isAtPosition(position: Position): boolean {
    if (!this.containerElement || !position) {
      return false;
    }

    const currentPosition = this.getPosition();

    if (!currentPosition) {
      return false;
    }

    try {
      return currentPosition.isEqual(position);
    } catch (error) {
      console.warn('Could not check if suggestion element is at position:', error);
      return false;
    }
  }

  remove(): Promise<void> {
    const {
      editing: { view },
    } = this.editor;

    return new Promise((resolve) => {
      view.change((writer) => {
        if (!this.containerElement) {
          resolve();

          return;
        }

        if (this.domElement.htmlElement) {
          this.fire('dom:lifecycle', { type: 'removal', element: this.domElement.htmlElement });
        }

        writer.remove(this.containerElement);
        this.containerElement = null;
        this.element = null;
        resolve();
      });
    });
  }

  async insertAt(position: Position, text: string) {
    const {
      editing: { mapper, view },
    } = this.editor;

    await this.remove();

    view.change(async (writer) => {
      try {
        const viewPosition = mapper.toViewPosition(position);

        this.element = writer.createAttributeElement('span', {
          'class': 'inline-suggestion',
          'data-suggestion': text,
        });

        this.containerElement = this.applyPreviousNodeStyles(writer, viewPosition, this.element);
        writer.setAttribute('id', 'suggestion', this.containerElement);

        writer.insert(viewPosition, this.containerElement);

        this.domElement.getHtmlElement().then((htmlElement) => {
          if (htmlElement) {
            this.fire('dom:lifecycle', { type: 'insertion', element: htmlElement });
          }
        });
      } catch (error) {
        this.element = null;
        this.containerElement = null;
        console.warn('Could not insert suggestion element:', error);
      }
    });
  }

  updateText(text: string) {
    const {
      editing: { view },
    } = this.editor;

    view.change((writer) => {
      if (!this.element) {
        return;
      }

      writer.setAttribute('data-suggestion', text, this.element);
    });
  }
}

abstract class SuggestionCommand extends Command {
  protected state: SuggestionsState;
  protected element: SuggestionElement;

  constructor(editor: Editor, state: SuggestionsState, element: SuggestionElement) {
    super(editor);
    this.state = state;
    this.element = element;

    this.isEnabled = !!this.state.currentSuggestion;
    this.state.on('suggestions:change', this.handleStateChange);
  }

  protected registerKeystroke(keystroke: string): void {
    this.editor.keystrokes.set(
      keystroke,
      (_event, cancel) => {
        if (!this.isEnabled) return;

        this.execute();
        cancel();
      },
      { priority: 'highest' }
    );
  }

  protected handleStateChange = () => {
    this.isEnabled = !!this.state.currentSuggestion;
  };

  abstract get keystrokeInfo(): { label: string; keystroke: string };
}

class AcceptCommand extends SuggestionCommand {
  protected readonly keystroke = 'Ctrl+I';

  constructor(editor: Editor, state: SuggestionsState, element: SuggestionElement) {
    super(editor, state, element);

    this.registerKeystroke(this.keystroke);

    // Handle Insert key through DOM events
    editor.editing.view.document.on('keydown', this.handleDocumentKeyDown, { priority: 'highest' });
  }

  protected handleDocumentKeyDown = (evt: EventInfo<string, unknown>, data: any) => {
    if (data.domEvent.key === 'Insert' && this.isEnabled) {
      evt.stop();
      data.preventDefault();
      this.execute();
    }
  };

  override execute() {
    const { currentSuggestion } = this.state;
    const position = this.element.getPosition();

    if (currentSuggestion && position) {
      this.editor.model.change((writer) => {
        // Get attributes from the document selection at this position
        const attributes = Array.from(this.editor.model.document.selection.getAttributes());

        writer.insertText(currentSuggestion, attributes, position);
      });
    }
  }

  get keystrokeInfo() {
    const { t } = this.editor;

    return {
      label: t('Accept suggestion'),
      keystroke: this.keystroke,
    };
  }

  override destroy() {
    this.editor.editing.view.document.off('keydown', this.handleDocumentKeyDown);
    super.destroy();
  }
}

class AcceptWordCommand extends SuggestionCommand {
  protected readonly keystroke = 'Ctrl+Shift+ArrowRight';

  constructor(editor: Editor, state: SuggestionsState, element: SuggestionElement) {
    super(editor, state, element);

    this.registerKeystroke(this.keystroke);
  }

  override execute() {
    const { currentSuggestion } = this.state;
    const position = this.element.getPosition();

    if (currentSuggestion && position) {
      // Match leading whitespace + word
      const nextWordMatch = currentSuggestion.match(/^\s*[^\s]*/);

      if (!nextWordMatch || nextWordMatch.length === 0) return;

      this.editor.model.change((writer) => {
        const attributes = Array.from(this.editor.model.document.selection.getAttributes());
        writer.insertText(nextWordMatch[0], attributes, position);
      });
    }
  }

  get keystrokeInfo() {
    const { t } = this.editor;

    return {
      label: t('Accept next word'),
      keystroke: this.keystroke,
    };
  }
}

class RejectCommand extends SuggestionCommand {
  protected readonly keystroke = 'Esc';

  constructor(editor: Editor, state: SuggestionsState, element: SuggestionElement) {
    super(editor, state, element);

    this.registerKeystroke(this.keystroke);
  }

  override execute() {
    this.state.currentSuggestion = null;
  }

  get keystrokeInfo() {
    const { t } = this.editor;

    return {
      label: t('Reject suggestion'),
      keystroke: this.keystroke,
    };
  }
}

class NextSuggestionCommand extends SuggestionCommand {
  protected readonly keystroke = 'Ctrl+ArrowRight';

  constructor(editor: Editor, state: SuggestionsState, element: SuggestionElement) {
    super(editor, state, element);

    this.registerKeystroke(this.keystroke);
  }

  override execute() {
    this.state.next();
  }

  get keystrokeInfo() {
    const { t } = this.editor;

    return {
      label: t('Next suggestion'),
      keystroke: this.keystroke,
    };
  }
}

class PreviousSuggestionCommand extends SuggestionCommand {
  protected readonly keystroke = 'Ctrl+ArrowLeft';

  constructor(editor: Editor, state: SuggestionsState, element: SuggestionElement) {
    super(editor, state, element);

    this.registerKeystroke(this.keystroke);
  }

  override execute() {
    this.state.prev();
  }

  get keystrokeInfo() {
    const { t } = this.editor;

    return {
      label: t('Previous suggestion'),
      keystroke: this.keystroke,
    };
  }
}

class FetchApi {
  protected editor: Editor;
  protected state: SuggestionsState;
  protected element: SuggestionElement;
  protected fetcher: AiSuggestionsFetcher;
  protected debounceMs: number;
  protected timeout: ReturnType<typeof setTimeout> | null = null;
  protected abortController: AbortController | null = null;

  constructor(
    editor: Editor,
    state: SuggestionsState,
    element: SuggestionElement,
    { fetcher, debounceMs }: Omit<Configuration, 'defaultEnabled'>
  ) {
    this.editor = editor;
    this.state = state;
    this.element = element;
    this.fetcher = fetcher;
    this.debounceMs = debounceMs;
  }

  protected getFetchPayload() {
    const { editing } = this.editor;
    const domRoot = editing.view.getDomRoot();

    if (!domRoot) {
      return null;
    }

    try {
      const fullHtml = domRoot.innerHTML;
      const suggestionRegex = /<span[^>]*data-suggestion(?:="[^"]*")?[^>]*>/;
      const [htmlBefore, htmlAfter] = fullHtml.split(suggestionRegex);

      if (!htmlBefore) {
        return null;
      }

      // Get last 50 characters before the suggestion element for context
      const chunkToComplete = htmlBefore
        .slice(-50)
        .replace(/<[^>]*>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/\s{2,}/g, ' ');

      const payload = JSON.stringify({
        htmlBefore,
        htmlAfter: htmlAfter || '',
        chunkToComplete,
      });

      return payload;
    } catch (error) {
      console.warn('Error in getFetchPayload:', error);

      return null;
    }
  }

  protected cleanup = () => {
    this.fetcher.cancel();

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  };

  async fetch(options?: FetchOptions) {
    if (this.abortController) {
      this.abortController.abort();

      // Wait for any queued microtasks (including abort event) to complete
      await Promise.resolve();

      this.abortController.signal.removeEventListener('abort', this.cleanup);
    }

    const abortController = new AbortController();

    this.timeout = setTimeout(
      async () => {
        const payload = this.getFetchPayload();

        if (!payload) {
          return;
        }

        const suggestions = await this.fetcher(payload, options?.instructions);

        if (Array.isArray(suggestions)) {
          this.state.suggestions = suggestions;
        }
      },
      options?.immediate ? 0 : this.debounceMs
    );

    abortController.signal.addEventListener('abort', this.cleanup, { once: true });
    this.abortController = abortController;
  }

  cancel() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.fetcher.cancel();
  }

  destroy() {
    this.cancel();
  }
}

export default class InlineSuggestions extends Plugin {
  static get pluginName() {
    return 'InlineSuggestions';
  }

  override isEnabled = false;

  protected state: SuggestionsState;
  protected api: FetchApi | null = null;
  protected element: SuggestionElement;

  constructor(editor: Editor) {
    super(editor);
    this.state = new SuggestionsState();
    this.element = new SuggestionElement(editor);
  }

  protected isLineBreakAfterPosition(position: Position): boolean {
    const nextNode = position.nodeAfter;

    return nextNode instanceof Element && ['softBreak', 'horizontalLine'].includes(nextNode.name);
  }

  protected findInsertedText() {
    const {
      model,
      model: {
        document: { differ },
      },
    } = this.editor;

    if (!this.element.exists()) return null;

    const change = differ.getChanges().find((change) => {
      return change.type === 'insert' && change.name === '$text' && this.element.isAtPosition(change.position);
    }) as DiffItemInsert;

    if (!change) return null;

    const range = model.createRange(change.position, change.position.getShiftedBy(change.length));
    const items = Array.from(range.getItems());

    const isTextItem = (item: Item): item is Item & { data: string } => 'data' in item && typeof item.data === 'string';
    const insertedText = items
      .filter(isTextItem)
      .map((item) => item.data)
      .join('');

    return insertedText || null;
  }

  protected handleSelectionChange = () => {
    const {
      model: {
        document: { selection },
      },
    } = this.editor;
    const lastPosition = selection.getLastPosition();

    if (!lastPosition) {
      this.api?.cancel();
      this.state.currentSuggestion = null;
      return;
    }

    if (this.state.currentSuggestion) {
      const insertedText = this.findInsertedText();

      if (insertedText && this.state.currentSuggestion.startsWith(insertedText)) {
        const nextSuggestion = this.state.currentSuggestion.slice(insertedText.length);

        if (nextSuggestion.length === 0) {
          this.element.insertAt(lastPosition, '');
          this.api?.fetch();
        } else {
          this.api?.cancel();
        }

        this.state.currentSuggestion = nextSuggestion;
        return;
      }
    }

    if (this.state.currentSuggestion && this.element.isAtPosition(lastPosition)) {
      return;
    }

    const shouldShowSuggestion =
      selection.isCollapsed &&
      !lastPosition.isAtStart &&
      (lastPosition.isAtEnd || this.isLineBreakAfterPosition(lastPosition));

    if (shouldShowSuggestion) {
      this.element.insertAt(lastPosition, '');
      this.state.currentSuggestion = '';
      this.api?.fetch();
    } else {
      this.api?.cancel();
      this.state.currentSuggestion = null;
    }
  };

  protected handleStateChange = () => {
    const { currentSuggestion } = this.state;

    if (currentSuggestion === null) {
      this.element.remove();
    } else {
      this.element.updateText(currentSuggestion);
    }

    this.fire('suggestions:stateChange', this.getState());
  };

  protected handleElementLifecycle = (_evt: EventInfo<'dom:lifecycle'>, data: ElementLifecycleData) => {
    this.fire('suggestions:domLifecycle', data);
  };

  init() {
    const { config, commands, accessibility, t } = this.editor;
    const {
      fetcher,
      debounceMs = 500,
      defaultEnabled = false,
    } = (config.get('inlineSuggestions') || {}) as Configuration;

    if (!fetcher) {
      return;
    }

    this.api = new FetchApi(this.editor, this.state, this.element, { fetcher, debounceMs });

    const acceptCommand = new AcceptCommand(this.editor, this.state, this.element);
    const acceptWordCommand = new AcceptWordCommand(this.editor, this.state, this.element);
    const rejectCommand = new RejectCommand(this.editor, this.state, this.element);
    const nextSuggestionCommand = new NextSuggestionCommand(this.editor, this.state, this.element);
    const previousSuggestionCommand = new PreviousSuggestionCommand(this.editor, this.state, this.element);

    commands.add('suggestions:accept', acceptCommand);
    commands.add('suggestions:acceptNextWord', acceptWordCommand);
    commands.add('suggestions:reject', rejectCommand);
    commands.add('suggestions:next', nextSuggestionCommand);
    commands.add('suggestions:prev', previousSuggestionCommand);

    accessibility.addKeystrokeInfoGroup({
      id: 'inline-suggestions',
      label: t('Keystrokes that can be used when inline suggestion is present'),
      keystrokes: [
        acceptCommand.keystrokeInfo,
        acceptWordCommand.keystrokeInfo,
        rejectCommand.keystrokeInfo,
        nextSuggestionCommand.keystrokeInfo,
        previousSuggestionCommand.keystrokeInfo,
      ],
    });

    if (defaultEnabled) {
      this.enable();
    }
  }

  enable() {
    if (this.isEnabled) return;

    const {
      model: {
        document: { selection },
      },
      editing: { view },
    } = this.editor;

    this.isEnabled = true;

    selection.on('change:range', this.handleSelectionChange);
    view.document.on('focus', this.handleSelectionChange);
    this.state.on('suggestions:change', this.handleStateChange);
    this.element.on('dom:lifecycle', this.handleElementLifecycle as any);
  }

  disable() {
    if (!this.isEnabled) return;

    const {
      model: {
        document: { selection },
      },
      editing: { view },
    } = this.editor;

    this.isEnabled = false;
    selection.off('change:range', this.handleSelectionChange);
    view.document.off('focus', this.handleSelectionChange);
    this.state.off('suggestions:change', this.handleStateChange);
    this.element.on('dom:lifecycle', this.handleElementLifecycle as any);

    this.api?.destroy();
    this.state.destroy();
    this.element.remove();
  }

  override destroy() {
    this.disable();
    super.destroy();
  }

  getState(): SerializedSuggestionsState {
    const { currentSuggestionIndex, suggestions, currentSuggestion } = this.state;

    return {
      suggestions,
      currentSuggestion,
      hasPrevSuggestion: currentSuggestionIndex > 0,
      hasNextSuggestion: currentSuggestionIndex < suggestions.length - 1,
      isNavigationEnabled: suggestions.length > 1,
    };
  }

  cancelPendingFetch() {
    this.api?.cancel();
  }

  refetch(options?: FetchOptions) {
    if (!this.element.exists()) return;

    return this.api?.fetch(options);
  }
}
