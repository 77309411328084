import { createSlice } from "@reduxjs/toolkit";
import {
  getSwotArgumentsList,
  createSwotArgument,
  updateSwotArgument,
  deleteSwotArgument
} from "./swotArgumentsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  swotArgumentsList: any[];
  createSwotArgumentsRes: any;
  updateSwotArgumentsRes: any;
  swotArgumentDeleteRes: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  swotArgumentsList: [],
  createSwotArgumentsRes: null,
  updateSwotArgumentsRes: null,
  swotArgumentDeleteRes: null
};
const swotArgumentsSlice = createSlice({
  name: "swotArguments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get SWOT Arguments
    builder.addCase(getSwotArgumentsList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getSwotArgumentsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.swotArgumentsList = action.payload?.data;
    });
    builder.addCase(getSwotArgumentsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create SWOT Arguments
    builder.addCase(createSwotArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createSwotArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createSwotArgumentsRes = action.payload?.data;
    });
    builder.addCase(createSwotArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update SWOT Arguments
    builder.addCase(updateSwotArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateSwotArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateSwotArgumentsRes = action.payload?.data;
    });
    builder.addCase(updateSwotArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete SWOT Arguments
    builder.addCase(deleteSwotArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteSwotArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.swotArgumentDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteSwotArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default swotArgumentsSlice.reducer;
