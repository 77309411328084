import { createSlice } from "@reduxjs/toolkit";
import {
  getProjectsList,
  getProjectById,
  createProject,
  updateProject,
  getProjectAllDocuments,
  getProjectDocument,
  updateProjectDocument,
  createProjectDocument,
  deleteProject,
  deleteProjectDocument,
} from "./projectsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  projectsList: any[];
  createProjectsRes: any;
  updateProjectsRes: any;
  projectsByIdRes: any;
  projectDocumentRes: any;
  updateProjectDocumentRes: any;
  createProjectDocumentRes: any;
  projectDeleteRes: any;
  loadingProjects: boolean;
  getProjectAllDocumentsRes: any;
  deleteProjectDocumentRes: any;
  selectedDocument: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  projectsList: [],
  createProjectsRes: null,
  updateProjectsRes: null,
  projectsByIdRes: null,
  projectDocumentRes: null,
  updateProjectDocumentRes: null,
  createProjectDocumentRes: null,
  projectDeleteRes: null,
  loadingProjects: false,
  getProjectAllDocumentsRes: null,
  deleteProjectDocumentRes: null,
  selectedDocument: null,
};
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setSelectedDocument: (state, action) => {
      state.selectedDocument = action.payload;
    },
    setChangedDocument: (state, action) => {
      const previousData = [...state.getProjectAllDocumentsRes || []];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === state.selectedDocument?.id
      );
      if (previousIndex !== -1) {
        previousData[previousIndex].content = action.payload;
        state.getProjectAllDocumentsRes = previousData;
      }
    },
  },

  extraReducers: (builder) => {
    // Get Projects List
    builder.addCase(getProjectsList.pending, (state) => {
      state.error = null;
      state.message = null;
      state.loadingProjects = true;
    });
    builder.addCase(getProjectsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.projectsList = action.payload?.data;
      state.loadingProjects = false;
    });
    builder.addCase(getProjectsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loadingProjects = false;
    });

    // Get Project By Id
    builder.addCase(getProjectById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getProjectById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.projectsByIdRes = action.payload?.data;
    });
    builder.addCase(getProjectById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Project
    builder.addCase(createProject.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createProject.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createProjectsRes = action.payload?.data;
    });
    builder.addCase(createProject.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Project
    builder.addCase(updateProject.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateProject.fulfilled, (state, action) => {
      state.error = null;

      state.projectsByIdRes["autosave"] = action.payload?.data?.autosave;
      
      state.message = action.payload?.message;
      state.updateProjectsRes = action.payload?.data;
    });
    builder.addCase(updateProject.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Project All Documents List
    builder.addCase(getProjectAllDocuments.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getProjectAllDocuments.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getProjectAllDocumentsRes = action.payload?.data || [];
    });
    builder.addCase(getProjectAllDocuments.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Project Document By Id
    builder.addCase(getProjectDocument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getProjectDocument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.projectDocumentRes = action.payload?.data;
    });
    builder.addCase(getProjectDocument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Project Document
    builder.addCase(createProjectDocument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createProjectDocument.fulfilled, (state, action) => {
      const previousData = [...(state.getProjectAllDocumentsRes || [])];
      state.getProjectAllDocumentsRes = [...previousData, action.payload?.data];
      state.selectedDocument = action.payload?.data;

      state.error = null;
      state.message = action.payload?.message;
      state.createProjectDocumentRes = action.payload?.data;
    });
    builder.addCase(createProjectDocument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Project Document
    builder.addCase(updateProjectDocument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateProjectDocument.fulfilled, (state, action) => {
      const previousData = [...(state.getProjectAllDocumentsRes || [])];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );

      if (previousIndex !== -1) {
        previousData[previousIndex] = {
          ...(action.payload.data || {}),
          content: previousData[previousIndex].content,
        };
        state.getProjectAllDocumentsRes = previousData;
      }

      state.error = null;
      state.message = action.payload?.message;
      state.updateProjectDocumentRes = action.payload.data;
    });
    builder.addCase(updateProjectDocument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Project Document
    builder.addCase(deleteProjectDocument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteProjectDocument.fulfilled, (state, action) => {
      const filterData = state.getProjectAllDocumentsRes?.filter(
        (item: { id: string }) => item?.id !== action.payload?.data?.id
      );
      state.getProjectAllDocumentsRes = filterData;

      state.error = null;
      state.message = action.payload?.message;
      state.deleteProjectDocumentRes = action.payload?.data;
    });
    builder.addCase(deleteProjectDocument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Project
    builder.addCase(deleteProject.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteProject.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.projectDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteProject.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedDocument, setChangedDocument } =
  projectsSlice.actions;
export default projectsSlice.reducer;
