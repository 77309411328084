import React from "react";
import {
  PencilSquareIcon,
  TrashIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/20/solid";
import WrapperLoader from "src/components/wrapperLoader";
import ItemWithDropdown from "src/components/itemWithDropdown";

interface DocumnetBoxProps {
  totalLength: number;
  data: any;
  onEdit: () => void;
  onDelete: () => void;
  deleteLoader: boolean;
  selectedData: any;
  onSelectTChart: () => void;
  selectedTChart: any;
}

export default function DocumnetBox({
  totalLength,
  data,
  onEdit,
  onDelete,
  deleteLoader,
  selectedData,
  onSelectTChart,
  selectedTChart,
}: DocumnetBoxProps) {
  const actionsList = [
    {
      id: 1,
      name: "Edit",
      onActionClick: onEdit,
      icon: (
        <PencilSquareIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 2,
      name: "Delete",
      onActionClick: onDelete,
      icon: (
        <TrashIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
  ];

  return (
    <li key={data?.id} className="mb-[8px] transition-all duration-500 ease-in-out">
      <div
        className={`rounded-md shadow-sm border ${
          selectedTChart?.id === data?.id
            ? "border-blue-400 bg-blue-50"
            : "border-gray-200 bg-white"
        } hover:border-blue-400 hover:bg-blue-50`}
        onClick={() => {
          onSelectTChart();
        }}
      >
        <WrapperLoader loading={selectedData?.id === data?.id && deleteLoader}>
          <div className={`flex items-center`}>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md cursor-pointer">
              <div className="flex-1 truncate px-4 py-2 text-xs">
                <div className="flex items-center">
                  <span className="font-medium text-xs text-gray-700 hover:text-customLightBlue">
                    {data?.details?.TBarTitle}
                  </span>
                </div>
              </div>
              <ItemWithDropdown actionsData={actionsList}>
                <EllipsisVerticalIcon
                  className="w-8 p-[8px]"
                  aria-hidden="true"
                />
              </ItemWithDropdown>
            </div>
          </div>
        </WrapperLoader>
      </div>
    </li>
  );
}
