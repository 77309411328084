import { ReactNode, ReactElement, ComponentProps } from 'react';
import { classNames } from 'src/utils/utilities';
import { GradientProgressLoader } from '../loader';

export type ColorVariant = 'gray' | 'halfBlue' | 'blue' | 'green' | 'orange' | 'red';

export type ConfigurationItemProps = Readonly<{
  title: string;
  value: number | string;
  description?: string;
  variant?: ColorVariant;
  className?: string;
  loading?: boolean;
}>;

export type ConfigurationGridProps = Readonly<{
  title: string;
  children: ReactNode;
  className?: string;
  isModal?: boolean;
}>;

type ConfigurationItemFullProps = ConfigurationItemProps & Omit<ComponentProps<'li'>, keyof ConfigurationItemProps>;
type ConfigurationGridFullProps = ConfigurationGridProps & Omit<ComponentProps<'div'>, keyof ConfigurationGridProps>;

export const colorClasses = {
  gray: 'bg-[#F7F7F8] text-[#14151A]',
  halfBlue: 'bg-[#F0F4FE] text-[#14151A]',
  blue: 'bg-[#F0F4FE] text-[#4778F5]',
  green: 'bg-[#EDFDF4] text-[#26BD6C]',
  orange: 'bg-[#FEF4EC] text-[#F48E2F]',
  red: 'bg-[#FCE5E4] text-[#E6483D]',
};

function ConfigurationItem({
  title,
  value,
  description,
  variant = 'gray',
  className,
  children,
  loading = false,
  ...rest
}: ConfigurationItemFullProps): ReactElement {
  return (
    <li
      className={classNames('col-span-1 flex rounded-md shadow-sm border border-[#DEE0E3]', className)}
      {...rest}
    >
      <div
        className={classNames(
          'w-16 flex-shrink-0 rounded-l-md border-r border-[#DEE0E3]',
          'flex items-center justify-center text-sm font-medium',
          colorClasses[variant]
        )}
      >
        {value}
      </div>
      <div
        className={classNames(
          'relative flex flex-1 items-center justify-between rounded-r-md bg-white'
        )}
      >
        <div className='flex-1 px-4 py-2 text-sm'>
          <div className='flex justify-between items-center'>
            <p className={classNames('font-medium text-gray-900 break-words break-all flex-grow')}>
              {title}
            </p>
            {children && <div className='ml-2 flex-shrink-0'>{children}</div>}
          </div>
          {description && (
            <p className={classNames('text-gray-500 break-words break-all w-full mt-2')}>
              {description}
            </p>
          )}
        </div>
        {loading && <GradientProgressLoader className='absolute bottom-0 left-0 right-0 translate-y-1/2' />}
      </div>
    </li>
  );
}

function ConfigurationGrid({ title, children, className, isModal, ...rest }: ConfigurationGridFullProps): ReactElement {
  return (
    <div
      className={className}
      {...rest}
    >
      <h2 className='text-gray-500 mb-5 text-lg'>{title}</h2>
      <ul className={`mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 ${isModal ? '2xl:grid-cols-2' : '2xl:grid-cols-3'}`}>
        {children}
      </ul>
    </div>
  );
}

export default Object.assign(ConfigurationGrid, { Item: ConfigurationItem });
