import {
  ArrowTopRightOnSquareIcon,
  TrashIcon,
  PaintBrushIcon,
  ChevronDoubleDownIcon,
  ChevronUpIcon,
  RectangleStackIcon,
  ChevronDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";
import {
  Handle,
  NodeResizer,
  NodeToolbar,
  Position,
  useReactFlow,
  useStore,
} from "@xyflow/react";
import { memo, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { Tooltip } from "react-tooltip";
import debounce from "lodash/debounce";
import { getRelativeNodesBounds } from "./utils";
import useDetachNodes from "./useDetachNodes";
import Popover from "../../../components/popover";
import ColorToolbar from "./shapeNode/colorToolbar";

const lineStyle = { borderColor: "white" };

function TextNode({ id, data, width }: any) {
  const { border_color, border_style } = data;
  const [nodeText, setNodeText] = useState("");

  const hasParent = useStore((store) => !!store.nodeLookup.get(id)?.parentId);
  const parentId = useStore((store) => store.nodeLookup.get(id)?.parentId);
  const { deleteElements, setNodes, getNodes } = useReactFlow();
  const detachNodes = useDetachNodes();

  const onDelete = () => deleteElements({ nodes: [{ id }] });
  const onDetach = () => detachNodes([id]);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const nodesList = getNodes();

  const { minWidth, minHeight } = useStore((store) => {
    const childNodes = Array.from(store.nodeLookup.values()).filter(
      (n) => n.parentId === id
    );
    const rect = getRelativeNodesBounds(childNodes);

    return {
      minWidth: rect.x + rect.width,
      minHeight: rect.y + rect.height,
      hasChildNodes: childNodes.length > 0,
    };
  }, isEqual);

  const fontSize = useMemo(() => {
    const baseSize = 14; // Default font size
    return Math.max(baseSize, Math.min(width) / 16); // Adjust font size
  }, [width]);

  const handleResize = useMemo(
    () =>
      debounce(() => {
        // Any resize logic that might cause the ResizeObserver warning can be placed here.
      }, 100),
    []
  );

  // Get Complete Node Object
  const nodeObject = useMemo(
    () => nodesList.find((n) => n.id === id),
    [nodesList, id]
  );

  // Get Parent Object
  const parentObject = useMemo(
    () => nodesList.find((n) => n.id === parentId),
    [nodesList, parentId]
  );

  useEffect(() => {
    const element = document.getElementById(id);
    if (!element) return;

    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect();
    }

    resizeObserverRef.current = new ResizeObserver(() => {
      handleResize();
    });

    resizeObserverRef.current.observe(element);

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [id, handleResize]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [nodeText]);

  useEffect(() => {
    if (parentObject?.style?.zIndex) {
      setNodes((nds) => {
        // Find the maximum zIndex of nodes with the same parent
        const maxSiblingZIndex = nds
          .filter((n) => n.parentId === parentId)
          .reduce((max, n) => Math.max(max, Number(n.style?.zIndex || 0)), 0);

        return nds.map((n) =>
          n.id === id
            ? {
                ...n,
                style: {
                  ...n.style,
                  zIndex: maxSiblingZIndex + 1, // Set zIndex to max sibling zIndex + 1
                },
              }
            : n
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (event.shiftKey) {
        return; // Allow new line
      } else {
        onUpdateNodes();
        event.preventDefault(); // Prevent default behavior of Enter key
      }
    }
  };

  const onUpdateNodes = useMemo(
    () =>
      debounce(() => {
        setNodes((nodes) =>
          nodes.map((node) =>
            node.id === id
              ? {
                  ...node,
                  data: { ...node.data, label: nodeText || data?.label },
                }
              : node
          )
        );

        setNodeText("");
      }, 300),
    [id, nodeText, data?.label, setNodes]
  );

  const onChangeNodeText = (e: any) => {
    const value = e.target.value;
    setNodeText(value);
  };

  const applyBorderStyle = (style: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              border_style: style,
            },
          };
        }

        return node;
      })
    );
  };

  const findMaxZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.max(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  const findMinZIndex = useCallback((nodesListArray: any[]) => {
    return nodesListArray.length > 0
      ? Math.min(
          ...nodesListArray
            .map((n) => n.style?.zIndex)
            .filter((z): z is number => z !== undefined)
        )
      : 0;
  }, []);

  function findClosestBiggerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const biggestZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex > selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return biggestZIndices.length > 0 ? Math.min(...biggestZIndices) : 1300;
  }

  const bringNodeToFront = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        // if (index === -1 || (absolute && index === nds.length - 1)) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const maxZIndex =
          newNodes.length > 0
            ? Math.max(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const greaterThenSelectedNode = findClosestBiggerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        if (absolute) {
          node.style = {
            ...node.style,
            zIndex: maxZIndex + 1000,
          };
        } else {
          node.style = {
            ...node.style,
            zIndex: greaterThenSelectedNode + 300,
          };
        }

        return newNodes;
      });
      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  function findClosestSmallerZIndex(
    selectedZIndex: number,
    nodesListArray: any[]
  ) {
    // Filter the array for zIndex values smaller than the selected one
    const smallerZIndices = nodesListArray
      .map((node) => node?.style?.zIndex)
      .filter((zIndex) => zIndex < selectedZIndex);

    // Return the maximum of the smaller zIndex values or null if none exists
    return smallerZIndices.length > 0 ? Math.max(...smallerZIndices) : 1300;
  }

  const sendNodeToBack = useCallback(
    (nodeId: string, absolute = false) => {
      setNodes((nds) => {
        const index = nds.findIndex((n) => n.id === nodeId);
        if (index === -1) return nds;

        const newNodes = [...nds];
        const node = newNodes[index];

        const minZIndex =
          newNodes.length > 0
            ? Math.min(
                ...newNodes
                  .map((n) => n.style?.zIndex)
                  .filter((z): z is number => z !== undefined)
              )
            : 0;

        const lessThenSelectedNode = findClosestSmallerZIndex(
          Number(node?.style?.zIndex),
          newNodes
        );

        let newZIndex = absolute
          ? minZIndex - 300
          : lessThenSelectedNode - 300;

        // Ensure the node's zIndex does not go below its parent's zIndex
        const parentNode = newNodes.find((n) => n.id === node.parentId);
        if (parentNode && newZIndex < Number(parentNode.style?.zIndex || 0)) {
          newZIndex = Number(parentNode.style?.zIndex || 0) + 1;
        }

        node.style = {
          ...node.style,
          zIndex: newZIndex,
        };

        return newNodes;
      });

      handleNodeMouseEnter();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNodes]
  );

  const handleNodeMouseEnter = useCallback(() => {
    // Clear selection of all nodes except the one being dragged
    setNodes((nds) =>
      nds.map((n) => ({
        ...n,
        selected: false,
      }))
    );
  }, [setNodes]);

  const onChangeBorderColor = (color: string) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: {
              ...node.data,
              border_color: color,
            },
          };
        }

        return node;
      })
    );
  };

  const iconStyle = "h-[20px] text-[#6f717c]";

  const iconWrapper =
    "cursor-pointer p-[10px] rounded-[5px] hover:bg-[#f3f4f5]";

  const borderStyles = ["solid", "dashed", "dotted"];

  return (
    <div
      onMouseLeave={() => onUpdateNodes()}
      className="relative px-4 py-2 h-[100%] bg-transparent border-2 rounded-[11px]"
      style={{ borderColor: border_color, borderStyle: border_style }}
    >
      <div className="text-center">
        <textarea
          ref={textareaRef}
          autoFocus
          value={nodeText || data?.label}
          onChange={onChangeNodeText}
          onKeyDown={handleKeyDown}
          className="p-2 bg-transparent border-0 text-zinc-900 text-xl font-semibold text-center resize-none h-full w-full"
          style={{
            boxShadow: "none",
            height: "100%_!important",
            fontSize: `${fontSize}px`,
          }}
          draggable={false}
        />
      </div>

      <Handle
        type="target"
        className="z-50"
        position={Position.Top}
        id="top-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Bottom}
        id="bottom-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Left}
        id="left-target"
      />
      <Handle
        type="target"
        className="z-50"
        position={Position.Right}
        id="right-target"
      />

      <Handle
        type="source"
        className="z-50"
        position={Position.Top}
        id="top-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Bottom}
        id="bottom-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Left}
        id="left-source"
      />
      <Handle
        type="source"
        className="z-50"
        position={Position.Right}
        id="right-source"
      />

      <NodeResizer
        lineStyle={lineStyle}
        minHeight={minHeight}
        minWidth={minWidth}
        isVisible={true}
        handleStyle={{
          width: "12px",
          height: "12px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          border: 0,
        }}
      />

      <NodeToolbar
        style={{
          borderRadius: 7,
          boxShadow: "0 0 15px 5px rgba(0, 0, 0, 0.1)",
        }}
        className="nodrag nowheel absolute p-[4px] top-0 left-0 bg-white border border-[#e0e0e0] flex items-center justify-center"
      >
        <Popover
          buttonContent={
            <div className={`${iconWrapper}`}>
              <PaintBrushIcon
                className={`${iconStyle} hover:text-green-600 h-[18px]`}
              />
            </div>
          }
          popoverContent={
            <div className="p-4">
              <p className="text-xs font-semibold text-zinc-500">
                Border Style:
              </p>
              <ul className="flex flex-row gap-2 my-3">
                {borderStyles.map((style, index) => (
                  <li key={index} onClick={() => applyBorderStyle(style)}>
                    <div
                      className={`border-2 border-zinc-800 w-[50px] h-[25px] p-2 cursor-pointer transition-all hover:scale-110 ${style === border_style ? "scale-110 shadow-lg" : ""}`}
                      style={{ borderStyle: style }}
                    ></div>
                  </li>
                ))}
              </ul>
              <p className="text-xs font-semibold text-zinc-500 mb-2">
                Border Color:
              </p>
              <div className="flex flex-wrap">
                <ColorToolbar
                  onColorChange={onChangeBorderColor}
                  activeColor={border_color}
                />
              </div>
            </div>
          }
          classes="w-[400px] bottom-[29px] left-[-170px]"
        />

        {/* {nodesList?.length > 1 && (
          <div className="p-[4px]">
            <Popover
              buttonContent={
                <div
                  className={`${iconWrapper}`}
                  data-tooltip-id="move-up-down-tooltip"
                >
                  <RectangleStackIcon className={`${iconStyle}`} />
                </div>
              }
              popoverContent={
                <div className="flex items-center justify-center gap-2">
                  {Number(nodeObject?.style?.zIndex) <
                    findMaxZIndex(nodesList) && (
                    <>
                      <div
                        className={`${iconWrapper} order-1`}
                        onClick={() => bringNodeToFront(id)}
                        data-tooltip-id="move-up-node-tooltip"
                      >
                        <ChevronUpIcon className={`${iconStyle}`} />
                      </div>

                      {!hasParent && (
                        <div
                          className={`${iconWrapper} order-3`}
                          onClick={() => bringNodeToFront(id, true)}
                          data-tooltip-id="move-absolute-top-node-tooltip"
                        >
                          <ChevronDoubleUpIcon className={`${iconStyle}`} />
                        </div>
                      )}
                    </>
                  )}

                  {Number(nodeObject?.style?.zIndex) >
                    findMinZIndex(nodesList) && (
                    <>
                      <div
                        className={`${iconWrapper} order-2`}
                        onClick={() => sendNodeToBack(id)}
                        data-tooltip-id="move-down-node-tooltip"
                      >
                        <ChevronDownIcon className={`${iconStyle}`} />
                      </div>

                      {!hasParent && (
                        <div
                          className={`${iconWrapper} order-4`}
                          onClick={() => sendNodeToBack(id, true)}
                          data-tooltip-id="move-absolute-bottom-node-tooltip"
                        >
                          <ChevronDoubleDownIcon className={`${iconStyle}`} />
                        </div>
                      )}
                    </>
                  )}
                </div>
              }
              classes={`w-auto bottom-[40px] 
                ${
                  Number(nodeObject?.style?.zIndex) <
                    findMaxZIndex(nodesList) ||
                  Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList)
                    ? "left-[-45px]"
                    : "left-[-90px]"
                }`}
            />
          </div>
        )} */}

        {nodesList?.length > 1 && (
          <div className="flex items-center justify-center gap-2">
            {Number(nodeObject?.style?.zIndex) < findMaxZIndex(nodesList) && (
              <>
                {/* <div
                className={`${iconWrapper} order-1`}
                onClick={() => bringNodeToFront(id)}
                data-tooltip-id="move-up-node-tooltip"
              >
                <ChevronUpIcon className={`${iconStyle}`} />
              </div> */}

                <div
                  className={`${iconWrapper} order-3`}
                  onClick={() => bringNodeToFront(id, true)}
                  data-tooltip-id="move-absolute-top-node-tooltip"
                >
                  <ChevronDoubleUpIcon className={`${iconStyle}`} />
                </div>
              </>
            )}

            {Number(nodeObject?.style?.zIndex) > findMinZIndex(nodesList) && (
              <>
                {/* <div
                className={`${iconWrapper} order-2`}
                onClick={() => sendNodeToBack(id)}
                data-tooltip-id="move-down-node-tooltip"
              >
                <ChevronDownIcon className={`${iconStyle}`} />
              </div> */}

                <div
                  className={`${iconWrapper} order-4`}
                  onClick={() => sendNodeToBack(id, true)}
                  data-tooltip-id="move-absolute-bottom-node-tooltip"
                >
                  <ChevronDoubleDownIcon className={`${iconStyle}`} />
                </div>
              </>
            )}
          </div>
        )}

        <div className={`${iconWrapper}`} data-tooltip-id="delete-node-tooltip">
          <TrashIcon onClick={onDelete} className={`${iconStyle}`} />
        </div>
        {hasParent && (
          <div
            className={`${iconWrapper}`}
            data-tooltip-id="detach-node-tooltip"
          >
            <ArrowTopRightOnSquareIcon
              onClick={onDetach}
              className={`${iconStyle}`}
            />
          </div>
        )}
      </NodeToolbar>

      <Tooltip className="z-[50]" id="delete-node-tooltip" place="top">
        Delete
      </Tooltip>

      <Tooltip className="z-[50]" id="detach-node-tooltip" place="top">
        Detach
      </Tooltip>
    </div>
  );
}

type IsEqualCompareObj = {
  minWidth: number;
  minHeight: number;
  hasChildNodes: boolean;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
  return (
    prev.minWidth === next.minWidth &&
    prev.minHeight === next.minHeight &&
    prev.hasChildNodes === next.hasChildNodes
  );
}

export default memo(TextNode);
