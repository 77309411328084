import React, { memo } from "react";
import { colors } from "../../utils";

type ColorToolbarProps = {
  activeColor: string;
  onColorChange?: (color: string) => void;
};

function ColorToolbar({
  onColorChange = () => false,
  activeColor,
}: ColorToolbarProps) {
  return (
    <>
      {colors.map((color) => (
        <span
          key={color}
          style={{ backgroundColor: color }}
          onClick={() => onColorChange(color)}
          className={`w-6 h-6 rounded-sm m-1 p-0 cursor-pointer transition-all hover:scale-125 ${
            color === activeColor ? `border-4 border-white outline outline-zinc-400` : ""
          }`}
        />
      ))}
    </>
  );
}

export default memo(ColorToolbar);
