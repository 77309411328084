import { useState, useEffect, useCallback, SetStateAction } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Analysis from './Analysis';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getProsConsById } from '../../redux/prosCons/prosConsApi';
import { getLastIdFromUrl, parseJSONFromAi } from '../../utils/utilities';
import WrapperLoader from '../../components/wrapperLoader';
import { ProsConsToolbar } from './Toolbar';
import {
  createProsConsArgument,
  deleteProsConsArgument,
  getProsConsArgumentsList,
  updateProsConsArgument,
} from 'src/redux/prosConsArguments/prosConsArgumentsApi';
import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';
import { nanoid } from '@reduxjs/toolkit';
import * as yup from 'yup';

const AiReplySchema = (() => {
  const argumentSchema = yup.object().shape({
    argument: yup.string().required(),
    argument_weight: yup.number().oneOf([1, 2, 3]).required(),
    explanation: yup.string().required(),
  });

  return yup.object().shape({
    pros: yup.array().required().of(argumentSchema),
    cons: yup.array().required().of(argumentSchema),
  });
})();

export default function ProsConsDetails() {
  const [prosConsByIdLoader, setProsConsByIdLoader] = useState(false);
  const [prosList, setProsList] = useState<any[] | null>(null);
  const [consList, setConsList] = useState<any[] | null>(null);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { prosConsByIdRes, selectedProsCons } = useAppSelector((state) => state.prosCons);

  const areProsConsReady = !!(!prosConsByIdLoader && prosConsByIdRes && prosList && consList);

  useEffect(() => {
    if (!selectedProsCons?.id) return;

    const fetchData = async () => {
      setProsConsByIdLoader(true);

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          getProsConsById({
            accessToken,
            id: selectedProsCons?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error getting access token:', error);
      } finally {
        setProsConsByIdLoader(false);
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently, selectedProsCons?.id]);

  useEffect(() => {
    if (!selectedProsCons?.id) return;

    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          getProsConsArgumentsList({
            accessToken,
            pnc_id: selectedProsCons?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;
        const data = res?.payload?.data;

        if (requestStatus !== 'fulfilled') {
          throw new Error('Something went wrong');
        }

        const argumentsList = (Array.isArray(data) ? data : []) as any[];

        const { prosList, consList } = argumentsList.reduce<{ prosList: any[]; consList: any[] }>(
          (acc, argument) => {
            if (argument.side === 'pro') {
              acc.prosList.push({ ...argument, source: 'user' });
            } else if (argument.side === 'con') {
              acc.consList.push({ ...argument, source: 'user' });
            }

            return acc;
          },
          { prosList: [], consList: [] }
        );

        setProsList(prosList);
        setConsList(consList);
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  }, [dispatch, getAccessTokenSilently, selectedProsCons?.id]);

  const setArgumentsList = useCallback((side: 'pro' | 'con', callback: SetStateAction<any[] | null>) => {
    if (side === 'pro') {
      setProsList(callback);
    }

    if (side === 'con') {
      setConsList(callback);
    }
  }, []);

  const updateArgument = useCallback(
    async (body: any, selectedArgument: any, side: 'pro' | 'con') => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          updateProsConsArgument({
            body,
            accessToken,
            pnc_id: prosConsByIdRes?.id,
            argument_id: selectedArgument?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus !== 'fulfilled') {
          throw new Error('Request rejected');
        }

        const data = res?.payload?.data?.pnc_argument;

        if (!data) {
          throw new Error('No data');
        }

        setArgumentsList(side, (current) => {
          if (!current) return null;

          const index = current.findIndex((item: any) => item.id === data.id);

          if (index === -1) {
            return current;
          }

          const updatedList = [...current];
          updatedList.splice(index, 1, data);

          return updatedList;
        });
      } catch (error) {
        console.error('Error updating argument:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, prosConsByIdRes?.id, setArgumentsList]
  );

  const createArgument = useCallback(
    async (argument: any, side: 'pro' | 'con') => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const { source = 'user', id: argumentId, ...body } = argument;

        const res = await dispatch(
          createProsConsArgument({
            body,
            accessToken,
            pnc_id: prosConsByIdRes?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus !== 'fulfilled') {
          throw new Error('Request rejected');
        }

        const data = res?.payload?.data?.details;

        if (!data) {
          throw new Error('No data');
        }

        setArgumentsList(side, (current) => {
          if (source === 'user') {
            return [...(current || []), { ...data, source: 'user' }];
          }

          const index = current?.findIndex((item: any) => item.id === argumentId) ?? -1;

          if (index === -1) {
            return current;
          }

          const updatedList = [...(current || [])];
          updatedList.splice(index, 1, { ...data, source: 'user' });

          return updatedList;
        });
      } catch (error) {
        console.error('Error creating argument:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, prosConsByIdRes?.id, setArgumentsList]
  );

  const deleteArgument = useCallback(
    async (selectedArgument: any, side: 'pro' | 'con') => {
      if (selectedArgument.source === 'ai') {
        return setArgumentsList(
          side,
          (current) => current?.filter((item: any) => item.id !== selectedArgument.id) || null
        );
      }

      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          deleteProsConsArgument({
            accessToken,
            argument_id: selectedArgument?.id,
            pnc_id: prosConsByIdRes?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        const requestStatus = res?.meta?.requestStatus;

        if (requestStatus === 'rejected') {
          throw new Error('Request rejected');
        }

        const argumentId = res?.payload?.data?.id;

        if (!argumentId) {
          throw new Error('No argument id');
        }

        setArgumentsList(side, (current) => current?.filter((item: any) => item.id !== argumentId) || null);
      } catch (error) {
        console.error('Error deleting argument:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, prosConsByIdRes?.id, setArgumentsList]
  );

  const generateSuggestions = useCallback(async () => {
    if (!areProsConsReady) return;

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const res = await dispatch(
        promptMessage({
          body: {
            prompt: JSON.stringify({
              title: prosConsByIdRes?.title || '',
              category: prosConsByIdRes?.category || '',
              description: prosConsByIdRes?.pnc_description || '',
              pros: prosList?.map((arg: any) => arg?.argument) ?? [],
              cons: consList?.map((arg: any) => arg?.argument) ?? [],
            }),
          },
          accessToken,
          type: 'pnc-generator',
        })
      );

      const requestStatus = res?.meta?.requestStatus;
      const reply = res?.payload?.data?.message;

      if (requestStatus !== 'fulfilled') {
        throw new Error('Request rejected');
      }

      const replyData = parseJSONFromAi(reply);

      const { pros: aiProsList, cons: aiConsList } = await AiReplySchema.validate(replyData);

      setProsList((current) => [
        ...(current || []),
        ...aiProsList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
      setConsList((current) => [
        ...(current || []),
        ...aiConsList.map((argument: any) => ({ ...argument, id: nanoid(), source: 'ai' })),
      ]);
    } catch (error) {
      console.error('Error generating arguments suggestions:', error);

      throw error;
    }
  }, [
    areProsConsReady,
    consList,
    dispatch,
    getAccessTokenSilently,
    prosConsByIdRes,
    prosList,
  ]);

  return (
    <WrapperLoader
      loading={prosConsByIdLoader}
      className='bg-customGray'
    >
      <ProsConsToolbar
        areProsConsReady={areProsConsReady}
        generateSuggestions={generateSuggestions}
      />
      <div className='p-6 pb-0'>
        <div>
          <Analysis
            prosList={prosList}
            consList={consList}
            updateArgument={updateArgument}
            createArgument={createArgument}
            deleteArgument={deleteArgument}
          />
        </div>
      </div>
    </WrapperLoader>
  );
}
