import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import WrapperLoader from '../../components/wrapperLoader';
import { getTemplateById, getPublicTemplateById } from '../../redux/templates/templatesApi';
import { getPathnameInSplitArray, getFirstPathFromUrl } from '../../utils/utilities';
import HtmlRenderer from '../../components/HTMLRenderer';
import { setTemplateSelectedDocument } from '../../redux/templates/templatesSlice';
import CustomButton from 'src/components/customButton';
import { ChevronLeftIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import routeHierarchy from 'src/utils/routeHierarchy';

const TemplatesDetails = () => {
  const [documentDetailLoader, setDocumentDetailLoader] = useState(false);
  const [templateDetail, setTemplateDetail] = useState<any>(null);

  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: templateId, docId: documentId = '' } = useParams<{ id: string; docId: string }>();

  const { getPublicTemplateByIdRes, templateByIdRes, templateSelectedDocument } = useAppSelector(
    (state) => state.templates
  );
  const currentPage = useAppSelector((state) => state.profile.currentPage);

  const firstPath = getFirstPathFromUrl(window.location.pathname);
  const isPrivateTemplate = currentPage === routeHierarchy.privateTemplates.details.documentView.name;

  useEffect(() => {
    gettingProjectTempDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstPath === 'template') {
      setTemplateDetail(templateByIdRes);
    }
    if (firstPath === 'community-template') {
      setTemplateDetail(getPublicTemplateByIdRes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPublicTemplateByIdRes, templateByIdRes]);

  useEffect(() => {
    const urlSplit = getPathnameInSplitArray(window.location.pathname);
    const document_id = urlSplit ? urlSplit[4] : '';

    if (templateDetail && templateDetail?.document_templates?.length > 0) {
      const selectedDocumentItem = templateDetail?.document_templates.find(
        (item: { id: string }) => item.id === document_id
      );

      dispatch(setTemplateSelectedDocument(selectedDocumentItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDetail]);

  const gettingProjectTempDetail = () => {
    const fetchData = async () => {
      setDocumentDetailLoader(true);
      const urlSplit = getPathnameInSplitArray(window.location.pathname);
      const template_id = urlSplit ? urlSplit[2] : '';

      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          if (firstPath === 'template') {
            await dispatch(
              getTemplateById({
                accessToken,
                id: template_id,
              })
            );
          }

          if (firstPath === 'community-template') {
            await dispatch(
              getPublicTemplateById({
                accessToken,
                id: template_id,
              })
            );
          }

          setDocumentDetailLoader(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setDocumentDetailLoader(false);
      }
    };

    fetchData();
  };

  const handleGoBack = () => {
    navigate(isPrivateTemplate ? `/template/${templateId}` : `/community-template/${templateId}`);
  };

  return (
    <WrapperLoader loading={documentDetailLoader}>
      <div className='w-full'>
        <div className='p-4 mb-4 mx-4 border-b border-gray-100 text-right'>
          <div className='flex items-center justify-between w-full'>
            <CustomButton
              text='Back'
              type='button'
              onClickBtn={handleGoBack}
              beforeIcon={<ChevronLeftIcon className='h-4 mr-1' />}
              buttonType="secondary"
              // btnStyle='text-customLightBlue border-transparent !shadow-none transition-colors hover:bg-zinc-100 hover:text-customLightBlue hover:border-transparent'
            />
            {isPrivateTemplate && (
              <CustomButton
                text='Edit'
                type='button'
                onClickBtn={() =>
                  navigate({
                    pathname: `/template/${templateId}/edit`,
                    search: new URLSearchParams({ active: 'documents', id: documentId }).toString(),
                  })
                }
                beforeIcon={<PencilIcon className='h-4 mr-1' />}
                buttonType="secondary"
                // btnStyle='shrink-0 border-zinc-300 text-customLightBlue hover:text-amber-400'
              />
            )}
          </div>
        </div>

        <div className='pb-4 px-8 h-[calc(100vh_-_131px)] overflow-y-auto overflow-x-hidden'>
          <HtmlRenderer htmlString={templateSelectedDocument?.content || ''} />
        </div>
      </div>
    </WrapperLoader>
  );
};

export default TemplatesDetails;
