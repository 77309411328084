import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Analysis from './Analysis';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDecisionMatrixById } from '../../redux/decisionMatrix/decisionMatrixApi';
import { getLastIdFromUrl, parseJSONFromAi } from '../../utils/utilities';
import WrapperLoader from '../../components/wrapperLoader';
import { DecisionMatrixToolbar } from './Toolbar';
import {
  createDecisionMatrixOption,
  getDecisionMatrixCriteriasList,
  getDecisionMatrixOptionsList,
  updateDecisionMatrixCriteria,
  createDecisionMatrixCriteria,
  deleteDecisionMatrixCriteria,
  deleteDecisionMatrixOption,
  updateDecisionMatrixOption,
  updateOptionRating,
} from 'src/redux/decisionMatrixAnalysis/decisionMatrixAnalysisApi';
import { useSearchParams } from 'react-router-dom';
import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';

export default function DecisionMatrixDetails() {
  const [decisionMatrixByIdLoader, setDecisionMatrixByIdLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { decisionMatrixByIdRes, selectedDecisionMatrix } = useAppSelector((state) => state.decisionMatrixs);
  const { decisionMatrixCriteriasList, decisionMatrixOptionsList } = useAppSelector(
    (state) => state.decisionMatrixAnalysis
  );
  const [searchParams] = useSearchParams();

  const isDecisionMatrixReady = !!(
    !decisionMatrixByIdLoader &&
    decisionMatrixByIdRes &&
    decisionMatrixByIdRes.id === searchParams.get('id')
  );

  useEffect(() => {
    if (selectedDecisionMatrix?.id) {
      const fetchData = async () => {
        setDecisionMatrixByIdLoader(true);

        try {
          const accessToken = await getAccessTokenSilently();

          if (!accessToken) {
            throw new Error('No access token');
          }

          await dispatch(
            getDecisionMatrixById({
              accessToken,
              id: selectedDecisionMatrix?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setDecisionMatrixByIdLoader(false);
        } catch (error) {
          console.error('Error getting access token:', error);
          setDecisionMatrixByIdLoader(false);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMatrix?.id]);

  useEffect(() => {
    if (selectedDecisionMatrix?.id) {
      const fetchData = async () => {
        try {
          const accessToken = await getAccessTokenSilently();

          if (!accessToken) {
            throw new Error('No access token');
          }

          await Promise.all([
            dispatch(
              getDecisionMatrixCriteriasList({
                accessToken,
                matrix_id: selectedDecisionMatrix?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            ),
            dispatch(
              getDecisionMatrixOptionsList({
                accessToken,
                matrix_id: selectedDecisionMatrix?.id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            ),
          ]);
        } catch (error) {
          console.error('Error fetching decision matrix criteria and options:', error);
        }
      };

      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMatrix?.id]);

  const updateCriteria = useCallback(
    async (body: any, criteriaId: string) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          updateDecisionMatrixCriteria({
            body,
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            criteria_id: criteriaId,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error updating decision matrix criteria:', error);
        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const updateOption = useCallback(
    async (body: any) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          updateDecisionMatrixOption({
            body,
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error updating decision matrix option:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const createCriteria = useCallback(
    async (body: any) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        /*
        {
          title: 'Abecadło',
          criteria_multiplier: 2,
          criteria_multiplier_title: 'Important',
        }
        */
        await dispatch(
          createDecisionMatrixCriteria({
            body,
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error creating criteria:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const createOption = useCallback(
    async (body: any) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        /*
        {
          title: 'Option3',
        }
        */
        await dispatch(
          createDecisionMatrixOption({
            body,
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error creating option:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const deleteCriteria = useCallback(
    async (criteriaId: string) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          deleteDecisionMatrixCriteria({
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            criteria_id: criteriaId,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error deleting criteria:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const deleteOption = useCallback(
    async (optionId: string) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        await dispatch(
          deleteDecisionMatrixOption({
            accessToken,
            option_id: optionId,
            matrix_id: selectedDecisionMatrix?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error deleting option:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const updateRating = useCallback(
    async (body: any, criteriaId: string) => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        /*
        {
          concept_id: '1d7a7c0e-04c2-4367-bf6f-149e24ff2b83', (option id)
          user_rating: 0,
        }
        */
        await dispatch(
          updateOptionRating({
            body,
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            criteria_id: criteriaId,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );
      } catch (error) {
        console.error('Error updating option rating:', error);

        throw error;
      }
    },
    [dispatch, getAccessTokenSilently, selectedDecisionMatrix?.id]
  );

  const generateSuggestions = useCallback(async () => {
    if (!isDecisionMatrixReady) return;

    const getCriteriaMultiplierTitle = (multiplier: number) => {
      switch (multiplier) {
        case 1:
          return 'Normal';
        case 2:
          return 'Important';
        case 3:
          return 'Very Important';
        default:
          return 'Normal';
      }
    };

    try {
      const accessToken = await getAccessTokenSilently();

      if (!accessToken) {
        throw new Error('No access token');
      }

      const res = await dispatch(
        promptMessage({
          body: {
            prompt: JSON.stringify({
              title: decisionMatrixByIdRes?.title || '',
              category: decisionMatrixByIdRes?.category || '',
              description: decisionMatrixByIdRes?.description || '',
              criteria: decisionMatrixCriteriasList?.map((item: any) => item?.criteria?.title) ?? [],
              options:
                decisionMatrixOptionsList?.map((option: any) => ({
                  title: option?.title,
                  ratings: decisionMatrixCriteriasList?.map((criteria: any) => {
                    const rating = criteria?.concepts?.find((concept: any) => concept.id === option.id);

                    return {
                      criteriaTitle: criteria?.criteria?.title,
                      value: rating?.user_rating ?? null,
                    };
                  }),
                })) ?? [],
            }),
          },
          accessToken,
          type: 'matrix-generator',
        })
      );

      const requestStatus = res?.meta?.requestStatus;
      const reply = res?.payload?.data?.message;

      if (requestStatus !== 'fulfilled') {
        throw new Error('Request rejected');
      }

      const replyData = parseJSONFromAi(reply);

      // Create all criteria in parallel
      await Promise.all(
        replyData.criteria.map((criteria: any) =>
          dispatch(
            createDecisionMatrixCriteria({
              body: {
                title: criteria.title,
                criteria_multiplier: criteria.multiplier,
                criteria_multiplier_title: getCriteriaMultiplierTitle(criteria.multiplier),
              },
              accessToken,
              matrix_id: selectedDecisionMatrix?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          )
        )
      );

      // Create all options in parallel
      const newOptions = await Promise.all(
        replyData.options.map((option: any) =>
          dispatch(
            createDecisionMatrixOption({
              body: { title: option.title },
              accessToken,
              matrix_id: selectedDecisionMatrix?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          )
        )
      );

      // Fetch updated lists after creating new items
      const [criteriaResponse, optionsResponse] = await Promise.all([
        dispatch(
          getDecisionMatrixCriteriasList({
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        ),
        dispatch(
          getDecisionMatrixOptionsList({
            accessToken,
            matrix_id: selectedDecisionMatrix?.id,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        ),
      ]);

      const allCriteria = criteriaResponse.payload.data;
      const allOptions = optionsResponse.payload.data;

      // Prepare rating updates for new options
      const newOptionRatings = newOptions.flatMap((optionRes: any) => {
        const option = replyData.options[newOptions.indexOf(optionRes)];

        return option.ratings
          .map((rating: any) => {
            const criteria = allCriteria.find((c: any) => c?.criteria?.title === rating.criteriaTitle);

            if (!criteria) return null;

            return {
              body: {
                concept_id: optionRes.payload.data.id,
                user_rating: rating.value,
              },
              criteria_id: criteria?.criteria?.id,
            };
          })
          .filter(Boolean);
      });

      // Prepare rating updates for existing options
      const existingOptionRatings =
        replyData.updatedRatings
          ?.map((rating: any) => {
            const { criteriaTitle, optionTitle, value } = rating;

            const criteria = allCriteria.find((c: any) => c?.criteria?.title === criteriaTitle);
            const option = allOptions.find((o: any) => o.title === optionTitle);

            if (!criteria || !option) return null;

            return {
              body: {
                concept_id: option.id,
                user_rating: value,
              },
              criteria_id: criteria?.criteria?.id,
            };
          })
          .filter(Boolean) ?? [];

      // Update all ratings in parallel
      await Promise.all(
        [...newOptionRatings, ...existingOptionRatings].map(({ body, criteria_id }) =>
          dispatch(
            updateOptionRating({
              body,
              accessToken,
              matrix_id: selectedDecisionMatrix?.id,
              criteria_id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          )
        )
      );
    } catch (error) {
      console.error('Error generating suggestions:', error);

      throw error;
    }
  }, [
    isDecisionMatrixReady,
    dispatch,
    getAccessTokenSilently,
    decisionMatrixByIdRes,
    decisionMatrixCriteriasList,
    decisionMatrixOptionsList,
    selectedDecisionMatrix?.id,
  ]);

  return (
    <WrapperLoader
      loading={decisionMatrixByIdLoader}
      className='h-full'
    >
      <DecisionMatrixToolbar
        isDecisionMatrixReady={isDecisionMatrixReady}
        generateSuggestions={generateSuggestions}
      />
      <div className='h-full p-6'>
        <div className='h-full'>
          <Analysis
            criteriaList={decisionMatrixCriteriasList}
            optionList={decisionMatrixOptionsList}
            updateCriteria={updateCriteria}
            updateOption={updateOption}
            createCriteria={createCriteria}
            createOption={createOption}
            deleteCriteria={deleteCriteria}
            deleteOption={deleteOption}
            updateRating={updateRating}
          />
        </div>
      </div>
    </WrapperLoader>
  );
}
