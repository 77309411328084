import { TrashIcon } from '@heroicons/react/24/outline';
import WrapperLoader from 'src/components/wrapperLoader';
import { getSpecialChatAssistant } from 'src/utils/special-chat-assistants';

interface ConversationBoxProps {
  data: any;
  onDelete: (e: any) => void;
  deleteLoader: boolean;
  selectedData: any;
  onSelectConversation: () => void;
  selectedConversation: any;
}

export default function ConversationBox({
  data,
  onDelete,
  deleteLoader,
  selectedData,
  onSelectConversation,
}: ConversationBoxProps) {
  const Icon = getSpecialChatAssistant(data?.conversation_type as any)?.icon;

  return (
    <li
      key={data?.id}
      className='mb-[8px] transition-all duration-500 ease-in-out'
    >
      <div
        className={`rounded-md shadow-sm overflow-hidden border border-gray-200 bg-white hover:border-blue-400 hover:bg-blue-50`}
        onClick={onSelectConversation}
      >
        <WrapperLoader
          loading={selectedData?.id === data?.id && deleteLoader}
          className='px-4 py-2 cursor-pointer'
        >
          <div className={`py-1.5 flex items-center`}>
            <div className='flex flex-1 items-center justify-between truncate rounded-r-md'>
              <div className='flex-1 truncate text-xs'>
                <div className='flex items-center'>
                  {Icon && <Icon className='h-4 w-4 mr-2 text-customLightBlue' />}
                  <span className='font-medium text-xs text-gray-700 hover:text-customLightBlue'>{data?.title}</span>
                </div>
              </div>
              <button
                className='group flex-shrink-0 flex items-center rounded-md'
                onClick={onDelete}
              >
                <TrashIcon className='h-4 w-4 cursor-pointer text-customLightBlue transition-colors group-hover:customDarkBlue' />
              </button>
            </div>
          </div>
          {data?.description && <p className='mt-2 pr-6 text-xs text-gray-500'>{data?.description}</p>}
        </WrapperLoader>
      </div>
    </li>
  );
}
