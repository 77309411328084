import { useState } from 'react';
import CustomModal from '../../../components/customModal';
import ArgumentForm from './argumentForm';
import ArgumentBox, { BaseArgumentBoxProps } from '../../../components/argumentBox';
import RoundButton from '../../../components/roundButton';
import { RatioBar } from '../../../components/progressBar';
import ConfirmationModal from '../../../components/confirmationModal';

interface AnalysisProps {
  prosList: any[] | null;
  consList: any[] | null;
  updateArgument: (body: any, selectedArgument: any, side: 'pro' | 'con') => Promise<void>;
  createArgument: (argumentBody: any, side: 'pro' | 'con') => Promise<void>;
  deleteArgument: (selectedArgument: any, side: 'pro' | 'con') => Promise<void>;
}

export default function Analysis({
  prosList,
  consList,
  updateArgument,
  createArgument,
  deleteArgument,
}: AnalysisProps) {
  const [openFormModal, setOpenFormModal] = useState(false);
  const [argumentFormLoader, setArgumentFormLoader] = useState(false);
  const [selectedArgument, setSelectedArgument] = useState<any>(null);
  const [argumentDeleteLoader, setArgumentDeleteLoader] = useState(false);
  const [side, setSide] = useState<'pro' | 'con'>('pro');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [submittedSuggestionArgument, setSubmittedSuggestionArgument] = useState<string | null>(null);

  const onCloseModal = () => {
    setOpenFormModal(false);
    setSelectedArgument(null);
  };

  const onOpenModal = (type: 'pro' | 'con') => {
    setSide(type);
    setOpenFormModal(true);
  };

  const onOpenEditModal = (data: any, type: 'pro' | 'con') => {
    setSide(type);
    setSelectedArgument(data);
    setOpenFormModal(true);
  };

  const updatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);

      try {
        await updateArgument(body, selectedArgument, side);
        onCloseModal();
      } finally {
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingArgument = (body: any) => {
    const fetchData = async () => {
      setArgumentFormLoader(true);

      try {
        await createArgument(body, side);
        onCloseModal();
      } finally {
        setArgumentFormLoader(false);
      }
    };

    fetchData();
  };

  const deletingArgument = () => {
    const fetchData = async () => {
      setArgumentDeleteLoader(true);

      try {
        await deleteArgument(selectedArgument, side);
        closeConfirmationModal();
      } finally {
        setArgumentDeleteLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitForm = async (body: any) => {
    if (selectedArgument) {
      updatingArgument(body);
    } else {
      creatingArgument(body);
    }
  };

  const deleteConfirmationModal = (data: any, side: 'pro' | 'con') => {
    setSelectedArgument(data);
    setSide(side);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedArgument(null);
  };

  const submittingAiSuggestionArgument = (argument: any, side: 'pro' | 'con') => {
    const fetchData = async () => {
      setSubmittedSuggestionArgument(argument?.id);

      try {
        const { id, argument: argumentName, argument_weight, explanation } = argument;

        await createArgument(
          {
            id,
            argument: argumentName,
            argument_weight,
            side,
            description: explanation,
            source: 'ai',
          },
          side
        );
      } finally {
        setSubmittedSuggestionArgument(null);
      }
    };

    fetchData();
  };

  return (
    <>
      <RatioBar
        optionAArguments={prosList}
        optionBArguments={consList}
        weightSelector={(item) => item.argument_weight}
      />

      <div className='grid grid-cols-2'>
        {/** Pros Column **/}
        <div className='border-r border-gray-200'>
          <div className='bg-white rounded-2xl overflow-hidden mt-4 mr-4 shadow-md'>
            <div className='pt-6 px-6 pb-4'>
              <div className='text-center'>
                <span className='font-semibold text-base'>Pros</span>
              </div>
            </div>

            <div className='border-t border-gray-200 p-4'>
              <div
                onClick={() => onOpenModal('pro')}
                className='mb-3 w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400'
              >
                <RoundButton onClickBtn={() => console.log('...')} />
              </div>
              <div className='relative w-[calc(100%+1rem)] pr-2'>
                <div
                  className='overflow-y-auto pr-2'
                  style={{ height: 'calc(100vh - 326px)' }}
                >
                  <ul className='grid grid-cols-1'>
                    {prosList?.map((argument: any) => {
                      const source = argument?.source || 'user';

                      const sharedProps: BaseArgumentBoxProps = {
                        name: argument.argument,
                        value: argument.argument_weight,
                        color: 'green',
                        status: submittedSuggestionArgument === argument?.id ? 'creating' : null,
                      };

                      if (source === 'user') {
                        return (
                          <ArgumentBox
                            key={argument?.id}
                            {...sharedProps}
                            variant='user'
                            description={argument.description}
                            onEdit={() => onOpenEditModal(argument, 'pro')}
                            onDelete={() => deleteConfirmationModal(argument, 'pro')}
                          />
                        );
                      }

                      return (
                        <ArgumentBox
                          key={argument?.id}
                          {...sharedProps}
                          variant='ai'
                          description={argument.explanation}
                          onThumbDown={() => deleteArgument(argument, 'pro')}
                          onThumbUp={() => submittingAiSuggestionArgument(argument, 'pro')}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>

              {/* <div className="text-center">
                <RoundButton onClickBtn={() => onOpenModal("pro")} />
              </div> */}
            </div>
          </div>
        </div>

        {/** Cons Column **/}
        <div className='bg-white rounded-2xl overflow-hidden mt-4 ml-4 shadow-md'>
          <div className='pt-6 px-6 pb-4'>
            <div className='text-center'>
              <span className='font-semibold text-base'>Cons</span>
            </div>
          </div>

          <div className='border-t border-gray-200 p-4'>
            <div
              onClick={() => onOpenModal('con')}
              className='mb-3 w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400'
            >
              <RoundButton onClickBtn={() => console.log('...')} />
            </div>
            <div className='relative w-[calc(100%+1rem)] pr-2'>
              <div
                className='overflow-y-auto pr-2'
                style={{ height: 'calc(100vh - 326px)' }}
              >
                <ul className='grid grid-cols-1'>
                  {consList?.map((argument: any) => {
                    const source = argument?.source || 'user';

                    const sharedProps: BaseArgumentBoxProps = {
                      name: argument.argument,
                      value: argument.argument_weight,
                      color: 'orange',
                      status: submittedSuggestionArgument === argument?.id ? 'creating' : null,
                    };

                    if (source === 'user') {
                      return (
                        <ArgumentBox
                          key={argument?.id}
                          {...sharedProps}
                          variant='user'
                          description={argument?.description}
                          onEdit={() => onOpenEditModal(argument, 'con')}
                          onDelete={() => deleteConfirmationModal(argument, 'con')}
                        />
                      );
                    }

                    return (
                      <ArgumentBox
                        key={argument?.id}
                        {...sharedProps}
                        variant='ai'
                        description={argument.explanation}
                        onThumbDown={() => deleteArgument(argument, 'con')}
                        onThumbUp={() => submittingAiSuggestionArgument(argument, 'con')}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* <div className="text-center">
              <RoundButton onClickBtn={() => onOpenModal("con")} />
            </div> */}
          </div>
        </div>
      </div>

      <CustomModal
        open={openFormModal}
        onCloseModal={() => onCloseModal()}
        title={`${selectedArgument ? 'Update' : 'Add'} Argument`}
        size='max-w-lg'
      >
        <ArgumentForm
          onCloseModal={() => onCloseModal()}
          loading={argumentFormLoader}
          onSubmitData={onSubmitForm}
          editData={selectedArgument}
          side={side}
        />
      </CustomModal>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingArgument}
        deleteLoader={argumentDeleteLoader}
        description={
          <span>
            Are you sure you want to delete an argument: <strong>{selectedArgument?.argument ?? ''}</strong>?
          </span>
        }
      />
    </>
  );
}
