import React, { useMemo } from 'react';
import Markdown, { type Components } from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import remarkGfm from 'remark-gfm';
import BlockAction from './blockAction';

interface MarkdownRendererProps {
  content?: string;
  addItemToTextEditor: Function;
}

const remarkPlugins = [remarkGfm];

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ content = '', addItemToTextEditor }) => {
  const components: Components = useMemo(
    () => ({
      code({ children, className, ...rest }) {
        const match = /language-(\w+)/.exec(className || '');

        return match ? (
          <div className='relative group not-prose'>
            <SyntaxHighlighter
              {...(rest as any)}
              PreTag='div'
              children={String(children).replace(/\n$/, '')}
              language={match[1]}
              style={okaidia}
            />

            <BlockAction
              textToCopy={String(children).replace(/\n$/, '')}
              addItemToTextEditor={() => addItemToTextEditor(String(children).replace(/\n$/, ''))}
            />
          </div>
        ) : (
          <code
            {...rest}
            className={`${className} break-words`}
          >
            {children}
          </code>
        );
      },
      p({ children }) {
        if (typeof children !== 'string') {
          return <>{children}</>;
        }

        const regex = /==(.*?)==/g;
        const parts = children.split(regex).map((part, index) => {
          if (index % 2 === 0) return part;

          return part.replace(/^==|==$/, '');
        });

        return (
          <p>
            {parts.map((part, index) =>
              index % 2 === 1 ? (
                <span
                  key={index}
                  className='bg-yellow-200 px-1'
                >
                  {part}
                </span>
              ) : (
                part
              )
            )}
          </p>
        );
      },
    }),
    [addItemToTextEditor]
  );

  return (
    <Markdown
      remarkPlugins={remarkPlugins}
      components={components}
      className='prose'
    >
      {content}
    </Markdown>
  );
};

export default MarkdownRenderer;
