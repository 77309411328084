import React, { TextareaHTMLAttributes } from "react";

interface CommonInputProps {
  id: string;
}

type TextAreaFieldProps = CommonInputProps &
  TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextAreaField: React.FC<TextAreaFieldProps> = ({ id, ...rest }) => {
  return (
    <textarea
      id={id}
      rows={4}
      className="block w-full bg-white rounded-md border-0 py-1.5 text-customDarkBlue shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-xs sm:leading-6"
      {...rest}
    />
  );
};

export default TextAreaField;
