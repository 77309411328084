import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../../utils/api";

export const getPublicCommentsList = createAsyncThunk(
  "getPublicCommentsList/get",
  async ({ accessToken, template_id }: any) => {
    try {
      const response = await getRequest({
        url: `/publicComments?template_id=${template_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createPublicComment = createAsyncThunk(
  "createPublicComment/Create",
  async ({ body, accessToken, template_id }: any) => {
    try {
      const response = await postRequest({
        url: `/publicComment?template_id=${template_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updatePublicComment = createAsyncThunk(
  "updatePublicComment/Update",
  async ({ body, accessToken, comment_id, template_id }: any) => {
    try {
      const response = await putRequest({
        url: `/publicComment?comment_id=${comment_id}&template_id=${template_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deletePublicComment = createAsyncThunk(
  "deleteComment/Delete",
  async ({ accessToken, comment_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/publicComment?comment_id=${comment_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
