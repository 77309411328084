import { createSlice } from "@reduxjs/toolkit";
import {
  getDiagramsList,
  getDiagramById,
  createDiagram,
  updateDiagram,
  deleteDiagram,
} from "./diagramsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  getDiagramsListRes: any[];
  getDiagramByIdRes: any;
  createDiagramRes: any;
  updateDiagramRes: any;
  deleteDiagramRes: any;
  selectedDiagram: any;
  openNodeSetting: boolean;
  selectedNodeSetting: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  getDiagramsListRes: [],
  getDiagramByIdRes: null,
  createDiagramRes: null,
  updateDiagramRes: null,
  deleteDiagramRes: null,
  selectedDiagram: null,
  openNodeSetting: false,
  selectedNodeSetting: null
};
const diagramsSlice = createSlice({
  name: "diagrams",
  initialState,
  reducers: {
    setSelectedDiagram: (state, action) => {
      state.selectedDiagram = action.payload;
    },
    setSelectedNodeSetting: (state, action) => {
      state.selectedNodeSetting = action.payload;
    },
    setOpenNodeSetting: (state, action) => {
      state.openNodeSetting = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Diagrams List
    builder.addCase(getDiagramsList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getDiagramsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getDiagramsListRes = action.payload?.data;

      if (action.payload?.data && action.payload?.data?.length > 0) {
        state.selectedDiagram = action.payload?.data[0];
      }
    });
    builder.addCase(getDiagramsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Get Diagram By Id
    builder.addCase(getDiagramById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getDiagramById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getDiagramByIdRes = action.payload?.data;
    });
    builder.addCase(getDiagramById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Diagram
    builder.addCase(createDiagram.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createDiagram.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const previousData = [...(state.getDiagramsListRes || [])];
      state.getDiagramsListRes = [...previousData, action.payload?.data];
      state.selectedDiagram = action.payload?.data;

      state.createDiagramRes = action.payload?.data;
    });
    builder.addCase(createDiagram.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Diagram
    builder.addCase(updateDiagram.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateDiagram.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const previousData = [...state.getDiagramsListRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );
      if (previousIndex !== -1) {
        previousData[previousIndex] = action.payload.data;
        state.getDiagramsListRes = previousData;
      }

      state.updateDiagramRes = action.payload?.data;
    });
    builder.addCase(updateDiagram.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Diagram
    builder.addCase(deleteDiagram.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteDiagram.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const filterData = state.getDiagramsListRes?.filter(
        (item: { id: string }) => item?.id !== action.payload?.data?.id
      );

      state.getDiagramsListRes = filterData;

      state.deleteDiagramRes = action.payload?.data;
    });
    builder.addCase(deleteDiagram.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedDiagram, setOpenNodeSetting, setSelectedNodeSetting } =
  diagramsSlice.actions;
export default diagramsSlice.reducer;
