import React, { useState, useRef, useEffect } from "react";

// Utility function to detect click outside the component
function useOutsideClick(
  ref: React.RefObject<HTMLDivElement>,
  onClickOutside: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
}

interface PopoverProps {
  buttonContent: React.ReactNode;
  popoverContent: React.ReactNode; // The content inside the popover
  classes?: string;
}

const Popover: React.FC<PopoverProps> = ({
  buttonContent,
  popoverContent,
  classes,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  // Handle outside click to close popover
  useOutsideClick(popoverRef, () => setIsVisible(false));

  return (
    <div className="relative flex items-center">
      <button
        id="popover_button"
        type="button"
        onClick={() => setIsVisible(!isVisible)}
        className="border-0"
        style={{ background: 'transparent', padding: 0 }}
      >
        {buttonContent}
      </button>

      {isVisible && (
        <div
          ref={popoverRef}
          className={`absolute z-50 inline-block text-sm transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm animate-fade-in ${classes}`}
        >
          <div className="p-1">{popoverContent}</div>
        </div>
      )}
    </div>
  );
};

export default Popover;
