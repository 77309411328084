import { useEffect, useRef } from 'react';

type FetchProps<Dependencies, Triggers> = {
  dependencies: Dependencies;
  triggers: Triggers;
  callback: (props: Dependencies & { triggers: Triggers }) => void;
};

export function useWatch<
  Dependencies extends Record<string, unknown>,
  Triggers extends unknown[],
>({ dependencies, triggers, callback }: FetchProps<Dependencies, Triggers>) {
  const dependenciesRef = useRef(dependencies);
  const callbackRef = useRef(callback);
  const prevTriggersRef = useRef<Triggers | null>(null);

  useEffect(() => {
    dependenciesRef.current = dependencies;
  }, [dependencies]);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const areTriggersEqual = prevTriggersRef.current && prevTriggersRef.current.every((trigger, index) => trigger === triggers[index]);

    if (areTriggersEqual) return;

    prevTriggersRef.current = triggers;
    callbackRef.current({ ...dependenciesRef.current, triggers });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, triggers);
}
