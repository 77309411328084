import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
  PencilIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getProsConsList,
  createProsCons,
  updateProsCons,
  getProsConsById,
  deleteProsCons,
} from "../../redux/prosCons/prosConsApi";
import CustomDrawer from "../../components/customDrawer";
import CreationForm from "./CreationForm";
import WrapperLoader from "../../components/wrapperLoader";
import ConfirmationModal from "../../components/confirmationModal";
import StatusLabel from "../../components/statusLabel";
import TableHeader from "../../components/tableHead";
import SearchField from "../../components/searchField";
import TableTitle from "../../components/tableTitle";
import Tooltip from "../../components/tooltipWrapper";
import CompleteEmptyPage from "../../components/completeEmptyPage";
import TableColumn from "../../components/tableColumn";
import { getLastIdFromUrl } from "../../utils/utilities";
import CustomButton from "../../components/customButton";

import prosConsSVG from "../../assets/images/Illustration-06.svg";

const ProsCons = () => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [prosConsFormLoader, setProsConsFormLoader] = useState(false);
  const [prosConsByIdLoader, setProsConsByIdLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [prosConsCompleteList, setProsConsCompleteList] = useState<any>([]);
  const [search, setSearch] = useState("");

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { prosConsList, prosConsByIdRes, loadingProsCons } = useAppSelector(
    (state) => state.prosCons
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  useEffect(() => {
    setProsConsCompleteList(prosConsList);
  }, [prosConsList]);

  const onSearchingList = (value: string) => {
    setSearch(value);
    if (value) {
      const filteredResults = prosConsList.filter((item: any) =>
        item?.title.toLowerCase().includes(value.toLowerCase())
      );

      setProsConsCompleteList(filteredResults);
    } else {
      setProsConsCompleteList(prosConsList);
    }
  };

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingProsConsById(id);
  };

  const onOpenCreateModal = () => {
    setOpen(true);
  };

  const updatingProsCons = (body: any) => {
    const fetchData = async () => {
      setProsConsFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProsCons({
              body,
              accessToken,
              id: prosConsByIdRes?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setProsConsFormLoader(false);

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setProsConsFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingProsCons = (body: any) => {
    const fetchData = async () => {
      setProsConsFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            createProsCons({
              body,
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setProsConsFormLoader(false);

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setProsConsFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProsCons = async (body: any) => {
    if (isEditMode) {
      updatingProsCons(body);
    } else {
      creatingProsCons(body);
    }
  };

  const gettingProsConsById = (id: string) => {
    const fetchData = async () => {
      setProsConsByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken)
          await dispatch(
            getProsConsById({
              accessToken,
              id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        setProsConsByIdLoader(false);
      } catch (error) {
        console.error("Error getting access token:", error);
        setProsConsByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingProsCons = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteProsCons({
              accessToken,
              id: selectedId,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setConfirmationLoader(false);
          deletingModalClose();

          await dispatch(
            getProsConsList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (id: string) => {
    setDeleteConfirm(true);
    setSelectedId(id);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedId(null);
  };

  return (
    <>
      {prosConsList?.length > 0 ? (
        <WrapperLoader loading={loadingProsCons}>
          <div className="mb-5 pb-5 pt-5 border-b border-gray-200 text-right">
            <div className="px-6 flex items-center justify-between">
              <div className="w-80">
                <SearchField
                  onSearchField={onSearchingList}
                  searchValue={search}
                />
              </div>

              <CustomButton
                text="New Pros-Cons"
                type="button"
                onClickBtn={() => onOpenModal()}
                beforeIcon={<PlusIcon className="h-4 mr-1" />}
                buttonType="primary"
              />
            </div>
          </div>

          <div className="px-6 pb-6">
            {prosConsCompleteList?.length > 0 && (
              <div className="flow-root">
                <div className="sm:-mx-6">
                  <div className="inline-block min-w-full align-middle sm:px-6 h-[calc(100vh_-_150px)] overflow-y-auto">
                    <table className="min-w-full divide-y divide-gray-300 bg-white rounded-xl shadow-md border border-zinc-200 overflow-hidden">
                      <thead>
                        <tr>
                          <TableHeader name="Title" />
                          <TableHeader name="Description" />
                          {/* <TableHeader name="Decision ID" /> */}
                          <TableHeader name="Status" />
                          <TableHeader name="Category" />
                          <TableHeader name="Actions" />
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {prosConsCompleteList?.map((item: any) => (
                          <tr
                            key={item?.id}
                            className="cursor-pointer hover:bg-zinc-100"
                            onClick={() =>
                              navigate(
                                `${window.location.pathname}?active=pros-cons&id=${item?.id}`
                              )
                            }
                          >
                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <TableTitle
                                  title={item?.title}
                                  status={item?.pnc_status}
                                  created_at={item?.created_at}
                                  updated_at={item?.updated_at}
                                />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-customDarkBlue text-xs">
                                <Tooltip
                                  text={item?.pnc_description}
                                  position="top"
                                >
                                  <div className="line-clamp-2">
                                    {item?.pnc_description}
                                  </div>
                                </Tooltip>
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                <StatusLabel status={item?.pnc_status} />
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="text-xs text-customLightBlue">
                                {item?.category}
                              </div>
                            </TableColumn>

                            <TableColumn>
                              <div className="relative text-right text-xs font-medium">
                                <div className="flex-shrink-0 pr-4 flex items-center">
                                  <PencilIcon
                                    className="h-5 w-5 mr-1 cursor-pointer text-slate-400 hover:text-blue-900"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onOpenEditModal(item?.id);
                                    }}
                                  />
                                  <TrashIcon
                                    className="h-5 w-5 cursor-pointer text-slate-400 hover:text-red-900"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      deletingModalOpen(item?.id);
                                    }}
                                  />
                                </div>
                              </div>
                            </TableColumn>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </WrapperLoader>
      ) : (
        <div
          className="flex items-center justify-center"
          style={{ height: "calc(100vh - 140px)" }}
        >
          <CompleteEmptyPage
            title="You don't have any Pros & Cons"
            subTitle="Get started by creating a new Pros & Cons"
            btnText="Create Pros & Cons"
            onClickBtn={onOpenCreateModal}
            icon={prosConsSVG}
          />
        </div>
      )}

      <CustomDrawer.ProCon
        open={open}
        onCloseModal={onCloseModal}
        isEditMode={isEditMode}
      >
        <WrapperLoader loading={prosConsByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={prosConsByIdRes}
            onSubmitForm={onSubmitProsCons}
            loader={prosConsFormLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer.ProCon>

      <ConfirmationModal
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProsCons}
        deleteLoader={confirmationLoader}
      />
    </>
  );
};

export default ProsCons;
