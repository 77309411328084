import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../utils/api";

export const getProsConsList = createAsyncThunk(
  "prosCons/get",
  async ({ accessToken, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/pncs?project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getProsConsById = createAsyncThunk(
  "prosConsById/get",
  async ({ accessToken, id, project_id }: any) => {
    try {
      const response = await getRequest({
        url: `/pnc?pnc_id=${id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const createProsCons = createAsyncThunk(
  "prosCons/Create",
  async ({ body, accessToken, project_id }: any) => {
    try {
      const response = await postRequest({
        url: `/pnc?project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const updateProsCons = createAsyncThunk(
  "prosCons/Update",
  async ({ body, accessToken, id, project_id }: any) => {
    try {
      const response = await putRequest({
        url: `/pnc?pnc_id=${id}&project_id=${project_id}`,
        payload: body,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const deleteProsCons = createAsyncThunk(
  "prosCons/Delete",
  async ({ accessToken, id, project_id }: any) => {
    try {
      const response = await deleteRequest({
        url: `/pnc?pnc_id=${id}&project_id=${project_id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);
