import { type DragEvent, useRef, useCallback, memo } from "react";
import { Tooltip } from "react-tooltip";

import Shape from "../shape";
import { type ShapeType } from "../shape/types";

type SidebarItemProps = {
  type: ShapeType;
  nodeType: string;
};

function SidebarItem({ type, nodeType }: SidebarItemProps) {
  const dragImageRef = useRef<HTMLDivElement>(null);

  const onDragStart = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.dataTransfer?.setData("application/reactflow", type);
    event.dataTransfer?.setData("application/nodeType", nodeType);

    if (dragImageRef.current) {
      event.dataTransfer.setDragImage(dragImageRef.current, 0, 0);
    }
  }, [type, nodeType]);

  return (
    <div
      className="bg-white p-[6px] mb-1 shadow-md mx-1 rounded-md cursor-move"
      draggable
      onDragStart={onDragStart}
    >
      <div
        className="sidebar-item-drag-image"
        ref={dragImageRef}
        data-tooltip-id={`shape-tooltip-${type}`}
      >
        {/* Shape node configuration */}
        <Shape
          type={type}
          width={28}
          height={28}
          fill="#3F8AE2"
          fillOpacity={0.7}
          stroke="#3F8AE2"
          strokeWidth={2}
        />
      </div>

      <Tooltip className="z-[50] capitalize" id={`shape-tooltip-${type}`} place="top">
        {type}
      </Tooltip>
    </div>
  );
}

export default memo(SidebarItem);
