import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { promptMessage } from 'src/redux/chatGPT/chatGPTApi';
import { useAuth0 } from '@auth0/auth0-react';
import type { AiSuggestionsFetcher } from 'ckeditor5/types/inlineSuggestions';

export function useAiSuggestionsFetcher(): AiSuggestionsFetcher | null {
  const currControllerRef = useRef<AbortController | null>(null);
  const projectId = useAppSelector((state) => state.projects.selectedDocument?.project_id);
  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const cancelPendingFetch = useCallback(() => {
    currControllerRef.current?.abort();
    currControllerRef.current = null;
  }, []);

  useEffect(() => {
    return () => cancelPendingFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    if (!projectId) return null;

    const fetch = async (serializedHTML: string, instructions: string[] = []): Promise<string[] | null> => {
      cancelPendingFetch();

      const accessToken = await getAccessTokenSilently();
      currControllerRef.current = new AbortController();

      try {
        const res = await dispatch(
          promptMessage({
            body: {
              prompt:
                instructions.length > 0
                  ? JSON.stringify({
                      html: serializedHTML,
                      instructions,
                    })
                  : serializedHTML,
            },
            accessToken,
            type: 'documents-assistant',
            signal: currControllerRef.current.signal,
          })
        );

        const requestStatus = res?.meta?.requestStatus;
        const reply = res?.payload?.data?.message;

        if (requestStatus === 'rejected' && currControllerRef.current.signal.aborted) {
          throw new Error('Request aborted');
        }

        if (requestStatus !== 'fulfilled') {
          throw new Error('Request rejected');
        }

        const suggestions = JSON.parse(reply.startsWith('```json') ? reply.slice(8, -3) : reply);

        if (!Array.isArray(suggestions)) {
          throw new Error('Invalid response from AI');
        }

        return suggestions;
      } catch (error) {
        const isAbortError = error instanceof Error && (error.name === 'AbortError' || error.message === 'Request aborted');

        if (isAbortError) return null;

        console.error('Error while fetching ai suggestions: ', error);

        return [];
      }
    };

    return Object.assign(fetch, { cancel: cancelPendingFetch });
  }, [projectId, cancelPendingFetch, getAccessTokenSilently, dispatch]);
}
