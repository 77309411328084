import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import RoundButton from '../../../../components/roundButton';
import ConfirmationModal from '../../../../components/confirmationModal';
import DecisionMatrixBox from './decisionMatrixBox';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getDecisionMatrixList,
  createDecisionMatrix,
  updateDecisionMatrix,
  deleteDecisionMatrix,
  getDecisionMatrixById,
} from '../../../../redux/decisionMatrix/decisionMatrixApi';
import WrapperLoader from '../../../../components/wrapperLoader';
import { getLastIdFromUrl, getQueryParams } from '../../../../utils/utilities';
import { setSelectedDecisionMatrix } from '../../../../redux/decisionMatrix/decisionMatrixSlice';
import DecisionMatrixForm from '../../../decisionMatrix/CreationForm';
import CustomDrawer from '../../../../components/customDrawer';

interface TChartListProps {
  checkingDetailTabShow: Function;
}

const DecisionMatrixList = ({ checkingDetailTabShow }: TChartListProps) => {
  const [open, setOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [allDecisionMatrixLoading, setAllDecisionMatrixLoading] = useState(false);
  const [decisionMatrixItems, setDecisionMatrixItems] = useState<any>([]);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [decisionMatrixByIdLoader, setDecisionMatrixByIdLoader] = useState(false);

  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useAppDispatch();
  const { decisionMatrixList, selectedDecisionMatrix, decisionMatrixByIdRes } = useAppSelector(
    (state) => state.decisionMatrixs
  );

  useEffect(() => {
    const decisionMatrixDataList: any = [...(decisionMatrixList || [])];
    setDecisionMatrixItems(decisionMatrixDataList || []);

    if (decisionMatrixList?.length > 0) {
      const queryStringObj = getQueryParams(window.location.href);

      const findItem = queryStringObj ? decisionMatrixList?.find((data) => data?.id === queryStringObj?.id) : null;

      dispatch(setSelectedDecisionMatrix(findItem || decisionMatrixList[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMatrixList]);

  useEffect(() => {
    checkingDetailTabShow();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMatrixItems]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          setAllDecisionMatrixLoading(true);
          await dispatch(
            getDecisionMatrixList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setAllDecisionMatrixLoading(false);
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (queryStringObj?.active === 'decision-matrix') {
      navigate(
        `${window.location.pathname}?active=decision-matrix&id=${selectedDecisionMatrix?.id || queryStringObj?.id}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDecisionMatrix]);

  useEffect(() => {
    const queryStringObj = getQueryParams(window.location.href);

    if (decisionMatrixItems?.length > 0 && queryStringObj?.active === 'decision-matrix') {
      const findDecisionMatrix = decisionMatrixItems.find((data: any) => data?.id === queryStringObj.id);

      dispatch(setSelectedDecisionMatrix(findDecisionMatrix || decisionMatrixItems[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decisionMatrixItems]);

  const gettingDecisionMatrixById = useCallback(
    (id: string) => {
      const fetchData = async () => {
        setDecisionMatrixByIdLoader(true);
        try {
          const accessToken = await getAccessTokenSilently();
          if (accessToken)
            await dispatch(
              getDecisionMatrixById({
                accessToken,
                id,
                project_id: getLastIdFromUrl(window.location.pathname),
              })
            );
          setDecisionMatrixByIdLoader(false);
        } catch (error) {
          console.error('Error getting access token:', error);
          setDecisionMatrixByIdLoader(false);
        }
      };

      fetchData();
    },
    [dispatch, getAccessTokenSilently]
  );

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = useCallback(
    (id: string) => {
      setIsEditMode(true);
      setOpen(true);

      if (id !== decisionMatrixByIdRes?.id) {
        gettingDecisionMatrixById(id);
      }
    },
    [decisionMatrixByIdRes?.id, gettingDecisionMatrixById]
  );

  const deletingDecisionMatrix = () => {
    const fetchData = async () => {
      setDeleteLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(
            deleteDecisionMatrix({
              accessToken,
              id: selectedData?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          setDeleteLoader(false);
          closeConfirmationModal();

          await dispatch(
            getDecisionMatrixList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          )
            .then((res) => {
              if (res?.payload?.data?.length > 0) {
                dispatch(setSelectedDecisionMatrix(res?.payload?.data[0]));
              }
            })
            .catch((err) => console.log('Props Cons', err));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setDeleteLoader(false);
      }
    };

    fetchData();
  };

  const updatingDecisionMatrix = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateDecisionMatrix({
              body,
              accessToken,
              id: decisionMatrixByIdRes?.id,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(
            getDecisionMatrixList({
              accessToken,
              project_id: getLastIdFromUrl(window.location.pathname),
            })
          );
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingDecisionMatrix = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (!accessToken) {
          throw new Error('No access token');
        }

        const res = await dispatch(
          createDecisionMatrix({
            body,
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        );

        dispatch(
          getDecisionMatrixList({
            accessToken,
            project_id: getLastIdFromUrl(window.location.pathname),
          })
        ).catch((err) => console.log('Error fetching decision matrix list: ', err));

        const { requestStatus } = res?.meta;
        const decisionMatrixId = res?.payload?.data?.id;

        if (requestStatus === 'fulfilled' && decisionMatrixId) {
          onCloseModal();
          navigate({
            search: new URLSearchParams({
              active: 'decision-matrix',
              id: decisionMatrixId,
            }).toString(),
          });
        }
      } catch (error) {
        console.error('Error creating decision matrix:', error);
      } finally {
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitDecisionMatrix = async (body: any) => {
    if (isEditMode) {
      updatingDecisionMatrix(body);
    } else {
      creatingDecisionMatrix(body);
    }
  };

  const deleteConfirmationModal = (data: any) => {
    setSelectedData(data);
    setDeleteConfirmation(true);
  };

  const closeConfirmationModal = () => {
    setDeleteConfirmation(false);
    setSelectedData(null);
  };

  const onSelectedDecisionMatrix = async (data: any) => {
    dispatch(setSelectedDecisionMatrix(data));
  };

  useEffect(() => {
    const listener = () => {
      if (decisionMatrixByIdRes?.id) {
        onOpenEditModal(decisionMatrixByIdRes.id);
      }
    };

    document.addEventListener('decision-matrix:edit', listener);

    return () => document.removeEventListener('decision-matrix:edit', listener);
  }, [onOpenEditModal, decisionMatrixByIdRes?.id]);

  return (
    <>
      <WrapperLoader loading={allDecisionMatrixLoading}>
        <div className='p-0'>
          <div className='mb-3'>
            <h3 className='h-toolbar text-md mb-2 border-b border-zinc-100 flex items-center'>Decision Matrix</h3>
            <div
              onClick={() => onOpenModal()}
              className='w-full p-2 border border-zinc-200 border-dashed rounded-md text-center cursor-pointer hover:border-blue-400'
            >
              <RoundButton onClickBtn={() => console.log('clicking...')} />
            </div>
          </div>

          <div
            className='overflow-y-hidden hover:overflow-y-auto transition-all'
            style={{ height: 'calc(100vh - 169px)' }}
          >
            <ul className='mt-3 grid grid-cols-1'>
              {decisionMatrixItems.map((item: any, index: number) => (
                <div key={index}>
                  <DecisionMatrixBox
                    totalLength={decisionMatrixItems?.length}
                    key={index}
                    data={item}
                    onEdit={() => onOpenEditModal(item?.id)}
                    onDelete={() => deleteConfirmationModal(item)}
                    deleteLoader={false}
                    selectedData={selectedData}
                    onSelectDecisionMatrix={() => onSelectedDecisionMatrix(item)}
                    selectedDecisionMatrix={selectedDecisionMatrix}
                  />
                </div>
              ))}
            </ul>
          </div>
        </div>
      </WrapperLoader>

      <CustomDrawer.DecisionMatrix
        open={open}
        onCloseModal={onCloseModal}
        isEditMode={isEditMode}
      >
        <WrapperLoader loading={decisionMatrixByIdLoader}>
          <DecisionMatrixForm
            onCloseModal={onCloseModal}
            editData={decisionMatrixByIdRes?.details}
            onSubmitForm={onSubmitDecisionMatrix}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer.DecisionMatrix>

      <ConfirmationModal
        open={deleteConfirmation}
        closingModal={() => closeConfirmationModal()}
        onDeleting={deletingDecisionMatrix}
        deleteLoader={deleteLoader}
        title="Decision Matrix Delete Confirmation"
        description={
          <span>
            Are you sure you want to delete <strong>{selectedData?.title}</strong>?
            This change is <strong className='text-red-700'> irreversible</strong>.
          </span>
        }
      />
    </>
  );
};

export default DecisionMatrixList;
