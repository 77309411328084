import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import InputField from '../../../components/formFields/inputField';
import CustomButton from '../../../components/customButton';
import TextAreaField from '../../../components/formFields/textareaField';
import SelectField from '../../../components/formFields/selectField';
import { categoriesList, getCategoryTitle, getCategoryValue, getStatusTitle, getStatusValue, statusList } from '../../../utils/utilities';
import { DRAWER_WIDTH } from 'src/config';
import { Category } from 'src/type';
import KeyboardShortcut from 'src/components/KeyboardShortcut';

interface CreationFormProps {
  onCloseModal: () => void;
  editData: any;
  onSubmitForm: Function;
  loader: boolean;
  editMode: boolean;
}

export default function CreationForm({ onCloseModal, editData, onSubmitForm, loader, editMode }: CreationFormProps) {
  const [selectedStatus, setSelectedStatus] = useState('Not Started');
  const [selectedCategory, setSelectedCategory] = useState('Cloud Architecture');

  const validationObject = {
    title: yup.string().required('Title is required'),
  };

  const validationSchema = yup.object().shape(validationObject);

  const formik: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: editMode ? editData?.title : '',
      description: editMode ? editData?.matrix_description : '',
      assumptions: editMode ? editData?.assumptions : '',
      implications: editMode ? editData?.implications : '',
      final_decision: editMode ? editData?.final_decision : '',
    },
    validationSchema,
    onSubmit: (values) => {
      const payloadObj = {
        title: values?.title,
        matrix_description: values?.description,
        matrix_status: selectedStatus,
        category: selectedCategory,
        assumptions: values?.assumptions,
        final_decision: values?.final_decision,
        implications: values?.implications,
      };

      onSubmitForm(payloadObj);
    },
  });

  useEffect(() => {
    if (editMode && editData?.complexity) {
      setSelectedCategory(editData?.category || 'Cloud Architecture');
      setSelectedStatus(editData?.status || 'Not Started');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  const eachFieldRow =
    'items-start space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5 border-b border-gray-200';

  return (
    <form
      className="flex flex-col bg-white shadow-xl rounded-b-lg"
      onSubmit={formik.handleSubmit}
    >
      {/* container */}
      <div
        className="overflow-y-scroll"
        style={{
          height: editMode ? "calc(100vh - 221px)" : "calc(100vh - 237px)",
        }}
      >
        {/* Title */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="title"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Title
            </label>
          </div>
          <div className="sm:col-span-2">
            <InputField
              type="text"
              id="title"
              {...formik.getFieldProps("title")}
              placeholder="Enter project name"
            />

            {formik.touched.title && formik.errors.title ? (
              <div className="text-red-600 text-xs">{formik.errors.title}</div>
            ) : null}
          </div>
        </div>

        {/* Description */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="description"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Description
            </label>
          </div>
          <div className="sm:col-span-2">
            <TextAreaField
              id="description"
              {...formik.getFieldProps("description")}
              placeholder="Enter a brief description of your project."
            />
          </div>
        </div>

        {/* Status */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="status"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Status
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectField
              options={statusList}
              value={getStatusValue(selectedStatus)}
              onSelect={(statusValue) =>
                setSelectedStatus(getStatusTitle(statusValue))
              }
            />
          </div>
        </div>

        {/* Category */}
        <div className={eachFieldRow}>
          <div>
            <label
              htmlFor="category"
              className="block text-xs font-medium leading-6 text-customDarkBlue"
            >
              Category
            </label>
          </div>
          <div className="sm:col-span-2">
            <SelectField
              options={categoriesList}
              value={getCategoryValue(selectedCategory)}
              onSelect={(categoryValue) =>
                setSelectedCategory(getCategoryTitle(categoryValue as Category))
              }
            />
          </div>
        </div>

        {editMode ? (
          <>
            {/* Assumptions */}
            <div className={eachFieldRow}>
              <div>
                <label
                  htmlFor="description"
                  className="block text-xs font-medium leading-6 text-customDarkBlue"
                >
                  Assumptions
                </label>
              </div>
              <div className="sm:col-span-2">
                <TextAreaField
                  id="assumptions"
                  {...formik.getFieldProps("assumptions")}
                  placeholder="Enter a brief assumptions of your project."
                />
              </div>
            </div>

            {/* Implications */}
            <div className={eachFieldRow}>
              <div>
                <label
                  htmlFor="description"
                  className="block text-xs font-medium leading-6 text-customDarkBlue"
                >
                  Implications
                </label>
              </div>
              <div className="sm:col-span-2">
                <TextAreaField
                  id="implications"
                  {...formik.getFieldProps("implications")}
                  placeholder="Enter a brief implications of your project."
                />
              </div>
            </div>

            {/* Final Decision */}
            <div className={eachFieldRow}>
              <div>
                <label
                  htmlFor="description"
                  className="block text-xs font-medium leading-6 text-customDarkBlue"
                >
                  Final Decision
                </label>
              </div>
              <div className="sm:col-span-2">
                <TextAreaField
                  id="final_decision"
                  {...formik.getFieldProps("final_decision")}
                  placeholder="Enter a brief final decision of your project."
                />
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>

      {/* Action buttons */}
      <div
        className="bg-white border-t border-gray-200 px-4 py-5 rounded-b-lg sm:px-6"
        style={{ width: `${DRAWER_WIDTH - 40}px` }}
      >
        <div className="flex justify-end space-x-3">
          <CustomButton
            type="button"
            onClickBtn={onCloseModal}
            text="Cancel"
            buttonType="secondary"
            btnStyle="mr-2"
            buttonSize='md'
          />

          <CustomButton
            type="submit"
            onClickBtn={() => console.log("Clicking...")}
            text="Save"
            loading={loader}
            beforeIcon={
              <KeyboardShortcut.ControlEnter
                className="mr-2"
                onTrigger={formik.handleSubmit}
              />
            }
            buttonType="primary"
            buttonSize='md'
          />
        </div>
      </div>
    </form>
  );
}
