import { useCallback, useEffect, useRef } from 'react';

export function useResizeObserver(callback: ResizeObserverCallback, options?: ResizeObserverOptions) {
    const elementRef = useRef<HTMLElement | null>(null);
    const observerRef = useRef<ResizeObserver>();

    useEffect(() => {
        const observer = new ResizeObserver(callback);

        if (elementRef.current) {
            observer.observe(elementRef.current, options);
        }

        observerRef.current = observer;

        return () => {
            observer.disconnect();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, JSON.stringify(options)]);

    return useCallback((element: HTMLElement | null) => {
        if (elementRef.current) {
            observerRef.current?.unobserve(elementRef.current);
        }

        if (element) {
            observerRef.current?.observe(element, options);
        }

        elementRef.current = element;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}
