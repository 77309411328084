import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import profileReducer from "./profile/profileSlice";
import tbarsReducer from "./tbars/tbarsSlice";
import tbarsArgumentsReducer from "./tbarsArguments/tbarsArgumentsSlice";
import prosConsReducer from "./prosCons/prosConsSlice";
import prosConsArgumentsReducer from "./prosConsArguments/prosConsArgumentsSlice";
import swotReducer from "./swot/swotSlice";
import swotArgumentsReducer from "./swotArguments/swotArgumentsSlice";
import decisionMatrixReducer from "./decisionMatrix/decisionMatrixSlice";
import decisionMatrixAnalysisReducer from "./decisionMatrixAnalysis/decisionMatrixAnalysisSlice";
import projectsReducer from "./projects/projectsSlice";
import chatGPTReducer from "./chatGPT/chatGPTSlice";
import userAiAssistantsReducer from "./userAiAssistants/userAiAssistantsSlice";
import templatesReducer from "./templates/templatesSlice";
import commentsReducer from "./comments/commentsSlice";
import documentComponentsReducer from "./documentComponents/documentComponentsSlice";
import diagramsSliceReducer from "./diagrams/diagramsSlice";

const authPersistConfig = {
  key: "auth",
  storage,
  whitelist: [],
};

const rootReducer = combineReducers({
  profile: persistReducer(authPersistConfig, profileReducer),
  tbars: tbarsReducer,
  tbarArguments: tbarsArgumentsReducer,
  prosCons: prosConsReducer,
  prosConsArguments: prosConsArgumentsReducer,
  swot: swotReducer,
  swotArguments: swotArgumentsReducer,
  decisionMatrixs: decisionMatrixReducer,
  decisionMatrixAnalysis: decisionMatrixAnalysisReducer,
  projects: projectsReducer,
  chatGPT: chatGPTReducer,
  userAiAssistants: userAiAssistantsReducer,
  templates: templatesReducer,
  comments: commentsReducer,
  documentComponents: documentComponentsReducer,
  diagrams: diagramsSliceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
