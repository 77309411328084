import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../hooks";
import {
  LightBulbIcon,
  PlusIcon,
	ChevronDownIcon
} from "@heroicons/react/24/outline";
import ItemWithDropdown from "../itemWithDropdown";
import CustomButton from "../customButton";
import { setTemplatesCurrentTab } from "../../redux/profile/profileSlice";
import TemplatesProjectsDocumentsModal from "../../pages/templates/templatesProjectsDocumentsModal";
import CustomDrawer from "../customDrawer";
import CreateProjectForm from "../../pages/projects/CreationForm";
import {
  getProjectsList,
  createProject,
} from "../../redux/projects/projectsApi";

const NewProjectButton = () => {
  const [openDocumentsTemplatesModal, setOpenDocumentsTemplatesModal] =
    useState(false);
  const [openProject, setOpenProject] = useState(false);
  const [formLoader, setFormLoader] = useState(false);

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const openingTemplateDocuments = (value: number) => {
    dispatch(setTemplatesCurrentTab(value));
    setOpenDocumentsTemplatesModal(true);
  };

  const gettingProjectsList = () => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectsList(accessToken));
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    fetchData();
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken })).then((res) => {
            if (res) {
              setOpenProject(false);
              setFormLoader(false);
              gettingProjectsList();
              navigate(`/projects`);
            }
          });
        }
      } catch (error) {
        console.error("Error getting access token:", error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const actionsList = [
    {
      id: 1,
      name: "Create New",
      onActionClick: () => setOpenProject(true),
      icon: (
        <LightBulbIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 3,
      name: "Community Templates",
      onActionClick: () => openingTemplateDocuments(2),
      icon: (
        <LightBulbIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
    {
      id: 4,
      name: "My Templates",
      onActionClick: () => openingTemplateDocuments(3),
      icon: (
        <LightBulbIcon className="mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue" />
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center relative">
        <CustomButton
          text="New Design"
          type="button"
          onClickBtn={() => setOpenProject(true)}
          beforeIcon={<PlusIcon className="h-4 mr-1" />}
          btnStyle="mr-[2px] rounded-r-none"
        />

        <ItemWithDropdown actionsData={actionsList}>
          <CustomButton
            text=""
            type="button"
            onClickBtn={() => console.log("Clicking...")}
            beforeIcon={<ChevronDownIcon className="h-4 font-bold" />}
            btnStyle="rounded-l-none px-[6px]"
          />
        </ItemWithDropdown>
      </div>

      {openDocumentsTemplatesModal && (
        <TemplatesProjectsDocumentsModal
          onCloseModal={() => setOpenDocumentsTemplatesModal(false)}
          openModal={openDocumentsTemplatesModal}
          onUseTemplate={() => console.log("Creating Document")}
        />
      )}

      <CustomDrawer.Project
        open={openProject}
        onCloseModal={() => setOpenProject(false)}
      >
        <CreateProjectForm
          onCloseModal={() => setOpenProject(false)}
          editData={null}
          onSubmitForm={creatingProject}
          loader={formLoader}
          editMode={false}
        />
      </CustomDrawer.Project>
    </>
  );
};

export default NewProjectButton;
