import { useSearchParams } from 'react-router-dom';

type SearchParamsSetter<T> = {
  set: <K extends keyof T>(key: K, value: T[K]) => void;
  append: <K extends keyof T>(key: K, value: T[K]) => void;
  delete: <K extends keyof T>(key: K) => void;
};
type AppSearchParamsHook<T> = [T, SearchParamsSetter<T>];

export function useAppSearchParams<T extends Record<string, string | string[]>>(
  defaults: Partial<T> = {}
): AppSearchParamsHook<T> {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateParams = (callback: (curr: URLSearchParams) => URLSearchParams) => {
    setSearchParams(
      (curr) => {
        const newSearchParams = new URLSearchParams(curr);

        return callback(newSearchParams);
      },
      { replace: true }
    );
  };

  const setter: SearchParamsSetter<T> = {
    set: (key, value) =>
      updateParams((newSearchParams) => {
        newSearchParams.delete(String(key));

        if (Array.isArray(value)) {
          value.forEach((val) => {
            newSearchParams.append(String(key), val);
          });
        } else if (defaults[key] !== value) {
          newSearchParams.set(String(key), value);
        }

        return newSearchParams;
      }),
    append: (key, value) =>
      updateParams((newSearchParams) => {
        newSearchParams.delete(String(key));

        if (Array.isArray(value)) {
          value.forEach((val) => {
            newSearchParams.append(String(key), val);
          });
        } else {
          newSearchParams.append(String(key), value);
        }

        return newSearchParams;
      }),
    delete: (key) =>
      updateParams((newSearchParams) => {
        newSearchParams.delete(String(key));
        return newSearchParams;
      }),
  };

  return [
    {
      ...defaults,
      ...Object.fromEntries(searchParams),
    } as T,
    setter,
  ];
}
