// kubernetes_nodes.js

export const kubernetes_nodes = [
  {
    name: "Control Plane",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: ["api", "c-c-m", "c-m", "k-proxy", "kubelet", "sched"],
  },
  {
    name: "Infrastructure",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: ["control-plane", "etcd", "node"],
  },
  {
    name: "Resources",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",
    items: [
      "c-role",
      "cm",
      "crb",
      "crd",
      "cronjob",
      "deploy",
      "ds",
      "ep",
      "group",
      "hpa",
      "ingress",
      "job",
      "limits",
      "name-space",
      "netpol",
      "pod",
      "psp",
      "pv",
      "pvc",
      "quota",
      "rb",
      "role",
      "rs",
      "sa",
      "sc",
      "secret",
      "sts",
      "svc",
      "user",
      "vol",
    ],
  },
];
