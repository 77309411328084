import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { PlusIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getProjectsList,
  getProjectById,
  createProject,
  updateProject,
  deleteProject,
} from '../../redux/projects/projectsApi';
import CustomDrawer from '../../components/customDrawer';
import CreationForm from './CreationForm';
import WrapperLoader from '../../components/wrapperLoader';
import SearchField from '../../components/searchField';
import ConfirmationModal from '../../components/confirmationModal';
import CompleteEmptyPage from '../../components/completeEmptyPage';
import NewProjectButton from '../../components/newProjectButton';
import CustomButton from '../../components/customButton';
import { setCollapseSidebar } from '../../redux/profile/profileSlice';
import ProjectListBox from '../../components/projectListBox';
import SelectField from '../../components/formFields/selectField';
import { clearableCategoriesList, clearableStatusList } from '../../utils/utilities';

import designSVG from '../../assets/images/Illustration-07.svg';
import { useAppSearchParams } from 'src/hooks/useAppSearchParams';
import { Category } from 'src/type';
import { pipe } from 'src/utils/pipe';
import { filter } from 'src/utils/filter';

// interface Item {
//   content: string;
//   created_at: string;
//   document_order: number;
//   id: string;
//   project_id: string;
//   title: string;
//   updated_at: string;
//   user_id: string;
// }

const Projects = () => {
  const [open, setOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState<any>(null);
  const [formLoader, setFormLoader] = useState(false);
  const [projectByIdLoader, setProjectByIdLoader] = useState(false);
  const [confirmationLoader, setConfirmationLoader] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const [{ category: selectedCategory, status: selectedStatus, search }, searchParamsSetter] = useAppSearchParams<{
    category: Category;
    status: string;
    search: string;
  }>({
    category: 'all',
    status: 'all',
    search: '',
  });

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { projectsList, projectsByIdRes, loadingProjects } = useAppSelector((state) => state.projects);

  const projectsCompleteList = pipe(
    () => projectsList,
    filter.byStatus(selectedStatus),
    filter.byCategory(selectedCategory),
    filter.byTitle(search)
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectsList(accessToken));
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    };

    fetchData();
  }, [getAccessTokenSilently, dispatch]);

  const onOpenModal = () => {
    setOpen(true);
    setIsEditMode(false);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsEditMode(false);
  };

  const onOpenEditModal = (id: string) => {
    setIsEditMode(true);
    setOpen(true);
    gettingProjectById(id);
  };

  const updatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(
            updateProject({
              body,
              accessToken,
              id: projectsByIdRes?.id,
            })
          );
          onCloseModal();
          setFormLoader(false);

          await dispatch(getProjectsList(accessToken));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const creatingProject = (body: any) => {
    const fetchData = async () => {
      setFormLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();

        if (accessToken) {
          await dispatch(createProject({ body, accessToken }));
          onCloseModal();
          setFormLoader(false);

          await dispatch(getProjectsList(accessToken));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setFormLoader(false);
      }
    };

    fetchData();
  };

  const onSubmitProject = async (body: any) => {
    if (isEditMode) {
      updatingProject(body);
    } else {
      creatingProject(body);
    }
  };

  const gettingProjectById = (id: string) => {
    const fetchData = async () => {
      setProjectByIdLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) await dispatch(getProjectById({ accessToken, id }));
        setProjectByIdLoader(false);
      } catch (error) {
        console.error('Error getting access token:', error);
        setProjectByIdLoader(false);
      }
    };

    fetchData();
  };

  const deletingProject = () => {
    const fetchData = async () => {
      setConfirmationLoader(true);
      try {
        const accessToken = await getAccessTokenSilently();
        if (accessToken) {
          await dispatch(deleteProject({ accessToken, id: selectedItem?.id }));
          setConfirmationLoader(false);
          deletingModalClose();

          await dispatch(getProjectsList(accessToken));
        }
      } catch (error) {
        console.error('Error getting access token:', error);
        setConfirmationLoader(false);
      }
    };

    fetchData();
  };

  const deletingModalOpen = (data: any) => {
    setDeleteConfirm(true);
    setSelectedItem(data);
  };

  const deletingModalClose = () => {
    setDeleteConfirm(false);
    setSelectedItem(null);
  };

  return (
    <>
      <WrapperLoader loading={loadingProjects}>
        <div className="px-4">
          {projectsList?.length > 0 && (
            <div className="py-4 mb-2 border-b border-gray-100 text-right">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="w-80 mr-3">
                    <SearchField
                      onSearchField={(value: string) =>
                        searchParamsSetter.set("search", value)
                      }
                      searchValue={search}
                      classes='!h-[48px] !text-[16px] !rounded-[12px]'
                    />
                  </div>
                  <div className="mr-3">
                    <SelectField
                      options={clearableStatusList}
                      onSelect={(newStatus) =>
                        searchParamsSetter.set("status", newStatus)
                      }
                      value={selectedStatus}
                      label="Status"
                      classes="py-[6px] !h-[48px] !text-[16px] !rounded-[12px]"
                    />
                  </div>

                  <div className="mr-3">
                    <SelectField
                      options={clearableCategoriesList}
                      onSelect={(newCategory) =>
                        searchParamsSetter.set(
                          "category",
                          newCategory as Category
                        )
                      }
                      value={selectedCategory}
                      label="Category"
                      classes="py-[6px] !h-[48px] !text-[16px] !rounded-[12px]"
                    />
                  </div>
                </div>

                <CustomButton
                  text="New Project"
                  type="button"
                  onClickBtn={() => onOpenModal()}
                  beforeIcon={<PlusIcon className="h-4 mr-1" />}
                  afterIcon={<ChevronRightIcon className="h-4 ml-1" />}
                  badgeNumber={projectsCompleteList?.length}
                  buttonType="primary"
                  buttonSize='lg'
                />
              </div>
            </div>
          )}

          <div className="ml-[-4px] mr-[-6px]">
            <div
              className="w-full overflow-y-auto"
              style={{
                height: "calc(100vh - 130px)",
              }}
            >
              {projectsCompleteList?.length > 0 && (
                <div className="grid grid-cols-1 [@media(min-width:1250px)]:grid-cols-3 [@media(min-width:1500px)]:grid-cols-4 [@media(min-width:2000px)]:grid-cols-5 gap-4">
                  {projectsCompleteList?.map((item: any, index: number) => {
                    return (
                      <ProjectListBox
                        key={index}
                        data={item}
                        onShow={() => {
                          navigate(`/projects/${item?.id}?active=documents`);
                          dispatch(setCollapseSidebar(true));
                        }}
                        onEdit={onOpenEditModal}
                        onDelete={deletingModalOpen}
                      />
                    );
                  })}
                </div>
              )}

              {(projectsList?.length === 0 || !projectsList) && (
                <div
                  className="flex items-center justify-center"
                  style={{ height: "calc(100vh - 140px)" }}
                >
                  <div>
                    <CompleteEmptyPage
                      title="Get started by creating a new project"
                      subTitle="Side by side with AI create high quality documentation for any project, process, product or IT Design. You can create a new project from scratch by clicking the button below or leverage the templates created our team or by the community."
                      btnText="new"
                      icon={designSVG}
                    />

                    <div className="flex items-center justify-center relative mt-4">
                      <NewProjectButton />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </WrapperLoader>
      <CustomDrawer.Project
        open={open}
        onCloseModal={onCloseModal}
        isEditMode={isEditMode}
      >
        <WrapperLoader loading={projectByIdLoader}>
          <CreationForm
            onCloseModal={onCloseModal}
            editData={projectsByIdRes}
            onSubmitForm={onSubmitProject}
            loader={formLoader}
            editMode={isEditMode}
          />
        </WrapperLoader>
      </CustomDrawer.Project>

      <ConfirmationModal
        description={
          <span>
            Are you sure you want to delete
            <strong> {selectedItem?.title}</strong> and all its components? This
            change is
            <strong className="text-red-700"> irreversible</strong>.
          </span>
        }
        open={deleteConfirm}
        closingModal={() => deletingModalClose()}
        onDeleting={deletingProject}
        deleteLoader={confirmationLoader}
      />
    </>
  );
};

export default Projects;
