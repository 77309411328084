import {
  AdjustmentsHorizontalIcon,
  ChevronLeftIcon,
  CubeIcon,
  DocumentDuplicateIcon,
  LightBulbIcon,
  PencilSquareIcon,
  PuzzlePieceIcon,
  ScaleIcon,
  Squares2X2Icon,
  TableCellsIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';

import Toolbar, { ToolbarItemType } from '../toolbar';
import { DetailsContext } from 'src/contexts/details/context';
import { useAppSelector } from 'src/hooks/store';
import { removeAllMediaElements } from 'src/utils/utilities';
import { useNavigate, useParams } from 'react-router-dom';
import CustomButton from '../customButton';
import KeyboardShortcut, { Keys } from '../KeyboardShortcut';
import { OpenSpecialChatFn } from 'src/type';
import { getValidateList } from 'src/utils/special-chat-assistants';

interface DocumentHeaderProps {
  updatingProjectDocument: Function;
  documentSaveLoading: boolean;
  onOpenSaveProjectTemp?: Function;
  openSpecialChat: OpenSpecialChatFn;
}

export default function DocumentHeader({
  updatingProjectDocument,
  documentSaveLoading,
  onOpenSaveProjectTemp,
  openSpecialChat,
}: DocumentHeaderProps) {
  const [, setAutoSaveLoader] = useState(false);

  const { projectsByIdRes } = useAppSelector((state) => state.projects);
  const { type: contextType, toggleAutoSave, updateDocument, selectedDocument, editorRef } = useContext(DetailsContext);

  const navigate = useNavigate();
  const { id: projectOrTemplateId } = useParams<{ id: string }>();

  const updatingProject = async () => {
    setAutoSaveLoader(true);

    try {
      await toggleAutoSave();
    } finally {
      setAutoSaveLoader(false);
    }
  };

  const updatingDocument = async (body: Record<string, any>) => {
    if (!selectedDocument || !projectsByIdRes) return;

    try {
      await updateDocument(
        {
          ...body,
          project_id: projectsByIdRes.id,
        },
        selectedDocument
      );
    } catch {}
  };

  const saveAsList = [
    {
      id: 1,
      name: 'Template',
      onActionClick: () => (onOpenSaveProjectTemp ? onOpenSaveProjectTemp(true) : console.log('...')),
      icon: <LightBulbIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 2,
      name: 'Design Standard',
      onActionClick: () => console.log('...'),
      icon: <PuzzlePieceIcon className='mr-3 h-4 w-4 text-gray-400' />,
      disabled: true,
      tag: 'Coming Soon',
    },
  ];

  const validateList = getValidateList('document', (conversationContext) =>
    openSpecialChat(conversationContext, {
      entityId: selectedDocument?.id,
      data: removeAllMediaElements(editorRef.current?.getData() || ''),
    })
  );

  const generateList = [
    {
      id: 1,
      name: 'Diagram',
      onActionClick: () => console.log('...'),
      icon: <CubeIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 2,
      name: 'T-Chart',
      onActionClick: () => console.log('...'),
      icon: <ScaleIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 3,
      name: 'Pros & Cons',
      onActionClick: () => console.log('...'),
      icon: <TableCellsIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 4,
      name: 'Decision Martix',
      onActionClick: () => console.log('...'),
      icon: <AdjustmentsHorizontalIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
    {
      id: 5,
      name: 'SWOT',
      onActionClick: () => console.log('...'),
      icon: <Squares2X2Icon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
    },
  ];

  const renderList: ToolbarItemType[] = [
    // new Toolbar.ChatSelectorItem({
    //   id: 1,
    //   selected: selectedDocument?.ai_conversation_id,
    //   setSelected: (chatId) => updatingDocument({ ai_conversation_id: chatId }),
    // }),
    contextType === 'project' && {
      id: 2,
      name: 'Generate',
      actions: generateList,
      width: 120,
    },
    new Toolbar.ValidateItem({
      id: 3,
      actions: validateList,
    }),
    contextType === 'project' && {
      id: 4,
      name: 'Save As',
      actions: saveAsList,
      width: 115,
      dropdownClassName: 'w-[17rem]',
    },
    {
      id: 5,
      name: 'Save',
      onActionClick: () => updatingProjectDocument(),
      width: 155,
      renderInToolbar: ({ name, onActionClick }) => (
        <CustomButton
          text={name}
          type='button'
          onClickBtn={onActionClick as Function}
          beforeIcon={<DocumentDuplicateIcon className='h-4 mr-1' />}
          afterIcon={
            <KeyboardShortcut
              keys={[Keys.Control, { key: 's', ui: <span>S</span> }]}
              withSeparator
              onTrigger={() => {
                const isEditorFocused = !!editorRef.current?.editing.view.document.isFocused;

                if (isEditorFocused) {
                  (onActionClick as VoidFunction)?.();
                }
              }}
              themed
            />
          }
          buttonType='secondary'
          loading={documentSaveLoading}
        />
      ),
      renderInDropdown: ({ name, renderButtonContent }) =>
        renderButtonContent({
          name,
          icon: <DocumentDuplicateIcon className='mr-3 h-4 w-4 text-gray-400 group-hover:text-customLightBlue' />,
        }),
    },
    // new Toolbar.ButtonItem({
    //   id: 5,
    //   name: 'Save',
    //   onActionClick: () => updatingProjectDocument(),
    //   loading: documentSaveLoading,
    //   iconComponent: DocumentDuplicateIcon,
    //   width: 80,
    // }),
  ];

  const actionsList = [
    new Toolbar.ButtonItem({
      id: 6,
      name: 'Edit',
      onActionClick: () => {
        document.dispatchEvent(new CustomEvent('document:edit'));
      },
      iconComponent: PencilSquareIcon,
      width: 0,
    }),
    new Toolbar.ButtonItem({
      id: 7,
      name: 'Delete',
      onActionClick: () => {
        document.dispatchEvent(new CustomEvent('document:delete'));
      },
      iconComponent: TrashIcon,
      width: 0,
    }),
  ];

  return (
    <Toolbar.Container>
      <Toolbar>
        <Toolbar.Left>
          {contextType === 'template' && (
            <CustomButton
              text='Back'
              type='button'
              onClickBtn={() => navigate(`/template/${projectOrTemplateId}`)}
              beforeIcon={<ChevronLeftIcon className='h-4 mr-1' />}
              buttonType='secondary'
              btnStyle='mr-4'
            />
          )}
          {contextType === 'project' && (
            <Toolbar.Switch
              enabled={projectsByIdRes?.autosave}
              setEnabled={() => updatingProject()}
            >
              Auto Save
            </Toolbar.Switch>
          )}
          <Toolbar.Switch
            setEnabled={() => updatingDocument({ ai_suggestions: !selectedDocument?.ai_suggestions })}
            enabled={!!selectedDocument?.ai_suggestions}
          >
            Assistant
          </Toolbar.Switch>
        </Toolbar.Left>
        <Toolbar.Right
          visibleItems={renderList}
          hiddenItems={contextType === 'project' ? actionsList : []}
        />
      </Toolbar>
    </Toolbar.Container>
  );
}
