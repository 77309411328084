import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getRequest,
  postRequest
} from "../../utils/api";

export const getDocumentComponents = createAsyncThunk(
  "getDocumentComponents/get",
  async ({ accessToken }: any) => {
    try {
      const response = await getRequest({
        url: `/dcm/components`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getDocumentComponentDetail = createAsyncThunk(
  "getDocumentComponentDetail/get",
  async ({ accessToken, id }: any) => {
    try {
      const response = await getRequest({
        url: `/dcm/component?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const getDocumentFavoriteComponents = createAsyncThunk(
  "getDocumentFavoriteComponents/get",
  async ({ accessToken }: any) => {
    try {
      const response = await getRequest({
        url: `/dcm/favoriteComponents`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const pinDocumentComponent = createAsyncThunk(
  "pinDocumentComponent/get",
  async ({ accessToken, id }: any) => {
    try {
      const response = await postRequest({
        payload: {},
        url: `/dcm/pinComponent?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

export const unpinDocumentComponent = createAsyncThunk(
  "unpinDocumentComponent/get",
  async ({ accessToken, id }: any) => {
    try {
      const response = await postRequest({
        payload: {},
        url: `/dcm/unpinComponent?id=${id}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response;
    } catch (error: any) {
      throw error.response;
    }
  }
);

