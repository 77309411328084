import React from "react";
import TemplatesData from "./templatesData";

export default function AiAssistants() {
  return (
    <div className="w-full">
      <TemplatesData isModal={false} tempType="my"  />
    </div>
  );
}
