import type { Location } from 'react-router-dom';

export function isAiAssistantPage({ pathname }: Location, type?: 'community' | 'private') {
  const pathnameChunks = pathname.split('/').slice(1);

  if (type === 'community') {
    return pathnameChunks[0] === 'community-ai-assistants' && pathnameChunks.length >= 2;
  }

  if (type === 'private') {
    return pathnameChunks[0] === 'ai-assistant' && pathnameChunks.length >= 2;
  }

  return false;
}
