interface RangeInputProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  onValueChange: (value: string) => void;
  id?: string;
  min: number;
  max: number;
  step: number;
  className?: string;
}

const RangeInput: React.FC<RangeInputProps> = ({
  value,
  onValueChange,
  id,
  min,
  max,
  step,
  className = '',
  ...props
}) => {
  const count = Math.floor((max - min) / step) + 1;
  const labels = Array.from({ length: count }, (_, i) => (min + i * step).toFixed(1));

  return (
    <div
      className={className}
      {...props}
    >
      <div className='relative -left-px flex justify-between text-xs text-zinc-400 mb-1'>
        {labels.map((label, index) => (
          <span key={index}>{label}</span>
        ))}
      </div>

      <input
        type='range'
        id={id}
        min={min}
        max={max}
        value={value}
        step={step}
        className='w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700'
        onChange={(e) => onValueChange(e.target.value)}
      />
    </div>
  );
};

export default RangeInput;
