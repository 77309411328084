import { createSlice } from "@reduxjs/toolkit";
import {
  getProsConsArgumentsList,
  createProsConsArgument,
  updateProsConsArgument,
  deleteProsConsArgument
} from "./prosConsArgumentsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  prosConsArgumentsList: any[];
  createProsConsArgumentsRes: any;
  updateProsConsArgumentsRes: any;
  prosConsArgumentDeleteRes: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  prosConsArgumentsList: [],
  createProsConsArgumentsRes: null,
  updateProsConsArgumentsRes: null,
  prosConsArgumentDeleteRes: null
};
const prosConsArgumentsSlice = createSlice({
  name: "prosConsArguments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Pros Cons Arguments
    builder.addCase(getProsConsArgumentsList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getProsConsArgumentsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.prosConsArgumentsList = action.payload?.data;
    });
    builder.addCase(getProsConsArgumentsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Pros Cons Arguments
    builder.addCase(createProsConsArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createProsConsArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createProsConsArgumentsRes = action.payload?.data;
    });
    builder.addCase(createProsConsArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Pros Cons Arguments
    builder.addCase(updateProsConsArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateProsConsArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateProsConsArgumentsRes = action.payload?.data;
    });
    builder.addCase(updateProsConsArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Pros Cons Arguments
    builder.addCase(deleteProsConsArgument.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteProsConsArgument.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.prosConsArgumentDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteProsConsArgument.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default prosConsArgumentsSlice.reducer;
