import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const MULTI_SELECTED_OPTIONS = [
  { id: 1, name: 'documents' },
  { id: 2, name: 'diagrams' },
  { id: 4, name: 't-chart' },
  { id: 5, name: 'pros-cons' },
  { id: 6, name: 'decision-matrix' },
  { id: 7, name: 'swot' },
  { id: 8, name: 'settings' },
];

export function useMultiSelectedOption(supportedOptions?: string[]): [number, (id: number) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('active');
  const selectedOption = MULTI_SELECTED_OPTIONS.find(({ name }) => name === activeTab) || MULTI_SELECTED_OPTIONS[0];

  useEffect(() => {
    if (!supportedOptions) return;

    if (!supportedOptions.includes(selectedOption.name)) {
      setSearchParams((currParams) => {
        currParams.set('active', supportedOptions[0]);

        return currParams;
      }, { replace: true })

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption])

  const navigateToOption = (optionId: number) => {
    const option = MULTI_SELECTED_OPTIONS.find(({ id }) => id === optionId);

    if (option) {
      setSearchParams((currParams) => {
        currParams.set('active', option.name);

        return currParams;
      }, { replace: true });
    }
  };

  return [selectedOption.id, navigateToOption];
}
