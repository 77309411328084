import { createSlice } from "@reduxjs/toolkit";
import {
  getPublicCommentsList,
  createPublicComment,
  updatePublicComment,
  deletePublicComment,
} from "./commentsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  getPublicCommentsListRes: any;
  createPublicCommentRes: any;
  updatePublicCommentRes: any;
  deletePublicCommentRes: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  getPublicCommentsListRes: [],
  createPublicCommentRes: null,
  updatePublicCommentRes: null,
  deletePublicCommentRes: null,
};
const templatesSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Public List
    builder.addCase(getPublicCommentsList.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getPublicCommentsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.getPublicCommentsListRes = action.payload?.data;
    });
    builder.addCase(getPublicCommentsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create Public Comment
    builder.addCase(createPublicComment.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createPublicComment.fulfilled, (state, action) => {
      const previousData = [...state.getPublicCommentsListRes];

      state.error = null;
      state.message = action.payload?.message;

      // push on list
      state.getPublicCommentsListRes = [action.payload?.data, ...previousData];

      state.createPublicCommentRes = action.payload?.data;
    });
    builder.addCase(createPublicComment.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update Public Comment
    builder.addCase(updatePublicComment.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updatePublicComment.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const previousData = [...state.getPublicCommentsListRes];
      const previousIndex = previousData?.findIndex(
        (item) => item?.id === action.payload?.data?.id
      );

      if (previousIndex !== -1) {
        previousData[previousIndex] = action.payload.data;
        state.getPublicCommentsListRes = previousData;
      }

      state.updatePublicCommentRes = action.payload?.data;
    });
    builder.addCase(updatePublicComment.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete Public Comment
    builder.addCase(deletePublicComment.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deletePublicComment.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;

      const filterData = state.getPublicCommentsListRes?.filter(
        (item: { id: string }) => item?.id !== action.payload?.data?.id
      );
      state.getPublicCommentsListRes = filterData;
      
      state.deletePublicCommentRes = action.payload?.data;
    });
    builder.addCase(deletePublicComment.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default templatesSlice.reducer;
