import type { TransitionClasses } from '@headlessui/react';

export const DRAWER_WIDTH = 768; // previously 570

export const AI_COMMANDS = [
    {
        id: 0,
        label: 'Improve writing',
        instruction: 'improve writing',
    },
    {
        id: 1,
        label: 'Make shorter',
        instruction: 'make shorter',
    },
    {
        id: 2,
        label: 'Make longer',
        instruction: 'make longer',
    },
    {
        id: 3,
        label: 'Simplify language',
        instruction: 'simplify language',
    },
] as const;

export const DROPDOWN_TRANSITION_CLASSES: TransitionClasses = {
    enter: 'transition ease-out duration-100',
    enterFrom: 'transform opacity-0 scale-95',
    enterTo: 'transform opacity-100 scale-100',
    leave: 'transition ease-in duration-75',
    leaveFrom: 'transform opacity-100 scale-100',
    leaveTo: 'transform opacity-0 scale-95',
} as const;

export function getConfig() {
    const domain = `${process.env.REACT_APP_DOMAIN}`;
    const clientId = `${process.env.REACT_APP_CLIENTID}`;
    const audienceUrl = `${process.env.REACT_APP_AUDIENCE}`;
    // Configure the audience here. By default, it will take whatever is in the config
    // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
    // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
    // don't have an API).
    // If this resolves to `null`, the API page changes to show some helpful info about what to do
    // with the audience.
    const audience = audienceUrl && audienceUrl !== 'YOUR_API_IDENTIFIER' ? audienceUrl : null;

    return {
        domain: domain,
        clientId: clientId,
        ...(audience ? { audience } : null),
    };
}
