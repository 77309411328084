// amazon_aws.js

export const aws_nodes = [
  {
    name: "Compute",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",

    items: [
      "ec2_ami_48",
      "ec2_auto-scaling_48",
      "ec2_aws-microservice-extractor-for-.net_48",
      "ec2_db-instance_48",
      "ec2_elastic-ip-address_48",
      "ec2_instance-with-cloudwatch_48",
      "ec2_instance_48",
      "ec2_instances_48",
      "ec2_rescue_48",
      "ec2_spot-instance_48",
      "res_aws-elastic-beanstalk_application_48",
      "res_aws-elastic-beanstalk_deployment_48",
      "res_aws-lambda_lambda-function_48",
    ],
  },
  {
    name: "Containers",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",

    items: [
      "elastic-container-registry_image_48",
      "elastic-container-registry_registry_48",
      "elastic-container-service_container-1_48",
      "elastic-container-service_container-2_48",
      "elastic-container-service_container-3_48",
      "elastic-container-service_copiiot-cli_48",
      "elastic-container-service_ecs-service-connect_48",
      "elastic-container-service_service_48",
      "elastic-container-service_task_48",
      "elastic-kubernetes-service_eks-on-outposts_48",
    ],
  },
  {
    name: "Database",
    categoryIcon:
      "https://spnodedata.blob.core.windows.net/nodes/azure_clean/general/backlog.svg",

    items: [
      "aurora-instance_48",
      "aurora-mariadb-instance-alternate_48",
      "aurora-mariadb-instance_48",
      "aurora-mysql-instance-alternate_48",
      "aurora-mysql-instance_48",
      "aurora-oracle-instance-alternate_48",
      "aurora-oracle-instance_48",
      "aurora-piops-instance_48",
      "aurora-postgresql-instance-alternate_48",
      "aurora-postgresql-instance_48",
      "aurora-sql-server-instance-alternate_48",
      "aurora-sql-server-instance_48",
      "aurora_amazon-aurora-instance-alternate_48",
      "aurora_amazon-rds-instance-aternate_48",
      "aurora_amazon-rds-instance_48",
      "aurora_trusted-language-extensions-for-postgresql_48",
      "documentdb_elastic-clusters_48",
      "dynamodb_amazon-dynamodb-accelerator_48",
      "dynamodb_attribute_48",
      "dynamodb_attributes_48",
      "dynamodb_global-secondary-index_48",
      "dynamodb_item_48",
      "dynamodb_items_48",
      "dynamodb_standard-access-table-class_48",
      "dynamodb_standard-infrequent-access-table-class_48",
      "dynamodb_stream_48",
      "dynamodb_table_48",
      "elasticache_cache-node_48",
      "elasticache_elasticache-for-memcached_48",
      "elasticache_elasticache-for-redis_48",
      "rds-proxy-instance-alternate_48",
      "rds-proxy-instance_48",
      "rds_blue-green-deployments_48",
      "rds_multi-az-db-cluster_48",
      "rds_multi-az_48",
      "rds_optimized-writes_48",
      "rds_trusted-language-extensions-for-postgresql_48",
      "res_aws-database-migration-service_database-migration-workflow-or-job_48",
    ],
  },
];
