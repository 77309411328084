class Platform {
  readonly macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  readonly windowsPlatforms = /(win32|win64|windows|wince)/i;
  readonly linuxPlatforms = /(linux)/i;

  private _isMac = false;
  private _isWindows = false;
  private _isLinux = false;

  private test(platforms: RegExp, platformName: string) {
    if ('userAgentData' in navigator) {
      return (navigator.userAgentData as any).platform.includes(platformName);
    }

    return platforms.test(navigator.userAgent.toLowerCase());
  }

  constructor() {
    if (this.test(this.macosPlatforms, 'macOS')) {
      this._isMac = true;
    } else if (this.test(this.windowsPlatforms, 'Windows')) {
      this._isWindows = true;
    } else if (this.test(this.linuxPlatforms, 'Linux')) {
      this._isLinux = true;
    }
  }

  get isMac() {
    return this._isMac;
  }

  get isWindows() {
    return this._isWindows;
  }

  get isLinux() {
    return this._isLinux;
  }
}

export const platform = new Platform();
