import React, { useState } from "react";
import { ArrowLongLeftIcon, Square3Stack3DIcon } from "@heroicons/react/24/outline";
import CustomModal from "../../../components/customModal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setTemplatesCurrentTab } from "../../../redux/profile/profileSlice";
import DocumentsTemplates from "../documentsTemplates";
import { classNames } from "../../../utils/utilities";
import { CategoriesLeftSidebar } from "../../../components/categoriesLeftSidebar";

import logoIcon from "../../../assets/images/logo_icon.png";
import { useAppSearchParams } from "src/hooks/useAppSearchParams";
import { Category } from "src/type";

const documentsTemplatesTabsList: any = [
  { id: 2, name: "Community Templates" },
  { id: 3, name: "My Templates" },
];

interface TemplatesProjectsDocumentsModalProps {
  onCloseModal: Function;
  openModal: boolean;
  onUseTemplate: Function;
}

export default function TemplatesProjectsDocumentsModal({
  onCloseModal,
  openModal,
  onUseTemplate,
}: TemplatesProjectsDocumentsModalProps) {
  const [showTempDetail, setShowTempDetail] = useState<any>(null);
  const [{ category }, searchParamsSetter] = useAppSearchParams<{ search: string; category: Category; }>({ search: '', category: 'all' });

  const dispatch = useAppDispatch();
  const { templatesCurrentTab } = useAppSelector((state) => state.profile);

  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => onCloseModal()}
      title={
        <div className="flex items-center">
          {showTempDetail && (
            <div
              onClick={() => setShowTempDetail(null)}
              className="mr-3 border px-3 border-gray-200 text-customLightBlue rounded flex items-center justify-center cursor-pointer hover:bg-amber-400 hover:text-white hover:border-gray-800"
            >
              <ArrowLongLeftIcon className="h-4 mr-2" />
              <span>Back</span>
            </div>
          )}
          <Square3Stack3DIcon className="h-5 mr-2 text-customLightBlue" />
          Document Templates
        </div>
      }
      size="max-w-7xl"
    >
      <div className="w-full flex">
        <div className="w-[30%] border-r border-zinc-200 pr-4">
          <nav aria-label="Tabs">
            {documentsTemplatesTabsList.map((tab: any) => (
              <div
                key={tab.id}
                onClick={() => dispatch(setTemplatesCurrentTab(tab.id))}
                className={classNames(
                  tab.id === templatesCurrentTab
                    ? "text-customDarkBlue bg-zinc-100 border-blue-500"
                    : "border-transparent text-customLightBlue hover:border-gray-300 hover:text-gray-700",
                  "border rounded-md p-3 text-xs font-medium cursor-pointer mb-3 flex items-center"
                )}
              >
                <span>
                  <img
                    className="h-6 mr-2"
                    src={logoIcon}
                    alt="Solutions Pilot"
                  />
                </span>
                <span>{tab.name}</span>
              </div>
            ))}
          </nav>
          {templatesCurrentTab === 2 && (
            <div className="mt-3 pt-4 border-t border-zinc-200">
              <CategoriesLeftSidebar
                setSelectedCategory={(newCategory) =>
                  searchParamsSetter.set("category", newCategory)
                }
                selectedCategory={category}
              />
            </div>
          )}
        </div>

        <div className="w-full">
          {templatesCurrentTab === 2 ||
            (templatesCurrentTab === 3 && (
              <DocumentsTemplates
                isModal={true}
                tempType={`${templatesCurrentTab === 3 ? "my" : "community"}`}
                selectedCategory={category}
                onCloseModal={() => onCloseModal()}
              />
            ))}
        </div>
      </div>
    </CustomModal>
  );
}
