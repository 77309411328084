import { createSlice } from "@reduxjs/toolkit";
import {
  getTbarsList,
  createTbar,
  updateTbar,
  getTbarById,
  deleteTbar
} from "./tbarsApi";

interface initialStateType {
  error: null | string;
  message: string | null;
  tBarsList: any[];
  createTbarRes: any;
  updateTbarRes: any;
  tbarByIdRes: any;
  tbarDeleteRes: any;
  loadingTbars: boolean;
  selectedTChart: any;
}
const initialState: initialStateType = {
  error: null,
  message: null,
  tBarsList: [],
  createTbarRes: null,
  updateTbarRes: null,
  tbarByIdRes: null,
  tbarDeleteRes: null,
  loadingTbars: false,
  selectedTChart: null
};
const tBarsSlice = createSlice({
  name: "tBars",
  initialState,
  reducers: {
    setSelectedTChart: (state, action) => {
      state.selectedTChart = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get T-Bars
    builder.addCase(getTbarsList.pending, (state) => {
      state.error = null;
      state.message = null;
      state.loadingTbars = true;
    });
    builder.addCase(getTbarsList.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.tBarsList = action.payload?.data;
      state.loadingTbars = false;
    });
    builder.addCase(getTbarsList.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loadingTbars = false;
    });

    // Get T-Bar By Id
    builder.addCase(getTbarById.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(getTbarById.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.tbarByIdRes = action.payload;
    });
    builder.addCase(getTbarById.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Create T-Bars
    builder.addCase(createTbar.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(createTbar.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.createTbarRes = action.payload?.data;
    });
    builder.addCase(createTbar.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Update T-Bars
    builder.addCase(updateTbar.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(updateTbar.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.updateTbarRes = action.payload?.data;
    });
    builder.addCase(updateTbar.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });

    // Delete T-Bar
    builder.addCase(deleteTbar.pending, (state) => {
      state.error = null;
      state.message = null;
    });
    builder.addCase(deleteTbar.fulfilled, (state, action) => {
      state.error = null;
      state.message = action.payload?.message;
      state.tbarDeleteRes = action.payload?.data;
    });
    builder.addCase(deleteTbar.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectedTChart } = tBarsSlice.actions;
export default tBarsSlice.reducer;
